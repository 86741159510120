import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import AccountModel from './account';
import InstitutionModel from './institution';
import PortfolioModel from './portfolio';
import SettlementModel from './settlement';
import TeamModel from './team';
import ActionModel from './trade';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = [
  'commitment',
  'ctype',
  'currency',
  'sharing',
  'kickback',
  'amount',
  'gross',
  'net',
  'institution',
  'rebate_institution',
  'trade',
  'account',
  'portfolio',
  'team',
  'settlement',
  'notes',
  'remarks',
  'quantity',
  'quantity_after_sharing',
  'quantity_after_rebate',
  'quantity_shared',
  'quantity_rebate',
];

export default class VoucherModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr({
    defaultValue: (record: VoucherModel) =>
      isEmpty(record.get('trade.commitment')) ? today() : record.get('trade.commitment'),
  })
  declare commitment: string;

  @attr({
    defaultValue: (record: VoucherModel) => (isEmpty(record.get('trade')) ? '3RDF' : 'IPMF'),
  })
  declare ctype: string;

  @attr({
    defaultValue: (record: VoucherModel) =>
      isEmpty(record.get('trade.resmain.ccymain')) ? null : record.get('trade.resmain.ccymain'),
  })
  declare currency: string;

  @attr({
    defaultValue(record: VoucherModel): number {
      // Attempt to get the custodian:
      const institution = record.get('institution') || record.get('trade.accmain.custodian');

      // Do we have any institution?
      if (isEmpty(institution)) {
        return 1;
      }

      // Check and return:
      return isEmpty(institution.get('sharing')) ? 1 : institution.get('sharing');
    },
  })
  declare sharing: number;

  // TODO: Figure out how to pass a record to defaultValue function.
  @attr({
    defaultValue(record: VoucherModel) {
      // Attempt to get the custodian:
      const institution = record.get('institution') || record.get('trade.accmain.custodian');

      // Do we have any institution?
      if (isEmpty(institution)) {
        return 0;
      }

      // Check and return:
      return isEmpty(institution.get('kickback')) ? 0 : institution.get('kickback');
    },
  })
  declare kickback: number;

  @attr('number')
  declare amount: number;

  @attr('number')
  declare gross: number;

  @attr('number')
  declare net: number;

  @attr('number')
  declare quantity: number;

  @attr('number')
  declare quantity_after_sharing: number;

  @attr('number')
  declare quantity_after_rebate: number;

  @attr('number')
  declare quantity_shared: number;

  @attr('number')
  declare quantity_rebate: number;

  @belongsTo('institution')
  declare institution?: BelongsTo<InstitutionModel>;

  @belongsTo('institution')
  declare rebate_institution?: BelongsTo<InstitutionModel>;

  @belongsTo('trade')
  declare trade?: BelongsTo<ActionModel>;

  @belongsTo('account')
  declare account?: BelongsTo<AccountModel>;

  @belongsTo('portfolio')
  declare portfolio?: BelongsTo<PortfolioModel>;

  @belongsTo('team')
  declare team?: BelongsTo<TeamModel>;

  @belongsTo('settlement')
  declare settlement?: BelongsTo<SettlementModel>;

  @attr()
  declare notes?: string;

  @attr()
  declare remarks?: string;

  @computed('id')
  get isSettled(): boolean {
    // TODO: Find a solution this `DS.Model#belongsTo`.
    // @ts-expect-error (TS2322)
    return !isEmpty(this.belongsTo('settlement').id());
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    voucher: VoucherModel;
  }
}
