import Model, { attr } from '@ember-data/model';

export default class TradeDirectionModel extends Model {
  @attr()
  name!: string;

  @attr('number')
  sign!: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trading/direction': TradeDirectionModel;
  }
}
