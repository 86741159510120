import { inject as service } from '@ember/service';
import { CommonController } from 'cafelatte/libs/embered';
import { DayCountDefinition } from 'cafelatte/libs/headless/services/daycount';
import DecafService from 'cafelatte/services/decaf';
import { FlexTableSpec } from '../components/x/flex-table';

export default class extends CommonController {
  @service declare decaf: DecafService;

  get records(): Promise<DayCountDefinition[]> {
    return this.decaf.services.daycount.get();
  }

  spec: FlexTableSpec<DayCountDefinition> = {
    ident: 'day-count-convention-table',
    vfill: true,
    columns: [
      { key: 'name' },
      { key: 'altn', label: 'Alternative Names', value: ({ record }) => record.altn.join(', ') },
      { key: 'currencies', label: 'Default Currencies', value: ({ record }) => record.currencies.join(', ') },
    ],
  };
}
