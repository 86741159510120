import { DecafClient } from '@decafhub/decaf-client';
import Decimal from 'decimal.js';
import { Id, IdName, Lookup } from '../../commons/types';
import { SDate, SDateTime } from '../../prelude/datetime';
import { isNothing, mapMaybe, Maybe } from '../../prelude/maybe';
import { toDecimal } from '../../prelude/numeric';

/**
 * Report query type.
 */
export type PConsolidationQuery = Record<string, string>;

/**
 * Response data item type.
 */
export interface PConsolidationResponseItem {
  id: Id;
  created: SDateTime;
  creator: Maybe<Id>;
  updated: SDateTime;
  updater: Maybe<Id>;
  guid: string;
  portfolio: Id;
  account: Maybe<Id>;
  shareclass: Maybe<Id>;
  date: SDate;
  ccy: string;
  accruals: Maybe<number>;
  liabilities: Maybe<number>;
  cost: Maybe<number>;
  pnl: Maybe<number>;
  gav: Maybe<number>;
  aum: Maybe<number>;
  nav: Maybe<number>;
  shares: Maybe<number>;
  price: Maybe<number>;
  navrefccy: Maybe<number>;
}

/**
 * Treated persisted consolidations item.
 */
export interface PConsolidationItem {
  id: Id;
  created: SDateTime;
  creator: Maybe<Id>;
  updated: SDateTime;
  updater: Maybe<Id>;
  guid: string;
  portfolio: IdName;
  account: Maybe<IdName>;
  shareclass: Maybe<IdName>;
  date: SDate;
  ccy: string;
  accruals: Maybe<Decimal>;
  liabilities: Maybe<Decimal>;
  cost: Maybe<Decimal>;
  pnl: Maybe<Decimal>;
  gav: Maybe<Decimal>;
  aum: Maybe<Decimal>;
  nav: Maybe<Decimal>;
  shares: Maybe<Decimal>;
  price: Maybe<Decimal>;
  navrefccy: Maybe<Decimal>;
}

/**
 * Finds and returns the barista version resource.
 *
 * @param client barista client.
 * @returns barista version resource.
 */
export async function request(client: DecafClient, query: PConsolidationQuery): Promise<Array<PConsolidationItem>> {
  // Define request parameters:
  const params = { ...query, page_size: -1 };

  // Perform the data request:
  const response = await client.barista.get<Array<PConsolidationResponseItem>>('/pconsolidations/', { params });

  // Get the response data:
  const items = response.data;

  // Get lookup tables:
  const portfolios = await getPortfolios(client, items);
  const accounts = await getAccounts(client, items);
  const shareclasses = await getShareClasses(client, items);

  // Compile the data and return:
  // @ts-expect-error ts2322
  return items.map((x) => ({
    id: x.id,
    created: x.created,
    creator: x.creator,
    updated: x.updated,
    updater: x.updater,
    guid: x.guid,
    portfolio: portfolios[x.portfolio],
    account: x.account ? accounts[x.account] : undefined,
    shareclass: x.shareclass ? shareclasses[x.shareclass] : undefined,
    date: x.date,
    ccy: x.ccy,
    accruals: mapMaybe(toDecimal, x.accruals),
    liabilities: mapMaybe(toDecimal, x.liabilities),
    cost: mapMaybe(toDecimal, x.cost),
    pnl: mapMaybe(toDecimal, x.pnl),
    gav: mapMaybe(toDecimal, x.gav),
    aum: mapMaybe(toDecimal, x.aum),
    nav: mapMaybe(toDecimal, x.nav),
    shares: isNothing(x.shares) ? undefined : new Decimal(x.shares),
    price: isNothing(x.price) ? undefined : new Decimal(x.price),
    navrefccy: isNothing(x.navrefccy) ? undefined : new Decimal(x.navrefccy),
  }));
}

export async function getPortfolios(
  cli: DecafClient,
  xs: Array<PConsolidationResponseItem>
): Promise<Lookup<Id, IdName>> {
  const ids = xs.map((x) => x.portfolio).uniq();
  if (ids.length === 0) {
    return {};
  }
  const response = await cli.barista.get<Array<IdName>>('/portfolios/', {
    params: { page_size: -1, id__in: ids.join(',') },
  });
  return response.data.reduce((p, i) => ({ ...p, [i.id]: { id: i.id, name: i.name } }), {});
}

export async function getAccounts(
  cli: DecafClient,
  xs: Array<PConsolidationResponseItem>
): Promise<Lookup<Id, IdName>> {
  const ids = xs
    .map((x) => x.account)
    .filter((x) => x)
    .uniq();
  if (ids.length === 0) {
    return {};
  }
  const response = await cli.barista.get<Array<IdName>>('/accounts/', {
    params: { page_size: -1, id__in: ids.join(',') },
  });
  return response.data.reduce((p, i) => ({ ...p, [i.id]: { id: i.id, name: i.name } }), {});
}

export async function getShareClasses(
  cli: DecafClient,
  xs: Array<PConsolidationResponseItem>
): Promise<Lookup<Id, IdName>> {
  const ids = xs
    .map((x) => x.shareclass)
    .filter((x) => x)
    .uniq();
  if (ids.length === 0) {
    return {};
  }
  const response = await cli.barista.get<Array<IdName>>('/shareclasses/', {
    params: { page_size: -1, id__in: ids.join(',') },
  });
  return response.data.reduce((p, i) => ({ ...p, [i.id]: { id: i.id, name: i.name } }), {});
}
