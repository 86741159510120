import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'created__gte',
    'created__lte',
    'updated__gte',
    'updated__lte',
    'name__icontains',
    'domicile',
    'team',
    'riskprofile',
    'sandbox',
    'rccy',
    'manager__icontains',
    'objective__icontains',
    'isin__icontains',
    'mandate_profile__icontains',
    'advisor__icontains',
    'asset_manager__icontains',
    'marketing_agent__icontains',
    'watched',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('portfolio', cleanParams(params));
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
