import { A } from '@ember/array';
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import { CommonRoute } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonRoute {
  @service declare ajax: CLAjaxService;

  model() {
    // Initialize the return value:
    const retval = EmberObject.create({ roles: A([]), permsets: A([]) });

    // Get the data and populate return value:
    this.ajax.request('/roles/').then((roles: any) => {
      // Add roles to the return value:
      retval.get('roles').pushObjects(roles);

      // Now get permissions and mark with roles:
      this.ajax.request('/roles/permissionsets/').then((permsets: any) => {
        // Iterate over permission sets:
        permsets.forEach((set: any) => {
          // Iterate over individual action in the permission set:
          set.actions.forEach((action: any) => {
            // Define a temporary lookup:
            const allows = new Set(action.allow);

            // Define a new vector with roles marked.
            action.roles = roles.map((role: any) => allows.has(role.code));
          });
        });

        // Add the permission sets to return value:
        retval.get('permsets').pushObjects(permsets);
      });
    });

    // Done, return the data:
    return retval;
  }
}
