import TeamModel from 'cafelatte/models/team';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<TeamModel> {
  get spec(): FlexTableSpec<TeamModel> {
    return {
      ident: 'cl-team-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id', component: '@link', options: { route: 'team.details', modelPath: 'id' } },
        { key: 'name', component: '@link', options: { route: 'team.details', modelPath: 'id' } },
        { key: 'members', value: ({ record }) => record.members?.map((r) => r.name).join(', ') },
        { key: 'portfolios', value: ({ record }) => record.portfolios?.map((r) => r.name).join(', ') },
        { key: 'portfolio_count', align: 'right' },
        ...mkAuditFields<TeamModel>(this.me),
      ],
    };
  }
}
