import { Service } from '../-service';
import { DayCountDefinition, getDayCountDefinitions } from './-internal';

// Export public symbols:
export { DayCountDefinition };

/**
 * Provides the barista day count service.
 */
export class DayCountService extends Service {
  /**
   * Returns the array of [[DayCountDefinition]] instances.
   */
  get(): Promise<Array<DayCountDefinition>> {
    return getDayCountDefinitions(this.client);
  }
}
