import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ComplianceCheckModel from 'cafelatte/models/compliancecheck';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';
import saver from 'file-saver';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<ComplianceCheckModel> {
  @service declare ajax: CLAjaxService;

  get spec(): FlexTableSpec<ComplianceCheckModel> {
    return {
      ident: 'cl-compliancecheck-list-table',
      vfill: true,
      actions: [
        {
          type: 'info',
          icon: 'download',
          label: 'Download',
          action: ({ record }) => this.download(record.id, false),
        },
        {
          type: 'info',
          icon: 'download',
          label: 'Download (Only Breaches)',
          action: ({ record }) => this.download(record.id, true),
        },
      ],
      columns: [
        { key: 'id' },
        { key: 'date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        ...mkAuditFields<ComplianceCheckModel>(this.me),
      ],
    };
  }

  @action download(id: string | number, summary: boolean) {
    // Download the file and handle the success/error states:
    this.ajax
      .request(`/compliancechecks/${id}/pdf/?summary=${summary ? 'True' : 'False'}`, {
        traditional: true,
        dataType: 'binary',
        xhrFields: { responseType: 'blob' },
      })
      .then((data) => saver.saveAs(data, 'compliancecheck.pdf'))
      .catch((error) => {
        console.error(error);
        this.flashMessages.danger('Error while downloading file. Please contact your system administrator.');
      });
  }
}
