import Model, { attr } from '@ember-data/model';

export default class CountryModel extends Model {
  @attr()
  declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    country: CountryModel;
  }
}
