import { action, computed, notifyPropertyChange } from '@ember/object';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import AccountModel from 'cafelatte/models/account';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends ControllerWithCurrentRecord<AccountModel> {
  createNewRecord(): AccountModel {
    return this.store.createRecord('account', { portfolio: this.model });
  }

  @computed('model.id')
  get records() {
    return this.store
      .query('account', { portfolio: this.model.id, page_size: -1 })
      .then((x) => x.sortBy('atype.content.isEmpty', 'name'));
  }

  get operations(): FlexTableRowAction<AccountModel>[] {
    return this.me.external
      ? []
      : mkCommonRowActions([
          { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
          { type: 'details', action: ({ record }) => this.router.transitionTo('account.details', record.id) },
        ]);
  }

  @action doReset(): void {
    this.doResetCurrentRecord();
    notifyPropertyChange(this, 'records');
  }

  @action onError(_message: string, error: any): void {
    console.error(error);
  }
}
