import { DecafClient } from '@decafhub/decaf-client';
import { AccountResource } from 'cafelatte/libs/headless/commons/resources/account-resource';
import { ArtifactResource } from 'cafelatte/libs/headless/commons/resources/artifact-resource';
import { parseDecimal } from 'cafelatte/libs/headless/prelude/numeric';
import Decimal from 'decimal.js';
import { DateType } from '../../../commons/date-type';
import { Currency, Guid, Id } from '../../../commons/types';
import { SDate, SDateTime } from '../../../prelude/datetime';
import { mapMaybe, Maybe } from '../../../prelude/maybe';
import { ConsolidationReportValuation } from '../commons/-shared';
import { RawAccrual } from '../commons/accrual';
import { RawHolding } from '../commons/holding';
import { buildAccountEntityLookupTable, buildArtifactEntityLookupTable } from '../commons/lookup-tables';
import { Positions } from '../commons/positions';
import { computePositions, computeValuation } from '../commons/top-level';
import {
  ConsolidationReportAccounts,
  ConsolidationReportContainer,
  ConsolidationReportFXRates,
  getAccountIdsOfInterest,
  getArtifactIdsOfInterest,
} from '../generic-report/-full';

/**
 * Fund report query type.
 */
export interface FundReportQuery {
  date: SDate;
  datetype: DateType;
  portfolio: Id;
  currency: Currency;
}

/**
 * Raw fund report share class entity information.
 */
export interface RawFundReportShareClass {
  id: Id;
  created: SDateTime;
  creator: Id;
  updated: SDateTime;
  updater: Id;
  portfolio: Id;
  name: string;
  currency: Currency;
  isin: Maybe<string>;
  bbgticker: Maybe<string>;
  liquidity: Maybe<string>;
  jurisdiction: Maybe<string>;
  administrator: Maybe<string>;
  mininvestment: Maybe<number>;
  subredperiod: Maybe<string>;
  freqmngt: Maybe<number>;
  freqperf: Maybe<number>;
  benchmark: Maybe<Id>;
  description: Maybe<string>;
  feeschedules: Array<any>;
  subscriptions: Array<Id>;
  outstanding: Maybe<number>;
}

/**
 * Fund report share class entity information.
 */
export interface FundReportShareClass {
  id: Id;
  created: SDateTime;
  creator: Id;
  updated: SDateTime;
  updater: Id;
  portfolio: Id;
  name: string;
  currency: Currency;
  isin: Maybe<string>;
  bbgticker: Maybe<string>;
  liquidity: Maybe<string>;
  jurisdiction: Maybe<string>;
  administrator: Maybe<string>;
  mininvestment: Maybe<number>;
  subredperiod: Maybe<string>;
  freqmngt: Maybe<number>;
  freqperf: Maybe<number>;
  benchmark: Maybe<Id>;
  description: Maybe<string>;
  feeschedules: Array<any>;
  subscriptions: Array<Id>;
  outstanding: Maybe<Decimal>;
}

export function buildFundReportShareClass(data: RawFundReportShareClass): FundReportShareClass {
  return {
    id: data.id,
    created: data.created,
    creator: data.creator,
    updated: data.updated,
    updater: data.updater,
    portfolio: data.portfolio,
    name: data.name,
    currency: data.currency,
    isin: data.isin,
    bbgticker: data.bbgticker,
    liquidity: data.liquidity,
    jurisdiction: data.jurisdiction,
    administrator: data.administrator,
    mininvestment: data.mininvestment,
    subredperiod: data.subredperiod,
    freqmngt: data.freqmngt,
    freqperf: data.freqperf,
    benchmark: data.benchmark,
    description: data.description,
    feeschedules: data.feeschedules,
    subscriptions: data.subscriptions,
    outstanding: mapMaybe(parseDecimal, data.outstanding),
  };
}

/**
 * Raw fund report share class external valuation information.
 */
export interface RawFundReportExternalValuation {
  created: SDateTime;
  creator: Maybe<Id>;
  updated: SDateTime;
  updater: Maybe<Id>;
  guid: Guid;
  portfolio: Id;
  shareclass: Id;
  date: SDate;
  shares: Maybe<number>;
  price: Maybe<number>;
  nav: Maybe<number>;
  hedgepnl: Maybe<number>;
  feemngt: Maybe<number>;
  feeperf: Maybe<number>;
  otheraccrued: Maybe<number>;
  totalaccrued: Maybe<number>;
  subred: Maybe<number>;
  perfdaily: Maybe<number>;
  perfweekly: Maybe<number>;
  perfmonthly: Maybe<number>;
  perfytd: Maybe<number>;
  perfstart: Maybe<number>;
  coefficient: Maybe<number>;
}

/**
 * Fund report share class external valuation information.
 */
export interface FundReportExternalValuation {
  created: SDateTime;
  creator: Maybe<Id>;
  updated: SDateTime;
  updater: Maybe<Id>;
  guid: Guid;
  portfolio: Id;
  shareclass: Id;
  date: SDate;
  shares: Maybe<Decimal>;
  price: Maybe<Decimal>;
  nav: Maybe<Decimal>;
  hedgepnl: Maybe<Decimal>;
  feemngt: Maybe<Decimal>;
  feeperf: Maybe<Decimal>;
  otheraccrued: Maybe<Decimal>;
  totalaccrued: Maybe<Decimal>;
  subred: Maybe<Decimal>;
  perfdaily: Maybe<Decimal>;
  perfweekly: Maybe<Decimal>;
  perfmonthly: Maybe<Decimal>;
  perfytd: Maybe<Decimal>;
  perfstart: Maybe<Decimal>;
  coefficient: Maybe<Decimal>;
}

export function buildFundReportExternalValuation(data: RawFundReportExternalValuation): FundReportExternalValuation {
  return {
    created: data.created,
    creator: data.creator,
    updated: data.updated,
    updater: data.updater,
    guid: data.guid,
    portfolio: data.portfolio,
    shareclass: data.shareclass,
    date: data.date,
    shares: mapMaybe(parseDecimal, data.shares),
    price: mapMaybe(parseDecimal, data.price),
    nav: mapMaybe(parseDecimal, data.nav),
    hedgepnl: mapMaybe(parseDecimal, data.hedgepnl),
    feemngt: mapMaybe(parseDecimal, data.feemngt),
    feeperf: mapMaybe(parseDecimal, data.feeperf),
    otheraccrued: mapMaybe(parseDecimal, data.otheraccrued),
    totalaccrued: mapMaybe(parseDecimal, data.totalaccrued),
    subred: mapMaybe(parseDecimal, data.subred),
    perfdaily: mapMaybe(parseDecimal, data.perfdaily),
    perfweekly: mapMaybe(parseDecimal, data.perfweekly),
    perfmonthly: mapMaybe(parseDecimal, data.perfmonthly),
    perfytd: mapMaybe(parseDecimal, data.perfytd),
    perfstart: mapMaybe(parseDecimal, data.perfstart),
    coefficient: mapMaybe(parseDecimal, data.coefficient),
  };
}

/**
 * Raw fund report share class information.
 */
export interface RawShareClassInfo {
  shareclass: RawFundReportShareClass;
  external: Maybe<RawFundReportExternalValuation>;
  nav: Maybe<number>;
  nav_adjusted: Maybe<number>;
  nav_adjusted_total: Maybe<number>;
  coefficient: Maybe<number>;
  gav_refccy: Maybe<number>;
  gav_clsccy: Maybe<number>;
  sharecount_prev: Maybe<number>;
  sharecount_curr: Maybe<number>;
  sharecount_diff: Maybe<number>;
  px_refccy: Maybe<number>;
  px_clsccy: Maybe<number>;
  ytdext: Maybe<number>;
  ytdint: Maybe<number>;
}

/**
 * Fund report share class information.
 */
export interface FundReportShareClassInfo {
  shareclass: FundReportShareClass;
  external: Maybe<FundReportExternalValuation>;
  nav: Maybe<Decimal>;
  navAdjusted: Maybe<Decimal>;
  navAdjustedTotal: Maybe<Decimal>;
  coefficient: Maybe<Decimal>;
  gavRefCcy: Maybe<Decimal>;
  gavClsCcy: Maybe<Decimal>;
  shareCountPrev: Maybe<Decimal>;
  shareCountCurr: Maybe<Decimal>;
  shareCountDiff: Maybe<Decimal>;
  pxRefCcy: Maybe<Decimal>;
  pxClsCcy: Maybe<Decimal>;
  ytdExternal: Maybe<Decimal>;
  ytdInternal: Maybe<Decimal>;
}

export function buildShareClassInfo(data: RawShareClassInfo): FundReportShareClassInfo {
  return {
    shareclass: buildFundReportShareClass(data.shareclass),
    external: mapMaybe(buildFundReportExternalValuation, data.external),
    nav: mapMaybe(parseDecimal, data.nav),
    navAdjusted: mapMaybe(parseDecimal, data.nav_adjusted),
    navAdjustedTotal: mapMaybe(parseDecimal, data.nav_adjusted_total),
    coefficient: mapMaybe(parseDecimal, data.coefficient),
    gavRefCcy: mapMaybe(parseDecimal, data.gav_refccy),
    gavClsCcy: mapMaybe(parseDecimal, data.gav_clsccy),
    shareCountPrev: mapMaybe(parseDecimal, data.sharecount_prev),
    shareCountCurr: mapMaybe(parseDecimal, data.sharecount_curr),
    shareCountDiff: mapMaybe(parseDecimal, data.sharecount_diff),
    pxRefCcy: mapMaybe(parseDecimal, data.px_refccy),
    pxClsCcy: mapMaybe(parseDecimal, data.px_clsccy),
    ytdExternal: mapMaybe(parseDecimal, data.ytdext),
    ytdInternal: mapMaybe(parseDecimal, data.ytdint),
  };
}

/**
 * Consolidation report response data type.
 */
export interface FundReportResponseData {
  reported: SDateTime;
  asof: SDate;
  type: DateType;
  ccy: Currency;
  portfolio: ConsolidationReportContainer;
  scvals: Array<RawShareClassInfo>;
  accounts: ConsolidationReportAccounts;
  holdings: Array<RawHolding>;
  accruals: Array<RawAccrual>;
  investment: Maybe<number>;
  valuation_net: Maybe<number>;
  valuation_abs: Maybe<number>;
  accrued: Maybe<number>;
  liabilities: Maybe<number>;
  gav: Maybe<number>;
  nav: Maybe<number>;
  aum: Maybe<number>;
  pnl: Maybe<number>;
  pnl_to_investment: Maybe<number>;
  fxrates: ConsolidationReportFXRates;
  subscriptions: Maybe<number>;
}

/**
 * Fund report data type.
 */
export interface FundReportData {
  timestamp: SDateTime;
  date: SDate;
  type: DateType;
  currency: Currency;
  portfolio: ConsolidationReportContainer;
  custodyAccounts: Array<AccountResource>;
  analyticalAccount: Array<AccountResource>;
  positions: Positions;
  valuation: ConsolidationReportValuation;
  fxrates: ConsolidationReportFXRates;
  subscriptions: Maybe<Decimal>;
  scinfos: Array<FundReportShareClassInfo>;
}

/**
 * Compiles the library representation of report query into remote endpoint query parameters.
 *
 * @param q Query.
 * @returns Remote endpoint query parameters.
 */
export function query2params(q: FundReportQuery): { [key: string]: string } {
  return {
    portfolio: `${q.portfolio}`,
    ccy: q.currency,
    date: q.date,
    type: q.datetype,
  };
}

/**
 * Converts fund report response data to fund report data.
 *
 * @param data Response data value.
 * @returns Fund report value.
 */
export function compileReportData(
  accounts: Record<Id, AccountResource>,
  artifacts: Record<Id, ArtifactResource>,
  data: FundReportResponseData
): FundReportData {
  //  Get total valuation figures for the report:
  const valuation = computeValuation(data);

  // Compile and return:
  return {
    timestamp: data.reported,
    date: data.asof,
    type: data.type,
    currency: data.ccy,
    portfolio: data.portfolio,
    // @ts-expect-error ts2322
    custodyAccounts: data.accounts.custody.map((x) => accounts[x.id]),
    // @ts-expect-error ts2322
    analyticalAccount: data.accounts.journal.map((x) => accounts[x.id]),
    positions: computePositions(data, accounts, artifacts, valuation),
    valuation,
    fxrates: data.fxrates,
    subscriptions: mapMaybe(parseDecimal, data.subscriptions),
    scinfos: data.scvals.map(buildShareClassInfo),
  };
}

/**
 * Retrieves and compiles fund report.
 *
 * @param client barista client.
 * @returns Fund report data.
 */
export async function retrieveFundReport(client: DecafClient, query: FundReportQuery): Promise<FundReportData> {
  // Get remote data:
  const { data } = await client.barista.get<FundReportResponseData>('/portfolioreport/', {
    params: query2params(query),
  });

  // Get holdings and accruals:
  const { holdings, accruals } = data;

  // Attempt to get accounts lookup table:
  const lookupAccounts = await buildAccountEntityLookupTable(client, getAccountIdsOfInterest(holdings, accruals));

  // Attempt to get artifacts lookup table:
  const lookupArtifacts = await buildArtifactEntityLookupTable(client, getArtifactIdsOfInterest(holdings, accruals));

  // Compile the data and return:
  return compileReportData(lookupAccounts, lookupArtifacts, data);
}
