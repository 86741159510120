import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import {
  isSimpleChoice,
  mkSimpleChoice,
  SimpleChoice,
  SimpleChoices,
  SimpleChoiceValueType,
} from 'cafelatte/libs/headless/prelude/simple-choice';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

interface ICLSelectControl<T extends SimpleChoiceValueType> {
  choices: SimpleChoices<T>;
  keyValue: string;
  keyLabel: string;
}

@tagName('')
export default class CLSelectControl<T extends SimpleChoiceValueType> extends Control implements ICLSelectControl<T> {
  layout = layout;

  onChange!: Function;

  @computed('options.value')
  get keyValue(): string {
    // @ts-expect-error
    return this.options?.value || 'value';
  }

  @computed('options.label')
  get keyLabel(): string {
    // @ts-expect-error
    return this.options?.label || 'label';
  }

  @computed('options.choices.[]')
  get choices(): SimpleChoices<T> {
    // Get items first:
    // @ts-expect-error
    const items = this.options?.choices || [];

    // Map over items and convert each to a simple choice:
    return items.map((x: any) => {
      if (isSimpleChoice(x)) {
        return x;
      } else if (typeof x === 'string' || typeof x === 'number' || typeof x === 'symbol') {
        return mkSimpleChoice(x);
      } else {
        throw new Error(`Programming error. "${x}" is not a valid simple choice type but a "${typeof x}"`);
      }
    });
  }

  @computed('options.multiple')
  get isMultiple() {
    // @ts-expect-error
    return this.options?.multiple || false;
  }

  @computed('choices.[]', 'keyValue', 'value')
  get selected() {
    if (this.isMultiple) {
      // @ts-expect-error
      return this.value.map((y: any) => this.choices.find((x: SimpleChoice<any>) => x[this.keyValue] == y));
    } else {
      // @ts-expect-error
      return this.choices.find((x: SimpleChoice<any>) => x[this.keyValue] == this.value);
    }
  }

  @action onValueChanged(value?: SimpleChoice<string> | SimpleChoice<string>[]) {
    if (this.isMultiple) {
      // @ts-expect-error
      this.onChange(value ? value.map((x: SimpleChoice<string>) => x.value) : undefined);
    } else {
      // @ts-expect-error
      this.onChange(value ? value.value : undefined);
    }
  }
}
