import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

interface CLRemoteSelectControlOptions {
  modelName?: string;
  modelLabel?: string;
  modelById?: boolean;
  modelIdProperty?: string;
  searchKey?: string;
  multiple?: boolean;
  filter?: any;
  extraPayload?: any;
  onCreate?: any;
  itemComponent?: any;
}

@tagName('')
export default class CLRemoteSelectControl extends Control {
  /**
   * Fix the layout.
   */
  layout = layout;

  /**
   * Options passed to this component on the call-site.
   */
  @tracked options!: Maybe<CLRemoteSelectControlOptions>;

  /**
   * Options which can be overridden by classes inheriting this control class.
   */
  defaultOptions: CLRemoteSelectControlOptions = {};

  /**
   * Combined, effective options.
   */
  @computed('options.*')
  get controlOptions(): CLRemoteSelectControlOptions {
    return { ...this.options, ...this.defaultOptions };
  }
}
