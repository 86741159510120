import { Service } from '../-service';
import { PreTradeSimulationQuery, PreTradeSimulationResult, simulatePreTrade } from './-pretrade';
import { SimpleSimulationQuery, SimpleSimulationResult, simulateSimple } from './-simple';

// Export public symbols:
export { PreTradeSimulationQuery, PreTradeSimulationResult };

/**
 * Provides the barista simulation service.
 */
export class SimulationService extends Service {
  /**
   * Attempts to run a pre-trade simulation.
   *
   * @param query Pre-trade simulation query.
   * @returns Pre-trade simulation result data.
   */
  preTrade(query: PreTradeSimulationQuery): Promise<PreTradeSimulationResult> {
    return simulatePreTrade(this.client, query);
  }

  /**
   * Attempts to run a simple simulation.
   *
   * @param query Simple simulation query.
   * @returns Simple simulation result data.
   */
  simple(query: SimpleSimulationQuery): Promise<SimpleSimulationResult> {
    return simulateSimple(this.client, query);
  }
}
