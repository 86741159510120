import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { colorClass } from 'cafelatte/helpers/color-class';
import { formatNumber } from 'cafelatte/helpers/format-number';
import { buildIds, CommonController, parseIds } from 'cafelatte/libs/embered';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { getDate, getPreviousWorkday, today } from 'cafelatte/libs/headless/prelude/datetime';
import { saveBlob } from 'cafelatte/libs/headless/prelude/downloading';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import {
  Performance,
  PerformanceSummary,
  PerformanceSummaryItem,
  ReturnStatisticsMetric,
  TimeSeries,
  TimeSeriesFrequency,
} from 'cafelatte/libs/headless/services/performance';
import { FlexTableColumnSpec, FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
import PapaParse from 'papaparse';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

const Metric: Record<ReturnStatisticsMetric, string> = {
  return: 'Returns',
  stddev: 'Vol (p.a.)',
  sharpe: 'Sharpe',
  maxddown: 'Maximum Drawdown',
};

export default class PerformanceController extends CommonController {
  @service declare decaf: DecafService;

  queryParams = [
    'portfolios',
    'shareclasses',
    'teams',
    'portfoliogroups',
    'benchmarks',
    'artifacts',
    'frequency',
    'start',
    'end',
    'overlayextval',
    'accounts',
  ];

  @tracked frequency: TimeSeriesFrequency = 'daily';
  @tracked start = getPreviousWorkday(getDate('this-year-start'));
  @tracked end = today();
  @tracked overlayextval = true;
  @tracked artifacts = '';
  @tracked benchmarks = '';
  @tracked portfolios = '';
  @tracked shareclasses = '';
  @tracked teams = '';
  @tracked portfoliogroups = '';
  @tracked accounts = '';

  get artifactInstances(): Id[] {
    return parseIds(this, 'artifacts');
  }

  set artifactInstances(x: Maybe<Id[]>) {
    buildIds(this, 'artifacts', x);
  }

  get benchmarkInstances(): Id[] {
    return parseIds(this, 'benchmarks');
  }

  set benchmarkInstances(x: Maybe<Id[]>) {
    buildIds(this, 'benchmarks', x);
  }

  get portfolioInstances(): Id[] {
    return parseIds(this, 'portfolios');
  }

  set portfolioInstances(x: Maybe<Id[]>) {
    buildIds(this, 'portfolios', x);
  }

  get shareclassInstances(): Id[] {
    return parseIds(this, 'shareclasses');
  }

  set shareclassInstances(x: Maybe<Id[]>) {
    buildIds(this, 'shareclasses', x);
  }

  get teamInstances(): Id[] {
    return parseIds(this, 'teams');
  }

  set teamInstances(x: Maybe<Id[]>) {
    buildIds(this, 'teams', x);
  }

  get portfoliogroupInstances(): Id[] {
    return parseIds(this, 'portfoliogroups');
  }

  set portfoliogroupInstances(x: Maybe<Id[]>) {
    buildIds(this, 'portfoliogroups', x);
  }

  get accountInstances(): Id[] {
    return parseIds(this, 'accounts');
  }

  set accountInstances(x: Maybe<Id[]>) {
    buildIds(this, 'accounts', x);
  }

  @computed(
    'teams',
    'portfoliogroups',
    'portfolios',
    'shareclasses',
    'benchmarks',
    'artifacts',
    'frequency',
    'start',
    'end',
    'overlayextval',
    'accounts'
  )
  get report() {
    return DS.PromiseObject.create({
      // @ts-expect-error
      promise: this.decaf.services.performance.get({
        start: this.start,
        end: this.end,
        frequency: this.frequency,
        overlayextval: this.overlayextval,
        artifacts: this.artifactInstances,
        benchmarks: this.benchmarkInstances,
        portfolios: this.portfolioInstances,
        shareclasses: this.shareclassInstances,
        teams: this.teamInstances,
        portfoliogroups: this.portfoliogroupInstances,
        accounts: this.accountInstances,
      }),
    });
  }

  @computed('report.content')
  get spec(): FlexTableSpec<PerformanceSummaryItem> {
    const report = this.report.content as Maybe<Performance>;
    const periods = isNothing(report) ? [] : report.summary.periods;

    return {
      ident: 'cl-performance-report',
      columns: [
        { key: 'symbol' },
        { key: 'metric', value: ({ record }) => Metric[record.metric] },
        ...periods.map(
          (x) =>
            ({
              key: x,
              align: 'right',
              value: ({ record }) => formatNumber([record.values[x], record.metric == 'sharpe' ? '0,0.00' : '0,0.00%']),
              dataValue: ({ record }) => record.values[x],
              classes: [({ record }) => [colorClass(record.values[x], undefined, undefined)]],
            } as FlexTableColumnSpec<PerformanceSummaryItem>)
        ),
      ],
    };
  }

  @computed('report.content')
  get plotDataIndexed() {
    // Get the data:
    const content = this.report.content as Maybe<Performance>;

    // If no content, return:
    if (isNothing(content)) {
      return undefined;
    }

    // Get the index:
    const index = content.result.indexed.index;

    // Define traces:
    const traces: any = [];

    // Iterate over data:
    for (let i = 0; i < content.result.indexed.columns.length; i++) {
      // Create the trace object:
      const trace = { name: content.result.indexed.columns[i], type: 'scatter', x: index, y: [] as number[] };

      // Iterate over data:
      for (let j = 0; j < content.result.indexed.data.length; j++) {
        // @ts-expect-error ts2532
        trace.y.pushObject(content.result.indexed.data[j][i]);
      }

      // Add to the traces:
      traces.pushObject(trace);
    }

    // Done, return:
    return traces;
  }

  @computed('report.content')
  get plotDataReturns() {
    // Get the data:
    const content = this.report.content as Maybe<Performance>;

    // If no content, return:
    if (isNothing(content)) {
      return undefined;
    }

    // Get the index:
    const index = content.result.returns.index;

    // Define traces:
    const traces: any = [];

    // Iterate over data:
    for (let i = 0; i < content.result.returns.columns.length; i++) {
      // Create the trace object:
      const trace = { name: content.result.returns.columns[i], type: 'bar', x: index, y: [] as number[] };

      // Iterate over data:
      for (let j = 0; j < content.result.returns.data.length; j++) {
        // @ts-expect-error ts2532
        trace.y.pushObject(content.result.returns.data[j][i]);
      }

      // Add to the traces:
      traces.pushObject(trace);
    }

    // Done, return:
    return traces;
  }

  plotLayoutIndexed = {
    title: 'Performance Comparison',
    xaxis: { title: 'Timeline' },
    yaxis: { title: 'Performance Index' },
    margin: { b: 100 },
  };

  plotLayoutReturns = {
    title: 'Period Returns Comparison',
    xaxis: { title: 'Timeline' },
    yaxis: { title: 'Period Returns' },
    margin: { b: 100 },
  };

  @action download(what: 'levels' | 'returns' | 'indexed' | 'statistics') {
    const performance = this.report.content as any as Performance;
    if (what === 'statistics') {
      this.downloadCsv(this.summaryToTableData(performance.summary), `${what}.csv`);
    } else {
      this.downloadCsv(this.timeSeriesToTableData(performance.result[what]), `${what}.csv`);
    }
  }

  timeSeriesToTableData(x: TimeSeries): string[][] {
    const header = ['Date', ...x.columns];
    const rows = x.index.map((date, idx) => [date, ...(x.data[idx] || []).map((x) => `${x || ''}`)]);
    return [header, ...rows];
  }

  summaryToTableData(x: PerformanceSummary): string[][] {
    const header = ['Symbol', 'Metric', ...x.periods];
    const rows = x.records.map((r) => [r.symbol, r.metric, ...x.periods.map((p) => `${r.values[p] || ''}`)]);
    return [header, ...rows];
  }

  downloadCsv(tabledata: string[][], filename: string): void {
    // Convert to CSV:
    const data = PapaParse.unparse(tabledata);

    // Create a blob of data:
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

    // Save it, nicely:
    saveBlob(blob, filename);
  }
}
