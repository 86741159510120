import Store from '@ember-data/store';
import { action, computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import TradeOrderModel from 'cafelatte/models/tradeorder';
import TradingService from 'cafelatte/services/trading';
import { RecordFormArgs } from '../../../commons/record/form';

export default class extends Component<RecordFormArgs<TradeOrderModel>> {
  @service declare flashMessages: any;
  @service declare store: Store;
  @service declare trading: TradingService;

  @alias('args.record.allocationCount')
  allocationCount!: any;

  @computed('args.record.{allocationCount,allocations.firstObject}')
  get singleAllocation() {
    return this.args.record?.allocationCount == 1 ? this.args.record?.get('allocations.firstObject') : null;
  }

  @computed('singleAllocation')
  get quickAllocation() {
    return this.singleAllocation;
  }

  @alias('args.record.ctype.id')
  tradeTypeId: any;

  get tradeTypeFilter() {
    return this.trading.tradeTypeKeys.join(',');
  }

  @computed('tradeTypeId')
  get tradeTypeSpec(): any {
    return isEmpty(this.tradeTypeId) ? [[], []] : this.trading.getTradeType(this.tradeTypeId);
  }

  @computed('tradeTypeSpec')
  get mainInstTypeOfInterest() {
    return this.tradeTypeSpec[0];
  }

  @computed('tradeTypeSpec')
  get altnInstTypeOfInterest() {
    return this.tradeTypeSpec[1];
  }

  @computed('mainInstTypeOfInterest.[]')
  get mainResTypeFilter() {
    return this.mainInstTypeOfInterest.join(',');
  }

  @computed('altnInstTypeOfInterest.[]')
  get altnResTypeFilter() {
    return this.altnInstTypeOfInterest.join(',');
  }

  @action mainInstrumentChanged(instrument: any) {
    // Check:
    if (!this.trading.tradeTypes.has(parseInt(this.tradeTypeId))) {
      console.error(`Unknown trade type: ${this.tradeTypeId}`);
      return;
    }

    // Set the main instrument:
    // @ts-expect-error
    set(this, 'args.record.resmain', instrument);

    // Attempt to find the currency and update the alternative:
    this.store.query('resource', { symbol: instrument.get('ccymain') }).then((instr) => {
      if (!isEmpty(instr)) {
        // @ts-expect-error
        set(this, 'args.record.resaltn', instr.firstObject);
      }
    });
  }

  @action onFormSave(record: TradeOrderModel): void {
    if (this.quickAllocation) {
      this.quickAllocation.save().then(
        () => {
          // Reload the record:
          record.reload();

          // Inform the user:
          this.flashMessages.success('Quick allocation successfully saved.');

          // Propagate:
          this.args.onSave?.(record);
        },
        (err: any) => {
          // Reload the record:
          record.reload();

          // Inform the user:
          this.flashMessages.danger('Error while saving the quick allocation record.');

          // Propagate:
          console.error(err);
        }
      );
    } else {
      // Reload the record:
      record.reload();

      // Propagate:
      this.args.onSave?.(record);
    }
  }
}
