import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'name__icontains',
    'symbol__icontains',
    'ctype__in',
    'stype__in',
    'cflag',
    'cflag__isnull',
    'issued__gte',
    'issued__lte',
    'launch__gte',
    'launch__lte',
    'ceased__gte',
    'ceased__lte',
    'expiry__gte',
    'expiry__lte',
    'underlying',
    'mic__icontains',
    'ticker__icontains',
    'isin__icontains',
    'country__in',
    'sector__in',
    'issuer__in',
    'ccymain',
    'ccyaltn',
    'tags',
    'riskprofile',
    'riskprofile__isnull',
    'assetclass',
    'assetclass__isnull',
    'watched',
    'attributes',
    'creator',
    'updater',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('resource', cleanParams({ ...params, attributes: JSON.stringify(params.attributes) }));
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
