import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import CLAjaxService from 'cafelatte/services/ajax';
import DecafService from 'cafelatte/services/decaf';
import saver from 'file-saver';
import { DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = [
  'name',
  'external',
  'ctype',
  'category',
  'group',
  'extref',
  'date',
  'time',
  'summary',
  'mimetype',
  'filename',
];

export default class DocumentModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @service declare ajax: CLAjaxService;
  @service declare flashMessages: any;
  @service declare decaf: DecafService;

  @attr()
  declare name?: string;

  @attr('boolean', { defaultValue: false })
  declare external: boolean;

  @attr()
  declare ctype?: string;

  @attr()
  declare category?: string;

  @attr()
  declare group?: string;

  @attr()
  declare extref?: string;

  @attr()
  declare date?: string;

  @attr()
  declare time?: string;

  @attr()
  declare summary?: string;

  @attr()
  declare content?: string;

  @attr()
  declare mimetype?: string;

  @attr()
  declare filename?: string;

  download(): void {
    if (!this.filename) {
      this.flashMessages.danger('This document has no file attachment.');
      return;
    }

    this.decaf.client.barista
      .get(`/documents/${this.id}/download/`, { responseType: 'arraybuffer' })
      .then((response: any) => saver.saveAs(new Blob([response.data]), this.filename))
      .catch((error) => {
        console.error(error);
        this.flashMessages.danger('Error while attempting to download file.');
      });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    document: DocumentModel;
  }
}
