import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @tracked email = '';

  @action requestPasswordReset() {
    // Get the email:
    const email = this.email;

    // Cleanup emails:
    this.email = '';

    // Request the password reset email and handle the response:
    this.ajax
      .request('/users/passwordresetrequest/', {
        method: 'POST',
        dataType: 'json',
        data: {
          email,
          appid: 'cafelatte',
        },
      })
      .then(() => {
        // Inform the user that the password reset request was
        // successful and she will soon receive an email:
        this.flashMessages.success('You will receive an email shortly.');

        // Redirect to the login route for convenience:
        this.router.transitionTo('auth.login');
      })
      .catch((errors) => {
        // Log errors to the console for debugging:
        console.error(errors);

        // Inform the user that there was a problem:
        this.flashMessages.danger('An error occured. Try again...');
      });
  }
}
