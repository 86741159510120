import Model, { attr, belongsTo } from '@ember-data/model';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['name', 'description'];

export default class AnalyticalTypeModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @attr()
  declare kind?: string;

  @belongsTo('analyticaltype', { inverse: null })
  declare contra?: BelongsTo<AnalyticalTypeModel>;

  @attr()
  declare description?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    analyticaltype: AnalyticalTypeModel;
  }
}
