import Model, { attr } from '@ember-data/model';
import { DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['symbol'];

export default class OHLCSeriesModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare symbol?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    ohlc: OHLCSeriesModel;
  }
}
