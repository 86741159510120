import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import AssetClassModel from 'cafelatte/models/assetclass';
import { RecordFormArgs } from '../../commons/record/form';

export default class extends Component<RecordFormArgs<AssetClassModel>> {
  @service declare flashMessages: any;

  @action onParentChanged(parent: AssetClassModel): void {
    // Guard against self-reference:
    if (parent.id === this.args.record.id) {
      this.flashMessages.danger('Can not set the parent to self.');
      return;
    }

    // @ts-expect-error
    set(this, 'args.record.parent', parent);
  }
}
