import { DecafClient } from '@decafhub/decaf-client';
import { Id } from '../../commons/types';
import { SDate } from '../../prelude/datetime';

/**
 * Encoding of password change request.
 */
export interface PasswordChangeForm {
  password1: string;
  password2: string;
}

/**
 * Encoding of password change response data.
 */
export interface PasswordChangeResponse {
  status: string;
}

/**
 * Validates the password change form.
 *
 * @param form Form to validate.
 * @returns A promise of the same form input.
 */
export function validatePasswordChangeForm(form: PasswordChangeForm): Promise<PasswordChangeForm> {
  return new Promise((resolve, reject) => {
    if (form.password1 != form.password2) {
      reject('Passwords do not match.');
    } else if ((form.password1?.length || 0) < 8) {
      reject('Password length should be at least 8.');
    }
    resolve(form);
  });
}

/**
 * Attempts to change the password of the given principal.
 *
 * @param client Barista client.
 * @param id Principal ID.
 * @param form Password change form.
 * @returns A promise of status.
 */
export function changePassword(client: DecafClient, id: Id, form: PasswordChangeForm): Promise<{ status: string }> {
  return validatePasswordChangeForm(form).then((x) =>
    client.barista.post<{ status: string }>(`/users/${id}/password/`, x).then((r) => r.data)
  );
}

/**
 * API token frequency response item encoding.
 */
export interface APITokenFrequencyResponseItem {
  principal: Id;
  principal__guid: string;
  principal__username: string;
  date: SDate;
  count: number;
}

/**
 * API token frequency response encoding.
 */
export type APITokenFrequencyResponse = Array<APITokenFrequencyResponseItem>;

/**
 * API token frequency item encoding.
 */
export interface APITokenFrequencyItem {
  principal: Id;
  principal__guid: string;
  principal__username: string;
  date: SDate;
  count: number;
}

/**
 * API token frequency response encoding.
 */
export type APITokenFrequency = Array<APITokenFrequencyItem>;

/**
 * Retrieves API token frequency
 *
 * @param client Barista client.
 * @returns A promise of status.
 */
export function getAPITokenFrequency(client: DecafClient, params?: Record<string, string>): Promise<APITokenFrequency> {
  return client.barista.get<APITokenFrequencyResponse>('/apitokens/frequency/', { params }).then((x) => x.data);
}
