import Model, { attr } from '@ember-data/model';
import { DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['code', 'name', 'desc'];

export default class ArtifactAttributeModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare code?: string;

  @attr()
  declare name?: string;

  @attr()
  declare desc?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    artifactattribute: ArtifactAttributeModel;
  }
}
