import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
import TradingService from 'cafelatte/services/trading';

export default class extends CommonController {
  @service declare trading: TradingService;

  @tracked currentTradeOrder: any = undefined;

  reloadModel() {
    this.model.reload();
    this.model.notifyPropertyChange('id');
    this.model.notifyPropertyChange('orders');
  }

  get operations() {
    // eslint-disable-next-line ember/no-side-effects
    return mkCommonRowActions([{ type: 'link', action: ({ record }) => (this.currentTradeOrder = record) }]);
  }

  @action save(instance: any) {
    this.reloadModel();
    this.router.transitionTo('trading.tradeordergroup.details', instance.get('id'));
  }

  @action createNewOrder(group: any) {
    this.trading.createTradeOrderForGroup(group).then((x) => (this.currentTradeOrder = x));
  }

  @action orderSaved() {
    this.save(this.model);
  }

  @action orderCancelled() {
    this.currentTradeOrder = undefined;
    this.save(this.model);
  }
}
