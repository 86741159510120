import Model from '@ember-data/model';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { RecordFormComponent } from '.';

export default class ModelRecordFormComponent<T extends Model> extends RecordFormComponent<T> {
  /**
   * Injects user notification service.
   */
  @service declare flashMessages: any;

  /**
   * Handles the event that the form is submitted.
   *
   * @param record Record to be saved.
   */
  @action onFormSubmit(record: T): void {
    record
      .save()
      .then((savedRecord) => {
        this.flashMessages.success('Record has been saved successfuly.');
        this.args.onSave?.(savedRecord);
      })
      .catch((error) => {
        this.flashMessages.danger('Error while attempting to save the record.');
        this.args.onError?.(record, 'Error while attempting to save the record', error);
      });
  }

  /**
   * Handles the event that the form is cancelled.
   *
   * @param record Record of the form that is cancelled.
   */
  @action onFormCancel(record: T): void {
    const reverted = record.hasDirtyAttributes;
    record.rollbackAttributes();
    this.flashMessages.warning('All changes to the record have been cancelled.');
    // @ts-expect-error
    this.args.onCancel?.(record, reverted);
  }

  /**
   * Handles the event that the record is to be deleted.
   *
   * @param record Record to be deleted.
   */
  @action onFormDelete(record: T): void {
    if (confirm('Are you sure you want to proceed with this operation?')) {
      record.destroyRecord().then(() => {
        this.flashMessages.success('Record has been deleted successfully.');
        this.args.onDelete?.();
      });
    }
  }
}
