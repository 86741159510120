import Store from '@ember-data/store';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import ActionModel from 'cafelatte/models/trade';

export interface Args {
  record: ActionModel;
  form: any;
}

export default class extends Component<Args> {
  @service declare store: Store;

  /**
   * Path to action type specific content components.
   */
  typeSpecificComponentPath = 'c-l/action/form/content/-types';

  /**
   * Default/generic action form content component.
   */
  genericActionTypeComponent = `${this.typeSpecificComponentPath}/10`;

  /**
   * Attempts to return the component path.
   *
   * If the component is defined for the action type, return it, otherwise
   * return the generic component.
   */
  get contentComponentPath() {
    // Attempt to get the lower case type:
    const ctype = (this.args.record.get('ctype.id') || '').toLowerCase();

    // If we do not have any ctype (for whatever reason), return the default component:
    if (!ctype) {
      return this.genericActionTypeComponent;
    }

    // Get the component path:
    const component = `${this.typeSpecificComponentPath}/${ctype}`;

    // If we do not have such a component (for whatever reason), return the
    // default component, otherwise itself:
    //
    // TODO: Try to work with `component:`, not `template:` (due to Glimmer).
    return getOwner(this).hasRegistration(`template:components/${component}`)
      ? component
      : this.genericActionTypeComponent;
  }
}
