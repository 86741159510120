import { DecafClient, gql } from '@decafhub/decaf-client';

export const queryCategories = gql`
  query {
    items: document(
      distinct_on: [category]
      where: { _and: [{ category: { _is_null: false } }, { category: { _neq: "" } }] }
    ) {
      value: category
    }
  }
`;

export const queryGroups = gql`
  query {
    items: document(distinct_on: [group], where: { _and: [{ group: { _is_null: false } }, { group: { _neq: "" } }] }) {
      value: group
    }
  }
`;

export const queryTypes = gql`
  query {
    items: document(distinct_on: [type], where: { _and: [{ type: { _is_null: false } }, { type: { _neq: "" } }] }) {
      value: type
    }
  }
`;

export function getTypeList(client: DecafClient): Promise<string[]> {
  return getList(client, queryTypes);
}

export function getCategoriesList(client: DecafClient): Promise<string[]> {
  return getList(client, queryCategories);
}

export function getGroupsList(client: DecafClient): Promise<string[]> {
  return getList(client, queryGroups);
}

export function getList(client: DecafClient, query: any): Promise<string[]> {
  return client.microlot
    .query<{ items: { value: string }[] }>({ query })
    .then(({ data }) => data.items.map((x) => x.value) || []);
}
