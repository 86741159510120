import { action, computed, get, set, setProperties } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { SimpleChoices } from 'cafelatte/libs/headless/prelude/simple-choice';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
// @ts-expect-error
import { storageFor } from 'ember-local-storage';

type AttributeFilter = {
  code?: string;
  value: any;
};
export default class extends CommonController {
  @storageFor('appstate') appstate: any;

  queryParams = [
    'page',
    'page_size',
    'search',
    'name__icontains',
    'symbol__icontains',
    'ctype__in',
    'stype__in',
    'cflag',
    'cflag__isnull',
    'issued__gte',
    'issued__lte',
    'launch__gte',
    'launch__lte',
    'ceased__gte',
    'ceased__lte',
    'expiry__gte',
    'expiry__lte',
    'underlying',
    'riskprofile',
    'riskprofile__isnull',
    'assetclass',
    'assetclass__isnull',
    'mic__icontains',
    'ticker__icontains',
    'isin__icontains',
    'country__in',
    'sector__in',
    'issuer__in',
    'ccymain',
    'ccyaltn',
    'tags',
    'watched',
    'attributes',
    'creator',
    'updater',
  ];

  @tracked page = 1;
  @tracked ctype__in: any;
  @tracked stype__in: any;
  @tracked issuer__in: any;
  @tracked country__in: any;
  @tracked sector__in: any;
  @tracked watched = '';
  @tracked attributes: AttributeFilter[] = [];

  choicesWathced: SimpleChoices<string> = [
    { value: '', label: 'All' },
    { value: 'True', label: 'Watched' },
    { value: 'False', label: 'Not Watched' },
  ];

  @computed()
  get artifactAttributes() {
    return this.store.query('artifactattribute', { page_size: -1 });
  }

  get page_size(): any {
    return get(this.appstate, 'routesResourceIndexPageSize');
  }

  set page_size(value) {
    if (!isEmpty(value) && value <= 500) {
      set(this.appstate, 'routesResourceIndexPageSize', value);
    }
  }

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('resource.details', record.id) },
    ]);
  }

  @computed('ctype__in')
  get ctypeSelected() {
    return isEmpty(this.ctype__in) ? [] : this.ctype__in.split(',');
  }

  @computed('stype__in')
  get stypeSelected() {
    return isEmpty(this.stype__in) ? [] : this.stype__in.split(',');
  }

  @computed('issuer__in')
  get issuerSelected() {
    return isEmpty(this.issuer__in) ? [] : this.issuer__in.split(',');
  }

  @computed('sector__in')
  get sectorSelected() {
    return isEmpty(this.sector__in) ? [] : this.sector__in.split(',');
  }

  @computed('country__in')
  get countrySelected() {
    return isEmpty(this.country__in) ? [] : this.country__in.split(',');
  }

  @action propSelected(what: any, items: any) {
    // @ts-expect-error
    set(this, `${what}__in`, items.join(','));
  }

  @action onNewArtifact(options?: Record<string, any>) {
    this.router.transitionTo('resource.details', '__new__', {
      queryParams: { ...(options || {}), ctype: (options || {})?.ctype?.id },
    });
  }

  @action attributeFilterChanged(code: string, value: any) {
    const existing = this.attributes.find((x) => x.code === code);
    let attrs = [];
    if (!existing) {
      attrs = [...this.attributes, { code, value }];
    } else {
      attrs = this.attributes.map((attr) => (attr.code === code ? { code, value } : attr));
    }
    this.attributes = attrs.filter((attr) => !!attr.value);
  }

  @action reset() {
    setProperties(this, {
      ...this.queryParams.reduce((o, k) => ({ ...o, [k]: '' }), {}),
      page: 1,
      attributes: [],
    });
  }
}
