import { tagName } from '@ember-decorators/component';
import { action, computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import DecafService from 'cafelatte/services/decaf';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  @service declare decaf: DecafService;

  layout = layout;

  @computed()
  get initialOptions() {
    const attribute = get(this, 'options.attribute') || 'type';

    // Define the promise:
    const promise =
      attribute == 'type'
        ? this.decaf.services.document.getTypes()
        : attribute == 'category'
        ? this.decaf.services.document.getCategories()
        : this.decaf.services.document.getGroups();

    // Done, return:
    // @ts-expect-error
    return DS.PromiseArray.create({ promise });
  }

  @action doSearch(k?: Maybe<string>) {
    return ((this.initialOptions.content || []) as string[]).filter((x: string) =>
      isNothing(k) || k == '' ? true : x.toUpperCase().includes(k.toUpperCase())
    );
  }
}
