import ComplianceExemptionModel from 'cafelatte/models/complianceexemption';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<ComplianceExemptionModel> {
  get spec(): FlexTableSpec<ComplianceExemptionModel> {
    return {
      ident: 'cl-compliance-exemtions-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        {
          key: 'portfolio.name',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'portfolio.id' },
        },
        { key: 'account.name', component: '@link', options: { route: 'account.details', modelPath: 'account.id' } },
        { key: 'since', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'until', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'description' },
        { key: 'created', component: '@date', options: { format: 'human' } },
        { key: 'updated', component: '@date', options: { format: 'human' } },
      ],
    };
  }
}
