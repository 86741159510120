import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';

export default class extends CommonController {
  @tracked page = 1;
  @tracked page_size = 20;

  @computed('model.id', 'page', 'page_size')
  get records() {
    return this.store.query('pconsolidation', { account: this.model.id, page: this.page, page_size: this.page_size });
  }
}
