import Model, { attr } from '@ember-data/model';
import { DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['name', 'description', 'order'];

export default class RiskProfileModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @attr()
  declare description?: string;

  @attr('number')
  declare order?: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    riskprofile: RiskProfileModel;
  }
}
