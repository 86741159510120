import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import KonfigService from 'cafelatte/services/konfig';
import MeService from 'cafelatte/services/me';
import CLSessionService from 'cafelatte/services/session';

export abstract class CommonRoute extends Route {
  @service declare konfig: KonfigService;
  @service declare me: MeService;
  @service declare session: CLSessionService;
  @service declare store: Store;

  @action refreshModel(): void {
    this.refresh();
  }
}

export abstract class CommonController extends Controller {
  @service declare flashMessages: any;
  @service declare konfig: KonfigService;
  @service declare me: MeService;
  @service declare router: RouterService;
  @service declare session: CLSessionService;
  @service declare store: Store;

  /**
   * Attempts to refresh the model on the route, if the slot is defined
   * (therefore unsafe).
   */
  @action doRefreshModel() {
    this.send('refreshModel');
  }
}

export abstract class ControllerWithCurrentRecord<T> extends CommonController {
  /**
   * Creates a new record.
   */
  abstract createNewRecord(): T;

  /**
   * Current record.
   */
  @tracked currentRecord?: T;

  /**
   * Sets the current record.
   *
   * @param x Current record.
   */
  @action doSetCurrentRecord(x?: T): Promise<T | undefined> {
    // (Un)set the current record:
    this.currentRecord = x;

    // Return with promise:
    return Promise.resolve(x);
  }

  /**
   * Creates a new record and sets as the current record.
   */
  @action doSetNewCurrentRecord(): Promise<T> {
    // Create the new record:
    const record = this.createNewRecord();

    // Set the current record:
    this.doSetCurrentRecord(record);

    // Return with promise:
    return Promise.resolve(record);
  }

  /**
   * Unsets the current record.
   */
  @action doResetCurrentRecord(): Promise<void> {
    this.doSetCurrentRecord(undefined);
    return Promise.resolve();
  }

  /**
   * Unsets the current record and refreshes the model.
   */
  @action doResetCurrentRecordRefreshModel() {
    this.doResetCurrentRecord().then(this.doRefreshModel);
  }
}
