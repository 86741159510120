import { action } from '@ember/object';
import Component from '@glimmer/component';
import { saveDataUri } from 'cafelatte/libs/headless/prelude/downloading';
import html2canvas from 'html2canvas';

export default class extends Component {
  @action shoot() {
    const element = document.querySelector('body');
    if (element) {
      // @ts-expect-error
      element.getElementsByClassName('cafelatte-footer')[0].hidden = true;
      html2canvas(element).then((canvas) => {
        saveDataUri(canvas.toDataURL(), 'screenshot.png');
        // @ts-expect-error
        element.getElementsByClassName('cafelatte-footer')[0].hidden = false;
      });
    }
  }
}
