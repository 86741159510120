import Model, { attr } from '@ember-data/model';

export default class AgentTypeModel extends Model {
  @attr()
  name!: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'agent/type': AgentTypeModel;
  }
}
