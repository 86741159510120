import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import QuantModel from 'cafelatte/models/quant';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<QuantModel> {
  @service declare ajax: CLAjaxService;

  get spec(): FlexTableSpec<QuantModel> {
    return {
      ident: 'cl-quants-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id', component: '@link', options: { route: 'quant.details', modelPath: 'id' } },
        { key: 'cflag', label: 'Flag', component: '@flag', action: ({ record }) => this.onFlagClicked(record) },
        { key: 'commitment', label: 'Trade Date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'settlement', label: 'Value Date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        {
          key: 'account.name',
          label: 'Account',
          component: '@link',
          options: { route: 'account.details', modelPath: 'account.id' },
        },
        {
          key: 'account.portfolio.name',
          label: 'Portfolio',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'account.portfolio.id' },
        },
        {
          key: 'account.portfolio.team.name',
          label: 'Team',
          component: '@link',
          options: { route: 'team.details', modelPath: 'account.portfolio.team.id' },
        },
        {
          key: 'account.custodian.name',
          label: 'Custodian',
          component: '@link',
          options: { route: 'institution.details', modelPath: 'account.custodian.id' },
          hidden: true,
        },
        {
          key: 'resource.symbol',
          label: 'Instrument (Symbol)',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resource.id' },
        },
        { key: 'quantity', align: 'right', component: '@number', options: { format: '0,0.00[0000]', colorize: true } },
        { key: 'valccy', label: 'Value (CCY)' },
        {
          key: 'valamt',
          label: 'Value (Amt)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        {
          key: 'trade.id',
          label: 'Trade (ID)',
          component: '@link',
          options: { route: 'trade.details', modelPath: 'trade.id' },
        },
        { key: 'trade.type' },
        { key: 'trade.subtype' },
        { key: 'trade.reference' },
        { key: 'type', label: 'TXN Type' },
        {
          key: 'resource.name',
          label: 'Instrument (Name)',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resource.id' },
        },
        {
          key: 'trade.resundr.symbol',
          label: 'Underlying (Symbol)',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'trade.resundr.id' },
        },
        { key: 'refccy', label: 'Value (Ref CCY)' },
        {
          key: 'refamt',
          label: 'Value (Ref Amt)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'executedat', label: 'Executed At' },
        { key: 'pseudorder', label: 'Ordering' },
        ...mkAuditFields<QuantModel>(this.me),
      ],
    };
  }

  onFlagClicked(record: QuantModel): void {
    // Attempt to get the next flag:
    const next = ((record.cflag || 0) + 1) % 6 || null;

    // Update remote:
    this.ajax
      .post(`/quants/${record.get('id')}/flag/`, {
        data: { cflag: next },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.flashMessages.success(`Record is ${isEmpty(next) ? 'un' : ''}flagged.`);
        record.reload();
      })
      .catch(() => {
        this.flashMessages.danger('Record could not be flagged.');
      });
  }
}
