import Application from '@ember/application';
import { injectPublicConfiguration } from 'cafelatte/libs/embered/config';

export default {
  name: 'konfig',

  initialize(application: Application) {
    // Defer the application readiness as we will first load the remote public
    // configurations:
    application.deferReadiness();

    // Attempt to inject remote public configurations and advance readiness:
    injectPublicConfiguration()
      //.then(() => application.advanceReadiness())
      .then(() => application.advanceReadiness())
      .catch((error) => {
        // Log it:
        console.error('Can not retrieve the remote public configurations.', error);

        // At this point, we simply wont proceed any further.
        alert(
          'Remote public configuration can not be retrieved at this moment.\n\nYou can refresh your browser or contact your systems administrator if the problem persists.'
        );
      });
  },
};
