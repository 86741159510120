import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { oneWay } from '@ember/object/computed';
import InstitutionModel from './institution';
import SettlementBreakdownModel from './settlement/breakdown';
import VoucherTypeModel from './vouchers/type';
import { BelongsTo, DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = [
  'received',
  'currency',
  'amount',
  'institution',
  'invoice',
  'notes',
  'remarks',
  'conv_ccy',
  'conv_rate',
  'conv_date',
  'breakdowns',
];

export default class SettlementModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare received?: string;

  @attr()
  declare currency?: string;

  @attr('number')
  declare amount?: number;

  @belongsTo('institution')
  declare institution?: BelongsTo<InstitutionModel>;

  @attr()
  declare institution__name?: string;

  @attr()
  declare invoice?: string;

  @attr()
  declare notes?: string;

  @attr()
  declare remarks?: string;

  @attr('number')
  declare vouchers_amount?: number;

  @attr('number')
  declare adjustment?: number;

  @hasMany('vouchers/type')
  declare ctypes?: HasMany<VoucherTypeModel>;

  @attr()
  declare conv_ccy?: string;

  @attr('number')
  declare conv_rate?: number;

  @attr()
  declare conv_date?: string;

  @attr()
  declare phead?: string;

  @attr()
  declare plast?: string;

  @hasMany('settlement/breakdown')
  declare breakdowns?: HasMany<SettlementBreakdownModel>;

  @oneWay('id')
  declare name?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    settlement: SettlementModel;
  }
}
