import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ConsolidationReportData } from 'cafelatte/libs/headless/services/consolidation-report';
import {
  addressers,
  buildHoldingsTree,
  composeHoldingAddressers,
  HoldingAddresser,
} from 'cafelatte/libs/headless/services/consolidation-report/commons/holdings-tree';
// @ts-expect-error
import ScrollBooster from 'scrollbooster';

const holdingAddressers: Array<HoldingAddresser> = [addressers.classification];

interface Args {
  report: ConsolidationReportData;
}

const SHOW_ACCOUNT_NAMES_KEY = 'holdingsTable::showAccountNames';
const SHOW_HOLDING_DETAILS_KEY = 'holdingsTable::showHoldingDetails';
export default class extends Component<Args> {
  @tracked showHoldingDetails = true;

  @tracked showAccountNames = false;

  @tracked sortByCurrencyAndValue = false;

  /**
   * All holdings from the report.
   */
  @tracked holdings = this.args.report.positions.holdings;

  /**
   * Filtered holdings from the report.
   */
  @tracked filteredHoldings = this.holdings;

  /**
   * Holdings tree:
   */
  get holdingsTree() {
    return buildHoldingsTree(
      this.args.report,
      this.holdings,
      composeHoldingAddressers(holdingAddressers),
      this.sortByCurrencyAndValue
    );
  }

  /**
   * Indicates whether scroll booster is enabled.
   */
  scrollBoosterEnabled = false;

  /**
   * Scroll booster object.
   */
  scrollBooster: any = undefined;

  @action didInsert(element: HTMLElement): void {
    this.activateScrollBooster(element);
    // read table settings from local storage
    this.showAccountNames = localStorage.getItem(SHOW_ACCOUNT_NAMES_KEY) === '0' ? false : true;
    this.showHoldingDetails = localStorage.getItem(SHOW_HOLDING_DETAILS_KEY) === '0' ? false : true;
  }

  @action toggleHoldingDetails(visible: boolean): void {
    this.showHoldingDetails = visible;
    localStorage.setItem(SHOW_HOLDING_DETAILS_KEY, visible ? '1' : '0');
  }

  @action toggleAccountNames(visible: boolean): void {
    this.showAccountNames = visible;
    localStorage.setItem(SHOW_ACCOUNT_NAMES_KEY, visible ? '1' : '0');
  }

  /**
   * Activates scrool booster.
   *
   * @param element Viewport for the tabulation.
   */
  @action activateScrollBooster(element: HTMLElement): void {
    // Do nothing if the scroolbooster is not enabled:
    if (!this.scrollBoosterEnabled) {
      return;
    }

    // Initialize and activate scrool booster:
    this.scrollBooster = new ScrollBooster({
      viewport: element,
      scrollMode: 'native',
      textSelection: true,
    });
  }

  /**
   * Deactivates scrool booster.
   *
   * @param element Viewport for the tabulation.
   */
  @action deactivateScrollBooster(_: HTMLElement): void {
    // Destroy the booster if activated:
    this.scrollBooster?.destroy();

    // Forget about it:
    this.scrollBooster = undefined;
  }

  calculateTableSize(element: HTMLDivElement) {
    const maxHeight = (window.innerHeight * 75) / 100;
    element.style.maxHeight = `${maxHeight}px`;
  }

  @action didInsertHoldingsTable(element: HTMLDivElement) {
    this.calculateTableSize(element);
    window.addEventListener('resize', () => this.calculateTableSize(element));
  }

  @action didRemoveHoldingsTable(element: HTMLDivElement) {
    super.willDestroy();
    window.removeEventListener('resize', () => this.calculateTableSize(element));
  }
}
