import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { CommonController } from 'cafelatte/libs/embered';
import AgentModel from 'cafelatte/models/agent';

export default class extends CommonController {
  @action onSave(_record: AgentModel): void {
    this.router.transitionTo('agent');
    getOwner(this).lookup('route:agent').refresh();
  }

  @action onError(_message: string, error: any): void {
    console.error(error);
  }

  @action onCancel(_record: AgentModel, _reverted: boolean): void {
    this.router.transitionTo('agent');
  }

  @action onDelete() {
    this.router.transitionTo('agent');
    getOwner(this).lookup('route:agent').refresh();
  }
}
