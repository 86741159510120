import Model, { attr, belongsTo } from '@ember-data/model';
import { A } from '@ember/array';
// @ts-expect-error
import { buildValidations, validator } from 'ember-cp-validations';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

/**
 * Builds validations of `ds-error` type for the given fields.
 *
 * @param fields Fields to create validators for.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeValidators(fields: Array<string>): Record<string, any> {
  return buildValidations(fields.reduce((s, e) => ({ ...s, [e]: [validator('ds-error')] }), {}));
}

/**
 * Defines a type alias for DS.belongsTo types (just for concenience).
 */
export type BelongsTo<T extends object> = DS.PromiseObject<T>;

/**
 * Defines a type alias for DS.hasMany types (just for convenience).
 */
export type HasMany<T extends Model> = DS.PromiseManyArray<T>;

/**
 * Creates and return an empty promise array.
 */
export function createEmptyPromiseArray<K>(): DS.PromiseArray<K> {
  // TODO: Check how to deal with missing DS.PromseArray#create type def.
  // @ts-expect-error
  return DS.PromiseArray.create({ promise: Promise.resolve(A([])) });
}

/**
 * Defines an interface for executiveSummary methods
 */
// TODO: Refactor this into a service or library (see refs to this interface).
export interface ExecutiveSummary {
  pending: number;
  settled: number;
  totaled: number;
  nav: number;
  aum: number;
  roi: number | null;
}

/**
 * Defines DECAF Barista audit fields.
 */
export const DecafAuditFields = {
  created: attr(),
  updated: attr(),
  creator: belongsTo('user', { inverse: null }),
  updater: belongsTo('user', { inverse: null }),
};
