import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Id } from 'cafelatte/libs/headless/commons/types';
import ActionModel from 'cafelatte/models/trade';

export default class extends Component {
  @service declare store: Store;

  @tracked trade?: ActionModel;

  @action getTrade(_element: HTMLElement, [id]: [id: Id]) {
    this.store.findRecord('trade', id).then((x) => (this.trade = x));
  }
}
