import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import CLAjaxService from 'cafelatte/services/ajax';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  @service declare ajax: CLAjaxService;

  layout = layout;

  @action searchEndpoint(keyword: string) {
    // @ts-expect-error
    return this.ajax.request(this.options.endpoint, { data: { search: keyword } });
  }
}
