import { action, get, set, setProperties } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import ActionModel from 'cafelatte/models/trade';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
// @ts-expect-error
import { storageFor } from 'ember-local-storage';

export default class extends CommonController {
  @storageFor('appstate') appstate: any;

  queryParams = [
    'page',
    'page_size',
    'search',
    'ctype',
    'stype',
    'cflag',
    'cflag__null',
    'commitment__gte',
    'commitment__lte',
    'settlement__gte',
    'settlement__lte',
    'resmain',
    'resmain__ctype',
    'resmain__stype',
    'acc_any',
    'qtymain__gte',
    'qtymain__lte',
    'accmain__portfolio',
    'notes__icontains',
    'accmain__portfolio__team',
    'custodian',
    'remarks__icontains',
    'horizon',
    'reference__iexact',
    'tags',
    'nojournal',
    'accmain__portfolio__groups',
    'creator',
    'updater',
  ];

  queryParamsDefaults: any = {
    nojournal: 'True',
  };

  @tracked page = 1;
  @tracked nojournal = 'True';

  get page_size(): any {
    return get(this.appstate, 'routesTradeIndexPageSize');
  }

  set page_size(value) {
    if (!isEmpty(value) && value <= 500) {
      set(this.appstate, 'routesTradeIndexPageSize', value);
    }
  }

  get tableOperations(): FlexTableRowAction<ActionModel>[] {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('trade.details', record.id) },
      { type: 'delete', onDelete: () => this.send('refreshModel') },
    ]);
  }

  @action onNewAction(options?: Record<string, any>) {
    this.router.transitionTo('trade.details', '__new__', {
      queryParams: { ...(options || {}), ctype: (options || {})?.ctype?.id },
    });
  }

  @action reset() {
    setProperties(this, {
      ...this.queryParams.reduce((o, k) => ({ ...o, [k]: '' }), {}),
      ...this.queryParamsDefaults,
      page: 1,
      search: '',
    });
  }
}
