import Model from '@ember-data/model';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { FlexTableRecords, FlexTableRowAction, FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import MeService from 'cafelatte/services/me';

/**
 * Provides a component argument interface for standard Cafelatte record listing
 * components.
 */
export interface RecordListArgs<T extends Model> {
  records?: FlexTableRecords<T>;
  operations?: FlexTableRowAction<T>[];
}

/**
 * Provides a component class for standard Cafelatte record listing.
 */
export abstract class RecordListComponent<T extends Model> extends Component<RecordListArgs<T>> {
  /**
   * Injects the flash messages service.
   */
  @service declare flashMessages: any;

  /**
   * Injects the me service.
   */
  @service declare me: MeService;

  /**
   * Injects the router service.
   */
  @service declare router: RouterService;

  /**
   * Function that returns table specification.
   */
  abstract get spec(): FlexTableSpec<T>;
}
