import { CommonRoute } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  /**
   * Prohibits this route in case that we are already authenticated.
   */
  beforeModel(/* transition: Transition */) {
    this.session.prohibitAuthentication('index');
  }
}
