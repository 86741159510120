import EmberObject, { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { Holding } from 'cafelatte/libs/headless/services/consolidation-report/commons/holding';
import DecafService from 'cafelatte/services/decaf';

interface Args {
  holding: Holding;
  reportDate: string;
  excludes: string[];
  onClick: (holding: Holding) => void;
}

export default class extends Component<Args> {
  @service declare decaf: DecafService;

  @computed('args.holding')
  get priceInfo() {
    const retval = EmberObject.create({ price: undefined, date: undefined, pending: true });

    const artifact = this.args.holding.artifact;

    this.decaf.client.barista
      .get(
        `/ohlcobservations/?ordering=-date&date__lte=${this.args.reportDate}&page_size=1&series__symbol=${
          artifact.ohlccode ?? artifact.symbol
        }`
      )
      .then(({ data }) => {
        const price = data.results[0]?.close;
        const date = data.results[0]?.date;
        set(retval, 'price', price);
        set(retval, 'date', date);
      })
      .finally(() => {
        set(retval, 'pending', false);
      });
    return retval;
  }
}
