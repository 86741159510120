import { dayjs, today } from 'cafelatte/libs/headless/prelude/datetime';
import AccountModel from 'cafelatte/models/account';
import {
  FlexTableSpec,
  mkAuditFields,
  mkDateColumn,
  mkLinkColumn,
  mkNumberColumn,
} from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<AccountModel> {
  get spec(): FlexTableSpec<AccountModel> {
    return {
      ident: 'cl-account-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'name' },
        { key: 'portfolio.id', hidden: true },
        mkLinkColumn('portfolio.details', 'portfolio.id', { key: 'portfolio.name' }),
        { key: 'portfolio.team.id', label: 'Team ID', hidden: true },
        mkLinkColumn('team.details', 'portfolio.team.id', { key: 'portfolio.team.name', label: 'Team Name' }),
        { key: 'custodian.id', label: 'Custodian ID', hidden: true },
        mkLinkColumn('institution.details', 'custodian.id', { key: 'custodian.name' }),
        {
          key: 'domicile',
          value: ({ record }) => record.get('domicile.name') || record.get('portfolio.domicile.name'),
        },
        mkDateColumn({ key: 'inception' }),
        {
          key: 'status',
          value: ({ record }) =>
            record.isActive ? 'Active' : `Closed on ${dayjs(record.closed).format('DD-MM-YYYY')}`,
          classes: [({ record }) => (record.isClosed ? ['text-danger'] : [])],
        },
        mkDateColumn({ key: 'closed', hidden: true }),
        { key: 'htype', label: 'Type', exclude: !this.me.internal },
        { key: 'rccy', label: 'CCY' },
        mkNumberColumn({
          key: 'quickvaluation.nav',
          label: 'NAV',
          action: ({ record }) => this.gotoConsolidation(record),
        }),
        mkNumberColumn({
          key: 'quickvaluation.aum',
          label: 'AuM',
          action: ({ record }): void => this.gotoConsolidation(record),
        }),
        mkNumberColumn({
          key: 'quickvaluation.exp',
          label: 'Exposure',
          action: ({ record }): void => this.gotoConsolidation(record),
        }),
        { key: 'riskprofile.id', label: 'Risk Profile ID', hidden: true, exclude: !this.me.internal },
        mkLinkColumn('riskprofile.details', 'riskprofile.id', {
          key: 'riskprofile.name',
          label: 'Risk Profile Name',
          exclude: !this.me.internal,
        }),
        mkNumberColumn({
          key: 'mfpercentage.value',
          label: 'Mngt. Fee (%)',
          options: { format: '0,0.00[00]%' },
          exclude: !this.me.internal,
        }),
        { key: 'mandatetype', label: 'Mandate Type', exclude: !this.me.internal },
        { key: 'horizontype', label: 'Horizon Type', exclude: !this.me.internal },
        { key: 'data_source', exclude: !this.me.internal },
        mkDateColumn({ key: 'last.tx.commitment', label: 'TXN Update', exclude: !this.me.internal }),
        mkDateColumn({ key: 'last.px.date', label: 'PX Update', exclude: !this.me.internal }),
        ...mkAuditFields<AccountModel>(this.me),
      ],
    };
  }

  gotoConsolidation(record: AccountModel): void {
    this.router.transitionTo('reports.consolidation', {
      queryParams: {
        date: today(),
        c: 'account',
        i: record.id,
        ccy: record.rccy,
      },
    });
  }
}
