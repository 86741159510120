import { DecafClient } from '@decafhub/decaf-client';
import Decimal from 'decimal.js';
import { IdName } from '../../commons/types';
import { SDate } from '../../prelude/datetime';
import { Maybe } from '../../prelude/maybe';
import { ExternalValuationItem, ExternalValuationQuery } from '../external-valuation';
import { request as requestExternal } from '../external-valuation/-internal';
import { PConsolidationItem, PConsolidationQuery } from '../persisted-consolidation';
import { request as requestInternal } from '../persisted-consolidation/-internal';

/**
 * Report query type.
 */
export interface HistoricalValuationQuery {
  internal: PConsolidationQuery;
  external: ExternalValuationQuery;
}

/**
 * Historical valuation item encoding.
 */
export interface HistoricalValuationItem {
  date: SDate;
  portfolio: IdName;
  shareclass: Maybe<IdName>;
  ccy: string;
  internal: Maybe<PConsolidationItem>;
  external: Maybe<ExternalValuationItem>;
  nav: Maybe<Decimal>;
  price: Maybe<Decimal>;
  shares: Maybe<Decimal>;
}

/**
 * Attempts to query and return historical valuations.
 *
 * @param client barista client.
 * @returns Historical valuations.
 */
export async function request(
  client: DecafClient,
  query: HistoricalValuationQuery
): Promise<Array<HistoricalValuationItem>> {
  // First get internal historical valuations (persisted consolidations):
  const internal = await requestInternal(client, query.internal);

  // Now get external historical valuations (external valuations):
  const external = await requestExternal(client, query.external);

  // Merge internal and external, and return:
  return _merge(internal, external);
}

export function _merge(
  internal: Array<PConsolidationItem>,
  external: Array<ExternalValuationItem>
): Array<HistoricalValuationItem> {
  // Initialize the buffer:
  const buffer: Map<string, HistoricalValuationItem> = new Map();

  // Iterate over internals and populate:
  internal.forEach((x) => {
    // If account is set, skip:
    if (x.account) {
      return;
    }

    // Get the key:
    const key = `${x.date}-${x.ccy}-${x.portfolio}-${x.shareclass}`;

    // Check if we have the value for the key, and init if not:
    if (!buffer.has(key)) {
      buffer.set(key, {
        date: x.date,
        portfolio: x.portfolio,
        shareclass: x.shareclass,
        ccy: x.ccy,
        internal: undefined,
        external: undefined,
        nav: undefined,
        price: undefined,
        shares: undefined,
      });
    }

    // Get the item:
    // @ts-expect-error
    const item: HistoricalValuationItem = buffer.get(key);

    // Set the values:
    item.internal = x;
    item.nav = x.nav;
    item.price = x.price;
    item.shares = x.shares;
  });

  // Iterate over externals and populate:
  external.forEach((x) => {
    // Get the key:
    const key = `${x.date}-${x.ccy}-${x.portfolio}-${x.shareclass}`;

    // Check if we have the value for the key, and init if not:
    if (!buffer.has(key)) {
      buffer.set(key, {
        date: x.date,
        portfolio: x.portfolio,
        shareclass: x.shareclass,
        ccy: x.ccy,
        internal: undefined,
        external: undefined,
        nav: undefined,
        price: undefined,
        shares: undefined,
      });
    }

    // Get the item:
    // @ts-expect-error
    const item: HistoricalValuationItem = buffer.get(key);

    // Set the values:
    item.external = x;
    item.nav = x.nav;
    item.price = x.price;
    item.shares = x.shares;
  });

  // Done, return the buffer:
  return Array.from(buffer.values());
}
