import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Extend dayjs:
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);

// Re-export extdayjs:
export { dayjs, Dayjs };

/**
 * Type alias for ISO-formatted (YYYY-mm-dd) dates.
 */
export type SDate = string;

/**
 * Type encoding for ISO-formatted date ranges.
 */
export interface SDateRange {
  start?: SDate;
  end?: SDate;
}

/**
 * Type alias for ISO-formatted (YYYY-mm-dd HH:MM:SS) date/time.
 */
export type SDateTime = string;

/**
 * Type alias for date ranges as a 2-tuple of dates.
 */

export type DateRange = [SDate, SDate];

/**
 * Type alias for univariate series with date index type and arbitrary value types.
 */
export type DateSeries<T> = { index: Array<SDate>; values: Array<T> };

/**
 * Returns the ISO formatted date/time as of now.
 */
export function now(): string {
  return dayjs().format('YYYY-MM-DD HH:mm:ss');
}

/**
 * Returns the ISO formatted date as of today.
 */
export function today(): string {
  return dayjs().format('YYYY-MM-DD');
}

/**
 * Known date keys.
 */
export type KnownDateKeys =
  | 'last-year-end'
  | 'this-year-start'
  | 'last-month-end'
  | 'this-month-start'
  | 'last-quarter-end'
  | 'this-quarter-start'
  | 'last-week-end'
  | 'this-week-start'
  | 'yesterday'
  | 'today'
  | 'tomorrow'
  | 'this-week-end'
  | 'next-week-start'
  | 'next-week-end'
  | 'this-month-end'
  | 'next-month-start'
  | 'next-month-end'
  | 'this-quarter-end'
  | 'next-quarter-start'
  | 'next-quarter-end'
  | 'this-year-end'
  | 'next-year-start'
  | 'next-year-end';

/**
 * Provides a record of functions for retrieving known dates.
 */
// prettier-ignore
export const KnownDates: Record<KnownDateKeys, (x: string) => Dayjs> = {
  'last-year-end': (x: string) => dayjs(x).subtract(1, 'year').endOf('year'),
  'this-year-start': (x: string) => dayjs(x).startOf('year'),
  'last-month-end': (x: string) => dayjs(x).subtract(1, 'month').endOf('month'),
  'this-month-start': (x: string) => dayjs(x).startOf('month'),
  'last-quarter-end': (x: string) => dayjs(x).subtract(1, 'quarter').endOf('quarter'),
  'this-quarter-start': (x: string) => dayjs(x).startOf('quarter'),
  'last-week-end': (x: string) => dayjs(x).subtract(1, 'week').endOf('week'),
  'this-week-start': (x: string) => dayjs(x).startOf('week'),
  yesterday: (x: string) => dayjs(x).subtract(1, 'day'),
  today: (x: string) => dayjs(x),
  tomorrow: (x: string) => dayjs(x).add(1, 'day'),
  'this-week-end': (x: string) => dayjs(x).endOf('week'),
  'next-week-start': (x: string) => dayjs(x).add(1, 'week').startOf('week'),
  'next-week-end': (x: string) => dayjs(x).add(1, 'week').endOf('week'),
  'this-month-end': (x: string) => dayjs(x).endOf('month'),
  'next-month-start': (x: string) => dayjs(x).add(1, 'month').startOf('month'),
  'next-month-end': (x: string) => dayjs(x).add(1, 'month').endOf('month'),
  'this-quarter-end': (x: string) => dayjs(x).endOf('quarter'),
  'next-quarter-start': (x: string) => dayjs(x).add(1, 'quarter').startOf('quarter'),
  'next-quarter-end': (x: string) => dayjs(x).add(1, 'quarter').endOf('quarter'),
  'this-year-end': (x: string) => dayjs(x).endOf('year'),
  'next-year-start': (x: string) => dayjs(x).add(1, 'year').startOf('year'),
  'next-year-end': (x: string) => dayjs(x).add(1, 'year').endOf('year'),
};

/**
 * Returns the date for the given specification.
 */
export function getDate(spec: KnownDateKeys, date?: string): string {
  return KnownDates[spec](date || today()).format('YYYY-MM-DD');
}

/**
 * Returns the previous workday as of the given date.
 *
 * @param asof Date as of we need the previous work day.
 * @returns Previous workday.
 */
export function getPreviousWorkday(asof?: SDate): SDate {
  const workday = dayjs(asof || today());
  const day = workday.day();
  let diff = 1;
  if (day == 0 || day == 1) {
    diff = day + 2;
  }
  return workday.subtract(diff, 'days').format('YYYY-MM-DD');
}
