import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import {
  CommonController,
  getQueryParamDefault,
  queryParamAsNumberArray,
  QueryParamDefaultsFactory,
} from 'cafelatte/libs/embered';
import { Currency } from 'cafelatte/libs/headless/commons/types';
import { getDate, SDate } from 'cafelatte/libs/headless/prelude/datetime';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { PreTradeSimulationQuery } from 'cafelatte/libs/headless/services/simulation';
import DecafService from 'cafelatte/services/decaf';

export const QueryParamDefaults: QueryParamDefaultsFactory = {
  portfolio: '',
  currency: '',
  dateasof: () => getDate('today'),
  profiles: '',
  tradeOrders: '',
  tradeOrderGroups: '',
};

export default class SimulationTrading extends CommonController {
  @service declare decaf: DecafService;

  queryParams = Object.keys(QueryParamDefaults);

  @tracked portfolio: Maybe<number>;
  @tracked currency: Maybe<Currency>;
  @tracked dateasof: SDate = getQueryParamDefault(QueryParamDefaults, 'dateasof');
  @tracked profiles: Maybe<string>;
  @tracked tradeOrders: Maybe<string>;
  @tracked tradeOrderGroups: Maybe<string>;
  @tracked simulation: Maybe<any>;

  get profileIds() {
    return queryParamAsNumberArray(this.profiles);
  }

  set profileIds(xs: Array<number>) {
    this.profiles = (xs || []).join(',');
  }

  get tradeOrderIds(): Array<number> {
    return queryParamAsNumberArray(this.tradeOrders);
  }

  set tradeOrderIds(xs: Array<number>) {
    this.tradeOrders = (xs || []).join(',');
  }

  get tradeOrderGroupIds(): Array<number> {
    return queryParamAsNumberArray(this.tradeOrderGroups);
  }

  set tradeOrderGroupIds(xs: Array<number>) {
    this.tradeOrderGroups = (xs || []).join(',');
  }

  get query(): Maybe<PreTradeSimulationQuery> {
    // @ts-expect-error
    return isEmpty(this.currency) || isEmpty(this.portfolio)
      ? undefined
      : {
          tradeOrders: this.tradeOrderIds,
          tradeOrderGroups: this.tradeOrderGroupIds,
          query: {
            consolidation: {
              date: this.dateasof,
              datetype: 'commitment',
              elements: { container: 'portfolio', instances: [this.portfolio] },
              currency: this.currency,
              sandbox: 'include',
            },
            profiles: this.profileIds,
          },
        };
  }

  @action onFormSubmit() {
    // Attempt to build the query payload:
    const query = this.query;

    // If we don't have any query, do nothing:
    if (isNothing(query)) {
      return;
    }

    // Reset the simulation:
    this.simulation = undefined;

    // Attempt to run the simulation and set the result:
    this.decaf.services.simulation.preTrade(query).then((result) => {
      this.simulation = result;
    });
  }
}
