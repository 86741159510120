import AccountCreditLineModel from 'cafelatte/models/accountcreditline';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<AccountCreditLineModel> {
  get spec(): FlexTableSpec<AccountCreditLineModel> {
    return {
      ident: 'cl-account-credit-line-list-table',
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'since', label: 'Since', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'until', label: 'Until', component: '@date', options: { format: 'DD-MM-YYYY' } },
        {
          key: 'account.name',
          label: 'Account',
          component: '@link',
          options: { route: 'account.details', modelPath: 'account.id' },
        },
        { key: 'currency' },
        { key: 'quantity', label: 'Quantity', component: '@number', options: { format: '0,0.00' } },
        ...mkAuditFields<AccountCreditLineModel>(this.me),
      ],
    };
  }
}
