import Model, { attr, belongsTo } from '@ember-data/model';
import AccountModel from './account';
import PortfolioModel from './portfolio';
import ShareClassModel from './shareclass';
import { BelongsTo } from './_internal/machinery';

export default class PersistedConsolidationModel extends Model {
  @belongsTo('portfolio')
  declare portfolio?: BelongsTo<PortfolioModel>;

  @belongsTo('account')
  declare account?: BelongsTo<AccountModel>;

  @belongsTo('shareclass')
  declare shareclass?: BelongsTo<ShareClassModel>;

  @attr()
  declare date?: string;

  @attr()
  declare ccy?: string;

  @attr()
  declare accruals?: string;

  @attr()
  declare liabilities?: string;

  @attr()
  declare cost?: string;

  @attr()
  declare pnl?: string;

  @attr()
  declare gav?: string;

  @attr()
  declare aum?: string;

  @attr()
  declare nav?: string;

  @attr()
  declare shares?: string;

  @attr()
  declare price?: string;

  @attr()
  declare navrefccy?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    pconsolidation: PersistedConsolidationModel;
  }
}
