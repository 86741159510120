import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'ctype',
    'stype',
    'cflag',
    'cflag__isnull',
    'commitment__gte',
    'commitment__lte',
    'settlement__gte',
    'settlement__lte',
    'resmain',
    'resmain__ctype',
    'resmain__stype',
    'acc_any',
    'accmain__portfolio',
    'accmain__portfolio__team',
    'accmain__portfolio__groups',
    'custodian',
    'qtymain__gte',
    'qtymain__lte',
    'notes__icontains',
    'remarks__icontains',
    'horizon',
    'reference__iexact',
    'tags',
    'nojournal',
    'creator',
    'updater',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('trade', cleanParams(params));
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
