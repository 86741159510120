import { helper } from '@ember/component/helper';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import Decimal from 'decimal.js';
import numbro from 'numbro';

const na = '#N/A';

export function formatNumber([value, format]: [Maybe<Decimal | number | string>, string]): string {
  if (isNothing(value)) {
    return na;
  } else if (typeof value === 'string') {
    value = Number.parseFloat(value);
  } else if (value instanceof Decimal) {
    value = value.toNumber();
  }

  return isNaN(value) ? na : numbro(value).format(format);
}

export default helper(formatNumber);
