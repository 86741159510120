import { action } from '@ember/object';
import ArtifactModel from 'cafelatte/models/resource';
import { ArtifactSpecificForm } from '..';

export default class extends ArtifactSpecificForm {
  coreArtifactTypeCode = 'FXFUT';

  @action onFXOptionChanged(artifact?: ArtifactModel) {
    // Get currencies of the selected artifact, if any:
    const currency1 = artifact?.ccymain;
    const currency2 = artifact?.ccyaltn;

    // If we have some currency, attempt to find its artifact and set as other
    // leg's artifact:
    if (currency1 && currency2) {
      this.store.query('resource', { symbol: currency1 }).then((artifacts) => {
        if (artifacts.firstObject) {
          this.args.record.set('resundr', artifacts.firstObject);
        }
      });

      this.store.query('resource', { symbol: currency2 }).then((artifacts) => {
        if (artifacts.firstObject) {
          this.args.record.set('rescntr', artifacts.firstObject);
        }
      });
    }

    // Finally, set the artifact of this leg as intended:
    this.args.record.set('resmain', artifact);
  }
}
