import Model, { attr, belongsTo } from '@ember-data/model';
import ArtifactTypeModel from '../resource/type';
import { BelongsTo } from '../_internal/machinery';

export default class ActionTypeModel extends Model {
  @attr()
  name!: string;

  @belongsTo('resource/type')
  atype?: BelongsTo<ArtifactTypeModel>;

  // TODO: We will need the type of this attribute ONCE we start using this.
  @attr()
  fields!: object;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trade/type': ActionTypeModel;
  }
}
