import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  layout = layout;

  value!: any;
  onChange!: Function;

  @tracked isValidJSON = false;

  get editorValue() {
    let json = '';
    try {
      json = JSON.stringify(this.value, undefined, 2);
      this.isValidJSON = true;
    } catch (err) {
      console.error(err);
      this.isValidJSON = false;
    }
    return json;
  }

  @action onEdited(value: any) {
    let obj = undefined;
    try {
      obj = JSON.parse(value.target.value);
      this.isValidJSON = true;
      this.onChange(obj);
    } catch (err) {
      console.error('Error while editing', err);
      this.isValidJSON = false;
    }
  }
}
