import Store from '@ember-data/store';
import { action } from '@ember/object';
import { Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { SDate } from 'cafelatte/libs/headless/prelude/datetime';
import { getOrElse, isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { sumDecimal, ZERO } from 'cafelatte/libs/headless/prelude/numeric';
import { StockAtHand } from 'cafelatte/libs/headless/services/stocks';
import ArtifactModel from 'cafelatte/models/resource';
import { ActionHandlerFactory } from 'cafelatte/models/resource/_machinery';
import DecafService from 'cafelatte/services/decaf';
import MeService from 'cafelatte/services/me';
import Decimal from 'decimal.js';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

interface Args {
  artifact: ArtifactModel;
  groupBy: Maybe<'portfolio' | 'team' | 'custodian'>;
  date: SDate;
  stocks: Array<StockAtHand>;
}

type RowItem = GroupRow | StockAtHand;

interface GroupRow {
  name: string;
  quantity: Decimal;
}

const groupers: Record<'portfolio' | 'team' | 'custodian', (x: StockAtHand) => string> = {
  portfolio: (x) => x.account.portfolio.name,
  team: (x) => x.account.portfolio.team.name,
  custodian: (x) => x.account.custodian.name,
};

function groupBy(
  key: 'portfolio' | 'team' | 'custodian',
  stocks: Array<StockAtHand>
): Record<string, { items: Array<StockAtHand>; quantity: Decimal }> {
  const buffer: Record<string, Array<StockAtHand>> = {};
  const grouper = groupers[key];
  stocks.forEach((x) => {
    const name = grouper(x);

    if (!(name in buffer)) {
      buffer[name] = [];
    }

    // @ts-expect-error ts2532
    buffer[name].pushObject(x);
  });
  return Object.entries(buffer)
    .map(([name, items]) => ({
      name: name,
      items: items,
      quantity: getOrElse(
        sumDecimal(items, (x) => x.quantity),
        ZERO
      ),
    }))
    .reduce((p, c) => ({ ...p, [c.name]: { items: c.items, quantity: c.quantity } }), {});
}

export default class extends Component<Args> {
  @service declare router: Router;
  @service declare me: MeService;
  @service declare store: Store;
  @service declare decaf: DecafService;

  @tracked total = getOrElse(
    sumDecimal(this.args.stocks, (x) => x.quantity),
    ZERO
  );

  get records(): Array<RowItem> {
    if (isNothing(this.args.groupBy)) {
      return this.args.stocks.sort((x, y) => y.quantity.comparedTo(x.quantity));
    }

    const grouped = Object.entries(groupBy(this.args.groupBy, this.args.stocks)).sort((x, y) =>
      y[1].quantity.comparedTo(x[1].quantity)
    );

    const retval: Array<RowItem> = [];

    for (const [name, { items, quantity }] of grouped) {
      retval.pushObject({ name, quantity: quantity });
      retval.pushObjects(items.sort((x, y) => y.quantity.comparedTo(x.quantity)));
    }

    return retval;
  }

  @action onOpenChangePosition(
    artaction: ActionHandlerFactory,
    artifact: ArtifactModel & DS.PromiseObject<ArtifactModel>,
    stock: StockAtHand
  ) {
    artaction
      .getParams(artifact, stock.account.id, -stock.quantity, undefined)
      // @ts-expect-error
      .then((queryParams) => window.open(this.router.urlFor('trade.details', '__new__', { queryParams })));
  }

  @action downloadTableData() {
    this.decaf.services.stocks.downloadCsv(this.args.stocks.sort((x, y) => y.quantity.comparedTo(x.quantity)));
  }
}
