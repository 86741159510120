import { action, computed, notifyPropertyChange, setProperties } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { DateType } from 'cafelatte/libs/headless/commons/date-type';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { isJust, isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { downloadHoldings } from 'cafelatte/libs/headless/services/consolidation-report/commons/holding';
import {
  FundReportData,
  FundReportQuery,
} from 'cafelatte/libs/headless/services/consolidation-report/fund-report/-full';
import DecafService from 'cafelatte/services/decaf';
import { resolve } from 'rsvp';
import { Query } from '../../components/c-l/reports/valuation/fund/query-form/component';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

function isQueryComplete(form: Query): boolean {
  return (form.date && form.datetype && form.currency && form.portfolio && true) || false;
}

export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = ['portfolio', 'date', 'type', 'ccy'];

  @tracked portfolio: Maybe<number> = undefined;
  @tracked date: Maybe<string> = undefined;
  @tracked type: Maybe<DateType> = undefined;
  @tracked ccy: Maybe<string> = undefined;

  @computed('date', 'type', 'portfolio', 'ccy')
  get formQuery(): Query {
    return {
      portfolio: this.portfolio,
      date: this.date || today(),
      datetype: this.type || 'commitment',
      currency: this.ccy || 'USD',
    };
  }

  @computed('portfolio')
  get portfolioInstance() {
    return this.portfolio ? this.store.findRecord('portfolio', this.portfolio) : undefined;
  }

  @computed('formQuery')
  get query(): Maybe<FundReportQuery> {
    // @ts-expect-error
    return isQueryComplete(this.formQuery)
      ? {
          date: this.formQuery.date,
          datetype: this.formQuery.datetype,
          portfolio: this.formQuery.portfolio,
          currency: this.formQuery.currency,
        }
      : undefined;
  }

  @computed('query')
  get report(): Promise<Maybe<FundReportData>> {
    // Check if we have a query:
    if (isNothing(this.query)) {
      return ObjectPromiseProxy.create({ promise: resolve(undefined) });
    }

    return ObjectPromiseProxy.create({
      promise: resolve(this.decaf.services.consolidationReport.getFundReport(this.query)),
    });
  }

  @action onQueryChanged(query: Query) {
    // @ts-expect-error
    setProperties(this, {
      portfolio: query.portfolio,
      date: query.date,
      type: query.datetype,
      ccy: query.currency,
    });
  }

  @action refresh() {
    notifyPropertyChange(this, 'query');
  }

  @action downloadReport() {
    if (isJust(this.query)) {
      this.decaf.services.consolidationReport.download(
        {
          date: this.query.date,
          datetype: this.query.datetype,
          currency: this.query.currency,
          elements: { container: 'portfolio', instances: [this.query.portfolio] },
          sandbox: 'include',
        },
        'fund_report.pdf'
      );
    }
  }

  @action downloadHoldings() {
    // @ts-expect-error;
    if (this.report.content) {
      // @ts-expect-error;
      downloadHoldings(this.report.content.positions.holdings);
    }
  }

  @action doExit() {
    setProperties(this, {
      portfolio: undefined,
      date: undefined,
      type: undefined,
      ccy: undefined,
      // @ts-expect-error
      'report.content': undefined,
    });
  }
}
