import Component from '@glimmer/component';
import { FlexTableCellArguments } from '../../../../-internal/runtime';

export default class FlexTableCellComponent<T> extends Component<FlexTableCellArguments<T>> {
  get value() {
    return this.args.column.value(this.args);
  }

  get dataValue() {
    return this.args.column.dataValue?.(this.args);
  }
}
