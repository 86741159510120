import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { CommonController } from 'cafelatte/libs/embered';

export default class extends CommonController {
  reloadModel() {
    this.model.reload();
    this.model.notifyPropertyChange('id');
  }

  @action save(instance: any) {
    this.reloadModel();
    this.router.transitionTo('trading.tradeorder.details', instance.get('id'));
  }

  @action cancel(_instance: any) {
    if (isEmpty(this.model.get('id'))) {
      this.router.transitionTo('trading.tradeorder.index');
    }
  }
}
