import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { CommonController } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import DecafService from 'cafelatte/services/decaf';

export default class extends CommonController {
  @service declare decaf: DecafService;

  get reportParams() {
    return { portfolio: this.model.id, date: today(), ccy: this.model.rccy, type: 'commitment' };
  }

  @action toggleWatch() {
    this.decaf.client.barista
      .post(`/portfolios/${this.model.id}/watch/`, { status: !this.model.watched?.status })
      .then(() => {
        this.model.notifyPropertyChange('watched');
      });
  }
}
