import Store from '@ember-data/store';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import TradeOrderModel from 'cafelatte/models/tradeorder';
import TradeOrderAllocationModel from 'cafelatte/models/tradeorderallocation';
import TradeOrderExecutionModel from 'cafelatte/models/tradeorderexecution';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
import TradingService from 'cafelatte/services/trading';

interface Args {
  record: TradeOrderModel;
  onSave?: Function;
  onCancel?: Function;
}

export default class extends Component<Args> {
  @service declare flashMessages: any;
  @service declare store: Store;
  @service declare trading: TradingService;

  @tracked currentAllocation?: TradeOrderAllocationModel = undefined;
  @tracked currentExecution?: TradeOrderExecutionModel = undefined;

  get allocationTableOperations(): FlexTableRowAction<TradeOrderAllocationModel>[] {
    return [
      ...(mkCommonRowActions([
        // eslint-disable-next-line ember/no-side-effects
        { type: 'edit', action: ({ record }) => (this.currentAllocation = record) },
        { type: 'delete', onDelete: this.onDeleteAllocationHandler },
      ]) as FlexTableRowAction<TradeOrderAllocationModel>[]),
      {
        icon: 'bolt',
        label: 'Exec',
        type: 'success',
        // eslint-disable-next-line ember/no-side-effects
        action: ({ record }) => set(this, 'currentExecution', this.trading.createTradeOrderExecution(record)),
      },
    ];
  }

  get executionTableOperations(): FlexTableRowAction<TradeOrderExecutionModel>[] {
    return [
      ...(mkCommonRowActions([
        // eslint-disable-next-line ember/no-side-effects
        { type: 'edit', action: ({ record }) => (this.currentExecution = record) },
      ]) as FlexTableRowAction<TradeOrderExecutionModel>[]),
      {
        icon: 'bolt',
        label: 'Book',
        type: 'success',
        action: ({ record }) => {
          // Consume the booking:
          const tradeid: any = prompt('Enter Trade ID');

          // Check:
          if (isEmpty(tradeid) || !parseInt(tradeid)) {
            this.flashMessages.warning('Cancelling booking...');
            return;
          }

          // Attempt to find the trade:
          this.store.findRecord('trade', tradeid).then(
            (res) => {
              record.set('booking', res);
              record.save().then(() => {
                this.flashMessages.success('Execution booked successfully...');
                this.onSaveExecutionHandler();
              });
            },
            (err) => {
              this.flashMessages.danger('No such trade found...');
              console.error(err);
            }
          );
        },
      },
    ];
  }

  @action onSaveHandler(record: TradeOrderModel) {
    record.reload();
    this.args.onSave?.(record);
  }

  @action onCancelHandler(record: TradeOrderModel) {
    if (!record.get('isDeleted')) {
      record.reload();
    }
    this.args.onCancel?.(record);
  }

  ////////////////////////
  // ALLOCATION RELATED //
  ////////////////////////

  @action createNewAllocation(order: TradeOrderModel) {
    this.currentAllocation = this.trading.createTradeOrderAllocation(order);
  }

  @action onSaveAllocationHandler(record: TradeOrderAllocationModel) {
    // Remove the current allocation pointer:
    this.currentAllocation = undefined;

    // The allocation is saved, therefore we must reload the record:
    record.reload();

    // Propogate changes to the parent:
    // @ts-expect-error
    this.onSaveHandler(record.get('order'));
  }

  @action onCancelAllocationHandler(record: TradeOrderAllocationModel) {
    // Remove the current allocation pointer:
    this.currentAllocation = undefined;

    // Propogate changes to the parent:
    // @ts-expect-error
    this.onCancelHandler(record.get('order'));
  }

  @action onDeleteAllocationHandler() {
    this.currentAllocation = undefined;
    this.args.record.reload();
  }

  ///////////////////////
  // EXECUTION RELATED //
  ///////////////////////

  @action onSaveExecutionHandler() {
    window.location.reload();
  }

  @action onCancelExecutionHandler() {
    window.location.reload();
  }

  @action onDeleteExecutionHandler() {
    window.location.reload();
  }
}
