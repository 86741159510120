import { action } from '@ember/object';
import { CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams(['page', 'page_size', 'search']);

  model(params: Record<string, string | undefined>) {
    return this.store.query('strategy', params);
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
