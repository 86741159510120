import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  queryParams = ['page', 'page_size', 'search', 'name__icontains', 'control', 'portfolios', 'members'];

  page = 1;
  page_size = 10;

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('portfoliogroup.details', record.id) },
      { type: 'delete', onDelete: ({ record }) => record.destroyRecord().then(() => this.send('refreshModel')) },
    ]);
  }
}
