import { Maybe } from './maybe';

export function head<T>(xs: Array<T>): Maybe<T> {
  return xs.length == 0 ? undefined : xs[0];
}

export function tail<T>(xs: Array<T>): Maybe<Array<T>> {
  return xs.length == 0 ? undefined : xs.slice(1);
}

export function last<T>(xs: Array<T>): Maybe<T> {
  return xs.length == 0 ? undefined : xs[xs.length - 1];
}

export function compareStringArrays(x: Array<string>, y: Array<string>): number {
  const xLen = x.length;
  const yLen = y.length;
  const minLength = Math.min(xLen, yLen);

  for (let i = 0; i < minLength; i++) {
    // @ts-expect-error ts2532
    const comparison = x[i].localeCompare(y[i]);
    if (comparison != 0) {
      return comparison;
    }
  }

  return Math.sign(xLen - yLen);
}

export type NonEmptyArray<T> = [T, ...T[]];

export function uniqElements<T>(x: Array<T>): Array<T> {
  return Array.from(new Set(x));
}

export function sortElements<T>(x: Array<T>, compareFn?: ((a: T, b: T) => number) | undefined): Array<T> {
  return [...x].sort(compareFn);
}

export function uniqSortElements<T>(x: Array<T>, compareFn?: ((a: T, b: T) => number) | undefined): Array<T> {
  return sortElements(uniqElements(x), compareFn);
}

export function padArray<T>(x: Array<T>, len: number): Array<Maybe<T>> {
  return len - x.length > 0 ? [...x, ...new Array(len - x.length)] : x;
}
