import Application from '@ember/application';
import * as Sentry from '@sentry/ember';
import config from 'cafelatte/config/environment';
import { konfiguration } from 'cafelatte/libs/embered/config';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

Sentry.InitSentryForEmber({
  release: konfiguration.APP.version,
  ignoreErrors: [
    'Adapter operation failed',
    'Ajax authorization failed',
    'Request Aborted',
    'Request failed with status code 401',
    'The adapter operation was aborted',
    'TransitionAborted',
  ],
  beforeSend: (event: Sentry.Event) => {
    // NOTE: Filter-out events of non-interest here (if any).
    return event;
  },
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
