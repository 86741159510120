import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { CommonController } from 'cafelatte/libs/embered';
import { isNothing } from 'cafelatte/libs/headless/prelude/maybe';
import { Bean } from 'cafelatte/libs/headless/services/beanbag';

function makeMenu(items: Array<Bean>): Record<string, Array<Bean>> {
  const menu: Record<string, Array<Bean>> = {};

  items.forEach((i: Bean) => {
    if (!(i.category in menu)) {
      menu[i.category] = [];
    }

    // @ts-expect-error ts2532
    menu[i.category].push(i);
  });

  return menu;
}

export default class extends CommonController {
  @computed('model.[]')
  get menu() {
    return isEmpty(this.model) || isNothing(this.model) ? {} : makeMenu(this.model);
  }
}
