import { action } from '@ember/object';
import { CommonController } from 'cafelatte/libs/embered';
import RiskProfileModel from 'cafelatte/models/riskprofile';

export default class extends CommonController {
  @action saved(record: RiskProfileModel) {
    record.reload();
    this.router.transitionTo('riskprofile.details', record.id);
  }

  @action cancelled(record: RiskProfileModel) {
    if (record.get('isDeleted')) {
      this.router.transitionTo('riskprofile');
    }
  }

  @action deleted() {
    this.router.transitionTo('riskprofile');
  }
}
