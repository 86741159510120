import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import ActionModel from 'cafelatte/models/trade';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';
import { RecordListComponent } from '../../../commons/record/list';

export default class extends RecordListComponent<ActionModel> {
  @service declare ajax: CLAjaxService;

  get spec(): FlexTableSpec<ActionModel> {
    return {
      ident: 'cl-actions-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'notes', label: 'N', component: '@note', options: { title: 'Internal Notes' } },
        { key: 'remarks', label: 'R', component: '@note', options: { title: 'External Remarks' } },
        { key: 'id', component: '@link', options: { route: 'trade.details', modelPath: 'id' } },
        { key: 'cflag', label: 'Flag', component: '@flag', action: ({ record }) => this.onFlagClicked(record) },
        { key: 'commitment', label: 'Trade Date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'settlement', label: 'Value Date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        {
          key: 'shrcls.name',
          label: 'Share Class',
          component: '@link',
          options: { route: 'shareclass.details', modelPath: 'shrcls.id' },
        },
        {
          key: 'accmain.name',
          label: 'Account',
          component: '@link',
          options: { route: 'account.details', modelPath: 'accmain.id' },
        },
        {
          key: 'resmain.symbol',
          label: 'Currency',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resmain.id' },
        },
        {
          key: 'qtymain',
          label: 'Quantity',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]', colorize: true },
        },
        {
          key: 'pxnavs',
          label: 'NAV/Share',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        {
          key: 'sharecount',
          label: 'Shares',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'agent.name', label: 'Investor' },
        { key: 'promoter.name', label: 'Promoter' },
        ...mkAuditFields<ActionModel>(this.me),
      ],
    };
  }

  onFlagClicked(record: ActionModel): void {
    // Attempt to get the next flag:
    const next = ((record.cflag || 0) + 1) % 6 || null;

    // Update remote:
    this.ajax
      .patch(`/trades/${record.get('id')}/`, {
        data: { cflag: next },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.flashMessages.success(`Record is ${isEmpty(next) ? 'un' : ''}flagged.`);
        record.reload();
      })
      .catch(() => {
        this.flashMessages.danger('Record could not be flagged.');
      });
  }
}
