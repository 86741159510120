import SettlementModel from 'cafelatte/models/settlement';
import { FlexTableColumnSpec, FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

const numberOptions: Partial<FlexTableColumnSpec<any>> = {
  align: 'right',
  component: '@number',
  options: { format: '0,0.00' },
};

export default class extends RecordListComponent<SettlementModel> {
  get spec(): FlexTableSpec<SettlementModel> {
    return {
      ident: 'cl-settlements-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id', component: '@link', options: { route: 'settlement.details', modelPath: 'id' } },
        { key: 'received', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'currency' },
        { key: 'amount', ...numberOptions },
        { key: 'vouchers_amount', label: 'Amount (Recored)', ...numberOptions },
        { key: 'adjustment', ...numberOptions },
        { key: 'phead', label: 'Since', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'plast', label: 'Until', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'invoice', label: 'Invoice' },
        {
          key: 'institution.name',
          label: 'Institution',
          component: '@link',
          options: { route: 'institution.details', modelPath: 'institution.id' },
        },
        {
          key: 'types',
          label: 'Types',
          value: ({ record }) => (record.get('ctypes') || []).map((x: any) => x.name).join(', '),
        },
        { key: 'conv_ccy', label: 'Conv. Currency' },
        {
          key: 'conv_rate',
          label: 'Conv. Rate',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[00]' },
        },
        { key: 'conv_date', label: 'Conv. Date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        ...mkAuditFields<SettlementModel>(this.me),
      ],
    };
  }
}
