import Controller from '@ember/controller';
import Route from '@ember/routing/route';

/**
 * Resets page parameter on the route in case that there we hit a non-existing page on the server.
 *
 * This is a common issue that happens when user first moves to a *next* page and then uses filters which in return
 * result in records less than `page * page_size`, hence an invalid page. This function attempts to catch such errors,
 * transitions to the same route but resetting the page back to 1.
 *
 * @param route Route which received error.
 * @param error Error to be used to find out whether we will transition or ignore in this function.
 */
export function handle404OnNonExistingPage(route: Route, error: any): boolean | void {
  // TODO: transitionTo on route parameter is deprecated. Try to routerService.
  if (error.isAdapterError && 'errors' in error && error.errors.length > 0 && error.errors[0].status == 404) {
    route.transitionTo({ queryParams: { page: 1 } });
  } else {
    return true;
  }
}

/**
 * Compiles standard `queryParams` specification from a given array of query parameter keys.
 */
export function compileQueryParams(keys: Array<string>): Record<string, Record<string, boolean>> {
  return keys.reduce((o, k: string) => {
    return { ...o, [k]: { refreshModel: true } };
  }, {});
}

/**
 * Resets a controller's query params.
 */
export function resetControllerParams(
  controller: Controller,
  properties: Record<string, any>,
  parameters: Array<string>
): void {
  controller.setProperties({
    ...(parameters || []).reduce((o, k) => ({ ...o, [k]: undefined }), {}),
    ...(properties || {}),
  });
}

/**
 * Provides a type alias for query parameter defaults factory.
 */
export type QueryParamDefaultsFactory = Record<string, string | (() => string)>;

/**
 * Provides a helper function for producing the default value for a given query parameter name.
 *
 * @param defaults Defaults factory.
 * @param key Query parameter name.
 */
export function getQueryParamDefault(defaults: QueryParamDefaultsFactory, key: string): string {
  const param = defaults[key];
  // @ts-expect-error ts2722
  return typeof param === 'string' ? param : param();
}

/**
 * Resets a controller's query params for a given default query string record.
 */
export function resetControllerParamsForDefaults(controller: Controller, defaults: QueryParamDefaultsFactory): void {
  // Get query string parameters list:
  const qparams = Object.keys(defaults);

  // Get defaults specification:
  const defaultValues = Array.from(qparams).reduce((o, i) => ({ ...o, [i]: getQueryParamDefault(defaults, i) }), {});

  // Reset controller parameters:
  resetControllerParams(controller, defaultValues, qparams);
}

/**
 * Provides a convenience function for converting a value into a an array of numbers.
 *
 * @param value Value to convert to an array of numbers.
 * @param sep Separator. Defaults to `','`.
 * @returns An array of numbers.
 */
export function queryParamAsNumberArray(value: string | undefined | null, sep = ','): Array<number> {
  return (value || '')
    .split(sep)
    .map((x) => parseInt(x.trim()))
    .filter((x) => !isNaN(x));
}
