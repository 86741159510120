import { helper } from '@ember/component/helper';
import { getOrElse, isNothing, mapMaybe, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { parseSign, PossiblyNumeric } from 'cafelatte/libs/headless/prelude/numeric';

export type ColorMap = [string, string, string];

export function colorClass(value: Maybe<PossiblyNumeric>, map: Maybe<ColorMap>, unknown: Maybe<string>): string {
  // Get the sign of the value:
  const sign = mapMaybe(parseSign, value);

  // Attempt to map to color and return:
  // @ts-expect-error ts2322
  return isNothing(sign)
    ? getOrElse(unknown, 'text-muted')
    : getOrElse(map, ['text-negative', 'text-neutral', 'text-positive'])[sign + 1];
}

export default helper(([value, map, unknown]) => colorClass(value, map, unknown));
