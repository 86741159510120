import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';

export default class extends CommonController {
  queryParams = ['page', 'page_size', 'search', 'date__gte', 'date__lte', 'updated__gte', 'updated__lte'];

  @tracked page = 1;
  @tracked page_size = 20;
  @tracked search = '';
}
