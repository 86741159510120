import { action, notifyPropertyChange } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { identity } from 'cafelatte/libs/headless/prelude';
import { today } from 'cafelatte/libs/headless/prelude/datetime';

export default class extends CommonController {
  @tracked flushPass?: string = undefined;
  @tracked closingDate = today();
  @tracked startingDate = today();
  @tracked transferTo = undefined;
  @tracked transferDate = today();

  @action close(): void {
    if (confirm('Are you sure you want to proceed with this operation?')) {
      this.model
        .doClose(this.closingDate)
        .then(() => {
          this.flashMessages.success('Account is closed successfully.');
          this.model.reload();
          notifyPropertyChange(this, 'stocksDate');
        })
        .catch((e: any) => {
          console.error(e);
          this.flashMessages.danger(`Account could not be closed. Error: ${e.payload.map(identity).join(' ')}`);
        });
    }
  }

  @action transfer(): void {
    if (this.transferTo === undefined || isEmpty(this.transferTo)) {
      this.flashMessages.danger('Please choose the account to transfer to.');
      return;
    }
    if (confirm('Are you sure you want to proceed with this operation?')) {
      this.model
        .doTransfer(this.transferDate, this.transferTo)
        .then(() => {
          this.flashMessages.success('Account is transferred successfully.');
          this.model.reload();
          notifyPropertyChange(this, 'stocksDate');
        })
        .catch((e: any) => {
          console.error(e);
          this.flashMessages.danger(`Account could not be transferred. Error: ${e.payload.map(identity).join(' ')}`);
        });
    }
  }

  @action flushAccount(): void {
    if (this.flushPass != 'FLUSH') {
      this.flashMessages.danger('Type FLUSH to proceed');
      return;
    }

    if (confirm('Are you sure you want to proceed with this operation?')) {
      this.model
        .doFlush()
        .then(() => {
          this.flashMessages.success('Account flushed successfully.');
          notifyPropertyChange(this, 'accountStocks');
        })
        .catch((e: any) => {
          console.error(e);
          this.flashMessages.danger(`Account could not be flushed. Error: ${e.payload || e}`);
        });
    }

    this.flushPass = undefined;
  }

  @action runPConsolidation(): void {
    if (confirm('Are you sure you want to proceed with this operation?')) {
      this.model
        .doTriggerConsolidations(this.startingDate)
        .then(() => this.flashMessages.success('Job is scheduled.'))
        .catch((e: any) => {
          console.error(e);
          this.flashMessages.danger('Job could not be scheduled.');
        });
    }
  }
}
