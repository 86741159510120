import { AccountResource } from 'cafelatte/libs/headless/commons/resources/account-resource';
import { ArtifactResource } from 'cafelatte/libs/headless/commons/resources/artifact-resource';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { NonEmptyArray, uniqElements } from 'cafelatte/libs/headless/prelude/arrays';
import { SDate } from 'cafelatte/libs/headless/prelude/datetime';
import { mapMaybe, mapMaybeOrElse, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { HUNDRED, safeDivide, sumDecimalDefaultZero, ZERO } from 'cafelatte/libs/headless/prelude/numeric';
import Decimal from 'decimal.js';
import saver from 'file-saver';
import PapaParse from 'papaparse';
import { ConsolidationReportAccount } from '../generic-report/-full';
import { ConsolidationReportValuation } from './-shared';

export interface ClassificationItem {
  name: string;
  order: number | string;
}

export type Classification = Array<ClassificationItem>;

export interface HoldingTags {
  classification: Classification;
}

export interface RawHoldingInvestmentFigure {
  px: {
    org: Maybe<number>;
    ref: Maybe<number>;
  };
  txncosts: {
    org: Maybe<number>;
    ref: Maybe<number>;
  };
  accrued: {
    org: Maybe<number>;
    ref: Maybe<number>;
  };
  value: {
    org: Maybe<number>;
    ref: Maybe<number>;
  };
}

export interface RawHoldingValuationFigure {
  px: {
    org: Maybe<number>;
    ref: Maybe<number>;
  };
  accrued: {
    org: Maybe<number>;
    ref: Maybe<number>;
  };
  value: {
    net: {
      org: Maybe<number>;
      ref: Maybe<number>;
    };
    abs: {
      org: Maybe<number>;
      ref: Maybe<number>;
    };
  };
  exposure: {
    net: {
      org: Maybe<number>;
      ref: Maybe<number>;
    };
    abs: {
      org: Maybe<number>;
      ref: Maybe<number>;
    };
  };
}

export interface BaseRawHolding {
  artifact: { id: Id; type: { id: string; name: string; order: number }; underlying_id: Maybe<Id> };
  quantity: number;
  accounts: Array<ConsolidationReportAccount>;
  investment: RawHoldingInvestmentFigure;
  valuation: RawHoldingValuationFigure;
  change: Maybe<number>;
  pnl: Maybe<number>;
  pnl_to_investment: Maybe<number>;
  opendate: SDate;
  lastdate: SDate;
}

export interface RawHolding extends BaseRawHolding {
  tags: HoldingTags;
  children: Array<SubRawHolding>;
}

export type SubRawHolding = BaseRawHolding;

export interface HoldingInvestmentFigure {
  px: {
    org: Maybe<Decimal>;
    ref: Maybe<Decimal>;
  };
  txncosts: {
    org: Maybe<Decimal>;
    ref: Maybe<Decimal>;
  };
  accrued: {
    org: Maybe<Decimal>;
    ref: Maybe<Decimal>;
  };
  value: {
    org: Maybe<Decimal>;
    ref: Maybe<Decimal>;
  };
}

export interface HoldingValuationFigure {
  px: {
    org: Maybe<Decimal>;
    ref: Maybe<Decimal>;
  };
  accrued: {
    org: Maybe<Decimal>;
    ref: Maybe<Decimal>;
  };
  value: {
    net: {
      org: Maybe<Decimal>;
      ref: Maybe<Decimal>;
    };
    abs: {
      org: Maybe<Decimal>;
      ref: Maybe<Decimal>;
    };
  };
  exposure: {
    net: {
      org: Maybe<Decimal>;
      ref: Maybe<Decimal>;
    };
    abs: {
      org: Maybe<Decimal>;
      ref: Maybe<Decimal>;
    };
  };
  ratios: {
    netValue: Decimal;
    netExposure: Decimal;
    absExposure: Decimal;
  };
}

export interface BaseHolding {
  artifact: ArtifactResource;
  underlying: Maybe<ArtifactResource>;
  stockOpenDate: SDate;
  stockLastDate: SDate;
  quantity: Decimal;
  investment: HoldingInvestmentFigure;
  valuation: HoldingValuationFigure;
  change: Decimal;
  unrealizedPNL: Decimal;
  unrealizedPNLRatio: Decimal;
  classification: Classification;
}

export interface Holding extends BaseHolding {
  accounts: NonEmptyArray<AccountResource>;
  children: Array<SubHolding>;
}

export interface SubHolding extends BaseHolding {
  account: AccountResource;
}

export function isTopHolding(holding: Holding | SubHolding): holding is Holding {
  return 'accounts' in holding;
}

export function isSubHolding(holding: Holding | SubHolding): holding is SubHolding {
  return 'account' in holding;
}

export function buildHolding(
  accounts: Record<Id, AccountResource>,
  artifacts: Record<Id, ArtifactResource>,
  valuation: ConsolidationReportValuation,
  raw: RawHolding
): Holding {
  // Get the base holding:
  const baseHolding = buildBaseHolding(artifacts, valuation, raw);

  // Add accounts and children:
  // @ts-expect-error
  baseHolding.accounts = raw.accounts.map((i) => accounts[i.id]);
  // @ts-expect-error
  baseHolding.children = raw.children.map((c) =>
    buildSubHolding(accounts, artifacts, valuation, c, baseHolding.classification)
  );

  // Done, return:
  // @ts-expect-error
  return baseHolding;
}

export function buildSubHolding(
  accounts: Record<Id, AccountResource>,
  artifacts: Record<Id, ArtifactResource>,
  valuation: ConsolidationReportValuation,
  raw: SubRawHolding,
  classification: Classification
): SubHolding {
  // Get the base holding:
  const baseHolding = buildBaseHolding(artifacts, valuation, raw, classification);

  // Add accounts and children:
  // @ts-expect-error
  baseHolding.account = accounts[raw.accounts[0].id];

  // Done, return:
  // @ts-expect-error
  return baseHolding;
}

export function buildBaseHolding(
  artifacts: Record<Id, ArtifactResource>,
  valuation: ConsolidationReportValuation,
  raw: RawHolding | SubRawHolding,
  classification?: Classification
): BaseHolding {
  return {
    // @ts-expect-error ts2322
    artifact: artifacts[raw.artifact.id],
    underlying: raw.artifact.underlying_id ? artifacts[raw.artifact.underlying_id] : undefined,
    stockOpenDate: raw.opendate,
    stockLastDate: raw.lastdate,
    quantity: mapMaybeOrElse((x) => new Decimal(x), ZERO, raw.quantity),
    investment: {
      px: {
        org: mapMaybe((x) => new Decimal(x), raw.investment.px.org),
        ref: mapMaybe((x) => new Decimal(x), raw.investment.px.ref),
      },
      txncosts: {
        org: mapMaybe((x) => new Decimal(x), raw.investment.txncosts.org),
        ref: mapMaybe((x) => new Decimal(x), raw.investment.txncosts.ref),
      },
      accrued: {
        org: mapMaybe((x) => new Decimal(x), raw.investment.accrued.org),
        ref: mapMaybe((x) => new Decimal(x), raw.investment.accrued.ref),
      },
      value: {
        org: mapMaybe((x) => new Decimal(x), raw.investment.value.org),
        ref: mapMaybe((x) => new Decimal(x), raw.investment.value.ref),
      },
    },
    valuation: {
      px: {
        org: mapMaybe((x) => new Decimal(x), raw.valuation.px.org),
        ref: mapMaybe((x) => new Decimal(x), raw.valuation.px.ref),
      },
      accrued: {
        org: mapMaybe((x) => new Decimal(x), raw.valuation.accrued.org),
        ref: mapMaybe((x) => new Decimal(x), raw.valuation.accrued.ref),
      },
      value: {
        net: {
          org: mapMaybe((x) => new Decimal(x), raw.valuation.value.net.org),
          ref: mapMaybe((x) => new Decimal(x), raw.valuation.value.net.ref),
        },
        abs: {
          org: mapMaybe((x) => new Decimal(x), raw.valuation.value.abs.org),
          ref: mapMaybe((x) => new Decimal(x), raw.valuation.value.abs.ref),
        },
      },
      exposure: {
        net: {
          org: mapMaybe((x) => new Decimal(x), raw.valuation.exposure.net.org),
          ref: mapMaybe((x) => new Decimal(x), raw.valuation.exposure.net.ref),
        },
        abs: {
          org: mapMaybe((x) => new Decimal(x), raw.valuation.exposure.abs.org),
          ref: mapMaybe((x) => new Decimal(x), raw.valuation.exposure.abs.ref),
        },
      },
      ratios: {
        netValue: new Decimal(raw.valuation.value.net.ref || 0).dividedBy(valuation.nav),
        netExposure: new Decimal(raw.valuation.exposure.net.ref || 0).dividedBy(valuation.nav),
        absExposure: new Decimal(raw.valuation.exposure.abs.ref || 0).dividedBy(valuation.nav),
      },
    },
    change: mapMaybeOrElse((x) => new Decimal(x), ZERO, raw.change),
    unrealizedPNL: mapMaybeOrElse((x) => new Decimal(x), ZERO, raw.pnl),
    unrealizedPNLRatio: mapMaybeOrElse((x) => new Decimal(x), ZERO, raw.pnl_to_investment),
    // @ts-expect-error
    classification: raw?.tags?.classification || classification,
  };
}

export function _extractTeam(h: Holding | SubHolding): string {
  if (isSubHolding(h)) {
    return h.account.team_name;
  }

  const teams = uniqElements(h.accounts.map((x) => x.team_name));

  // @ts-expect-error ts2322
  return teams.length == 1 ? teams[0] : `Multiple (${teams.length})`;
}

export function _extractPortfolio(h: Holding | SubHolding): string {
  if (isSubHolding(h)) {
    return h.account.portfolio_name;
  }

  const teams = uniqElements(h.accounts.map((x) => x.portfolio_name));

  // @ts-expect-error ts2322
  return teams.length == 1 ? teams[0] : `Multiple (${teams.length})`;
}

export function _extractCustodian(h: Holding | SubHolding): string {
  if (isSubHolding(h)) {
    return h.account.custodian_name;
  }

  const teams = uniqElements(h.accounts.map((x) => x.custodian_name));

  // @ts-expect-error ts2322
  return teams.length == 1 ? teams[0] : `Multiple (${teams.length})`;
}

export function _extractAccount(h: Holding | SubHolding): string {
  if (isSubHolding(h)) {
    return h.account.name;
  }

  const teams = uniqElements(h.accounts.map((x) => x.name));

  // @ts-expect-error ts2322
  return teams.length == 1 ? teams[0] : `Multiple (${teams.length})`;
}

export function _extractClassification(h: Holding | SubHolding): string {
  return h.classification.map((x) => x.name).join(' | ');
}

export function _extractClassificationNode(idx: number, h: Holding | SubHolding): string {
  return h.classification[idx]?.name || '';
}

type CellValue = Maybe<string | number>;

export function csvExtractor(
  totalValue: Decimal
): Array<[column: string, func: (h: Holding | SubHolding) => CellValue | Array<CellValue>]> {
  return [
    ['Breakdown', (h) => (isSubHolding(h) ? 'True' : 'False')],
    ['Team', _extractTeam],
    ['Portfolio', _extractPortfolio],
    ['Custodian', _extractCustodian],
    ['Account', _extractAccount],
    ['Classification 1', (x) => _extractClassificationNode(0, x)],
    ['Classification 2', (x) => _extractClassificationNode(1, x)],
    ['Classification 3', (x) => _extractClassificationNode(2, x)],
    ['Classification 4', (x) => _extractClassificationNode(3, x)],
    ['Classification', _extractClassification],
    ['Symbol', (h) => h.artifact.symbol],
    ['Name', (h) => h.artifact.name],
    ['CCY', (h) => h.artifact.ccymain],
    ['Type', (h) => h.artifact.type],
    ['Type Code', (h) => h.artifact.ctype],
    ['Sub Type', (h) => h.artifact.stype],
    ['Country', (h) => h.artifact.country],
    ['Issuer', (h) => h.artifact.issuer],
    ['Sector', (h) => h.artifact.sector],
    ['Market', (h) => h.artifact.mic],
    ['Ticker', (h) => h.artifact.ticker],
    ['ISIN', (h) => h.artifact.isin],
    ['FIGI', (h) => h.artifact.figi],
    ['Expiry', (h) => h.artifact.expiry],
    ['Underlying (Symbol)', (h) => h.underlying?.symbol],
    ['Underlying (ISIN)', (h) => h.underlying?.isin],
    ['QTY', (h) => h.quantity.toNumber()],
    ['PX Cost', (h) => h.investment.px.org?.toNumber()],
    ['PX Last', (h) => h.valuation.px.org?.toNumber()],
    ['PX Chg%', (h) => h.change.toNumber()],
    ['Investment', (h) => h.investment.value.ref?.toNumber()],
    ['Accrued', (h) => h.valuation.accrued.ref?.toNumber()],
    ['Value', (h) => h.valuation.value.net?.ref?.toNumber()],
    [
      'Value %',
      (h) => mapMaybe((x) => x.times(HUNDRED).toNumber(), safeDivide(h.valuation.value.net?.ref || ZERO, totalValue)),
    ],
    ['PnL', (h) => h.unrealizedPNL.toNumber()],
    ['PnL/Inv', (h) => h.unrealizedPNLRatio.toNumber()],
    ['Net Exposure', (h) => h.valuation.exposure.net?.ref?.toNumber()],
    [
      'Net Exposure%',
      (h) =>
        mapMaybe((x) => x.times(HUNDRED).toNumber(), safeDivide(h.valuation.exposure.net?.ref || ZERO, totalValue)),
    ],
    ['Abs Exposure', (h) => h.valuation.exposure.abs?.ref?.toNumber()],
    [
      'Abs Exposure%',
      (h) =>
        mapMaybe((x) => x.times(HUNDRED).toNumber(), safeDivide(h.valuation.exposure.abs?.ref || ZERO, totalValue)),
    ],
  ];
}

// ooClassification

export function _extract(
  func: (h: Holding | SubHolding) => CellValue | Array<CellValue>,
  holding: Holding | SubHolding
): Array<CellValue> {
  const value = func(holding);
  return value instanceof Array ? value : [value];
}

export function toCSV(holdings: Array<Holding>): Blob {
  // If no holdings, return as is:
  if (!holdings.length) {
    return new Blob([PapaParse.unparse({ fields: [], data: [] })], {
      type: 'text/csv;charset=utf-8',
    });
  }

  // Initialize the content buffer:
  const content: Array<Array<any>> = [];

  // Get extractor:
  const extractor = csvExtractor(sumDecimalDefaultZero(holdings, (h) => h.valuation.value.net.ref));

  // Get the header:
  const header = extractor.map(([x, _]) => [x]).reduce((p, c) => [...p, ...c], []);

  // Iterate over holdings and populate contents:
  for (const holding of holdings) {
    content.pushObject(extractor.map(([_, f]) => _extract(f, holding)).reduce((p, c) => [...p, ...c], []));
    for (const subholding of holding.children) {
      content.pushObject(extractor.map(([_, f]) => _extract(f, subholding)).reduce((p, c) => [...p, ...c], []));
    }
  }

  // Done, return the CSV
  return new Blob([PapaParse.unparse({ fields: header, data: content })], {
    type: 'text/csv;charset=utf-8',
  });
}

export function downloadHoldings(holdings: Array<Holding>): void {
  saver.saveAs(toCSV(holdings), 'holdings.csv');
}
