import { action } from '@ember/object';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import AccountManagementFeeSchemeModel from 'cafelatte/models/account/mfeescheme';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends ControllerWithCurrentRecord<AccountManagementFeeSchemeModel> {
  get tableOperations(): FlexTableRowAction<AccountManagementFeeSchemeModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doReset },
    ]);
  }

  createNewRecord(): AccountManagementFeeSchemeModel {
    return this.store.createRecord('account/mfeescheme', { account: this.model });
  }

  @action doReset() {
    this.doResetCurrentRecord();
    this.model.reload();
  }

  @action onError(_message: string, error: any): void {
    console.error(error);
  }
}
