import axios from 'axios';
import { CommonController } from 'cafelatte/libs/embered';
// @ts-expect-error
import { storageFor } from 'ember-local-storage';

export default class extends CommonController {
  @storageFor('appstate') declare appstate: any;

  get modalTerms(): boolean {
    return this.appstate.get('showTermsAndConditions');
  }

  set modalTerms(x: boolean) {
    this.appstate.set('showTermsAndConditions', x);
  }

  get tnc() {
    return axios.get<string>(this.konfig.konfiguration.APP.publicConfiguration.tnc).then((r) => r.data);
  }
}
