import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  queryParams = [
    'page',
    'page_size',
    'search',
    'date_joined__gte',
    'date_joined__lte',
    'last_login__gte',
    'last_login__lte',
    'username__icontains',
    'first_name__icontains',
    'last_name__icontains',
    'email__icontains',
    'mobile__icontains',
    'is_active',
    'teams',
    'roles',
    'portfolio_groups',
  ];

  @tracked page = 1;
  @tracked page_size = 20;
  @tracked is_active = '';

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('user.details', record.id) },
    ]);
  }
}
