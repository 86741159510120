import { CommonRoute, DetailRouteParams, getDetailRouteModel } from 'cafelatte/libs/embered';
import PortfolioModel from 'cafelatte/models/portfolio';

export default class extends CommonRoute {
  model(params: DetailRouteParams) {
    return getDetailRouteModel(this.store, 'portfolio', params);
  }

  afterModel(model: PortfolioModel) {
    if (model.get('isNew')) {
      this.transitionTo('portfolio.details.profile', '__new__');
    }
  }
}
