import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { BelongsTo, DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = ['name', 'order', 'description', 'parent'];

export default class AssetClassModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @attr()
  declare order?: string;

  @attr()
  declare description?: string;

  @belongsTo('assetclass', { inverse: 'children' })
  declare parent?: BelongsTo<AssetClassModel>;

  @hasMany('assetclass')
  declare children?: HasMany<AssetClassModel>;

  @hasMany('assetclass', { inverse: null })
  declare ancestors?: HasMany<AssetClassModel>;

  @attr()
  declare path?: Array<string>;

  @computed('path.[]')
  get address(): string | undefined {
    return this.path?.join(' » ');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    assetclass: AssetClassModel;
  }
}
