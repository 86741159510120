import Store from '@ember-data/store';
import { action, computed, set } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { uniqElements } from 'cafelatte/libs/headless/prelude/arrays';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { resolve } from 'rsvp';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

/**
 * Ugly hack to overcome an issue with ember-power-select.
 *
 * When `@allowClear={{true}}`:
 *
 * 1. Returning null did not clear the selected.
 * 2. Returning new Promise((r) => r(null)) cleared the selected but broke component (couldn't select anything again).
 * 3. Only below promised null was OK.
 *
 * TODO: Follow future releases to remove this hack.
 */
function promisedNull() {
  return ObjectPromiseProxy.create({ promise: resolve(null) });
}

interface Args {
  model: string;
  selected: Maybe<any>;
  multiple: Maybe<boolean>;
  byid: Maybe<boolean>;
  label: Maybe<string>;
  property: Maybe<string>;
  empty: Maybe<any>;
  filter: Maybe<boolean>;
  searchKey: Maybe<string>;
  extraPayload: Maybe<any>;
  initialOptions: Maybe<Array<any>>;
  allowClear: Maybe<boolean>;
  placeholder: Maybe<string>;
  disabled?: Maybe<boolean>;
  onChange?: Maybe<(x: any) => void>;
  onCreate?: Maybe<(x: any) => void>;
}

export default class RMISelectComponent extends Component<Args> {
  @service declare store: Store;

  get component(): string {
    return this.args.multiple
      ? 'power-select-multiple'
      : this.args.onCreate
      ? 'power-select-with-create'
      : 'power-select';
  }

  get placeholder(): string {
    return this.args.placeholder || 'Select';
  }

  get allowClear(): boolean {
    return isNothing(this.args.allowClear) ? true : this.args.allowClear;
  }

  getRecord(id: Id) {
    return this.store.peekRecord(this.args.model, id) || this.store.findRecord(this.args.model, id);
  }

  @computed('args.{selected,multiple,byid,model}', function () {
    if (isEmpty(this.args.selected) || this.args.selected === 'null' || this.args.selected === 'undefined') {
      // See `promisedNull` definition why we don't return `null` here.
      return this.args.multiple ? [] : promisedNull();
    }

    if (!this.args.byid) {
      return this.args.selected;
    }

    return this.args.multiple
      ? // @ts-expect-error ts2345
        uniqElements(this.args.selected).map((x: Id) => this.getRecord(x))
      : this.getRecord(this.args.selected);
  })
  value!: any;

  @computed('args.initialOptions', {
    get() {
      return isPresent(this._initialOptions) ? this._initialOptions : this.args.initialOptions || [];
    },

    set(_, value) {
      return (this._initialOptions = value);
    },
  })
  initialOptions!: any;

  searchTimeout?: NodeJS.Timeout;

  doDebouncedSearch(keyword: string, timeout: number): any {
    const { model, filter, extraPayload } = this.args;

    if (isEmpty(model)) {
      return [];
    }

    if (this.searchTimeout !== undefined) {
      clearTimeout(this.searchTimeout);
    }

    // We can overrde search key:
    const search = { [isNothing(this.args.searchKey) ? 'search' : this.args.searchKey]: keyword, page_size: 50 };

    // Build parameters:
    const params = filter === true ? { id: keyword } : { ...search, ...(extraPayload || {}) };

    return new Promise(
      (resolve) => (this.searchTimeout = setTimeout(() => resolve(this.store.query(model, params)), timeout))
    );
  }

  @action doSearch(keyword: string): any {
    return this.doDebouncedSearch(keyword, 500);
  }

  @action onChange(item: any): void {
    const onChange = this.args.onChange || (() => false);

    if (isEmpty(item)) {
      onChange(this.args.multiple ? [] : this.args.empty || null);
    } else if (isEmpty(this.args.property)) {
      onChange(item);
    } else {
      onChange(this.args.multiple ? item.map((i: any) => i.get(this.args.property)) : item.get(this.args.property));
    }
  }

  @action onCreate(value: any): void {
    (this.args.onCreate || (() => false))(value);
  }

  @action onOpen() {
    if ((this.args.initialOptions || []).length === 0) {
      set(this, 'initialOptions', this.doDebouncedSearch('', 800));
    }
  }
}
