import { inject as service } from '@ember/service';
import { CommonRoute, DetailRouteParams } from 'cafelatte/libs/embered';
import TradingService from 'cafelatte/services/trading';

export default class extends CommonRoute {
  @service declare trading: TradingService;

  model(params: DetailRouteParams) {
    return params.id === '__new__' ? this.trading.createTradeOrder() : this.store.findRecord('tradeorder', params.id);
  }
}
