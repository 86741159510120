import { inject as service } from '@ember/service';
import { cleanParams, CommonRoute, compileQueryParams } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonRoute {
  @service declare ajax: CLAjaxService;

  queryParams = compileQueryParams(['portfolio', 'ccy', 'asof', 'dtype']);

  model(params: any) {
    return params.portfolio
      ? this.ajax.request('/pnlbooks/', {
          data: cleanParams({
            portfolio: params.portfolio,
            currency: params.ccy,
            dateasof: params.asof,
            datetype: params.dtype,
          }),
          traditional: true,
        })
      : undefined;
  }
}
