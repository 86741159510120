import PortfolioCreditLineModel from 'cafelatte/models/portfoliocreditline';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<PortfolioCreditLineModel> {
  get spec(): FlexTableSpec<PortfolioCreditLineModel> {
    return {
      ident: 'cl-portfolio-credit-line-list-table',
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'since', label: 'Since', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'until', label: 'Until', component: '@date', options: { format: 'DD-MM-YYYY' } },
        {
          key: 'portfolio.name',
          label: 'Portfolio',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'portfolio.id' },
        },
        { key: 'currency' },
        { key: 'quantity', label: 'Quantity', component: '@number', options: { format: '0,0.00' } },
        ...mkAuditFields<PortfolioCreditLineModel>(this.me),
      ],
    };
  }
}
