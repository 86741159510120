import UserModel from 'cafelatte/models/user';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<UserModel> {
  get spec(): FlexTableSpec<UserModel> {
    return {
      ident: 'cl-user-list-table',
      vfill: true,
      actions: this.args.operations || [],
      rowClasses: [
        ({ record }) => [
          record.isInactive ? 'table-danger' : !record.groups?.any((r) => r.internal) ? 'table-info' : '',
        ],
      ],
      columns: [
        { key: 'id', component: '@link', options: { route: 'user.details', modelPath: 'id' } },
        { key: 'username' },
        { key: 'fullName', label: 'Full Name' },
        { key: 'email', label: 'E-Mail Address' },
        { key: 'mobile', label: 'Mobile Number' },
        { key: 'internal', value: ({ record }) => record.groups?.any((r) => r.internal) || false, component: '@bool' },
        {
          key: 'teams',
          value: ({ record }) => record.teams?.map((r) => r.name)?.join(', '),
          options: { truncate: 30 },
        },
        {
          key: 'roles',
          value: ({ record }) => record.groups?.map((r) => r.shortName)?.join(', '),
          options: { truncate: 30 },
        },
        {
          key: 'portfolioGroups',
          label: 'Portfolio Groups',
          value: ({ record }) => record.portfolioGroups?.map((r) => r.name)?.join(', '),
          options: { truncate: 30 },
        },
        { key: 'dateJoined', label: 'Date Joined', component: '@date', options: { format: 'human' } },
        { key: 'lastLogin', label: 'Last Activity', component: '@date', options: { format: 'human' } },
      ],
    };
  }
}
