import { PrivateConfiguration } from 'cafelatte/libs/embered/config';
import KonfigService from 'cafelatte/services/konfig';
import MeService from 'cafelatte/services/me';

export type Predicate<T> = (x: T) => boolean;

export type Predicator<K extends string, T> = Record<K, Predicate<T>>;

export type AuthPredicator = Predicator<'*' | 'e' | 'i' | 'p', MeService>;

export type RolePredicator = Predicator<'*' | 'accountant', MeService>;

export type FuncPredicator = Predicator<'*' | 'pm' | 'fm' | 'eb' | 'pt', PrivateConfiguration | undefined>;

export type Navigation = Array<NavItem | NavGroup>;

export interface NavNode {
  name: string;
  icon?: string;
  auth?: Array<keyof AuthPredicator>;
  role?: Array<keyof RolePredicator>;
  func?: Array<keyof FuncPredicator>;
}

export interface NavItem extends NavNode {
  route: string;
  model?: string;
  query?: Record<string, string>;
}

export interface NavGroup extends NavNode {
  navs: Navigation;
}

const authPredicator: AuthPredicator = {
  '*': (x) => x.external || x.internal || x.privileged,
  e: (x) => x.external,
  i: (x) => x.internal,
  p: (x) => x.privileged,
};

const rolePredicator: RolePredicator = {
  '*': (_) => true,
  accountant: (x) => x.privileged || x.roleCodes.includes('accountant'),
};

const funcPredicator: FuncPredicator = {
  '*': (x) => x?.functions.portmngt || x?.functions.fundmngt || x?.functions.ebanking || x?.functions.pretrade || false,
  pm: (x) => x?.functions.portmngt || false,
  fm: (x) => x?.functions.fundmngt || false,
  eb: (x) => x?.functions.ebanking || false,
  pt: (x) => x?.functions.pretrade || false,
};

export function canDisplay(node: NavNode, me: MeService, konfig: KonfigService): boolean {
  return (
    (node.auth || ['*']).any((x) => authPredicator[x](me)) &&
    (node.role || ['*']).any((x) => rolePredicator[x](me)) &&
    (node.func || ['*']).any((x) => funcPredicator[x](konfig.konfiguration.APP.privateConfiguration))
  );
}

export const catalogue: Record<string, NavItem> = {
  consolidation: { name: 'Consolidation', icon: 'th-list', route: 'reports.consolidation' },
  portfolioreport: { name: 'Portfolio Report', icon: 'th-list', route: 'reports.portfolio' },
  fundreport: { name: 'Fund Report', icon: 'th-list', route: 'reports.fund' },
  dashboard: { name: 'Dashboard', icon: 'tachometer-alt', route: 'index' },
  action: { name: 'Trades', icon: 'th-large', route: 'trade' },
  quant: { name: 'Transactions', icon: 'th', route: 'quant' },
  artifact: { name: 'Instruments', icon: 'certificate', route: 'resource' },
  portfolio: { name: 'Portfolios', icon: 'briefcase', route: 'portfolio' },
  account: { name: 'Accounts', icon: 'folder', route: 'account' },
  voucher: { name: 'Vouchers', auth: ['i', 'p'], route: 'voucher' },
  settlement: { name: 'Settlements', auth: ['i', 'p'], role: ['accountant'], route: 'settlement' },
  myaccount: { name: 'My Profile & Password', route: 'profile' },
  user: { name: 'System Users', auth: ['p'], route: 'user' },
  portfoliogroup: { name: 'Portfolio Groups', auth: ['p'], route: 'portfoliogroup' },
  permissionmatrix: { name: 'Permission Matrix', auth: ['p'], route: 'permissions' },
  apikey: { name: 'API Keys', auth: ['p'], route: 'apikey' },
  cashstatement: { name: 'Cash Statement', route: 'cashstatement' },
  pnlbook: { name: 'PnL Book', route: 'pnlbook' },
  ledger: { name: 'Ledger', route: 'ledger' },
  beanbag: { name: 'Custom Reports', route: 'beanbag' },
  trading: { name: 'Pre-Trade Functions', auth: ['i', 'p'], func: ['pt'], route: 'trading' },
  simulation: { name: 'Pre-Trade Simulation', auth: ['i', 'p'], func: ['pt'], route: 'trading.simulation' },
  strategy: { name: 'Strategies', auth: ['i', 'p'], route: 'strategy' },
  analyticaltype: { name: 'Analytical Types', auth: ['i', 'p'], route: 'analyticaltype' },
  accrualschedule: { name: 'Accrual Schedules', auth: ['i', 'p'], route: 'accrualschedule' },
  shareclass: { name: 'Share Classes', auth: ['i', 'p'], func: ['fm'], route: 'shareclass' },
  scfeeschedule: { name: 'Share Class Fee Schedules', auth: ['i', 'p'], func: ['fm'], route: 'scfeeschedule' },
  externalvaluation: {
    name: 'External Portfolio/Share Class Valuations',
    auth: ['i', 'p'],
    route: 'externalvaluation',
  },
  externalvaluationAccount: {
    name: 'External Account Valuations',
    auth: ['i', 'p'],
    route: 'externalaccountvaluation',
  },
  riskprofile: { name: 'Risk Profiles', auth: ['i', 'p'], route: 'riskprofile' },
  complianceProfiles: { name: 'Compliance Profiles', auth: ['i', 'p'], route: 'compliance.profiles' },
  compliancecheck: { name: 'Compliance Checks', auth: ['i', 'p'], route: 'compliancecheck' },
  complianceexemption: { name: 'Compliance Exemptions', auth: ['i', 'p'], route: 'complianceexemption' },
  complianceQuickreport: { name: 'Portfolio Compliance', auth: ['i', 'p'], route: 'compliance.quickreport' },
  team: { name: 'Teams', auth: ['p'], route: 'team' },
  assetclass: { name: 'Asset Classes', auth: ['i', 'p'], route: 'assetclass' },
  price: { name: 'Prices', route: 'ohlc.observation' },
  document: { name: 'Document Library', route: 'document' },
  institution: { name: 'Institutions', auth: ['i', 'p'], route: 'institution' },
  agent: { name: 'Agents', auth: ['i', 'p'], route: 'agent' },
  artifactattribute: { name: 'Instrument Attributes', auth: ['i', 'p'], route: 'artifactattribute' },
  daycounts: { name: 'Day Count Conventions', auth: ['i', 'p'], route: 'daycounts' },
  stockcomparison: { name: 'Stock Comparison', route: 'dashboard.stockcomparison' },
};

export const catalogueSections: Record<string, NavGroup> = {
  definitions: {
    name: 'Definitions',
    auth: ['i', 'p'],
    // @ts-expect-error ts2322
    navs: [catalogue.artifactattribute, catalogue.daycounts],
  },
  compliance: {
    name: 'Compliance',
    auth: ['i', 'p'],
    navs: [
      // @ts-expect-error ts2322
      catalogue.riskprofile,
      // @ts-expect-error ts2322
      catalogue.complianceProfiles,
      // @ts-expect-error ts2322
      catalogue.compliancecheck,
      // @ts-expect-error ts2322
      catalogue.complianceexemption,
      // @ts-expect-error ts2322
      catalogue.complianceQuickreport,
    ],
  },
  reporting: {
    name: 'Reporting',
    // @ts-expect-error ts2322
    navs: [catalogue.beanbag, catalogue.cashstatement, catalogue.stockcomparison, catalogue.pnlbook, catalogue.ledger],
  },
  pretrade: {
    name: 'Trading',
    auth: ['i', 'p'],
    func: ['pt'],
    // @ts-expect-error ts2322
    navs: [catalogue.trading, catalogue.simulation],
  },
  mysettings: {
    name: 'My Settings',
    // @ts-expect-error ts2322
    navs: [catalogue.myaccount],
  },
  administration: {
    name: 'System Administration',
    auth: ['p'],
    // @ts-expect-error ts2322
    navs: [catalogue.team, catalogue.user, catalogue.portfoliogroup, catalogue.permissionmatrix, catalogue.apikey],
  },
};

export const predefined: Navigation = [
  // @ts-expect-error ts2322
  catalogue.dashboard,
  {
    name: 'Reports',
    icon: 'chart-line',
    // @ts-expect-error ts2322
    navs: [catalogue.consolidation, catalogue.portfolioreport, catalogue.fundreport],
  },
  // @ts-expect-error ts2322
  catalogue.action,
  // @ts-expect-error ts2322
  catalogue.quant,
  // @ts-expect-error ts2322
  catalogue.artifact,
  // @ts-expect-error ts2322
  catalogue.portfolio,
  // @ts-expect-error ts2322
  catalogue.account,
  {
    name: 'Accounting',
    icon: 'money-bill',
    auth: ['i', 'p'],
    navs: [
      // @ts-expect-error ts2322
      catalogue.voucher,
      // @ts-expect-error ts2322
      catalogue.settlement,
      // @ts-expect-error ts2322
      catalogue.analyticaltype,
      // @ts-expect-error ts2322
      catalogue.accrualschedule,
      // @ts-expect-error ts2322
      catalogue.shareclass,
      // @ts-expect-error ts2322
      catalogue.scfeeschedule,
      // @ts-expect-error ts2322
      catalogue.externalvaluation,
      // @ts-expect-error ts2322
      catalogue.externalvaluationAccount,
    ],
  },
  {
    name: 'Extras',
    icon: 'cubes',
    navs: [
      // @ts-expect-error ts2322
      catalogue.price,
      // @ts-expect-error ts2322
      catalogue.document,
      // @ts-expect-error ts2322
      catalogue.institution,
      // @ts-expect-error ts2322
      catalogue.agent,
      // @ts-expect-error ts2322
      catalogue.assetclass,
      // @ts-expect-error ts2322
      catalogue.strategy,
      // @ts-expect-error ts2322
      catalogueSections.reporting,
      // @ts-expect-error ts2322
      catalogueSections.pretrade,
      // @ts-expect-error ts2322
      catalogueSections.compliance,
      // @ts-expect-error ts2322
      catalogueSections.definitions,
    ],
  },
  {
    name: 'Manage',
    icon: 'cogs',
    // @ts-expect-error ts2322
    navs: [catalogueSections.mysettings, catalogueSections.administration],
  },
];
