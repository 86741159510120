import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { handleFlexTableResize } from 'cafelatte/helpers/flex-table-resize-handler';
import { saveBlob, saveDataUri } from 'cafelatte/libs/headless/prelude/downloading';
import DecafService from 'cafelatte/services/decaf';
import KonfigService from 'cafelatte/services/konfig';
import html2canvas from 'html2canvas';
import PapaParse from 'papaparse';
import { FlexTable, FlexTableColumnSpec, FlexTableRecords, FlexTableRowAction, FlexTableSpec } from './';
import { FlexTableRowClassFunction } from './-internal/runtime';
import { mkFlexTable } from './-internal/spec';

interface Args<T> {
  spec?: FlexTableSpec<T>;
  ident?: string;
  columns?: FlexTableColumnSpec<T>[];
  actions?: FlexTableRowAction<T>[];
  rowClasses?: FlexTableRowClassFunction<T>[];
  vfill?: boolean;
  empty?: string;
  disableFooter?: boolean;
  disableFooterActions?: boolean;
  options?: Record<string, any>;
  records?: FlexTableRecords<T>;
  isLoading?: boolean;
}

export default class FlexTableComponent<T> extends Component<Args<T>> {
  @service declare decaf: DecafService;
  @service declare flashMessages: any;
  @service declare konfig: KonfigService;

  @tracked tableElement?: HTMLElement;

  get spec(): FlexTableSpec<T> {
    return {
      ident: this.args.spec?.ident || this.args.ident || 'cl-unknown-table',
      columns: this.args.spec?.columns || this.args.columns,
      actions: this.args.spec?.actions || this.args.actions,
      rowClasses: this.args.spec?.rowClasses || this.args.rowClasses,
      vfill: this.args.spec?.vfill || this.args.vfill,
      emptyTableMessage: this.args.spec?.emptyTableMessage || this.args.empty,
      options: this.args.spec?.options || this.args.options,
      disableFooter: this.args.spec?.disableFooter || this.args.disableFooter,
      disableFooterActions: this.args.spec?.disableFooterActions || this.args.disableFooterActions,
      didInsert: this.args.spec?.didInsert,
      willDestroy: this.args.spec?.willDestroy,
    };
  }

  get table(): FlexTable<T> {
    return mkFlexTable(this.spec);
  }

  getTableData(formatted: boolean, element?: Element): string[][] {
    // If no element is provided, return empty data:
    if (element === undefined) {
      return [];
    }

    // Define the attribute to retrieve the data from:
    const attr = formatted ? 'data-flex-table-cell-value' : 'data-flex-table-cell-data-value';

    // Define cell selector query:
    const select = `[date-flex-table-cell]${formatted ? '[data-flex-table-cell-hidden="0"]' : ''}`;

    // Attempt to get header:
    const header = [...(element.querySelector('thead > tr')?.querySelectorAll(select) || [])].map(
      (e) => e.getAttribute(attr) || ''
    );

    // Attempt to get content:
    const content = [...element.querySelectorAll('tbody tr')].map((row) =>
      [...row.querySelectorAll(select)].map((e) => e.getAttribute(attr) || '')
    );

    // Concat data and return:
    return [header, ...content];
  }

  downloadCsv(tabledata: string[][], filename: string): void {
    // Convert to CSV:
    const data = PapaParse.unparse(tabledata);

    // Create a blob of data:
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

    // Save it, nicely:
    saveBlob(blob, filename);
  }

  downloadXlsx(tabledata: string[][], filename: string): void {
    // Convert to CSV:
    const payload = PapaParse.unparse(tabledata);

    // Post to convert:
    this.decaf.client.bare
      .request<Blob>({
        url: '/apis/function/csv2xlsx',
        method: 'post',
        data: payload,
        headers: { 'Content-Type': 'text/csv' },
        responseType: 'blob',
      })
      .then((response) => saveBlob(response.data, filename));
  }

  @action onElement(element: HTMLElement) {
    this.tableElement = element;
    if (this.spec?.vfill) {
      handleFlexTableResize();
      window.addEventListener('resize', handleFlexTableResize);
    }
    this.spec.didInsert?.(element);
  }

  @action unregisterElement(): void {
    super.willDestroy();
    if (this.spec?.vfill) {
      window.removeEventListener('resize', handleFlexTableResize); // remove listeners
    }
    this.args.spec?.willDestroy?.();
  }

  @action onCopy(formatted?: boolean) {
    // Attempt to get the table data:
    const tabledata = this.getTableData(formatted || false, this.tableElement);

    // Convert to tab seperated values:
    const data = PapaParse.unparse(tabledata, { delimiter: '\t' });

    // Copy to clipboard:
    navigator.clipboard
      .writeText(data)
      .then(() => this.flashMessages.success('Table data is copied to clipboard'))
      .catch((error) => {
        console.error(error);
        this.flashMessages.danger('Table data could not be copied to clipboard');
      });
  }

  @action onDownload(formatted?: boolean, filetype?: 'csv' | 'xlsx') {
    // Ensure filetype:
    filetype = filetype || 'csv';

    // Attempt to get the table data:
    const tabledata = this.getTableData(formatted || false, this.tableElement);

    // Build the file name:
    const filename = `${this.table.ident || 'tabledata'}_${formatted ? 'formatted' : 'unformatted'}.${filetype}`;

    // Download as per filetype:
    if (filetype == 'csv') {
      this.downloadCsv(tabledata, filename);
    } else if (filetype == 'xlsx') {
      this.downloadXlsx(tabledata, filename);
    } else {
      console.error(`Unknown filetype: ${filetype}`);
    }
  }

  @action onScreenshot() {
    // If no element is available, return empty data:
    if (this.tableElement === undefined) {
      return;
    }

    // Take a screenshot:
    html2canvas(this.tableElement).then((canvas) => {
      saveDataUri(canvas.toDataURL(), `${this.table.ident || 'tabledata'}_screenshot.png`);
    });
  }
}
