import TradeOrderModel from 'cafelatte/models/tradeorder';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../../commons/record/list';

export default class extends RecordListComponent<TradeOrderModel> {
  get spec(): FlexTableSpec<TradeOrderModel> {
    return {
      ident: 'cl-trade-order-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id', component: '@link', options: { route: 'trading.tradeorder.details', modelPath: 'id' } },
        {
          key: 'team.name',
          label: 'Trading Team',
          component: '@link',
          options: { route: 'trading.tradeteam.details', modelPath: 'team.id' },
        },
        {
          key: 'group.id',
          label: 'Group',
          component: '@link',
          options: { route: 'trading.tradeordergroup.details', modelPath: 'group.id' },
        },
        { key: 'narrative' },
        { key: 'cstate.name', label: 'State' },
        { key: 'date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'time' },
        { key: 'instructed', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'validuntil', label: 'Valid Until', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'ctype.name', label: 'Trade Type' },
        { key: 'otype.name', label: 'Order Type' },
        { key: 'ttype.name', label: 'Time-to-Live' },
        { key: 'direction.name', label: 'Direction' },
        {
          key: 'trader.name',
          label: 'Trader',
          component: '@link',
          options: { route: 'agent.details', modelPath: 'trader.id' },
        },
        {
          key: 'resmain.symbol',
          label: 'Main Instrument',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resmain.id' },
        },
        {
          key: 'resaltn.symbol',
          label: 'Altn Insrument',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resaltn.id' },
        },
        { key: 'pxmain', label: 'Price', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        {
          key: 'pxfact',
          label: 'Price Factor',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        {
          key: 'pxaltn',
          label: 'Altn Price',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'pxflip', label: 'Price Flip' },
        { key: 'notes' },
        { key: 'remarks' },
        { key: 'allocated', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        { key: 'cancelled', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        { key: 'quantity', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        { key: 'executed', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        { key: 'outstanding', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        ...mkAuditFields<TradeOrderModel>(this.me),
      ],
    };
  }
}
