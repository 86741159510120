import { A } from '@ember/array';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { CommonController } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  // TODO: Use Microlot instead of Barista.
  @computed('model.isFund')
  get plotData(): any {
    // Is it?
    if (isEmpty(this.model.isFund) || !this.model.isFund) {
      return [];
    }

    // Define the return value:
    const retval: any = A([]);

    // Get and populate:
    this.ajax.request('portfolios/' + this.model.id + '/navshare/').then((data: any) => {
      if (!data) {
        return;
      }

      data.forEach((series: any) => {
        retval.pushObject({
          name: series.name,
          x: series.data.map((d: any) => d.date),
          y: series.data.map((d: any) => d.value),
        });
      });
    });

    // Done, return:
    return retval;
  }

  plotLayout = {
    title: 'NAV/Share Series',
    xaxis: { title: 'Timeline' },
    yaxis: { title: 'NAV/Share' },
  };
}
