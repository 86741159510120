import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import ArtifactAttributeModel from 'cafelatte/models/artifactattribute';
import { FlexTableSpec, mkAuditFields, mkCommonRowActions } from '../components/x/flex-table';

export default class extends ControllerWithCurrentRecord<ArtifactAttributeModel> {
  createNewRecord(): ArtifactAttributeModel {
    return this.store.createRecord('artifactattribute');
  }

  get spec(): FlexTableSpec<ArtifactAttributeModel> {
    return {
      ident: 'cl-artifact-attribute-list-table',
      vfill: true,
      columns: [
        { key: 'id' },
        { key: 'code' },
        { key: 'name' },
        { key: 'desc', label: 'Description' },
        ...mkAuditFields<ArtifactAttributeModel>(this.me),
      ],
      actions: !this.me.internal
        ? []
        : mkCommonRowActions([
            { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
            { type: 'delete', onDelete: this.doResetCurrentRecordRefreshModel },
          ]),
    };
  }
}
