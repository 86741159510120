import TradeOrderGroup from 'cafelatte/models/tradeordergroup';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../../commons/record/list';

export default class extends RecordListComponent<TradeOrderGroup> {
  get spec(): FlexTableSpec<TradeOrderGroup> {
    return {
      ident: 'cl-trade-order-group-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id', component: '@link', options: { route: 'trading.tradeordergroup.details', modelPath: 'id' } },
        { key: 'cstate.name', label: 'State' },
        { key: 'instructed', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'notes' },
        { key: 'remarks' },
        ...mkAuditFields<TradeOrderGroup>(this.me),
      ],
    };
  }
}
