import { inject as service } from '@ember/service';
import VoucherModel from 'cafelatte/models/voucher';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<VoucherModel> {
  @service declare ajax: CLAjaxService;

  get spec(): FlexTableSpec<VoucherModel> {
    return {
      ident: 'cl-vouchers-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id', component: '@link', options: { route: 'voucher.details', modelPath: 'id' } },
        { key: 'commitment', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'ctype', label: 'Type' },
        { key: 'currency', label: 'CCY' },
        { key: 'sharing', component: '@number', align: 'right', options: { format: '0.00[00]%' } },
        { key: 'kickback', label: 'Rebate', align: 'right', component: '@number', options: { format: '0.00[00]%' } },
        {
          key: 'amount',
          label: 'Base',
          align: 'right',
          component: '@number',
          options: { format: '0.00[00]', colorize: true },
        },
        {
          key: 'gross',
          align: 'right',
          component: '@number',
          options: { format: '0.00[00]', colorize: true },
        },
        {
          key: 'net',
          align: 'right',
          component: '@number',
          options: { format: '0.00[00]', colorize: true },
        },
        { key: 'trade.id', component: '@link', options: { route: 'trade.details', modelPath: 'trade.id' } },
        { key: 'trade.type', label: 'Trade Type' },
        { key: 'trade.stype', label: 'Trade Subtype' },
        {
          key: 'trade.reference',
          label: 'Trade Reference',
          component: '@link',
          options: { route: 'trade.details', modelPath: 'trade.id' },
        },
        { key: 'trade.remarks', options: { truncate: 50 } },
        {
          key: 'account.name',
          label: 'Account',
          component: '@link',
          options: { route: 'account.details', modelPath: 'account.id' },
        },
        {
          key: 'portfolio.name',
          label: 'Portfolio',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'portfolio.id' },
        },
        {
          key: 'team.name',
          label: 'Team',
          component: '@link',
          options: { route: 'team.details', modelPath: 'team.id' },
        },
        {
          key: 'institution.name',
          label: 'Institution',
          component: '@link',
          options: { route: 'institution.details', modelPath: 'institution.id' },
        },
        {
          key: 'rebate_institution.name',
          label: 'Rebate Institution',
          component: '@link',
          options: { route: 'institution.details', modelPath: 'rebate_institution.id' },
        },
        {
          key: 'isSettled',
          label: 'Settled?',
          component: '@bool',
        },
        {
          key: 'settlement.id',
          label: 'Settlement',
          component: '@link',
          options: { route: 'settlement.details', modelPath: 'settlement.id' },
        },
        ...mkAuditFields<VoucherModel>(this.me),
      ],
    };
  }
}
