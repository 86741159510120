import { sort } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';

export default class extends CommonController {
  queryParams = ['page', 'page_size', 'ordering', 'refccy'];

  @tracked page = 1;
  @tracked page_size = 100;
  @tracked ordering = '-updated';
  @tracked refccy = 'USD';
  @tracked sortKey = ['refamt:desc'];

  @sort('model', 'sortKey') sortedQuants: any;
}
