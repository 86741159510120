import { Service } from '../-service';
import { requestGrid, ReturnsGrid, ReturnsGridItem, ReturnsGridQuery } from './-internal';

// Export public symbols:
export { ReturnsGrid, ReturnsGridQuery, ReturnsGridItem };

/**
 * Provides a returns grid service.
 */
export class ReturnsGridService extends Service {
  /**
   * Retrieves and compiles vouchers timeline report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  grid(query: ReturnsGridQuery): Promise<ReturnsGrid> {
    return requestGrid(this.client, query);
  }
}
