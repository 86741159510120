import { action, computed, get, set, setProperties } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { SimpleChoices } from 'cafelatte/libs/headless/prelude/simple-choice';
import { FlexTableColumnSpec, FlexTableSpec, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
// @ts-expect-error
import { storageFor } from 'ember-local-storage';

interface SummaryBreakdownTableRecord {
  level: number;
  type: string;
  id: Id;
  parent?: Id;
  instance: any;
  pending: number;
  settled: number;
  totaled: number;
  currency: string;
}

function mkNumberColumn<T>(x: Partial<FlexTableColumnSpec<T>> & { key: string }): FlexTableColumnSpec<T> {
  return { align: 'right', component: '@number', options: { format: '0,0', colorize: true }, ...x };
}

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @storageFor('appstate') declare appstate: any;

  queryParams = [
    'page',
    'page_size',
    'search',
    'commitment__gte',
    'commitment__lte',
    'ctype',
    'currency',
    'sharing',
    'kickback',
    'amount__gte',
    'amount__lte',
    'gross__gte',
    'gross__lte',
    'net__gte',
    'net__lte',
    'account',
    'portfolio',
    'team',
    'institution',
    'rebate_institution',
    'trade',
    'trade__resmain',
    'settlement__isnull',
    'institution__isnull',
    'rebate_institution__isnull',
    'team__isnull',
    'settlement',
    'settlement__invoice__icontains',
    'notes__icontains',
    'remarks__icontains',
    'amounttype',
    'valueccy',
    'breakdown1',
    'breakdown2',
    'trade__resmain__ctype',
    'pendings',
    'portfolio__groups',
  ];

  queryParamsDefaults: any = {
    amounttype: 'gross',
    valueccy: 'USD',
    breakdown1: 'team',
    breakdown2: 'institution',
    pendings: true,
    search: '',
  };

  @tracked page = 1;
  @tracked search = '';
  @tracked settlement__isnull = '';
  @tracked institution__isnull = '';
  @tracked team__isnull = '';
  @tracked rebate_institution__isnull = '';

  allYesNoOptions: SimpleChoices<string> = [
    { value: '', label: 'All' },
    { value: 'True', label: 'Yes' },
    { value: 'False', label: 'No' },
  ];

  allYesNoOptionsFlipped: SimpleChoices<string> = [
    { value: '', label: 'All' },
    { value: 'False', label: 'Yes' },
    { value: 'True', label: 'No' },
  ];

  choicesBreakdown: SimpleChoices<string> = [
    { value: 'ctype', label: 'Type' },
    { value: 'team', label: 'Team' },
    { value: 'portfolio', label: 'Portfolio' },
    { value: 'account', label: 'Account' },
    { value: 'institution', label: 'Institution' },
  ];

  choicesAmount: SimpleChoices<string> = [
    { value: 'amount', label: 'Original' },
    { value: 'gross', label: 'After Sharing' },
    { value: 'net', label: 'After Rebate' },
  ];

  @oneWay('queryParamsDefaults.amounttype') amounttype: any;
  @oneWay('queryParamsDefaults.valueccy') valueccy: any;
  @oneWay('queryParamsDefaults.breakdown1') breakdown1: any;
  @oneWay('queryParamsDefaults.breakdown2') breakdown2: any;
  @oneWay('queryParamsDefaults.pendings') pendings: any;

  @tracked institution: any = undefined;
  @tracked settlementInstance: any = undefined;

  get page_size(): any {
    return get(this.appstate, 'routesVoucherIndexPageSize');
  }

  set page_size(value) {
    if (!isEmpty(value) && value <= 500) {
      set(this.appstate, 'routesVoucherIndexPageSize', value);
    }
  }

  @computed('model', 'model.meta.extras.totals.[]')
  get summary() {
    // Get totals:
    const totals: any = get(this, 'model.meta.extras.totals');

    // If empty, return nothing:
    if (isEmpty(totals)) {
      return null;
    }

    // Define the return value:
    const retval: any = {
      pending: 0,
      settled: 0,
      totaled: 0,
      currency: 0,
      breakdown1: null,
      breakdown2: null,
      breakdowns: {},
    };

    // Iterate over totals and populate the return value:
    totals.forEach((item: any) => {
      // Add currency:
      retval.currency = item.valueccy;

      // Add breakdown1/2:
      retval.breakdown1 = item.breakdown1_type;
      retval.breakdown2 = item.breakdown2_type;

      // Add total aggregate:
      retval.totaled += item.converted;

      // Add aggregate as per settled flag:
      if (item.settled) {
        retval.settled += item.converted;
      } else {
        retval.pending += item.converted;
      }

      // Do we have any breakdowns?
      if (isEmpty(item.breakdown1_type)) {
        return;
      }

      // Add to breakdowns:
      if (!(item.breakdown1 in retval.breakdowns)) {
        retval.breakdowns[item.breakdown1] = {
          pending: 0,
          settled: 0,
          totaled: 0,
          breakdowns: {},
        };
      }

      // Add total value:
      retval.breakdowns[item.breakdown1].totaled += item.converted;

      // Add aggregate as per settled flag:
      if (item.settled) {
        retval.breakdowns[item.breakdown1].settled += item.converted;
      } else {
        retval.breakdowns[item.breakdown1].pending += item.converted;
      }

      // Now we will go for the second level. Do we have any breakdowns?
      if (isEmpty(item.breakdown2_type)) {
        return;
      }

      // Add to breakdowns:
      if (!(item.breakdown2 in retval.breakdowns[item.breakdown1].breakdowns)) {
        retval.breakdowns[item.breakdown1].breakdowns[item.breakdown2] = {
          pending: 0,
          settled: 0,
          totaled: 0,
        };
      }

      // Add total value:
      retval.breakdowns[item.breakdown1].breakdowns[item.breakdown2].totaled += item.converted;

      // Add aggregate as per settled flag:
      if (item.settled) {
        retval.breakdowns[item.breakdown1].breakdowns[item.breakdown2].settled += item.converted;
      } else {
        retval.breakdowns[item.breakdown1].breakdowns[item.breakdown2].pending += item.converted;
      }
    });

    return retval;
  }

  get operations() {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('voucher.details', record.id) },
      { type: 'delete', onDelete: this.refresh },
    ]);
  }

  @computed('summary.[]')
  get summaryBriefTableData() {
    return isEmpty(this.summary)
      ? []
      : [
          { name: 'Pending', amount: this.summary.pending, currency: this.summary.currency },
          { name: 'Settled', amount: this.summary.settled, currency: this.summary.currency },
          { name: 'Total', amount: this.summary.totaled, currency: this.summary.currency },
        ];
  }

  get summaryBriefTableSpec(): FlexTableSpec<{ name: string; value: number; currency: string }> {
    return {
      ident: 'cl-vouchers-summary-brief',
      vfill: false,
      columns: [{ key: 'name' }, mkNumberColumn({ key: 'amount' }), { key: 'currency', label: 'CCY' }],
    };
  }

  @computed('summary.[]')
  get summaryBreakdownTableData(): SummaryBreakdownTableRecord[] {
    if (isEmpty(this.summary)) {
      return [];
    }
    return Object.entries(this.summary.breakdowns as Record<Id, any>).reduce(
      (accum: SummaryBreakdownTableRecord[], [bd1Id, bd1]) => {
        accum.push({
          level: 0,
          type: this.summary.breakdown1,
          id: bd1Id,
          parent: undefined,
          instance:
            this.summary.breakdown1 == 'ctype'
              ? { id: bd1Id, name: bd1Id }
              : DS.PromiseObject.create({
                  promise: this.store.findRecord(this.summary.breakdown1, bd1Id),
                }),
          pending: bd1.pending,
          settled: bd1.settled,
          totaled: bd1.totaled,
          currency: this.summary.currency,
        });

        accum.pushObjects(
          Object.entries(bd1.breakdowns as Record<Id, any>).map(([bd2Id, bd2]) => ({
            level: 1,
            type: this.summary.breakdown2,
            id: bd2Id,
            parent: bd1Id,
            instance:
              this.summary.breakdown2 == 'ctype'
                ? { id: bd2Id, name: bd2Id }
                : DS.PromiseObject.create({
                    promise: this.store.findRecord(this.summary.breakdown2, bd2Id),
                  }),
            pending: bd2.pending,
            settled: bd2.settled,
            totaled: bd2.totaled,
            currency: this.summary.currency,
          }))
        );

        return accum;
      },
      []
    );
  }

  get summaryBreakdownTableSpec(): FlexTableSpec<SummaryBreakdownTableRecord> {
    return {
      ident: 'cl-vouchers-summary-breakdown',
      vfill: false,
      columns: [
        { key: 'level', hidden: true },
        { key: 'type', hidden: true },
        { key: 'instance.name', classes: [({ record }) => (record.level ? [`ps-${record.level * 3}`] : [])] },
        mkNumberColumn({
          key: 'pending',
          action: ({ record }) =>
            record.level > 0
              ? this.filterVouchers('True', this.summary.breakdown1, record.parent, this.summary.breakdown2, record.id)
              : this.filterVouchers('True', this.summary.breakdown1, record.id),
        }),
        mkNumberColumn({
          key: 'settled',
          action: ({ record }) =>
            record.level > 0
              ? this.filterVouchers('False', this.summary.breakdown1, record.parent, this.summary.breakdown2, record.id)
              : this.filterVouchers('False', this.summary.breakdown1, record.id),
        }),
        mkNumberColumn({
          key: 'totaled',
          label: 'Total',
          action: ({ record }) =>
            record.level > 0
              ? this.filterVouchers('', this.summary.breakdown1, record.parent, this.summary.breakdown2, record.id)
              : this.filterVouchers('', this.summary.breakdown1, record.id),
        }),
        { key: 'currency', label: 'CCY' },
      ],
    };
  }

  @action refresh() {
    this.send('refreshModel');
  }

  @action newSettlement() {
    this.settlementInstance = this.store.createRecord('settlement', {
      currency: this.summary.currency,
      amount: this.summary.pending,
      institution: this.institution,
    });
  }

  @action saveSettlement() {
    this.settlementInstance.save().then(
      (settlement: any) => {
        this.flashMessages.success('Record saved successfully.');
        this.ajax
          .request('settlements/' + settlement.get('id') + '/vouchers/', {
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({ ids: get(this, 'model.meta.extras.pendings') }),
          })
          .then(
            () => {
              this.settlementInstance = undefined;
              this.send('refreshModel');
            },
            () => this.flashMessages.danger('Error saving records. Please check your input.')
          );
      },
      () => this.flashMessages.danger('Error saving the record. Please check your input.')
    );
  }

  @action cancelSettlement() {
    this.settlementInstance.rollbackAttributes();
    this.settlementInstance = undefined;
  }

  @action reset() {
    setProperties(this, {
      ...this.queryParams.reduce((o, k) => ({ ...o, [k]: '' }), {}),
      ...this.queryParamsDefaults,
      page: 1,
      search: '',
    });
  }

  @action filterVouchers(value: any = '', recordModel: any, recordID: any, record2Model?: any, record2ID?: any) {
    record2Model && record2ID && set(this, record2Model, record2ID);
    // @ts-expect-error
    setProperties(this, { settlement__isnull: value, [recordModel]: recordID });
  }
}
