import CLSelectControl from '../cl-select/component';

export default class extends CLSelectControl<string> {
  get choices() {
    return [
      {
        value: 'account',
        label: 'Account',
      },
      {
        value: 'portfolio',
        label: 'Portfolio',
      },
      {
        value: 'custodian',
        label: 'Custodian',
      },
      {
        value: 'team',
        label: 'Team',
      },
      {
        value: 'portfoliogroup',
        label: 'Portfolio Group',
      },
    ];
  }
}
