// TODO: Add a link to go the price observations to edit the FX rate.
import Component from '@glimmer/component';
import { ConsolidationReportFXRate } from 'cafelatte/libs/headless/services/consolidation-report/generic-report/-full';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';

interface Args {
  rates: ConsolidationReportFXRate[];
}

export default class extends Component<Args> {
  get tableSpec(): FlexTableSpec<ConsolidationReportFXRate> {
    return {
      ident: 'cl-report-valuation-fxrates-table',
      vfill: false,
      columns: [
        { key: 'ccy1', label: 'From' },
        { key: 'ccy2', label: 'To' },
        { key: 'value', label: 'Rate', align: 'right', component: '@number', options: { format: '0,0.0000[0000]' } },
      ],
    };
  }
}
