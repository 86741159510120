import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  queryParams = ['page', 'page_size', 'created__gte', 'created__lte', 'cstate'];

  @tracked page = 1;
  @tracked page_size = 20;

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'link', action: ({ record }) => this.router.transitionTo('trading.tradeordergroup.details', record.id) },
    ]);
  }
}
