import { action } from '@ember/object';
import { CommonController } from 'cafelatte/libs/embered';
import VoucherModel from 'cafelatte/models/voucher';

export default class extends CommonController {
  @action saved(record: VoucherModel) {
    record.reload().then((x) => {
      this.router.transitionTo('voucher.details', x.get('id'));
    });
  }

  @action cancelled(record: VoucherModel) {
    if (record.get('isDeleted')) {
      this.router.transitionTo('voucher');
    }
  }

  @action deleted() {
    this.router.transitionTo('voucher');
  }
}
