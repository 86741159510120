import { helper } from '@ember/component/helper';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { PossiblyNumeric } from 'cafelatte/libs/headless/prelude/numeric';
import Decimal from 'decimal.js';

export function isNonZero(value: Maybe<PossiblyNumeric>): boolean {
  if (isNothing(value)) {
    return false;
  } else if (value instanceof Decimal) {
    return !value.isZero();
  }
  return (value && true) || false;
}

export default helper(([value]) => isNonZero(value));
// XREVIEW=TODO
// TODO: Check if we really need this helper.
