import { action } from '@ember/object';
import Component from '@glimmer/component';
import { isJust, Maybe } from 'cafelatte/libs/headless/prelude/maybe';

interface Meta {
  count?: number;
  next?: number;
  previous?: number;
}

interface Args {
  page: number;
  size: number;
  count?: number;
  total?: number;
  records: Maybe<ArrayLike<any> & { meta?: Meta }>;
  onPageChanged?: (x: number) => void;
  onSizeChanged?: (x: number) => void;
}

export default class extends Component<Args> {
  get page(): number {
    return this.args.page;
  }

  get size(): number {
    return this.args.size;
  }

  get meta(): Maybe<Meta> {
    return isJust(this.args.records) && isJust(this.args.records.meta) ? this.args.records.meta : undefined;
  }

  get count(): number {
    return (isJust(this.args.records) ? this.args.records.length : this.args.count) || 0;
  }

  get total(): number {
    return (isJust(this.meta) ? this.meta.count : this.args.total) || 0;
  }

  get prev(): number | undefined {
    return this.page > 1 ? this.page - 1 : undefined;
  }

  get next(): number | undefined {
    return this.page < this.last ? this.page + 1 : undefined;
  }

  get last() {
    return Math.ceil(this.total / this.size) || 1;
  }

  @action onSizeChanged(x: number) {
    this.args.onSizeChanged?.(x);
  }

  @action onPageChanged(x: number) {
    this.args.onPageChanged?.(x);
  }

  @action onFirstPage() {
    this.onPageChanged(1);
  }

  @action onLastPage() {
    this.onPageChanged(this.last);
  }
}
