// @ts-expect-error
import DRFSerializer from '../drf';

export default class VoucherType extends DRFSerializer.extend({
  primaryKey: 'code',
}) {}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'vouchers/type': VoucherType;
  }
}
