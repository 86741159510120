import { compareStringArrays, last } from 'cafelatte/libs/headless/prelude/arrays';
import { getOrElse, isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { safeDivideOrZero, sumDecimal, ZERO } from 'cafelatte/libs/headless/prelude/numeric';
import Decimal from 'decimal.js';
import { ConsolidationReportData } from '../generic-report/-full';
import { Holding } from './holding';

export interface HoldingAddressSegment {
  value: string;
  label: string;
  order: string | number;
}

export type HoldingAddress = Array<HoldingAddressSegment>;

export interface HoldingsNode {
  address: HoldingAddress;
  holdings: Array<Holding>;
  children: Array<HoldingsNode>;
  totals: {
    investment: Decimal;
    accrued: Decimal;
    netValue: Decimal;
    netValueRatio: Decimal;
    absValue: Decimal;
    absValueRatio: Decimal;
    netExposure: Decimal;
    netExposureRatio: Decimal;
    absExposure: Decimal;
    absExposureRatio: Decimal;
    pnl: Decimal;
    pnlRatio: Decimal;
  };
}

export type HoldingsTree = HoldingsNode;

export type HoldingAddresser = (holding: Holding) => HoldingAddress;

export function composeHoldingAddressers(addressers: Array<HoldingAddresser>): HoldingAddresser {
  return (holding: Holding) => addressers.reduce((p: HoldingAddress, c: HoldingAddresser) => [...p, ...c(holding)], []);
}

export type AvailableAddresserKeys = 'classification' | 'currency' | 'country' | 'issuer' | 'sector';

export function makeSimpleAddresser(def: string, labeler: (holding: Holding) => Maybe<string>): HoldingAddresser {
  return (holding: Holding) => {
    // Attempt to get the label:
    const label = labeler(holding) || def;

    // Get the value:
    const value = label.toUpperCase();

    // Done, return:
    return [{ label, value, order: value }];
  };
}

export const addressers: Record<AvailableAddresserKeys, HoldingAddresser> = {
  classification: (holding: Holding) => {
    // Get the classification:
    const classification = holding.classification;

    // Build the address and return:
    return classification.map((x) => ({
      label: x.name,
      value: x.name.toUpperCase(),
      order: `${x.order}`.toUpperCase(),
    }));
  },
  currency: makeSimpleAddresser('[Undefined Currency]', (holding: Holding) => holding.artifact.ccymain),
  country: makeSimpleAddresser('[Undefined Country]', (holding: Holding) => holding.artifact.country),
  issuer: makeSimpleAddresser('[Undefined Issuer]', (holding: Holding) => holding.artifact.issuer),
  sector: makeSimpleAddresser('[Undefined Sector]', (holding: Holding) => holding.artifact.sector),
};

export function makeHoldingsNode(address: HoldingAddress): HoldingsNode {
  return {
    address,
    holdings: [],
    children: [],
    totals: {
      investment: ZERO,
      accrued: ZERO,
      netValue: ZERO,
      netValueRatio: ZERO,
      absValue: ZERO,
      absValueRatio: ZERO,
      netExposure: ZERO,
      netExposureRatio: ZERO,
      absExposure: ZERO,
      absExposureRatio: ZERO,
      pnl: ZERO,
      pnlRatio: ZERO,
    },
  };
}

export function initHoldingsTree(): HoldingsTree {
  return makeHoldingsNode([]);
}

export function buildHoldingsTree(
  report: ConsolidationReportData,
  holdings: Array<Holding>,
  addresser: HoldingAddresser,
  sortByCurrencyAndValue: boolean
): HoldingsTree {
  // Initialize the tree:
  const tree = initHoldingsTree();

  // Iterate over holdings and populate the tree:
  for (const holding of holdings) {
    // Get the address of the holding:
    const address = addresser(holding);

    // Add the holding to the tree at the address:
    _addHoldingToAddress(tree, address, holding);
  }

  // Tree is ready by now. We need to treat the tree for 2 objectives:
  //
  // 1. Calculate totals.
  // 2. Sort nodes and holdings.
  _treatTree(report, tree, sortByCurrencyAndValue);

  // Done, return the tree:
  return tree;
}

/**
 * Sorts holdings first by currency and than by their reference value.
 *
 * @param holdings Holdings to sort.
 * @returns Sorted holdings.
 */

export function sortHoldings(holdings: Array<Holding>): Array<Holding> {
  return [...holdings].sort((a: Holding, b: Holding) => {
    // First sort by original currency:
    const cmp1 = (a.artifact.ccymain || '').localeCompare(b.artifact.ccymain || '');

    // If they are not the same return:
    if (cmp1 != 0) {
      return cmp1;
    }

    // Now, return the value comparison:
    if (isNothing(a.valuation.value.net.ref)) {
      return -1;
    } else if (isNothing(b.valuation.value.net.ref)) {
      return 1;
    }

    // Get comparison:
    const cmp2 = a.valuation.value.net.ref.comparedTo(b.valuation.value.net.ref);

    // If they are not the same, return:
    if (cmp2 != 0) {
      return cmp2 * -1;
    }

    // Finally, check artifact names:
    return a.artifact.name?.localeCompare(b.artifact.name || '') || -1;
  });
}

export function _treatTree(report: ConsolidationReportData, tree: HoldingsTree, sortByCurrencyAndValue: boolean): void {
  // Sort holdings:
  if (sortByCurrencyAndValue) {
    tree.holdings = sortHoldings(tree.holdings);
  } else {
    tree.holdings = tree.holdings.sort((h1, h2) => h1.artifact.name?.localeCompare(h2.artifact.name || '') || -1);
  }

  // Sort children:
  tree.children = tree.children.sort((t1, t2) => {
    // Get current address segments:
    const segment1 = last(t1.address);
    const segment2 = last(t2.address);

    // If we don't have segments, return now:
    if (isNothing(segment1)) {
      return -1;
    } else if (isNothing(segment2)) {
      return 1;
    }

    // Get orders:
    const order1 = segment1.order;
    const order2 = segment2.order;

    // Check and return:
    return `${order1}`.localeCompare(`${order2}`);
  });

  // Get holdings:
  const holdings = tree.holdings;

  // Get children:
  const children = tree.children;

  // Get node totals:
  const totals = tree.totals;

  // Treat child nodes:
  children.forEach((n) => _treatTree(report, n, sortByCurrencyAndValue));

  // Calculate totals:
  totals.investment = getTotalBy(holdings, holdingInvestment).add(getTotalBy(children, nodeInvestment));
  totals.accrued = getTotalBy(holdings, holdingAccrued).add(getTotalBy(children, nodeAccrued));
  totals.netValue = getTotalBy(holdings, holdingNetValue).add(getTotalBy(children, nodeNetValue));
  totals.absValue = getTotalBy(holdings, holdingAbsValue).add(getTotalBy(children, nodeAbsValue));
  totals.netExposure = getTotalBy(holdings, holdingNetExposure).add(getTotalBy(children, nodeNetExposure));
  totals.absExposure = getTotalBy(holdings, holdingAbsExposure).add(getTotalBy(children, nodeAbsExposure));
  totals.pnl = getTotalBy(holdings, holdingPnL).add(getTotalBy(children, nodePnL));

  // Calculate ratios:
  totals.netValueRatio = safeDivideOrZero(totals.netValue, report.valuation.nav);
  totals.absValueRatio = safeDivideOrZero(totals.absValue, report.valuation.nav);
  totals.netExposureRatio = safeDivideOrZero(totals.netExposure, report.valuation.nav);
  totals.absExposureRatio = safeDivideOrZero(totals.absExposure, report.valuation.nav);
  totals.pnlRatio = safeDivideOrZero(totals.pnl, totals.investment);
}

export function _addHoldingToAddress(tree: HoldingsTree, address: HoldingAddress, holding: Holding): void {
  // Get the starting (current) node:
  let node = tree;

  // Iterate over address and traverse the tree while adding new nodes when required:
  const sofar: Array<string> = [];
  const sofarAddress: HoldingAddress = [];
  for (const segment of address) {
    // Append to address buffer:
    sofar.pushObject(segment.value);
    sofarAddress.pushObject(segment);

    // Attempt to find the child:
    // prettier-ignore
    let child = node.children.find((n) => compareStringArrays(n.address.map(x => x.value), sofar) == 0);

    // Add or use?
    if (child === undefined) {
      // Create the new node:
      child = makeHoldingsNode([...sofarAddress]);

      // Add the new node to the current node as a child:
      node.children.pushObject(child);
    }

    // Set the current node to the child:
    node = child;
  }

  // Done, we append the holding to the current node and return from the procedure:
  node.holdings.pushObject(holding);
}

export function getTotalBy<T>(items: Array<T>, fun: (x: T) => Maybe<Decimal>): Decimal {
  return getOrElse(sumDecimal(items, fun), ZERO);
}

export const holdingInvestment = (x: Holding) => x.investment.value.ref;

export const holdingAccrued = (x: Holding) => x.valuation.accrued.ref;

export const holdingNetValue = (x: Holding) => x.valuation.value.net.ref;

export const holdingAbsValue = (x: Holding) => x.valuation.value.abs.ref;

export const holdingNetExposure = (x: Holding) => x.valuation.exposure.net.ref;

export const holdingAbsExposure = (x: Holding) => x.valuation.exposure.abs.ref;

export const holdingPnL = (x: Holding) => x.unrealizedPNL;

export const nodeInvestment = (x: HoldingsNode) => x.totals.investment;

export const nodeAccrued = (x: HoldingsNode) => x.totals.accrued;

export const nodeNetValue = (x: HoldingsNode) => x.totals.netValue;

export const nodeAbsValue = (x: HoldingsNode) => x.totals.absValue;

export const nodeNetExposure = (x: HoldingsNode) => x.totals.netExposure;

export const nodeAbsExposure = (x: HoldingsNode) => x.totals.absExposure;

export const nodePnL = (x: HoldingsNode) => x.totals.pnl;
