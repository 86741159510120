import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  queryParams = [
    'page',
    'page_size',
    'search',
    'cstate',
    'created__gte',
    'created__lte',
    'team',
    'resmain',
    'date__gte',
    'date__lte',
    'ctype',
  ];

  @tracked page = 1;
  @tracked page_size = 20;

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'link', action: ({ record }) => this.router.transitionTo('trading.tradeorder.details', record.id) },
    ]);
  }
}
