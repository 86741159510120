import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from '../components/x/flex-table';

export default class extends CommonController {
  @tracked queryParams = ['page', 'page_size', 'portfolio', 'shareclass', 'date__gte', 'date__lte'];

  @tracked page = 1;
  @tracked page_size = 50;

  tableOperations = mkCommonRowActions([{ type: 'delete', onDelete: () => this.send('refreshModel') }]);
}
