import { action } from '@ember/object';
import {
  CommonRoute,
  compileQueryParams,
  getIndexRouteModel,
  handle404OnNonExistingPage,
  IndexRouteParams,
} from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams(['page', 'page_size', 'search']);

  model(params: IndexRouteParams) {
    return getIndexRouteModel(this.store, 'institution', params);
  }

  @action error(error: any) {
    return handle404OnNonExistingPage(this, error);
  }
}
