import { Service } from '../-service';
import { downloadCsv, request, StockAtHand, StocksAtHandQuery } from './-internal';

// Export public symbols:
export { StocksAtHandQuery, StockAtHand };

/**
 * Provides a stocks-at-hand service.
 */
export class StocksService extends Service {
  /**
   * Retrieves and compiles stocks-at-hand report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  get(query: StocksAtHandQuery): Promise<Array<StockAtHand>> {
    return request(this.client.barista, query);
  }

  /**
   * Retrieves and compiles stocks-at-hand report data as a CSV file.
   *
   * @param query Report query.
   */
  downloadCsv(stocks: Array<StockAtHand>): void {
    return downloadCsv(stocks);
  }
}
