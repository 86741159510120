import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import PortfolioGroupModel from './portfoliogroup';
import RoleModel from './role';
import TeamModel from './team';
import TradeTeamModel from './tradeteam';
import { HasMany, makeValidators } from './_internal/machinery';

const _fields = ['username', 'email', 'mobile', 'firstName', 'lastName', 'isActive', 'groups', 'teams'];

export default class UserModel extends Model.extend(makeValidators(_fields)) {
  @attr()
  declare username?: string;

  @attr()
  declare email?: string;

  @attr()
  declare mobile?: string;

  @attr()
  declare firstName?: string;

  @attr()
  declare lastName?: string;

  @attr()
  declare lastLogin?: string;

  @attr()
  declare dateJoined?: string;

  @attr('boolean', { defaultValue: true })
  declare isActive: boolean;

  @attr('boolean', { defaultValue: false })
  declare privileged: boolean;

  @hasMany('role')
  declare groups?: HasMany<RoleModel>;

  @hasMany('team', { inverse: 'members' })
  declare teams?: HasMany<TeamModel>;

  @hasMany('tradeteam', { inverse: 'members' })
  declare tradeteams?: HasMany<TradeTeamModel>;

  @hasMany('portfoliogroup', { inverse: 'members' })
  declare portfolioGroups?: HasMany<PortfolioGroupModel>;

  @computed('firstName', 'lastName')
  get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  @not('isActive')
  declare isInactive: boolean;

  @alias('username')
  declare name?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
