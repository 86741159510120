import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dayjs, SDate } from 'cafelatte/libs/headless/prelude/datetime';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';

interface Args {
  value: Maybe<SDate>;
  onChange?: (x?: SDate) => void;
  disableInput?: boolean;
  disableClear?: boolean;
  placeholder?: string;
}

export default class extends Component<Args> {
  @tracked picker?: any = undefined;

  get value(): string | null {
    return this.args.value || null;
  }

  @action onReady(_ds: any, _fds: any, picker: any) {
    this.picker = picker;
    // There is no "on-empty" event which causes an issue. Let's fix it.
    this.picker.altInput.oninput = ({ target }: any) => {
      if (!target.value) {
        this.onClear();
      }
    };
  }

  @action onClear() {
    this.picker?.clear();
  }

  @action onChange(x?: Date[]) {
    this.args.onChange?.((x && x.length > 0 && dayjs(x[0]).format('YYYY-MM-DD')) || undefined);
  }
}
