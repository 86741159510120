import { DecafClient } from '@decafhub/decaf-client';
import Decimal from 'decimal.js';
import { DateType } from '../../commons/date-type';
import { Currency, Id } from '../../commons/types';
import { SDate, SDateTime } from '../../prelude/datetime';
import { isJust, mapMaybe, Maybe } from '../../prelude/maybe';
import { toDecimal } from '../../prelude/numeric';
import { sanitizeString } from '../../prelude/strings';

/**
 * Report query type.
 */
export interface LedgerReportQuery {
  artifact: Id;
  accounts?: Array<Id>;
  portfolios?: Array<Id>;
  since?: SDate;
  until?: SDate;
  datetype?: DateType;
}

/**
 * Response data type.
 */
export interface LedgerReportResponseData {
  entries: Array<LedgerReportResponseDataItem>;
}

/**
 * Response data item type.
 */
export interface LedgerReportResponseDataItem {
  id: Id;
  ctype: Id;
  htype: string;
  cflag: Maybe<number>;
  commitment: SDate;
  settlement: SDate;
  executedat: Maybe<SDate>;
  pseudorder: Maybe<number>;
  created: SDateTime;
  updated: SDateTime;
  account_id: Id;
  account__name: string;
  account__portfolio_id: Id;
  account__portfolio__name: string;
  account__custodian_id: Id;
  account__custodian__name: string;
  account__portfolio__team_id: Maybe<Id>;
  account__portfolio__team__name: Maybe<string>;
  quantity: number;
  valccy: Currency;
  valamt: number;
  trade_id: Id;
  trade__ctype: Id;
  trade__stype: Maybe<string>;
  trade__reference: Maybe<string>;
  trade__htype: string;
  trade__pxmain: Maybe<number>;
  trade__notes: Maybe<string>;
  trade__remarks: Maybe<string>;
  trade__resmain_id: Id;
  trade__resmain__ctype: Id;
  trade__resmain__symbol: string;
  trade__resmain__name: Maybe<string>;
  trade__resmain__isin: Maybe<string>;
  trade__resmain__underlying_id: Maybe<Id>;
  trade__resmain__underlying__symbol: Maybe<string>;
  trade__resmain__underlying__isin: Maybe<string>;
  balance: number;
}

/**
 * Report data type.
 */
export type LedgerReport = Array<LedgerReportItem>;

/**
 * Report data item type.
 */
export interface LedgerReportItem {
  quant: LedgerReportQuant;
  account: LedgerReportAccount;
  portfolio: LedgerReportPortfolio;
  custodian: LedgerReportCustodian;
  team: Maybe<LedgerReportTeam>;
  action: LedgerReportAction;
  balance: Decimal;
}

/**
 * Report quant type.
 */
export interface LedgerReportQuant {
  id: Id;
  ctype: Id;
  htype: string;
  created: SDateTime;
  updated: SDateTime;
  commitment: SDate;
  settlement: SDate;
  executedat: Maybe<SDate>;
  pseudorder: Maybe<number>;
  cflag: Maybe<number>;
  quantity: Decimal;
  valccy: Currency;
  valamt: Decimal;
}

/**
 * Report account type.
 */
export interface LedgerReportAccount {
  id: Id;
  name: string;
}

/**
 * Report portfolio type.
 */
export interface LedgerReportPortfolio {
  id: Id;
  name: string;
}

/**
 * Report custodian type.
 */
export interface LedgerReportCustodian {
  id: Id;
  name: string;
}

/**
 * Report team type.
 */
export interface LedgerReportTeam {
  id: Id;
  name: string;
}

/**
 * Report action type.
 */
export interface LedgerReportAction {
  id: Id;
  ctype: Id;
  htype: string;
  stype: Maybe<string>;
  reference: Maybe<string>;
  pxmain: Maybe<Decimal>;
  notes: Maybe<string>;
  remarks: Maybe<string>;
  artifact: LedgerReportActionArtifact;
}

/**
 * Report action artifact type.
 */
export interface LedgerReportActionArtifact {
  id: Id;
  ctype: Id;
  symbol: string;
  name: Maybe<string>;
  isin: Maybe<string>;
  underlyingId: Maybe<Id>;
  underlyingSymbol: Maybe<string>;
  underlyingIsin: Maybe<string>;
}

/**
 * Compiles raw endpoint response data to report data.
 *
 * @param response Response data received from the API endpoint.
 * @returns Final report data encoded for library client usage.
 */
export function compileReport(response: LedgerReportResponseData): LedgerReport {
  return response.entries.map((item) => ({
    quant: {
      id: item.id,
      ctype: item.ctype,
      htype: item.htype,
      created: item.created,
      updated: item.updated,
      commitment: item.commitment,
      settlement: item.settlement,
      executedat: item.executedat,
      pseudorder: item.pseudorder,
      cflag: item.cflag,
      quantity: toDecimal(item.quantity),
      valccy: item.valccy,
      valamt: toDecimal(item.valamt),
    },
    account: {
      id: item.account_id,
      name: item.account__name,
    },
    portfolio: {
      id: item.account__portfolio_id,
      name: item.account__portfolio__name,
    },
    custodian: {
      id: item.account__custodian_id,
      name: item.account__custodian__name,
    },
    team:
      isJust(item.account__portfolio__team_id) && isJust(item.account__portfolio__team__name)
        ? {
            id: item.account__portfolio__team_id,
            name: item.account__portfolio__team__name,
          }
        : undefined,
    action: {
      id: item.trade_id,
      ctype: item.trade__ctype,
      htype: item.trade__htype,
      stype: mapMaybe(sanitizeString, item.trade__stype),
      reference: mapMaybe(sanitizeString, item.trade__reference),
      pxmain: mapMaybe(toDecimal, item.trade__pxmain),
      notes: mapMaybe(sanitizeString, item.trade__notes),
      remarks: mapMaybe(sanitizeString, item.trade__remarks),
      artifact: {
        id: item.trade__resmain_id,
        ctype: item.trade__resmain__ctype,
        symbol: item.trade__resmain__symbol,
        name: mapMaybe(sanitizeString, item.trade__resmain__name),
        isin: mapMaybe(sanitizeString, item.trade__resmain__isin),
        underlyingId: item.trade__resmain__underlying_id,
        underlyingSymbol: mapMaybe(sanitizeString, item.trade__resmain__underlying__symbol),
        underlyingIsin: mapMaybe(sanitizeString, item.trade__resmain__underlying__isin),
      },
    },
    balance: toDecimal(item.balance),
  }));
}

/**
 * Compiles the library representation of report query into remote endpoint query parameters.
 *
 * @param query Query.
 * @returns Remote endpoint query parameters.
 */
export function query2params(query: LedgerReportQuery): { [key: string]: string | Array<string> } {
  return {
    artifact: `${query.artifact}`,
    accounts: query.accounts?.map((x) => `${x}`) || [],
    portfolios: query.portfolios?.map((x) => `${x}`) || [],
    datesmin: query.since || '',
    datesmax: query.until || '',
    datetype: query.datetype || '',
  };
}

/**
 * Finds and returns the barista version resource.
 *
 * @param client barista client.
 * @returns barista version resource.
 */
export function request(client: DecafClient, query: LedgerReportQuery): Promise<LedgerReport> {
  return client.barista
    .get<LedgerReportResponseData>('/ledgers/', { params: query2params(query) })
    .then(({ data }) => compileReport(data));
}
