import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  layout = layout;

  @action onPick(picked: any) {
    // @ts-expect-error
    this.onChange(isEmpty(picked) ? null : picked);
  }
}
