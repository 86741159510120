import { gql } from '@decafhub/decaf-client';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import EmberArray, { A } from '@ember/array';
import EmberObject, { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DayCountService from 'cafelatte/services/daycount';
import DecafService from 'cafelatte/services/decaf';
import AssetClassModel from './assetclass';
import ArtifactTypeModel from './resource/type';
import RiskProfileModel from './riskprofile';
import { BelongsTo, DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = [
  'ctype',
  'stype',
  'assetclass',
  'cflag',
  'symbol',
  'name',
  'ccymain',
  'ccyaltn',
  'ccystlm',
  'pxmain',
  'pxaltn',
  'pxflip',
  'pxcnst',
  'issued',
  'launch',
  'ceased',
  'expiry',
  'last_tradable',
  'first_notice',
  'convday',
  'underlying',
  'quantity',
  'lvrgfact',
  'style',
  'callput',
  'frequency',
  'eom',
  'mic',
  'ticker',
  'isin',
  'cusip',
  'figi',
  'telekurs',
  'ohlccode',
  'country',
  'sector',
  'sector_gics',
  'issuer',
  'domicile',
  'sp_rating',
  'sp_outlook',
  'reference',
  'riskprofile',
  'description',
  'tags',
  'attributes',
];

const ATTRIBUTE_LIST_QUERY = gql`
  query ($artifact: Int!) {
    artifactAttributeList: artifact_attribute_value(where: { artifact_id: { _eq: $artifact } }) {
      attribute {
        code
        name
      }
      value
    }
  }
`;

type AttributeListItem = {
  attribute: {
    code: string;
    name: string;
  };
  value: string;
};

export default class ArtifactModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @service declare daycount: DayCountService;
  @service declare decaf: DecafService;

  @attr()
  declare type?: string;

  @belongsTo('resource/type')
  declare ctype?: BelongsTo<ArtifactTypeModel>;

  @attr()
  declare stype?: string;

  @belongsTo('assetclass')
  declare assetclass?: BelongsTo<AssetClassModel>;

  @attr('number')
  declare cflag?: number;

  @attr()
  declare symbol?: string;

  @attr()
  declare name?: string;

  @attr()
  declare ccymain?: string;

  @attr()
  declare ccyaltn?: string;

  @attr()
  declare ccystlm?: string;

  @attr('number')
  declare pxmain?: number;

  @attr('number')
  declare pxaltn?: number;

  @attr('number')
  declare pxcomp?: number;

  @attr('number')
  declare pxsupp?: number;

  @attr('boolean', { defaultValue: false })
  declare pxflip: boolean;

  @attr('number')
  declare pxcnst?: number;

  @attr()
  declare issued?: string;

  @attr()
  declare launch?: string;

  @attr()
  declare ceased?: string;

  @attr()
  declare expiry?: string;

  @attr()
  declare last_tradable?: string;

  @attr()
  declare first_notice?: string;

  @attr()
  declare horizon?: string;

  @attr()
  declare convday?: string;

  @belongsTo('resource', { inverse: 'parents' })
  declare underlying?: BelongsTo<ArtifactModel>;

  @hasMany('resource', { inverse: 'underlying' })
  declare parents?: HasMany<ArtifactModel>;

  @attr('number', { defaultValue: 1 })
  declare quantity: number;

  @attr('number')
  declare lvrgfact?: number;

  @attr()
  declare style?: string;

  @attr('boolean')
  declare callput?: boolean;

  @attr('number')
  declare frequency?: number;

  @attr('number')
  declare eom?: number;

  @attr()
  declare mic?: string;

  @attr()
  declare ticker?: string;

  @attr()
  declare isin?: string;

  @attr()
  declare cusip?: string;

  @attr()
  declare figi?: string;

  @attr()
  declare telekurs?: string;

  @attr()
  declare ohlccode?: string;

  @attr()
  declare country?: string;

  @attr()
  declare sector?: string;

  @attr()
  declare sector_gics?: string;

  @attr()
  declare issuer?: string;

  @attr()
  declare domicile?: string;

  @attr()
  declare sp_rating?: string;

  @attr()
  declare sp_outlook?: string;

  @attr()
  declare reference?: string;

  @belongsTo('riskprofile')
  declare riskprofile?: BelongsTo<RiskProfileModel>;

  @attr({ defaultValue: null })
  declare extdata?: object;

  @attr()
  declare description?: string;

  @attr({ defaultValue: () => A([]) })
  declare tags: EmberArray<string>;

  @attr({ defaultValue: () => ({}) })
  declare attributes: object;

  @attr('boolean')
  declare incomplete?: boolean;

  @computed('symbol', 'name')
  get label(): string {
    return `[${this.symbol}] ${this.name}`;
  }

  @computed('id')
  get watched() {
    if (isEmpty(this.id)) {
      return undefined;
    }
    const retval = EmberObject.create({ status: false } as { status: boolean });

    this.decaf.client.barista.get(`/resources/${this.id}/watch/`).then(({ data }: any) => {
      set(retval, 'status', data.status);
    });
    return retval;
  }
  @computed('id')
  get attribute_list() {
    if (isEmpty(this.id)) {
      return undefined;
    }
    const retval = EmberObject.create({ items: [] } as { items: AttributeListItem[] });
    this.decaf.client.microlot
      .query({ query: ATTRIBUTE_LIST_QUERY, variables: { artifact: this.id } })
      .then(({ data }) => {
        set(retval, 'items', data.artifactAttributeList);
      });
    return retval;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    resource: ArtifactModel;
  }
}
