import { gql } from '@decafhub/decaf-client';

const query = gql`
  query {
    records: team {
      id
      label: name
    }
  }
`;

export default query;
