// @ts-expect-error
import SessionService from 'ember-simple-auth/services/session';

/**
 * Extends the base session service to override `handleInvalidation` method.
 */
export default class CLSessionService extends SessionService {
  // Ugly hack:
  [x: string]: any;

  /**
   * Authentication token, if any.
   */
  get token(): string | undefined {
    return this?.data?.authenticated?.token;
  }

  handleInvalidation(_routeAfterInvalidation: string) {
    window.location.reload();
  }
}
