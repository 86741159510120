import { CommonRoute, compileQueryParams, getIndexRouteModel, IndexRouteParams } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'name__icontains',
    'created__gte',
    'created__lte',
    'members',
  ]);

  model(params: IndexRouteParams) {
    return getIndexRouteModel(this.store, 'tradeteam', params);
  }
}
