import Model, { attr, hasMany } from '@ember-data/model';
import { alias } from '@ember/object/computed';
import UserModel from './user';
import { DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = ['name', 'description', 'members'];

export default class TradeTeamModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @attr()
  declare description?: string;

  @hasMany('user', { inverse: 'tradeteams' })
  declare members?: HasMany<UserModel>;

  @alias('name')
  declare label?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tradeteam: TradeTeamModel;
  }
}
