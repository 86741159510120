import { Service } from '../-service';
import {
  BreakdownType,
  BreakdownTypes,
  ChildNode,
  Node,
  ParentNode,
  ReportData,
  ReportQuery,
  request,
} from './-internal';

// Export public symbols:
export { ReportData, ReportQuery, Node, ChildNode, ParentNode, BreakdownType };

/**
 * Provides the barista asset evolution report service.
 */
export class AssetsEvolutionReportService extends Service {
  /**
   * Available breakdown type.
   */
  get breakdowns(): Array<BreakdownType> {
    return BreakdownTypes;
  }

  /**
   * Retrieves and compiles report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  getReport(query: ReportQuery): Promise<ReportData> {
    return request(this.client.barista, query);
  }
}
