import Store from '@ember-data/store';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';

interface Args {
  query?: Maybe<Record<string, any>>;
}

/**
 * This component is a wrapper around quant listing component consuming custom
 * queries.
 */
export default class extends Component<Args> {
  @service declare store: Store;

  @tracked page = 1;
  @tracked page_size = 20;

  @computed('args.query.*', 'page', 'page_size')
  get records() {
    return this.store.query('quant', {
      ...(this.args.query || {}),
      page: this.page,
      page_size: this.page_size,
    });
  }
}
