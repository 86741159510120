export function handleFlexTableResize() {
  const wrappers: NodeListOf<HTMLElement> = document.querySelectorAll('.scrollable-table'); // get the wrapper for our table
  wrappers.forEach((w) => {
    const footerHeight = 30; // height of the footer with some padding
    let parentPaddingBottom = 50; // default padding bottom of the container element
    const closestContainer = w.parentElement; // get the closest container element
    if (closestContainer) {
      parentPaddingBottom = parseFloat(getComputedStyle(closestContainer, null).paddingBottom);
      parentPaddingBottom = isNaN(parentPaddingBottom) || parentPaddingBottom < 10 ? 50 : parentPaddingBottom;
    }
    const bottomMargin = parentPaddingBottom + footerHeight;
    const boundingRect = w.getBoundingClientRect(); // get area and offset information.
    const maxHeight = window.innerHeight - boundingRect.top - bottomMargin; // this is the height of the our table content.
    if (maxHeight > 350) {
      // set it as fixed height ONLY if it is bigger than 350 px.
      w.style.maxHeight = `${maxHeight}px`; // calculate and set the height to the bottom of the screen.
    } else {
      w.style.maxHeight = '100%'; // set it to full height if the window is too small.
    }
  });
}
