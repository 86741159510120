import { tracked } from '@glimmer/tracking';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import ShareClassFeeScheduleModel from 'cafelatte/models/scfeeschedule';
import { FlexTableRowAction, mkCommonRowActions } from '../components/x/flex-table';

export default class extends ControllerWithCurrentRecord<ShareClassFeeScheduleModel> {
  queryParams = ['page', 'page_size', 'search'];

  @tracked page = 1;
  @tracked page_size = 10;

  createNewRecord(): ShareClassFeeScheduleModel {
    return this.store.createRecord('scfeeschedule');
  }

  get tableOperations(): FlexTableRowAction<ShareClassFeeScheduleModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doResetCurrentRecordRefreshModel },
    ]);
  }
}
