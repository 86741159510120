import { DecafClient } from '@decafhub/decaf-client';
import { AccountResource } from 'cafelatte/libs/headless/commons/resources/account-resource';
import { ArtifactResource } from 'cafelatte/libs/headless/commons/resources/artifact-resource';
import { download as downloadHelper } from 'cafelatte/libs/headless/prelude/downloading';
import { ConsolidationContainerType } from '..';
import { DateType } from '../../../commons/date-type';
import { Currency, Id } from '../../../commons/types';
import { SDate, SDateTime } from '../../../prelude/datetime';
import { Maybe, getOrElse, isNothing } from '../../../prelude/maybe';
import { ConsolidationContainers, ConsolidationReportValuation } from '../commons/-shared';
import { RawAccrual } from '../commons/accrual';
import { RawHolding } from '../commons/holding';
import { buildAccountEntityLookupTable, buildArtifactEntityLookupTable } from '../commons/lookup-tables';
import { Positions } from '../commons/positions';
import { computePositions, computeValuation } from '../commons/top-level';

/**
 * Consolidation report query type.
 */
export interface ConsolidationReportQuery {
  date: SDate;
  datetype: DateType;
  elements: ConsolidationContainers;
  currency: Currency;
  sandbox: Maybe<'include' | 'exclude'>;
}

/**
 * Consolidation container summary type.
 */
export interface ConsolidationReportContainer {
  id: Id;
  guid: string;
  name: string;
}

/**
 * Consolidation report containers section type.
 */
export interface ConsolidationReportContainers {
  level: ConsolidationContainerType;
  containers: Array<ConsolidationReportContainer>;
}

/**
 * Consoliddation account summary type.
 */
export type ConsolidationReportAccount = ConsolidationReportContainer;

/**
 * Consolidation report accounts section type.
 */
export interface ConsolidationReportAccounts {
  custody: Array<ConsolidationReportAccount>;
  journal: Array<ConsolidationReportAccount>;
}

/**
 * Consolidation report FX rate type.
 */
export interface ConsolidationReportFXRate {
  ccy1: Currency;
  ccy2: Currency;
  value: number;
  asof: SDate;
}

/**
 * Consolidation report FX rates section type.
 */
export type ConsolidationReportFXRates = ConsolidationReportFXRate[];

/**
 * Consolidation report response data type.
 */
export interface ConsolidationReportResponseData {
  reported: SDateTime;
  asof: SDate;
  type: DateType;
  ccy: Currency;
  containers: ConsolidationReportContainers;
  accounts: ConsolidationReportAccounts;
  holdings: Array<RawHolding>;
  accruals: Array<RawAccrual>;
  investment: Maybe<number>;
  valuation_net: Maybe<number>;
  valuation_abs: Maybe<number>;
  accrued: Maybe<number>;
  liabilities: Maybe<number>;
  gav: Maybe<number>;
  nav: Maybe<number>;
  aum: Maybe<number>;
  pnl: Maybe<number>;
  pnl_to_investment: Maybe<number>;
  fxrates: ConsolidationReportFXRates;
}

/**
 * Consolidation report data type.
 */
export interface ConsolidationReportData {
  timestamp: SDateTime;
  date: SDate;
  type: DateType;
  currency: Currency;
  containers: ConsolidationReportContainers;
  custodyAccounts: Array<AccountResource>;
  analyticalAccount: Array<AccountResource>;
  positions: Positions;
  valuation: ConsolidationReportValuation;
  fxrates: ConsolidationReportFXRates;
}

/**
 * Compiles the library representation of report query into remote endpoint query parameters.
 *
 * @param q Query.
 * @returns Remote endpoint query parameters.
 */
export function query2params(q: ConsolidationReportQuery): { [key: string]: string } {
  // Get part of the queryparams as per elements:
  const elements: { [key: string]: string | Array<string> } =
    q.elements === '*' ? {} : { c: q.elements.container, i: q.elements.instances.map((x) => `${x}`) };

  // Get part of the queryparams as per sandbox:
  const sandbox: { [key: string]: string } = isNothing(q.sandbox) || q.sandbox == 'include' ? {} : { sandbox: 'false' };

  // Construct params and return:
  return {
    ...elements,
    ...sandbox,
    ccy: q.currency,
    date: q.date,
    type: q.datetype,
  };
}

/**
 * Converts consolidation report response data to cosnolidation report data.
 *
 * @param data Response data from quick valuation endpoint.
 * @returns Quick valuation report value.
 */
export function compileReportData(
  accounts: Record<Id, AccountResource>,
  artifacts: Record<Id, ArtifactResource>,
  data: ConsolidationReportResponseData
): ConsolidationReportData {
  //  Get total valuation figures for the report:
  const valuation = computeValuation(data);

  // Compile and return:
  return {
    timestamp: data.reported,
    date: data.asof,
    type: data.type,
    currency: data.ccy,
    containers: data.containers,
    // @ts-expect-error ts2322
    custodyAccounts: data.accounts.custody.map((x) => accounts[x.id]),
    // @ts-expect-error ts2322
    analyticalAccount: data.accounts.journal.map((x) => accounts[x.id]),
    positions: computePositions(data, accounts, artifacts, valuation),
    valuation,
    fxrates: data.fxrates,
  };
}

export function getAccountIdsOfInterest(holdings: Array<RawHolding>, accruals: Array<RawAccrual>): Array<Id> {
  // Initialize the return value buffer:
  const retval: Set<Id> = new Set();

  // Get by holding accounts:
  holdings.forEach((h) => h.accounts.map((a) => a.id).forEach((x) => retval.add(x)));

  // Get by accrual accounts:
  accruals.forEach((p) => p.accounts.map((a) => a.account.id).forEach((x) => retval.add(x)));

  // Done, return:
  return Array.from(retval);
}

export function getArtifactIdsOfInterest(holdings: Array<RawHolding>, accruals: Array<RawAccrual>): Array<Id> {
  // Initialize the return value buffer:
  const retval: Set<Id> = new Set();

  // Get by holdings artifacts:
  holdings.map((h) => h.artifact.id).forEach((x) => retval.add(x));

  // Get by underlyings of holding artifacts:
  holdings
    .map((h) => h.artifact.underlying_id)
    .filter((i) => i)
    .forEach((x) => retval.add(x as Id));

  // Get by accrual artifacts:
  accruals.forEach((p) => p.accounts.map((a) => a.accruals.map((x) => x.artifact.id).forEach((x) => retval.add(x))));

  // Done, return:
  return Array.from(retval);
}

export async function compileConsolidationReportData(
  client: DecafClient,
  data: ConsolidationReportResponseData
): Promise<ConsolidationReportData> {
  // Get holdings and accruals:
  const { holdings, accruals } = data;

  // Attempt to get accounts lookup table:
  const lookupAccounts = await buildAccountEntityLookupTable(client, getAccountIdsOfInterest(holdings, accruals));

  // Attempt to get artifacts lookup table:
  const lookupArtifacts = await buildArtifactEntityLookupTable(client, getArtifactIdsOfInterest(holdings, accruals));

  // Compile the data and return:
  return compileReportData(lookupAccounts, lookupArtifacts, data);
}

/**
 * Requests consolidation.
 *
 * @param client barista client.
 * @returns Consolidation report data.
 */
export async function retrieveConsolidationReport(
  client: DecafClient,
  query: ConsolidationReportQuery
): Promise<ConsolidationReportData> {
  // Get remote data:
  const { data } = await client.barista.get<ConsolidationReportResponseData>('/consolidation/', {
    params: query2params(query),
  });

  // Compile report data:
  const report = await compileConsolidationReportData(client, data);

  // Done, return:
  return report;
}

/**
 * Downloads consolidation as a PDF file.
 *
 * @param client barista client.
 */
export function download(client: DecafClient, query: ConsolidationReportQuery, filename: Maybe<string>): void {
  // Get parameters:
  const params = { ...query2params(query), as: 'pdf' };

  // Download:
  downloadHelper(
    // @ts-expect-error
    client.barista,
    { url: '/consolidation/', method: 'GET', params, data: undefined },
    getOrElse(filename, 'consolidation.pdf'),
    true
  );
}
