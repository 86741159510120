import { action } from '@ember/object';
import PersistedConsolidationModel from 'cafelatte/models/pconsolidation';
import { FlexTableColumnSpec, FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

const numberOptions: Partial<FlexTableColumnSpec<any>> = {
  align: 'right',
  component: '@number',
  options: { format: '0,0.00' },
};

export default class extends RecordListComponent<PersistedConsolidationModel> {
  get spec(): FlexTableSpec<PersistedConsolidationModel> {
    return {
      ident: 'cl-persisted-consolidation-list-table',
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        {
          key: 'portfolio.name',
          label: 'Portfolio',
          // @ts-expect-error
          action: ({ record }): void => this.gotoRoute('portfolio.details', record.get('portfolio.id')),
        },
        {
          key: 'shareclass.name',
          label: 'Share Class',
          // @ts-expect-error
          action: ({ record }): void => this.gotoRoute('shareclass.details', record.get('shareclass.id')),
        },
        {
          key: 'account.name',
          label: 'Account',
          // @ts-expect-error
          action: ({ record }): void => this.gotoRoute('account.details', record.get('account.id')),
        },
        { key: 'date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'ccy', label: 'CCY' },
        { key: 'nav', label: 'NAV', ...numberOptions },
        { key: 'price', ...numberOptions, options: { format: '0,0.0000' } },
        { key: 'shares', ...numberOptions, options: { format: '0,0.00[00]' } },
        { key: 'aum', label: 'AUM', ...numberOptions },
        { key: 'accruals', label: 'Total Accruals', ...numberOptions },
        { key: 'navrefccy', label: 'NAV (Ref CCY)', ...numberOptions },
        { key: 'created', component: '@date', options: { format: 'human' } },
        { key: 'updated', component: '@date', options: { format: 'human' } },
      ],
    };
  }

  @action gotoRoute(route: string, id: any): void {
    if (id) {
      window.open(this.router.urlFor(route, id));
    }
  }
}
