import { DecafClient, gql } from '@decafhub/decaf-client';
import { SDate } from '../../prelude/datetime';

export const query = gql`
  query ($portfolio: Int!) {
    quant(
      limit: 1
      where: {
        _and: [
          { account: { analytical_type_id: { _is_null: true } } }
          { account: { portfolio_id: { _eq: $portfolio } } }
        ]
      }
      order_by: [{ commitment_date: desc }]
    ) {
      id
      commitment: commitment_date
      action: action_id
    }
  }
`;

/**
 * Attempts to retrieve last custody quant for the portfolio.
 *
 * @param client DECAF API clients.
 * @returns Last custody quant if any.
 */
export async function getLastQuant(
  client: DecafClient,
  portfolio: string | number
): Promise<{ id: number; commitment: SDate; action: number } | undefined> {
  return client.microlot.query({ query, variables: { portfolio } }).then((result) => (result.data?.quant || [])[0]);
}
