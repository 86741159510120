import { uniqSortElements } from './arrays';
import { isNothing, Maybe } from './maybe';

/**
 * Simple choice value type.
 */
export type SimpleChoiceValueType = string | number | symbol;

/**
 * Provides an encoding for a simple choice value/label type.
 */
export type SimpleChoice<T extends SimpleChoiceValueType> = { value: T; label: string };

/**
 * Provides an encoding of a list of simple choices.
 */
export type SimpleChoices<T extends SimpleChoiceValueType> = Array<SimpleChoice<T>>;

/**
 * Provides an encoding of a lookup table of simple choices.
 */
export type SimpleChoiceLookupTable<T extends SimpleChoiceValueType> = Record<T, SimpleChoice<T>>;

/**
 * Builds a lookup table of simple choices from a given list of simple choices.
 *
 * @param cs A list of simple choices.
 * @returns A lookup table of simple choices.
 */
export function buildLookupTable<T extends SimpleChoiceValueType>(cs: SimpleChoices<T>): SimpleChoiceLookupTable<T> {
  return cs.reduce((p, c) => ({ ...p, [c.value]: c }), {} as SimpleChoiceLookupTable<T>);
}

/**
 * Creates a [[SimpleChoice]] from the given value and optional label.
 *
 * If label is [[Nothing]], stringified value is used as label instead.
 *
 * @param value Value
 * @param label Label, if any.
 * @returns [[SimpleChoice]]
 */
export function mkSimpleChoice<T extends SimpleChoiceValueType>(value: T, label?: Maybe<string>): SimpleChoice<T> {
  // @ts-expect-error
  return { value, label: isNothing(label) ? `${value}` : label };
}

/**
 * Creates a [[SimpleChoices]] from the given object with the key of the object type we are interested in.
 *
 * @param xs Objects.
 * @param key key of the object type of interest.
 * @returns [[SimpleChoices]]
 */
export function buildSimpleChoices<T extends { [key in P]: V }, P extends keyof T, V extends SimpleChoiceValueType>(
  xs: T[],
  key: P & keyof T
): SimpleChoices<V> {
  return uniqSortElements(xs.map((x) => x[key])).map((x) => mkSimpleChoice(x));
}

/**
 * Type predicate to check if a given value is a simple choice.
 *
 * @param x Value.
 * @returns True if x is a SimpleChoice, false otherwise.
 */
export function isSimpleChoice<T extends SimpleChoiceValueType>(x: any): x is SimpleChoice<T> {
  if (typeof x !== 'object') {
    return false;
  }
  const keys = Object.keys(x);
  return keys.includes('value') && keys.includes('label');
}
