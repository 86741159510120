import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import CLSessionService from 'cafelatte/services/-ts/session';
import KonfigService from 'cafelatte/services/konfig';
import { Navigation, predefined } from '../../machinery';

export default class extends Component {
  @service declare konfig: KonfigService;
  @service declare session: CLSessionService;

  get navigation(): Navigation {
    return this.konfig.konfiguration.APP.cafelatteConfiguration.navigation || predefined;
  }

  /**
   * Action to logout the user from the system.
   */
  @action logout(all: boolean): void {
    this.session.invalidate('authenticator:decaf', all);
  }
}
