import Model, { attr } from '@ember-data/model';

export default class VoucherTypeModel extends Model {
  @attr()
  name!: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'vouchers/type': VoucherTypeModel;
  }
}
