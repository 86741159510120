import { DecafClient } from '@decafhub/decaf-client';
import { formatNumber } from 'cafelatte/helpers/format-number';
import { NavGroup, Navigation, NavItem, predefined as SiteMenu } from '../../c-l/application/navigation/machinery';
import {
  ACCOUNT_SEARCH_QUERY,
  INSTRUMENT_SEARCH_QUERY,
  PORTFOLIO_SEARCH_QUERY,
  TRADE_SEARCH_QUERY,
  TRANSACTION_SEARCH_QUERY,
} from './queries';

export interface SearchItem {
  label: string;
  route: {
    path: string;
    id?: number | string;
  };
  categories?: string[];
  icon?: string;
}

export type SearchType = 'portfolio' | 'instrument' | 'account' | 'trade' | 'transaction' | '';

type BoolExp = object;

type BoolExpFactory = (keyword: string) => undefined | BoolExp;

// generate nested object from dot-separated path
function dictFromPath(path: string, value: any) {
  const parts: any = path.split('.');
  const result: any = {};
  let current = result;
  for (let i = 0; i < parts.length - 1; i++) {
    current[parts[i]] = {};
    current = current[parts[i]];
  }
  current[parts[parts.length - 1]] = value;
  return result;
}

function byString(field: string, operator: string, prefix = '', suffix = '', relation?: string): BoolExpFactory {
  const buildResult = (s: string) => ({
    [field]: {
      [operator]: `${prefix || ''}${s}${suffix || ''}`,
    },
  });
  return (s: string) => (relation ? dictFromPath(relation, buildResult(s)) : buildResult(s));
}

function byInt(field: string, operator: string, relation?: string): BoolExpFactory {
  const buildResult = (s: any) => {
    return isNaN(s)
      ? undefined
      : {
          [field]: {
            [operator]: s,
          },
        };
  };
  return (s: string) => (relation ? dictFromPath(relation, buildResult(s)) : buildResult(s));
}

function orredFilters(xs: BoolExpFactory[], keyword: string) {
  return { _or: xs.map((x) => x(keyword)).filter((x) => !!x) };
}

export function buildSearchItems(routes: Navigation, categories?: string[]): SearchItem[] {
  return routes
    .map((route) => {
      if (Object.keys(route).includes('navs')) {
        return buildSearchItems((route as NavGroup).navs, [...(categories ?? []), route.name]);
      } else {
        const item = route as NavItem;
        return {
          label: item.name,
          route: {
            path: item.route,
          },
          icon: item.icon,
          categories: categories,
        };
      }
    })
    .flat() as SearchItem[];
}

export function getSearchValue(keyword: string): [SearchType, string] {
  const lowerKeyword = keyword.toLowerCase();
  const stripped = keyword.slice(2) || '';
  if (lowerKeyword.startsWith('p ')) {
    return ['portfolio', stripped];
  } else if (lowerKeyword.startsWith('i ')) {
    return ['instrument', stripped];
  } else if (lowerKeyword.startsWith('a ')) {
    return ['account', stripped];
  } else if (lowerKeyword.startsWith('t ')) {
    return ['trade', stripped];
  } else if (lowerKeyword.startsWith('q ')) {
    return ['transaction', stripped];
  } else {
    return ['', keyword];
  }
}

export function highlightMatchedText(keyword: string): void {
  if (!keyword) {
    return;
  }
  document.querySelectorAll('.csb-result-label').forEach((r) => {
    const text = r.textContent;
    if (text) {
      const regex = new RegExp(keyword, 'gi');
      const matched = text.match(regex);
      if (matched) {
        const replaced = text.replace(regex, '<b>$&</b>');
        r.innerHTML = replaced;
      }
    }
  });
}

export async function search(client: DecafClient, keyword: string): Promise<[SearchItem[], string]> {
  const [type, value] = getSearchValue(keyword);
  let result = [];
  if (!value) {
    return [[], value];
  }
  if (type === 'portfolio') {
    const filterFactories = [byString('name', '_ilike', '%', '%'), byInt('id', '_eq')];
    const filters = orredFilters(filterFactories, value);
    const { data } = await client.microlot.query({
      query: PORTFOLIO_SEARCH_QUERY,
      variables: { filter: filters },
    });
    result = data?.results?.map((r: any) => ({
      label: `#${r.id} - ${r.name}`,
      categories: ['Portfolios'],
      route: {
        path: 'portfolio.details',
        id: r.id,
      },
    }));
  } else if (type === 'instrument') {
    const filterFactories = [
      byString('name', '_ilike', '%', '%'),
      byString('symbol', '_ilike', '%', '%'),
      byInt('id', '_eq'),
    ];
    const filters = orredFilters(filterFactories, value);
    const { data } = await client.microlot.query({
      query: INSTRUMENT_SEARCH_QUERY,
      variables: { filter: filters },
    });
    result = data?.results?.map((r: any) => ({
      label: `#${r.id} - ${r.symbol}`,
      categories: ['Instruments'],
      route: {
        path: 'resource.details',
        id: r.id,
      },
    }));
  } else if (type === 'account') {
    const filterFactories = [byString('name', '_ilike', '%', '%'), byString('guid', '_eq'), byInt('id', '_eq')];
    const filters = orredFilters(filterFactories, value);
    const { data } = await client.microlot.query({
      query: ACCOUNT_SEARCH_QUERY,
      variables: { filter: filters },
    });
    result = data?.results?.map((r: any) => {
      const custodyOrAnalytical = r.analytical_type?.name || r.custodian.name;
      return {
        label: `#${r.id} - ${r.name} (${r.portfolio.name}) (${custodyOrAnalytical})`,
        categories: ['Accounts'],
        route: {
          path: 'account.details',
          id: r.id,
        },
      };
    });
  } else if (type === 'trade') {
    const filterFactories = [byString('guid', '_eq'), byInt('id', '_eq')];
    const filters = orredFilters(filterFactories, value);
    const { data } = await client.microlot.query({
      query: TRADE_SEARCH_QUERY,
      variables: { filter: filters },
    });
    result = data?.results?.map((r: any) => ({
      label: `#${r.id} - (${r.main_account.name}) (${r.main_artifact.name}) - (${formatNumber([
        r.main_quantity,
        '0,0.00',
      ])}) - ${r.main_price}`,
      categories: ['Trades'],
      route: {
        path: 'trade.details',
        id: r.id,
      },
    }));
  } else if (type === 'transaction') {
    const filterFactories = [byString('guid', '_eq'), byInt('id', '_eq')];
    const filters = orredFilters(filterFactories, value);
    const { data } = await client.microlot.query({
      query: TRANSACTION_SEARCH_QUERY,
      variables: { filter: filters },
    });
    result = data?.results?.map((r: any) => ({
      label: `#${r.id} - (${r.account.name}) (${r.artifact.name}) ${formatNumber([r.value_quantity, '0,0.00'])}`,
      categories: ['Transactions'],
      route: {
        path: 'quant.details',
        id: r.id,
      },
    }));
  } else {
    result = menu.filter((item) => item.label.toLowerCase().indexOf(keyword.toLowerCase()) > -1);
  }
  return [result, value];
}

export const menu = buildSearchItems(SiteMenu);
