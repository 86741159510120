import { action, setProperties } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { SimpleChoices } from 'cafelatte/libs/headless/prelude/simple-choice';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  queryParams = [
    'page',
    'page_size',
    'search',
    'name__icontains',
    'closed__isnull',
    'created__gte',
    'created__lte',
    'updated__gte',
    'updated__lte',
    'opened__gte',
    'opened__lte',
    'closed__gte',
    'closed__lte',
    'portfolio',
    'portfolio__team',
    'custodian',
    'atype',
    'riskprofile',
    'atype__isnull',
    'rccy',
  ];

  @tracked page = 1;
  @tracked page_size = 10;
  @tracked search = '';
  @tracked closed__isnull = 'True';
  @tracked atype__isnull = '';

  choicesActivity: SimpleChoices<string> = [
    { value: '', label: 'All' },
    { value: 'True', label: 'Open' },
    { value: 'False', label: 'Closed' },
  ];

  choicesAccountType: SimpleChoices<string> = [
    { value: '', label: 'All' },
    { value: 'True', label: 'Custody' },
    { value: 'False', label: 'Analytical' },
  ];

  get operations() {
    return mkCommonRowActions([
      { type: 'link', action: ({ record }) => this.router.transitionTo('account.details', record.id) },
      { type: 'edit', action: ({ record }) => this.router.transitionTo('account.details.profile', record.id) },
    ]);
  }

  @action reset() {
    setProperties(this, {
      ...this.queryParams.reduce((o, k) => ({ ...o, [k]: '' }), {}),
      page: 1,
      search: '',
      closed__isnull: 'True',
    });
  }
}
