import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import KonfigService from 'cafelatte/services/konfig';
import MeService from 'cafelatte/services/me';

export default class extends Component {
  @service declare me: MeService;
  @service declare konfig: KonfigService;

  get source() {
    return `https://static.zdassets.com/ekr/snippet.js?key=${this.konfig.konfiguration.APP.publicConfiguration.zendesk}`;
  }

  isOpen = false;

  @action toggle(): void {
    this.isOpen ? this.closeWidget() : this.openWidget();
  }

  openWidget(): void {
    window.zE('webWidget', 'open');
  }

  closeWidget(): void {
    window.zE('webWidget', 'close');
  }

  @action initButton(): void {
    window.zE('webWidget', 'hide');
    window.zE('webWidget:on', 'open', this.onOpen);
    window.zE('webWidget:on', 'close', this.onClose);
    window.zE('webWidget', 'prefill', { name: { value: this.me.displayName }, email: { value: this.me.email } });
  }

  @action onOpen(): void {
    window.zE('webWidget', 'show');
    this.isOpen = true;
  }

  @action onClose(): void {
    window.zE('webWidget', 'hide');
    this.isOpen = false;
  }
}
