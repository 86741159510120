import { action, computed, get, notifyPropertyChange, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { DateType } from 'cafelatte/libs/headless/commons/date-type';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { buildSimpleChoices, SimpleChoices } from 'cafelatte/libs/headless/prelude/simple-choice';
import { CashStatement, CashStatementItem } from 'cafelatte/libs/headless/services/cash-statement';
import DecafService from 'cafelatte/services/decaf';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import saver from 'file-saver';
import PapaParse from 'papaparse';
import { FlexTableSpec, mkCommonSorting, mkLinkColumn, mkNumberColumn } from '../components/x/flex-table';

export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = ['dateasof', 'datetype', 'currency'];

  @tracked page = 1;
  @tracked page_size = 50;

  @tracked currency = 'USD';
  @tracked dateasof = today();
  @tracked datetype: DateType = 'commitment';
  @tracked sorting?: { by: string; descending: boolean } = undefined;

  @tracked selectedTeams: SimpleChoices<string> = [];
  @tracked selectedPortfolios: SimpleChoices<string> = [];
  @tracked selectedAccounts: SimpleChoices<string> = [];
  @tracked selectedCustodians: SimpleChoices<string> = [];
  @tracked selectedInstruments: SimpleChoices<string> = [];
  @tracked selectedInstrumentTypes: SimpleChoices<string> = [];
  @tracked selectedCurrencies: SimpleChoices<string> = [];

  @computed('currency', 'dateasof', 'datetype')
  get records() {
    return DS.PromiseArray.create({
      // @ts-expect-error
      promise: this.decaf.services.cashStatement.get({
        currency: this.currency,
        dateasof: this.dateasof,
        datetype: this.datetype,
      }),
    });
  }

  @computed('records.content.[]')
  get safeRecords(): CashStatement {
    return (this.records.content as CashStatement | undefined) || [];
  }

  @computed(
    'records.content.[]',
    'selectedTeams.[]',
    'selectedPortfolios.[]',
    'selectedAccounts.[]',
    'selectedCustodians.[]',
    'selectedInstruments.[]',
    'selectedInstrumentTypes.[]',
    'selectedCurrencies.[]'
  )
  get filteredRecords() {
    // Get the records:
    const records = this.safeRecords;

    // Filter and return:
    return records
      .filter(
        (x) => this.selectedTeams.length == 0 || this.selectedTeams.filter((y) => y.value == x.teamname).length > 0
      )
      .filter(
        (x) =>
          this.selectedPortfolios.length == 0 || this.selectedPortfolios.filter((y) => y.value == x.portname).length > 0
      )
      .filter(
        (x) => this.selectedAccounts.length == 0 || this.selectedAccounts.filter((y) => y.value == x.accname).length > 0
      )
      .filter(
        (x) =>
          this.selectedCustodians.length == 0 || this.selectedCustodians.filter((y) => y.value == x.custname).length > 0
      )
      .filter(
        (x) =>
          this.selectedInstruments.length == 0 ||
          this.selectedInstruments.filter((y) => y.value == x.resname).length > 0
      )
      .filter(
        (x) =>
          this.selectedInstrumentTypes.length == 0 ||
          this.selectedInstrumentTypes.filter((y) => y.value == x.htype).length > 0
      )
      .filter(
        (x) =>
          this.selectedCurrencies.length == 0 || this.selectedCurrencies.filter((y) => y.value == x.ccyorg).length > 0
      );
  }

  @computed('filteredRecords.[]', 'sorting.{by,descending}')
  get sortedRecords() {
    return isNothing(this.sorting) || this.sorting == undefined
      ? this.filteredRecords
      : this.filteredRecords.sort((a: any, b: any) => {
          // @ts-expect-error
          const va = get(a, this.sorting.by);
          // @ts-expect-error
          const vb = get(b, this.sorting.by);
          return this.sorting?.descending ? (vb < va ? -1 : vb > va ? 1 : 0) : vb < va ? 1 : vb > va ? -1 : 0;
        });
  }

  @computed('sortedRecords.[]', 'page', 'page_size')
  get finalRecords() {
    return this.sortedRecords.slice((this.page - 1) * this.page_size, this.page * this.page_size);
  }

  get spec(): FlexTableSpec<CashStatementItem> {
    // Get records of interest:
    const records = this.safeRecords;

    // Build choices:
    const teamChoices = buildSimpleChoices(records, 'teamname');
    const portfolioChoices = buildSimpleChoices(records, 'portname');
    const accountChoices = buildSimpleChoices(records, 'accname');
    const custodianChoices = buildSimpleChoices(records, 'custname');
    const instrumentChoices = buildSimpleChoices(records, 'resname');
    const instrumentTypeChoices = buildSimpleChoices(records, 'htype');
    const currencyChoices = buildSimpleChoices(records, 'ccyorg');

    return {
      ident: 'cl-cash-statement',
      vfill: true,
      columns: [
        mkLinkColumn('team.details', 'teamid', {
          key: 'teamname',
          label: 'Team',
          ...mkCommonSorting(this, 'sorting', 'teamname'),
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedTeams', x, []),
          filterOptions: () => teamChoices,
          filterSelections: () => this.selectedTeams,
        }),
        mkLinkColumn('portfolio.details', 'portid', {
          key: 'portname',
          label: 'Portfolio',
          ...mkCommonSorting(this, 'sorting', 'portname'),
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedPortfolios', x, []),
          filterOptions: () => portfolioChoices,
          filterSelections: () => this.selectedPortfolios,
        }),
        mkLinkColumn('account.details', 'accid', {
          key: 'accname',
          label: 'Account',
          ...mkCommonSorting(this, 'sorting', 'accname'),
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedAccounts', x, []),
          filterOptions: () => accountChoices,
          filterSelections: () => this.selectedAccounts,
        }),
        mkLinkColumn('institution.details', 'custid', {
          key: 'custname',
          label: 'Custodian',
          ...mkCommonSorting(this, 'sorting', 'custname'),
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedCustodians', x, []),
          filterOptions: () => custodianChoices,
          filterSelections: () => this.selectedCustodians,
        }),
        mkLinkColumn('resource.details', 'resid', {
          key: 'ressymbol',
          label: 'Symbol',
          hidden: true,
          ...mkCommonSorting(this, 'sorting', 'ressymbol'),
        }),
        mkLinkColumn('resource.details', 'resid', {
          key: 'resname',
          label: 'Name',
          ...mkCommonSorting(this, 'sorting', 'resname'),
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedInstruments', x, []),
          filterOptions: () => instrumentChoices,
          filterSelections: () => this.selectedInstruments,
        }),
        mkLinkColumn('resource.details', 'resid', {
          key: 'htype',
          label: 'Type',
          ...mkCommonSorting(this, 'sorting', 'htype'),
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedInstrumentTypes', x, []),
          filterOptions: () => instrumentTypeChoices,
          filterSelections: () => this.selectedInstrumentTypes,
        }),
        mkLinkColumn('resource.details', 'resid', {
          key: 'ccyorg',
          label: 'Currency',
          ...mkCommonSorting(this, 'sorting', 'ccyorg'),
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedCurrencies', x, []),
          filterOptions: () => currencyChoices,
          filterSelections: () => this.selectedCurrencies,
        }),
        mkNumberColumn({ key: 'qtyorg', label: 'Quantity', ...mkCommonSorting(this, 'sorting', 'qtyorg') }),
        mkNumberColumn({ key: 'qtyref', label: 'Value', ...mkCommonSorting(this, 'sorting', 'qtyref') }),
      ],
    };
  }

  filterChanged<T extends { [key in P]: T[P] }, P extends keyof T>(
    of: T,
    key: P & keyof T,
    value: Maybe<T[P]>,
    def: T[P]
  ) {
    this.page = 1;
    set(of, key, isNothing(value) ? def : value);
  }

  @action refresh() {
    notifyPropertyChange(this, 'dateasof');
  }

  @action download() {
    // Attempt to get records:
    const records = this.safeRecords;

    // Attempt to prepare the data:
    const data = records.map((x: CashStatementItem) => ({
      Date: x.dateasof,
      Team: x.teamname,
      Portfolio: x.portname,
      Account: x.accname,
      Custodian: x.custname,
      'Analytical Type': x.analytical,
      Symbol: x.ressymbol,
      Name: x.resname,
      Type: x.htype,
      'Currency (Org)': x.ccyorg,
      'Quantity (Org)': x.qtyorg,
      'Currency (Ref)': x.ccyref,
      'Quantity (Ref)': x.qtyref,
    }));

    // Save the file:
    saver.saveAs(new Blob([PapaParse.unparse(data)], { type: 'text/csv;charset=utf-8' }), 'cash_statement.csv');
  }
}
