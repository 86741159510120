import Decimal from 'decimal.js';
import { Id } from '../../../commons/types';

/**
 * Consolidation container type definition.
 */
export type ConsolidationContainerType = 'account' | 'portfolio' | 'team' | 'custodian' | 'portfoliogroup';

/**
 * Available consolidation container types.
 */
export const ConsolidationContainerTypes: Array<ConsolidationContainerType> = [
  'account',
  'portfolio',
  'team',
  'custodian',
  'portfoliogroup',
];

/**
 * Consolidation container elements type definition.
 */
export type ConsolidationContainers = '*' | { container: ConsolidationContainerType; instances: Array<Id> };

/**
 * Consolidation report valuation segment.
 */
export interface ConsolidationReportValuation {
  gav: Decimal;
  nav: Decimal;
  aum: Decimal;
  cash: Decimal;
  loan: Decimal;
  deposit: Decimal;
  totalCashDeposit: Decimal;
  totalCashDepositPercentage: Decimal;
  netValue: Decimal;
  absValue: Decimal;
  netExposure: Decimal;
  netExposureRatio: Decimal;
  absExposure: Decimal;
  absExposureRatio: Decimal;
  accrued: Decimal;
  liabilities: Decimal;
  investment: Decimal;
  unrealizedPNL: Decimal;
  unrealizedPNLRatio: Decimal;
}
