import EmberObject, { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { Currency, Id } from 'cafelatte/libs/headless/commons/types';
import { SDate } from 'cafelatte/libs/headless/prelude/datetime';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import TradeOrderAllocationModel from 'cafelatte/models/tradeorderallocation';
import { FlexTableCellArgumentsWithValues } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';

export default class extends Component<FlexTableCellArgumentsWithValues<TradeOrderAllocationModel>> {
  @service declare decaf: DecafService;

  @computed('args.record.order.resmain.ccymain')
  get currency(): Maybe<Currency> {
    return get(this.args.record, 'order.resmain.ccymain');
  }

  @computed('args.record.order.date')
  get date(): Maybe<SDate> {
    return get(this.args.record, 'order.date');
  }

  @computed('args.record.monetary.qty')
  get monetary(): number {
    return get(this.args.record, 'monetary.qty') || 0;
  }

  @computed('args.column.options.of')
  get modelName(): 'account' | 'portfolio' {
    return this.args.column?.options?.of || 'account';
  }

  @computed('args.record.account.{id,portfolio.id}')
  get modelId(): Maybe<Id> {
    return get(this.args.record, this.modelName == 'account' ? 'account.id' : 'account.portfolio.id');
  }

  @computed('currency', 'date', 'modelName', 'modelId')
  get consolidation() {
    // Check input:
    if (isNothing(this.currency) || isNothing(this.date) || isNothing(this.modelName) || isNothing(this.modelId)) {
      return undefined;
    }

    // Define the return value:
    const retval = EmberObject.create({});

    // Get consolidation:
    this.decaf.services.consolidationReport
      .requestQuickValuation({
        date: this.date,
        datetype: 'commitment',
        elements: { container: this.modelName, instances: [this.modelId] },
        currency: this.currency,
        sandbox: 'include',
      })
      .then((x) => retval.setProperties(x));

    // Done, return:
    return retval;
  }

  @computed('consolidation.nav', 'monetary')
  get percentage() {
    // Get the NAV:
    const nav = this.consolidation?.nav || 0;

    // Compute the percentage and return:
    return nav == 0 ? undefined : this.monetary / nav;
  }
}
