import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  value: any;
  class?: string;
  placeholder?: string;
  drate?: number;
  onChange?: Function;
}

export default class extends Component<Args> {
  searchTimeout?: NodeJS.Timeout;

  @action handleSearch(event: InputEvent): void {
    if (this.searchTimeout !== undefined) {
      clearTimeout(this.searchTimeout);
    }
    const target = event.target as HTMLInputElement;
    this.searchTimeout = setTimeout(() => {
      this.args.onChange?.(target.value);
    }, this.args.drate || 500);
  }
}
