import { DecafClient } from '@decafhub/decaf-client';

/**
 * Encoding for remote DECAF API versions.
 */
export interface Versions {
  barista: string;
}

/**
 * Attempts to retrieve remote DECAF API versions.
 *
 * @param client DECAF APIs client.
 * @returns Remote DECAF API versions.
 */
export async function getVersions(client: DecafClient): Promise<Versions> {
  return {
    barista: await client.barista.get<{ version: string }>('/version/').then(({ data }) => data.version),
  };
}
