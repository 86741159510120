import { DecafClient } from '@decafhub/decaf-client';
import { Id } from '../../commons/types';
import { uniqElements } from '../../prelude/arrays';
import { SDate } from '../../prelude/datetime';
import { isJust, Maybe } from '../../prelude/maybe';
import { SimpleChoices } from '../../prelude/simple-choice';
import queryAccounts from './-query-accounts';
import queryInstitutions from './-query-institutions';
import queryPortfolios from './-query-portfolios';
import queryTeams from './-query-teams';

export type BreakdownType = 'ctype' | 'team' | 'portfolio' | 'account' | 'institution';

export type AmountType = 'amount' | 'gross' | 'net';

export interface VoucherTimelineItem {
  year: number;
  month: number;
  settled: number;
  breakdown1: Maybe<Id>;
  breakdown2: Maybe<Id>;
  pending: number;
  total: number;
}

export interface VoucherTimelineLabeledItem extends VoucherTimelineItem {
  breakdown1Type: BreakdownType;
  breakdown1Label: string;
  breakdown2Type: BreakdownType;
  breakdown2Label: string;
}

export interface VoucherTimelineQuery {
  breakdown1: BreakdownType;
  breakdown2: BreakdownType;
  type: AmountType;
  refccy: string;
  start: Maybe<SDate>;
  end: Maybe<SDate>;
}

export const BreakdownChoices: SimpleChoices<string> = [
  { value: 'ctype', label: 'Type' },
  { value: 'team', label: 'Team' },
  { value: 'portfolio', label: 'Portfolio' },
  { value: 'account', label: 'Account' },
  { value: 'institution', label: 'Institution' },
];

export const AmountChoices: SimpleChoices<string> = [
  { value: 'amount', label: 'Original' },
  { value: 'gross', label: 'After Sharing' },
  { value: 'net', label: 'After Rebate' },
];

/**
 * Attempts to retrieve and compile remote report data.
 *
 * @param client barista client.
 * @returns Report data.
 */
export async function request(client: DecafClient, query: VoucherTimelineQuery): Promise<VoucherTimelineLabeledItem[]> {
  // Get raw items:
  const { data } = await client.barista.get<{ values: VoucherTimelineItem[] }>('/vouchers/timeline/', {
    params: query,
  });

  // Get breakdown1/2 type:
  const { breakdown1, breakdown2 } = query;

  // Get breakdown1/2 catalogues:
  const breakdown1Catalogue = await getBreakdownCatalogue(
    client,
    breakdown1,
    // @ts-expect-error
    uniqElements(data.values.map((x) => x.breakdown1).filter((x) => isJust(x)))
  );
  const breakdown2Catalogue = await getBreakdownCatalogue(
    client,
    breakdown2,
    // @ts-expect-error
    uniqElements(data.values.map((x) => x.breakdown2).filter((x) => isJust(x)))
  );

  return data.values.map((x) => ({
    ...x,
    breakdown1Type: breakdown1,
    breakdown2Type: breakdown2,
    // @ts-expect-error
    breakdown1Label: isJust(x.breakdown1) ? breakdown1Catalogue[x.breakdown1] : '#N/A',
    // @ts-expect-error
    breakdown2Label: isJust(x.breakdown2) ? breakdown2Catalogue[x.breakdown2] : '#N/A',
  }));
}

export async function getBreakdownCatalogue(
  client: DecafClient,
  type: BreakdownType,
  ids: Id[]
): Promise<Record<Id, string>[]> {
  if (type == 'ctype') {
    // @ts-expect-error
    return Promise.resolve(ids.reduce((acc, i) => ({ ...acc, [i]: i }), {}));
  } else if (type == 'account') {
    return getRemoteLabeledItems(client, queryAccounts, ids);
  } else if (type == 'institution') {
    return getRemoteLabeledItems(client, queryInstitutions, ids);
  } else if (type == 'portfolio') {
    return getRemoteLabeledItems(client, queryPortfolios, ids);
  } else if (type == 'team') {
    return getRemoteLabeledItems(client, queryTeams, ids);
  }
  throw new Error(`Invalid catalogue type: ${type}`);
}

export async function getRemoteLabeledItems(client: DecafClient, query: any, ids: Id[]): Promise<Record<Id, string>[]> {
  const records: { id: Id; label: string }[] = await client.microlot
    .query({ query, variables: { ids } })
    .then((result) => result.data?.records || []);

  // @ts-expect-error
  return records.reduce((acc, x) => ({ ...acc, [x.id]: x.label }), {});
}
