import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import AssetClassModel from 'cafelatte/models/assetclass';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export interface AssetClassTree {
  instance: AssetClassModel;
  level: number;
  leveledName: string;
  children: Array<AssetClassTree>;
}

export function makeTree(list: AssetClassModel[]): Array<AssetClassTree> {
  return list.filter((x: AssetClassModel) => isEmpty(x.get('parent.id'))).map((x: AssetClassModel) => toTree(x, 0));
}

export function toTree(instance: AssetClassModel, level: number): AssetClassTree {
  return {
    instance,
    level,
    leveledName: `${level ? '╰' : ''}${prefixLevel(level)} ${instance.name}`,
    children: (instance.children || []).map((x: AssetClassModel) => toTree(x, level + 1)),
  };
}

export function prefixLevel(level: number): string {
  return '─'.repeat(level * 4);
}

export function makeList(roots: AssetClassTree[]): AssetClassTree[] {
  return roots.reduce((o, c) => [...o, ...toList(c)], [] as AssetClassTree[]);
}

function toList(root: AssetClassTree): Array<AssetClassTree> {
  return [root, ...makeList(root.children)];
}

export default class extends RecordListComponent<AssetClassModel> {
  get spec(): FlexTableSpec<AssetClassModel> {
    return {
      ident: 'cl-asset-class-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'instance.id', label: 'ID' },
        { key: 'leveledName', label: 'Name' },
        { key: 'instance.order', label: 'Order' },
        { key: 'instance.description', label: 'Description' },
        { key: 'instance.parent.name', label: 'Parent' },
        { key: 'instance.created', label: 'Created', component: '@date', options: { format: 'human' } },
        { key: 'instance.updated', label: 'Updated', component: '@date', options: { format: 'human' } },
      ],
    };
  }

  @computed('args.records.[]')
  get nodes() {
    const records = (this.args.records || []) as AssetClassModel[];
    return makeList(makeTree(records));
  }
}
