import AnalyticalTypeModel from 'cafelatte/models/analyticaltype';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<AnalyticalTypeModel> {
  get spec(): FlexTableSpec<AnalyticalTypeModel> {
    return {
      ident: 'cl-analyticaltype-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'name' },
        { key: 'kind' },
        { key: 'contra.name', label: 'Default Contra Type' },
        { key: 'description' },
        ...mkAuditFields<AnalyticalTypeModel>(this.me),
      ],
    };
  }
}
