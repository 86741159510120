// @ts-expect-error
import DRFSerializer from './drf';

export default class Currency extends DRFSerializer.extend({
  primaryKey: 'code',
}) {}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    currency: Currency;
  }
}
