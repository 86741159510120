import { Service } from '../-service';
import { InvalidNumberResult, isValidNumberResult, NumberResult, validate, ValidNumberResult } from './-internal';

// Export public symbols:
export { NumberResult, InvalidNumberResult, ValidNumberResult, isValidNumberResult };

/**
 * Provides the phoneless service.
 */
export class PhonelessService extends Service {
  /**
   * Retrieves and compiles vouchers timeline report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  validate(query: string): Promise<NumberResult> {
    return validate(this.client, query);
  }
}
