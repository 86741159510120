import { tracked } from '@glimmer/tracking';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import AccrualScheduleModel from 'cafelatte/models/accrualschedule';
import { FlexTableRowAction, mkCommonRowActions } from '../components/x/flex-table';

export default class extends ControllerWithCurrentRecord<AccrualScheduleModel> {
  queryParams = [
    'page',
    'page_size',
    'search',
    'portfolio',
    'account',
    'shareclass',
    'registry',
    'registry__atype',
    'start__gte',
    'start__lte',
    'until__gte',
    'until__lte',
  ];

  @tracked page = 1;
  @tracked page_size = 10;

  createNewRecord(): AccrualScheduleModel {
    return this.store.createRecord('accrualschedule');
  }

  get tableOperations(): FlexTableRowAction<AccrualScheduleModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doResetCurrentRecordRefreshModel },
    ]);
  }
}
