import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import saver from 'file-saver';
import { Maybe } from './maybe';

export function dataUriToBlob(datauri: string): Blob {
  // Get bytestring:
  // @ts-expect-error ts6587
  const bytes = atob(datauri.split(',')[1]);

  // Get mimestring:
  // @ts-expect-error ts2532
  const mimes = datauri.split(',')[0].split(':')[1].split(';')[0];

  // Initialize array buffer:
  const ab = new ArrayBuffer(bytes.length);

  // Create int array view:
  const ia = new Uint8Array(ab);

  // Set buffer:
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }

  // Write the ArrayBuffer to a blob and return:
  return new Blob([ab], { type: mimes });
}

export function saveBlob(blob: Blob, filename: string): void {
  saver.saveAs(blob, filename);
}

export function saveDataUri(datauri: string, filename: string): void {
  saveBlob(dataUriToBlob(datauri), filename);
}

export interface DownloadConfig {
  url: string;
  method: Maybe<'GET' | 'POST'>;
  params: Maybe<Record<string, string>>;
  data: Maybe<Record<string, string>>;
}

export function getFilename(response: AxiosResponse<Blob>, def: string): string {
  // Get headers:
  // @ts-expect-error
  const headers: Record<string, string | undefined> = response.headers;

  // Attempt to get the filename from the content disposition and return (with default):
  // @ts-expect-error ts2322
  return (headers['content-disposition'] || `filename=${def}`).split('filename=')[1];
}

export async function download(
  client: AxiosInstance,
  config: DownloadConfig,
  filename: string,
  forceFilename: Maybe<boolean>
): Promise<string> {
  // Define the effective configuration for the remote request:
  const effectiveConfig: Partial<AxiosRequestConfig> = {
    url: config.url,
    method: config.method || 'GET',
    params: config.params || undefined,
    data: config.data || undefined,
    responseType: 'blob',
  };

  // Perform the request and get the response:
  const response = await client.request<Blob>(effectiveConfig);

  // Attempt to get the filename to save to:
  const effectiveFilename = forceFilename ? filename : getFilename(response, filename);

  // Save the file:
  saveBlob(response.data, effectiveFilename);

  // Done, return the effective filename:
  return effectiveFilename;
}
