import { DecafClient } from '@decafhub/decaf-client';
import Store from '@ember-data/store';
import { AxiosResponse } from 'axios';
import { getDate, today } from 'cafelatte/libs/headless/prelude/datetime';
import {
  PortfolioReportResponseData,
  RawShareClassInfo,
} from 'cafelatte/libs/headless/services/consolidation-report/portfolio-report/-full';
import { Performance, PerformanceResult } from 'cafelatte/libs/headless/services/performance';
import ShareClassModel from 'cafelatte/models/shareclass';
import DecafService from 'cafelatte/services/decaf';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import numbro from 'numbro';

export type ClientResponse<T> = Promise<AxiosResponse<T>>;

export interface ShareClass extends ShareClassModel {
  nav: number;
  ytdChange: number;
  ytdChangeType: 'External' | 'Internal';
}

export type ShareClassNav = {
  id: string;
  nav: number;
};
export interface WatchedPortfolio {
  id: number;
  name: string;
  ccy: string;
  isFund: boolean;
  shareclasses: DS.PromiseArray<ShareClassModel>;
  shareclassvals: RawShareClassInfo[];
}

export type PortfolioNAV = Record<'nav', number>;

export interface Trend {
  name: string;
  trend?: 'falling' | 'rising' | 'stable';
  trendValueRel?: number;
  trendValueAbs?: number;
  trendDescription?: string;
}

export interface WatchedPortfolioWithNAV extends WatchedPortfolio {
  nav: number;
  performanceResult: PerformanceResult;
  prices: Record<'x' | 'y', any>;
  trends: Trend[];
}

export async function fetchWatchedPortfolios(store: Store, client: DecafClient): Promise<WatchedPortfolio[]> {
  return client.barista.get('/portfolios/?watched=true&page_size=-1').then(({ data }) => {
    const results: WatchedPortfolio[] = data.map((obj: any) => {
      const sclasses = obj.shareclasses.map((id: any) => {
        return store.findRecord('shareclass', id);
      });

      return {
        id: obj.id,
        name: obj.name,
        ccy: obj.refccy,
        isFund: !!obj.shareclasses?.length,
        nav: 0,
        shareclasses: sclasses,
      };
    });
    return results;
  });
}

export async function fetcPortfolioNAV(client: DecafClient, p: WatchedPortfolio): ClientResponse<PortfolioNAV> {
  return client.barista.get(
    `/consolidation/quick/?c=portfolio&i=${p.id}&ccy=${p.ccy}&date=${dayjs(new Date()).format(
      'YYYY-MM-DD'
    )}&type=commitment`
  );
}

export async function fetchFundNAV(
  client: DecafClient,
  p: WatchedPortfolio
): ClientResponse<PortfolioReportResponseData> {
  return client.barista.get(
    `/portfolioreport/?portfolio=${p.id}&ccy=${p.ccy}&date=${dayjs(new Date()).format('YYYY-MM-DD')}&type=commitment`
  );
}

export async function fetchPortfolioPerformance(decaf: DecafService, p: WatchedPortfolio): Promise<Performance> {
  return decaf.services.performance.get({
    start: getDate('this-year-start', today()),
    end: today(),
    ...(p.isFund ? { shareclasses: p.shareclasses.map((x) => x.get('id')) } : { portfolios: [p.id] }),
  });
}

export async function watchOrUnwatchPortfolio(
  client: DecafClient,
  pId: number,
  watch: boolean
): ClientResponse<Record<'status', boolean>> {
  return client.barista.post(`/portfolios/${pId}/watch/`, { status: watch });
}

export async function buildWatchedPortfoliosWithNAV(
  client: DecafClient,
  portfolios: WatchedPortfolio[]
): Promise<WatchedPortfolioWithNAV[]> {
  const navPromises = portfolios.map((p) => {
    if (p.isFund) {
      return fetchFundNAV(client, p);
    } else {
      return fetcPortfolioNAV(client, p);
    }
  });
  const objects: WatchedPortfolioWithNAV[] = [];
  return Promise.all(navPromises).then((values) => {
    values.forEach(({ data }, index) => {
      const result = { ...portfolios[index] } as WatchedPortfolioWithNAV;
      if (result) {
        if (result.isFund) {
          const d = data as PortfolioReportResponseData;
          result.shareclasses = result.shareclasses.map((sc) => {
            const s = d.scvals.find((val) => val.shareclass?.id.toString() === sc.get('id'));
            const navPerShare = s?.px_clsccy;
            sc.set('nav', navPerShare);
            if (s?.ytdext) {
              sc.set('ytdChange', Math.abs(s?.ytdext * 100));
              sc.set('ytdChangeType', 'External');
            } else {
              sc.set('ytdChange', Math.abs((s?.ytdint || 0) * 100));
              sc.set('ytdChangeType', 'Internal');
            }
            return sc;
          }) as any;
          result.shareclassvals = d.scvals;
          result.nav = d.nav ?? 0;
        } else {
          const d = data as PortfolioNAV;
          result.nav = d.nav;
        }
      }
      objects.push(result);
    });
    return objects;
  });
}

export async function buildWatchedPortfoliosWithPerformance(
  decaf: DecafService,
  portfolios: WatchedPortfolio[]
): Promise<WatchedPortfolioWithNAV[]> {
  const perfPromises = portfolios.map((p) => fetchPortfolioPerformance(decaf, p));
  const objects: WatchedPortfolioWithNAV[] = [];
  return Promise.all(perfPromises).then((values) => {
    values.forEach(({ result: perfResult }, index) => {
      const portfolio = { ...portfolios[index] } as WatchedPortfolioWithNAV;
      if (portfolio) {
        portfolio.performanceResult = perfResult;
        const trends: Trend[] = [];
        const lastIndex = portfolio.performanceResult.indexed.data.length - 1;

        const itemFirst = portfolio.performanceResult.indexed.data?.[0];
        const itemLast = portfolio.performanceResult.indexed.data?.[lastIndex];
        // trend calculations
        portfolio.performanceResult.indexed.columns.forEach((c, i) => {
          const trend: Trend = { name: c };
          const priceOne = itemFirst?.[i];
          const priceTwo = itemLast?.[i];
          if (priceOne !== undefined && priceTwo !== undefined) {
            trend.trendValueRel = Number(numbro(Math.abs((priceTwo - priceOne) / priceOne) * 100).format('0,0.00'));
            trend.trend = trend.trendValueRel === 0 ? 'stable' : priceOne < priceTwo ? 'rising' : 'falling';
            const dateOne = dayjs(portfolio.performanceResult.indexed.index?.[0]).format('DD-MM-YYYY');
            const dateTwo = dayjs(portfolio.performanceResult.indexed.index?.[lastIndex]).format('DD-MM-YYYY');
            trend.trendDescription = `% change between ${dateOne} and ${dateTwo}`;

            if (portfolio.isFund) {
              const sc = portfolio.shareclasses[i];
              trend.trendValueAbs = sc.get('nav');
              trend.trendValueRel = sc.get('ytdChange');
              trend.trendDescription = `${trend.name} (YTD % ${sc.get('ytdChangeType')}) `;
              trend.name = `${trend.name} (NAV/Share in ${sc.get('currency')}) `;
            }
          }
          trend.trend && trends.push(trend);
        });
        portfolio.trends = trends;
      }
      objects.push(portfolio);
    });
    return objects;
  });
}

// chart
export function chartNoDataHandler(chart: Chart) {
  if (chart.data.datasets.length === 0 || chart.data.datasets[0]?.data?.[1] === undefined) {
    // No data is present
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;
    chart.clear();

    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = "0.875em normal 'Helvetica Nueue'";
    ctx.fillStyle = '#fff';
    ctx.fillText('No data', width / 2, height / 2);
    ctx.restore();
  }
}

export const CHART_COLORS = ['rgb(55, 125, 255)', 'rgb(214, 255, 121)', 'rgb(75, 192, 192)', 'rgb(254, 95, 0)'];

export function buildChartData(record: WatchedPortfolioWithNAV): ChartData<'line', any> {
  const labels = [];
  const datasets = [];
  labels.push(...record.performanceResult.indexed.index.map((d) => dayjs(d).toDate()));
  const dataSetCommon = {
    borderColor: CHART_COLORS[0],
    borderWidth: 2,
    pointRadius: 0,
    pointHoverRadius: 0,
    tension: 0.4,
  };
  if (record.performanceResult.indexed.columns.length > 1) {
    record.performanceResult.indexed.columns.forEach((c, i) => {
      const dataset = {
        ...dataSetCommon,
        borderColor: CHART_COLORS[i],
        data: [],
        shareclass: c,
      };
      record.performanceResult.indexed.data.forEach((item) => {
        dataset.data.push(item[i] as never);
      });
      datasets.push(dataset);
    });
  } else {
    datasets.push({
      ...dataSetCommon,
      data: record.performanceResult.indexed.data.map((d) => d[0]),
    });
  }
  return {
    labels,
    datasets,
  };
}
export function buildChartOptions(record: WatchedPortfolioWithNAV): ChartOptions {
  return {
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
        mode: 'index',
        position: 'nearest',
        intersect: false,
        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem: any) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = '<thead>';

            titleLines.forEach(function (title) {
              innerHtml += '<tr><th>' + title + '</th></tr>';
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach(function (body, i) {
              const colors = tooltipModel.labelColors[i];
              let style = 'background:' + colors?.borderColor;
              style += '; border-color:' + colors?.borderColor;
              style += '; border-width: 2px; border-style: solid; margin-right: 5px;';
              const span = '<span style="' + style + '"></span>';
              innerHtml += '<tr><td>' + span + body + '</td></tr>';
            });
            innerHtml += '</tbody>';

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot ? (tableRoot.innerHTML = innerHtml) : null;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.backgroundColor = 'black';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontSize = '13px';
          tooltipEl.style.padding = '5px';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.zIndex = '99999';
        },
        callbacks: {
          title: (ctx) => {
            return dayjs(ctx[0]?.label)?.format('YYYY-MM-DD');
          },
          label: (context) => {
            return `${record.performanceResult.indexed.columns[context.datasetIndex]} : ${numbro(
              context.parsed.y
            ).format('0,0.00[0000]')}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };
}
// end of chart
