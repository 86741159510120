import ApplicationAdapter from '../application';

// @ts-expect-error (TS2339)
export default class AccountManagementFeeScheme extends ApplicationAdapter.extend({}) {
  pathForType(): string {
    return 'mfpercentages';
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'account/mfeescheme': AccountManagementFeeScheme;
  }
}
