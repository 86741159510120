import Model, { attr, belongsTo } from '@ember-data/model';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import AccountModel from './account';
import AnalyticalTypeModel from './analyticaltype';
import PortfolioModel from './portfolio';
import ShareClassModel from './shareclass';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = [
  'start',
  'until',
  'portfolio',
  'account',
  'shareclass',
  'registry',
  'atype',
  'type',
  'dcccode',
  'percentage',
  'frequency',
  'mpaccy',
  'mpaval',
];

export default class AccrualScheduleModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr({ defaultValue: today })
  declare start: string;

  @attr()
  declare until?: string;

  @belongsTo('portfolio')
  declare portfolio?: BelongsTo<PortfolioModel>;

  @belongsTo('account')
  declare account?: BelongsTo<AccountModel>;

  @belongsTo('shareclass')
  declare shareclass?: BelongsTo<ShareClassModel>;

  @belongsTo('account')
  declare registry?: BelongsTo<AccountModel>;

  @belongsTo('analyticaltype')
  declare atype?: BelongsTo<AnalyticalTypeModel>;

  @attr({ defaultValue: 'NAVB' })
  declare type: string;

  @attr({ defaultValue: 'Act/Act' })
  declare dcccode: string;

  @attr({ defaultValue: '0.01' })
  declare percentage: string;

  @attr('number', { defaultValue: 1 })
  declare frequency: number;

  @attr()
  declare mpaccy?: string;

  @attr('number', { defaultValue: 0 })
  declare mpaval: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    accrualschedule: AccrualScheduleModel;
  }
}
