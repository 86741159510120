// TODO: Check if we can move day count service to an internal library.
import { A } from '@ember/array';
import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { Promise } from 'rsvp';
import CLAjaxService from './ajax';

/**
 * Provides a day count convention service.
 */
export default class DayCountService extends Service {
  @service declare ajax: CLAjaxService;

  /**
   * Available day count conventions.
   */
  // TODO: Can we do any better than creating an array and populating it manually?
  @computed(function () {
    // Define the return value:
    const retval = A([]);

    // Get day count conventions and populate the return value:
    this.ajax.request('daycounts/').then((data: any) => retval.pushObjects(data));

    // Done, return:
    return retval;
  })
  conventions: any;

  /**
   * Computes the interest via API.
   */
  getInterest(convention: any, principal: any, rate: any, start: any, asof: any, end: any) {
    return new Promise((resolve, reject) => {
      this.ajax
        .post('daycounts/interest/', {
          data: {
            convention: convention,
            principal: principal,
            rate: rate,
            start: start,
            asof: asof,
            end: end,
          },
        })
        .then(
          (data) => resolve(data.interest),
          (problem) => reject(problem)
        );
    });
  }
}
