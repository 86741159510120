import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Resource } from 'cafelatte/libs/embered/config';
import KonfigService from 'cafelatte/services/konfig';
import MeService from 'cafelatte/services/me';

export default class extends Component {
  @service declare me: MeService;
  @service declare konfig: KonfigService;

  /**
   * Indicates if the modal is open or closed.
   */
  @tracked openModal = false;

  /**
   * Available help resources.
   */
  get available(): Resource[] {
    // Attempt to get the documentation specs:
    const specsInput: string | Resource[] = this.konfig.konfiguration.APP.privateConfiguration?.documentation || [];

    // Try to marshall (TODO: Check API improvements so that it is always a JSON document):
    let specs: Resource[];
    try {
      specs = specsInput instanceof Array ? specsInput : JSON.parse(specsInput || '[]');
    } catch (e) {
      specs = [];
    }

    return specs.filter((x: Resource) => {
      return (
        x.role == '*' || (x.role == 'internal' && this.me.internal) || (x.role == 'privileged' && this.me.privileged)
      );
    });
  }
}
