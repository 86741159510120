import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class Translate extends Helper {
  @service declare flashMessages: any;

  compute([message, method = 'success']: [string, 'success' | 'warning' | 'danger']) {
    return () => {
      this.flashMessages[method](message);
    };
  }
}
