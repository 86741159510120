import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  queryParams = [
    'page',
    'page_size',
    'search',
    'received__gte',
    'received__lte',
    'amount__gte',
    'amount__lte',
    'currency',
    'institution',
    'breakdowns__team',
    'invoice__icontains',
    'notes__icontains',
    'remarks__icontains',
    'conv_ccy',
    'conv_rate',
    'conv_date__gte',
    'conv_date__lte',
    'refccy',
  ];

  @tracked page = 1;
  @tracked page_size = 10;
  @tracked refccy = 'USD';

  get operations() {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('settlement.details', record.id) },
      { type: 'delete', onDelete: this.doRefreshModel },
    ]);
  }

  @action sum(x: number, y: number) {
    return x + y;
  }
}
