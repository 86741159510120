import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  layout = layout;

  @tracked value!: string;

  @action onChangePrivate(x: string) {
    // @ts-expect-error
    this.onChange?.(x);
  }
}
