import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { CommonRoute } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  @service declare router: RouterService;

  // TODO: Until we have a better trading dashboard idea, we will redirect to trade order listing.
  beforeModel() {
    this.router.transitionTo('trading.tradeorder.index');
  }
}
