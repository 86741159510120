import { action } from '@ember/object';
import { CommonController } from 'cafelatte/libs/embered';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  get tablePortfolios(): FlexTableSpec<any> {
    return {
      ident: 'cl-portfolio-group-portfolio-list',
      columns: [
        { key: 'id' },
        { key: 'name' },
        { key: 'domicile.name' },
        { key: 'inception', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'team.name' },
      ],
      actions: [
        {
          type: 'danger',
          label: 'Delete',
          icon: 'trash',
          action: ({ record }) => this.model.portfolios.removeObject(record),
        },
      ],
    };
  }

  get tableMembers(): FlexTableSpec<any> {
    return {
      ident: 'cl-portfolio-group-members-list',
      columns: [{ key: 'id' }, { key: 'username' }, { key: 'email' }, { key: 'fullName' }],
      actions: [
        {
          type: 'danger',
          label: 'Delete',
          icon: 'trash',
          action: ({ record }) => this.model.members.removeObject(record),
        },
      ],
    };
  }

  @action onSave() {
    this.model.save().then(
      () => this.router.transitionTo('portfoliogroup'),
      () => console.error('Error while saving data.')
    );
  }

  @action onCancel() {
    this.model.rollbackAttributes();
    this.router.transitionTo('portfoliogroup');
  }

  @action deletePortfolio(portfolio: any) {
    this.model.portfolios.removeObject(portfolio);
  }

  @action deleteMember(member: any) {
    this.model.members.removeObject(member);
  }
}
