import { CommonRoute, resetControllerParamsForDefaults } from 'cafelatte/libs/embered';
import { QueryParamDefaults } from './controller';

export default class extends CommonRoute {
  resetController(controller: any, isExiting: boolean) {
    if (isExiting) {
      resetControllerParamsForDefaults(controller, QueryParamDefaults);
    }
  }
}
