import { action, computed, notifyPropertyChange } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  CommonController,
  getQueryParamDefault,
  queryParamAsNumberArray,
  QueryParamDefaultsFactory,
} from 'cafelatte/libs/embered';
import { DateType, DateTypeChoices } from 'cafelatte/libs/headless/commons/date-type';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { getDate, SDate } from 'cafelatte/libs/headless/prelude/datetime';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { LedgerReport } from 'cafelatte/libs/headless/services/ledger-report';
import DecafService from 'cafelatte/services/decaf';

/**
 * Defines query parameter registry and their defaults.
 */
export const QueryParamDefaults: QueryParamDefaultsFactory = {
  artifact: '',
  accounts: '',
  portfolios: '',
  datespanMin: '',
  datespanMax: getDate('today'),
  // @ts-expect-error ts2532
  datetype: DateTypeChoices[0].value,
};

/**
 * Defines the ledger route controller.
 */
export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = Object.keys(QueryParamDefaults);

  @tracked artifact?: Id;
  @tracked accounts?: string;
  @tracked portfolios?: string;
  @tracked datespanMin?: SDate;
  @tracked datespanMax?: SDate = getQueryParamDefault(QueryParamDefaults, 'datespanMax');
  // @ts-expect-error
  @tracked datetype?: DateType = getQueryParamDefault(QueryParamDefaults, 'datetype');

  datetypes = DateTypeChoices;

  get accountIds(): Array<Id> {
    return queryParamAsNumberArray(this.accounts);
  }

  set accountIds(xs: Array<Id>) {
    this.accounts = xs.join(',');
  }

  get portfolioIds(): Array<Id> {
    return queryParamAsNumberArray(this.portfolios);
  }

  set portfolioIds(xs: Array<Id>) {
    this.portfolios = xs.join(',');
  }

  @computed('artifact', 'accountIds', 'portfolioIds', 'datespanMin', 'datespanMax', 'datetype')
  get ledger(): Promise<Maybe<LedgerReport>> {
    if (!this.artifact) {
      return Promise.resolve(undefined);
    }
    return this.decaf.services.ledgerReport.getReport({
      artifact: this.artifact,
      accounts: this.accountIds,
      portfolios: this.portfolioIds,
      since: this.datespanMin,
      until: this.datespanMax,
      datetype: this.datetype,
    });
  }

  plotLayout = {
    title: 'Daily Ledger Balances',
    xaxis: {
      title: 'Timeline',
    },
    yaxis: {
      title: 'Balances',
    },
    margin: { b: 100 },
  };

  get plotData() {
    return this.ledger.then((x) => {
      // If we don't have anything, return empty array:
      if (isNothing(x)) {
        return [];
      }

      // Get the raw plot data:
      const retval = x.reduce(
        (p, i) => ({
          ...p,
          [this.datetype == 'commitment' ? i.quant.commitment : i.quant.settlement]: i.balance.toNumber(),
        }),
        {}
      );

      return [
        {
          x: Object.keys(retval),
          y: Object.values(retval),
          fill: 'tozeroy',
        },
      ];
    });
  }

  @action refresh(): void {
    notifyPropertyChange(this, 'ledger');
  }
}
