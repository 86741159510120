import { Service } from '../-service';
import { HistoricalValuationItem, HistoricalValuationQuery, request } from './-internal';

// Export public symbols:
export { HistoricalValuationQuery, HistoricalValuationItem };

/**
 * Provides the barista historical valuation service.
 */
export class HistoricalValuationService extends Service {
  /**
   * Retrieves and compiles report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  query(query: HistoricalValuationQuery): Promise<Array<HistoricalValuationItem>> {
    return request(this.client, query);
  }
}
