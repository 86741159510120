import { DecafClient } from '@decafhub/decaf-client';
import { AccountResource } from 'cafelatte/libs/headless/commons/resources/account-resource';
import { ArtifactResource } from 'cafelatte/libs/headless/commons/resources/artifact-resource';
import { Id, Lookup } from 'cafelatte/libs/headless/commons/types';
import { uniqElements } from 'cafelatte/libs/headless/prelude/arrays';

/**
 * Type definition for standard Barista entities.
 */
export interface StandardBaristaEntity {
  id: Id;
}

/**
 * Builds and returns a lookup table of standard Barista entities.
 *
 * @param client Remote client.
 * @param endpoint Standard Barista entity endpoint.
 * @param ids Standard Barista entity identifiers.
 * @returns Standard Barista entity lookup table.
 */
export async function buildStandardBaristaEntityLookupTable<T extends StandardBaristaEntity>(
  client: DecafClient,
  endpoint: string,
  ids: Array<Id>
): Promise<Lookup<Id, T>> {
  // Get unique ids:
  ids = uniqElements(ids);

  // If we don't have any IDs, resolve immediately:
  if (ids.length == 0) {
    return {};
  }

  // Build params:
  const params = { page_size: '-1', id__in: ids.map((x) => `${x}`).join(',') };

  // Attempt to retrieve all entities:
  const { data } = await client.barista.get<Array<T>>(`/${endpoint}/`, { params });

  // Build the lookup table and return:
  return data.reduce((p, c) => ({ ...p, [c.id]: c }), {});
}

/**
 * Builds and returns a lookup table of Barista account entities.
 *
 * @param client Remote client.
 * @param ids Account entity identifiers.
 * @returns Account entity lookup table.
 */
export function buildAccountEntityLookupTable(
  client: DecafClient,
  ids: Array<Id>
): Promise<Lookup<Id, AccountResource>> {
  return buildStandardBaristaEntityLookupTable(client, 'accounts', ids);
}

/**
 * Builds and returns a lookup table of Barista artifact entities.
 *
 * @param client Remote client.
 * @param ids Artifact entity identifiers.
 * @returns Artifact entity lookup table.
 */
export function buildArtifactEntityLookupTable(
  client: DecafClient,
  ids: Array<Id>
): Promise<Lookup<Id, ArtifactResource>> {
  return buildStandardBaristaEntityLookupTable(client, 'resources', ids);
}
