import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import InstitutionModel from 'cafelatte/models/institution';
import SharingModel from 'cafelatte/models/sharing';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
import StockService from 'cafelatte/services/stocks';

export default class extends ControllerWithCurrentRecord<SharingModel> {
  @service declare stocks: StockService;

  @tracked stocksDate = today();

  get institutionStocks() {
    return this.stocks.fetchExtended({
      date: this.stocksDate,
      type: 'commitment',
      zero: false,
      artifacts: [],
      containers: [this.model.id],
      containerType: 'institution',
    });
  }

  @action onSave(record: InstitutionModel): void {
    this.router.transitionTo('institution.details', record.id);
  }

  @action onError(_message: string, error: any): void {
    console.error(error);
  }

  @action onCancel(_record: InstitutionModel, _reverted: boolean): void {
    if (isEmpty(this.model.id)) {
      this.router.transitionTo('institution');
    }
  }

  createNewRecord(): SharingModel {
    return this.store.createRecord('sharing', { institution: this.model });
  }

  get sharingOperations(): FlexTableRowAction<SharingModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doSharingReset },
    ]);
  }

  @action doSharingReset(): void {
    this.doResetCurrentRecord();
    this.model.reload();
  }

  @action onSharingError(_message: string, error: any): void {
    console.error(error);
  }
}
