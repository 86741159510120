import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  CommonController,
  getQueryParamDefault,
  queryParamAsNumberArray,
  QueryParamDefaultsFactory,
} from 'cafelatte/libs/embered';
import { DateType, DateTypeChoices } from 'cafelatte/libs/headless/commons/date-type';
import { getDate } from 'cafelatte/libs/headless/prelude/datetime';
import StockService, { Comparison, ContainerType } from 'cafelatte/services/stocks';

export const QueryParamDefaults: QueryParamDefaultsFactory = {
  container: 'account',
  instances: '',
  date1: () => getDate('last-month-end'),
  date2: () => getDate('today'),
  // @ts-expect-error ts2532
  datetype: DateTypeChoices[0].value,
};

export default class extends CommonController {
  @service declare stocks: StockService;

  queryParams = Object.keys(QueryParamDefaults);

  @tracked container: ContainerType = getQueryParamDefault(QueryParamDefaults, 'container') as ContainerType;
  @tracked instances: string = getQueryParamDefault(QueryParamDefaults, 'instances');
  @tracked date1: string = getQueryParamDefault(QueryParamDefaults, 'date1');
  @tracked date2: string = getQueryParamDefault(QueryParamDefaults, 'date2');
  @tracked datetype: DateType = getQueryParamDefault(QueryParamDefaults, 'datetype') as DateType;

  get instanceIds(): Array<number> {
    return queryParamAsNumberArray(this.instances);
  }

  set instanceIds(xs: Array<number>) {
    this.instances = (xs || []).join(',');
  }

  get comparison(): Promise<Array<Comparison>> {
    return this.stocks.fetchComparison(
      this.date1,
      this.date2,
      this.datetype,
      this.container,
      this.instanceIds,
      [],
      false
    );
  }
}
