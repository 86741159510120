import { action, computed, get, set } from '@ember/object';
import Component from '@glimmer/component';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { ZERO } from 'cafelatte/libs/headless/prelude/numeric';
import { Node, ParentNode, ReportData } from 'cafelatte/libs/headless/services/assets-evolution-report';
import { FlexTableSpec, mkNumberColumn } from 'cafelatte/pods/components/x/flex-table';
import Decimal from 'decimal.js';

interface Args {
  data?: ReportData;
  showBreakdowns: boolean;
  onParentSelected: (node: ParentNode | Node | undefined) => void | undefined;
}

interface Row {
  node?: ParentNode | Node;
  parent?: ParentNode;
  name: string;
  nav: Maybe<Decimal>;
  aum: Maybe<Decimal>;
  selected?: boolean;
}

export default class extends Component<Args> {
  @computed('args.{showBreakdowns,data.nodes.[]}')
  get records() {
    // Attempt to get nodes:
    const nodes = this.args.data?.nodes;

    // Check nodes:
    if (!nodes) {
      return [];
    }

    // Declare records:
    const records = [] as Row[];

    // Declare the total row:
    const total = {
      node: undefined,
      parent: undefined,
      name: 'Total',
      nav: new Decimal(0),
      aum: new Decimal(0),
      selected: true,
    } as Row;

    // Iterate over rows and build the records:
    nodes.forEach((x) => {
      records.push({ node: x, parent: undefined, name: x.instance.name, nav: x.lastNAV, aum: x.lastAUM });
      total.nav = (total.nav || ZERO).add(x.lastNAV || ZERO);
      total.aum = (total.aum || ZERO).add(x.lastAUM || ZERO);

      if (this.args.showBreakdowns) {
        records.pushObjects(
          x.breakdown
            .map((y) => ({
              node: y,
              parent: x,
              name: `${x.instance.name} » ${y.instance.name}`,
              nav: y.lastNAV,
              aum: y.lastAUM,
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        );
      }
    });

    // Done, return:
    return [...records.sort((a, b) => a.name.localeCompare(b.name)), total];
  }

  get spec(): FlexTableSpec<Row> {
    return {
      ident: 'cl-asset-evolution-tabulation',
      vfill: true,
      rowClasses: [({ record }) => (get(record, 'selected') ? ['table-primary'] : [])],
      columns: [
        {
          key: 'name',
          classes: [({ record }) => (record.parent ? ['ps-2', 'text-muted'] : [])],
          action: ({ record }) => {
            if (!record.parent) {
              this.onParentClicked(record);
            }
          },
        },
        mkNumberColumn({ key: 'nav', label: 'NAV', options: { format: '0,0' } }),
        mkNumberColumn({ key: 'aum', label: 'AuM', options: { format: '0,0' } }),
      ],
    };
  }

  @action onParentClicked(record: Row): void {
    this.records.forEach((x) => set(x, 'selected', record === x));
    this.args.onParentSelected?.(record.node);
  }
}
