import { DecafClient } from '@decafhub/decaf-client';
import { Maybe } from '../../prelude/maybe';

/**
 * Encoding for a single bean specification.
 */
export interface Bean {
  id?: Maybe<string | number>;
  category: string;
  title: string;
  description?: Maybe<string>;
  endpoint: string;
  datatype?: Maybe<string>;
  target?: Maybe<string>;
  filename?: Maybe<string>;
  parameters?: Maybe<Array<BeanParameter>>;
}

/**
 * Encoding for a single bean parameter specification.
 */
export interface BeanParameter {
  name: string;
  label: string;
  type?: Maybe<string>;
  required?: Maybe<boolean>;
  help?: Maybe<string>;
  choices?: Maybe<Array<{ value: string; label: string }>>;
}

/**
 * Attempts to retrieve remote [[Bean]] catalogue.
 *
 * @param client barista client.
 * @param url beanbag catalogue url.
 * @returns Array of [[Bean]] instances.
 */
export async function getBeanbagCatalogue(client: DecafClient, url: string): Promise<Array<Bean>> {
  return await getRemoteData(client, url);
}

// Workhorse to get the remote beanbag catalogue:
export async function getRemoteData(client: DecafClient, url: string): Promise<Array<Bean>> {
  // Attempt to get the data:
  const data: any = (await client.barista.get<Array<Bean>>(url)).data;

  // Check version:
  if (!Array.isArray(data)) {
    return data.items;
  }

  // Add index:
  data.forEach((x: Bean, idx: number) => {
    x.id = idx + 1;
  });

  // Return:
  return data;
}
