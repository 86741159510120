import Model, { attr, belongsTo } from '@ember-data/model';
import UserModel from './user';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['name', 'expiry', 'principal', 'description'];

export default class APIKeyModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @attr()
  declare expiry?: string;

  @belongsTo('user')
  declare principal?: BelongsTo<UserModel>;

  @attr()
  declare description?: string;

  @attr()
  declare secret?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    apikey: APIKeyModel;
  }
}
