import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';

interface Args {
  hlink: string;
  qparams?: Maybe<any>;
  icon?: string;
  bgclass?: string;
  title: string;
  subtitle?: string;
  hidden?: boolean;
}

export default class extends Component<Args> {
  @service declare router: RouterService;

  @action onClick() {
    if (this.args.qparams) {
      this.router.transitionTo(this.args.hlink, { queryParams: this.args.qparams || {} });
    } else {
      this.router.transitionTo(this.args.hlink);
    }
  }
}
