import { FrequencyOptionsLookup } from 'cafelatte/libs/headless/prelude/frequency';
import ShareClassModel from 'cafelatte/models/shareclass';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<ShareClassModel> {
  get spec(): FlexTableSpec<ShareClassModel> {
    return {
      ident: 'cl-share-class-list-table',
      actions: this.args.operations || [],
      vfill: true,
      columns: [
        { key: 'id' },
        { key: 'name' },
        { key: 'currency' },
        {
          key: 'freqmngt',
          label: 'Mngt. Fee Freq.',
          value: ({ record }) => (record.freqmngt ? FrequencyOptionsLookup[record.freqmngt]?.value : ''),
        },
        {
          key: 'freqperf',
          label: 'Perf. Fee Freq.',
          value: ({ record }) => (record.freqperf ? FrequencyOptionsLookup[record.freqperf]?.value : ''),
        },
        { key: 'isin', label: 'ISIN' },
        { key: 'bbgticker', label: 'BBG Ticker' },
        { key: 'liquidity' },
        { key: 'jurisdiction.name', label: 'Jurisdiction' },
        { key: 'administrator' },
        { key: 'subredperiod', label: 'Sub/Red Period' },
        {
          key: 'mininvestment',
          label: 'Min. Investment',
          align: 'center',
          component: '@number',
          options: { format: '0,0.[00]' },
        },
        { key: 'benchmark.symbol', label: 'Benchmark' },
        ...mkAuditFields<ShareClassModel>(this.me),
      ],
    };
  }
}
