import Store from '@ember-data/store';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

// TODO: Make the template further configurable like in cl-rsel.
@tagName('')
export default class extends Control {
  layout = layout;
  @service declare store: Store;

  @computed('options.modelName')
  get instances() {
    // @ts-expect-error
    return this.store.findAll(this.options.modelName);
  }
}
