import { action } from '@ember/object';
import { CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';
import { cleanParams } from 'cafelatte/models/resource/_machinery';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'received__gte',
    'received__lte',
    'amount__gte',
    'amount__lte',
    'currency',
    'institution',
    'breakdowns__team',
    'invoice__icontains',
    'notes__icontains',
    'remarks__icontains',
    'conv_ccy',
    'conv_rate',
    'conv_date__gte',
    'conv_date__lte',
    'refccy',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('settlement', cleanParams(params));
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
