import Controller from '@ember/controller';
import { CommonRoute } from 'cafelatte/libs/embered';
import { Bean } from 'cafelatte/libs/headless/services/beanbag';

export default class extends CommonRoute {
  setupController(controller: Controller, model: any, transition: any) {
    super.setupController(controller, model, transition);
    // @ts-expect-error
    controller.setProperties({ remoteData: undefined, remoteType: undefined });
  }

  model(params: any) {
    // Get the catalogue:
    const catalogue: Bean[] = this.modelFor('beanbag');

    // If the catalogue is not there yet, return undefined:
    if (!catalogue || catalogue.length == 0) {
      return undefined;
    }

    // Get the bean:
    const bean = (catalogue || []).filter((bean: Bean) => bean.id == params.id)[0];

    // Check and return:
    if (!bean) {
      throw new Error('Unknown Page Requested!');
    } else {
      return bean;
    }
  }
}
