import Model, { attr, belongsTo } from '@ember-data/model';
import AccountModel from './account';
import PortfolioModel from './portfolio';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['portfolio', 'account', 'since', 'until', 'description'];

export default class ComplianceExemptionModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @belongsTo('portfolio')
  declare portfolio?: BelongsTo<PortfolioModel>;

  @belongsTo('account')
  declare account?: BelongsTo<AccountModel>;

  @attr()
  declare since?: string;

  @attr()
  declare until?: string;

  @attr()
  declare description?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    complianceexemption: ComplianceExemptionModel;
  }
}
