import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';
import { getOrElse } from 'cafelatte/libs/headless/prelude/maybe';
import { QueryParamDefaults } from './controller';

export default class extends CommonRoute {
  queryParams = compileQueryParams(Object.keys(QueryParamDefaults));

  model(params: any) {
    return this.store.query(
      'ohlc/observation',
      cleanParams({
        page: getOrElse(params.page, 1),
        page_size: getOrElse(params.page_size, 20),
        search: params.search,
        series__symbol__iexact: params.symbol,
        date__gte: params.since,
        date__lte: params.until,
        source: params.source,
      })
    );
  }

  @action error(error: any) {
    return handle404OnNonExistingPage(this, error);
  }
}
