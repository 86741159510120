import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { download } from 'cafelatte/libs/headless/prelude/downloading';
import VoucherModel from 'cafelatte/models/voucher';
import { FlexTableSpec, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';

export default class extends CommonController {
  @service declare decaf: DecafService;

  @tracked breakdownInstance: any = undefined;

  breakdownTable: FlexTableSpec<any> = {
    ident: 'cl-settlement-breakdown-table',
    columns: [
      { key: 'id' },
      { key: 'team.name', component: '@link', options: { route: 'team.details', modelPath: 'id' } },
      { key: 'amount', component: '@number', options: { format: '0,0.00[00]' } },
      { key: 'created', component: '@date', options: { format: 'human' } },
      { key: 'updated', component: '@date', options: { format: 'human' } },
    ],
    actions: mkCommonRowActions([
      { type: 'edit', action: ({ record }): void => (this.breakdownInstance = record) },
      {
        type: 'delete',
        onDelete: (): void => {
          this.model.notifyPropertyChange('_dummy');
          this.breakdownInstance = undefined;
        },
      },
    ]),
  };

  @action saved(record: VoucherModel) {
    record.reload();
    this.router.transitionTo('settlement.details', record.id);
  }

  @action cancelled(record: VoucherModel) {
    if (record.get('isDeleted')) {
      this.router.transitionTo('settlement');
    }
  }

  @action deleted() {
    this.router.transitionTo('settlement');
  }

  @action newBreakdown() {
    this.breakdownInstance = this.store.createRecord('settlement/breakdown', { settlement: this.model });
  }

  @action saveBreakdown() {
    this.breakdownInstance.save().then(
      () => {
        this.flashMessages.success('Record saved successfully.');
        this.model.reload().then((x: any) => {
          x.notifyPropertyChange('_dummy');
          this.breakdownInstance = undefined;
        });
      },
      () => {
        this.flashMessages.danger('Error saving the record. Please check your input.');
      }
    );
  }

  @action cancelBreakdown() {
    this.breakdownInstance.rollbackAttributes();
    this.breakdownInstance = undefined;
  }

  @action downloadInvoice(settlementId: any, templateId: any) {
    download(
      // @ts-expect-error
      this.decaf.client.barista,
      { url: `settlements/${settlementId}/invoice/?template=${templateId}` },
      `invoice_${settlementId}.docx`,
      true
    ).catch(console.error);
  }
}
