import { action } from '@ember/object';
import Component from '@glimmer/component';
import { FlexTableCellArgumentsWithValues } from '../../../../../../-internal/runtime';

export default class FlexTableCellComponent<T> extends Component<FlexTableCellArgumentsWithValues<T>> {
  get actionCursor() {
    return this.args.column.action ? 'cursor-pointer' : '';
  }

  @action onClick() {
    this.args.column.action?.(this.args);
  }
}
