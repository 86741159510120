import Store from '@ember-data/store';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import ArtifactModel from 'cafelatte/models/resource';
import { Args } from '../component';

export abstract class ArtifactSpecificForm extends Component<Args> {
  @service declare store: Store;

  abstract readonly coreArtifactTypeCode: string;

  @computed('coreArtifactTypeCode')
  get coreArtifactType() {
    return this.store.findRecord('resource/type', this.coreArtifactTypeCode);
  }

  @computed()
  get cashArtifactType() {
    return this.store.findRecord('resource/type', 'CCY');
  }

  @action changeArtifact(thisLeg: string, thatLeg: string, artifact?: ArtifactModel) {
    // Get the currency of the selected artifact, if any:
    const currency = artifact?.ccymain;

    // If we have some currency, attempt to find its artifact and set as other
    // leg's artifact:
    if (currency) {
      this.store.query('resource', { symbol: currency }).then((artifacts) => {
        if (artifacts.firstObject) {
          this.args.record.set(thatLeg, artifacts.firstObject);
        }
      });
    }

    // Finally, set the artifact of this leg as intended:
    this.args.record.set(thisLeg, artifact);
  }
}
