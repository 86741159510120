import Model, { attr, hasMany } from '@ember-data/model';
import EmberObject, { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import CLAjaxService from 'cafelatte/services/ajax';
import PortfolioModel from './portfolio';
import UserModel from './user';
import { DecafAuditFields, ExecutiveSummary, HasMany, makeValidators } from './_internal/machinery';

const _fields = ['name', 'portfolios', 'members'];

export default class TeamModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @service declare ajax: CLAjaxService;

  @attr()
  declare name?: string;

  @hasMany('portfolio')
  declare portfolios?: HasMany<PortfolioModel>;

  @hasMany('user', { inverse: 'teams' })
  declare members?: HasMany<UserModel>;

  @alias('name')
  declare label?: string;

  @computed('portfolios')
  get portfolio_count(): number {
    // @ts-expect-error
    return this.hasMany('portfolios').ids().length;
  }

  @computed('members')
  get member_count(): number {
    // @ts-expect-error
    return this.hasMany('members').ids().length;
  }

  // TODO: Refactor this method into a service method or lib function.
  executiveSummary(team: TeamModel, asof: string, refccy: string): ExecutiveSummary {
    // Declare the return value:
    const retval: ExecutiveSummary = EmberObject.create({
      pending: 0,
      settled: 0,
      totaled: 0,
      nav: 0,
      aum: 0,
      roi: 0,
    });

    // Get the executive from remote:
    team
      .get('ajax')
      .request('teams/' + team.get('id') + '/executive/', {
        data: { refccy: refccy, asof: asof },
      })
      .then((data: ExecutiveSummary) => {
        set(retval, 'pending', data.pending);
        set(retval, 'settled', data.settled);
        set(retval, 'totaled', data.totaled);
        set(retval, 'nav', data.nav);
        set(retval, 'aum', data.aum);
        set(retval, 'roi', Math.abs(data.aum) < 1 ? null : data.totaled / data.aum);
      });

    // Done, return:
    return retval;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    team: TeamModel;
  }
}
