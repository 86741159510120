import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { dayjs } from 'cafelatte/libs/headless/prelude/datetime';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { buildSimpleChoices, SimpleChoices } from 'cafelatte/libs/headless/prelude/simple-choice';
import { CalendarEvent, CalendarEvents } from 'cafelatte/libs/headless/services/calendar-events';
import { FlexTableSpec, mkDateColumn, mkLinkColumn, mkNumberColumn } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = ['start', 'end'];

  @tracked start = dayjs().subtract(1, 'weeks').format('YYYY-MM-DD');
  @tracked end = dayjs().add(1, 'weeks').format('YYYY-MM-DD');
  @tracked toggleClosed = true;
  @tracked hideColumns?: any;
  @tracked disableColumns?: any;

  @tracked selectedDates: SimpleChoices<string> = [];
  @tracked selectedInstruments: SimpleChoices<string> = [];
  @tracked selectedInstrumentTypes: SimpleChoices<string> = [];
  @tracked selectedAccounts: SimpleChoices<string> = [];
  @tracked selectedPortfolios: SimpleChoices<string> = [];
  @tracked selectedTeams: SimpleChoices<string> = [];
  @tracked selectedCustodians: SimpleChoices<string> = [];

  @computed('start', 'end')
  get records() {
    return DS.PromiseArray.create({
      // @ts-expect-error
      promise: this.decaf.services.calendarEvents.get({ start: this.start, end: this.end }),
    });
  }

  @computed('records.content')
  get safeRecords(): CalendarEvents {
    return (this.records.content as CalendarEvents | undefined) || [];
  }

  @computed(
    'safeRecords.[]',
    'toggleClosed',
    'selectedDates.[]',
    'selectedInstruments.[]',
    'selectedInstrumentTypes.[]',
    'selectedAccounts.[]',
    'selectedPortfolios.[]',
    'selectedTeams.[]',
    'selectedCustodians.[]'
  )
  get filteredRecords() {
    // Get the records:
    const records = this.safeRecords;

    // Filter records and return:
    return records
      .filter((x) => !this.toggleClosed || x.quantity != 0)
      .filter((x) => this.selectedDates.length == 0 || this.selectedDates.filter((y) => y.value == x.date).length > 0)
      .filter(
        (x) =>
          this.selectedInstruments.length == 0 ||
          this.selectedInstruments.filter((y) => y.value == x.resource.name).length > 0
      )
      .filter(
        (x) =>
          this.selectedInstrumentTypes.length == 0 ||
          this.selectedInstrumentTypes.filter((y) => y.value == x.resource.ctype).length > 0
      )
      .filter(
        (x) =>
          this.selectedAccounts.length == 0 || this.selectedAccounts.filter((y) => y.value == x.account.name).length > 0
      )
      .filter(
        (x) =>
          this.selectedPortfolios.length == 0 ||
          this.selectedPortfolios.filter((y) => y.value == x.portfolio.name).length > 0
      )
      .filter(
        (x) => this.selectedTeams.length == 0 || this.selectedTeams.filter((y) => y.value == x.team.name).length > 0
      )
      .filter(
        (x) =>
          this.selectedCustodians.length == 0 ||
          this.selectedCustodians.filter((y) => y.value == x.custodian.name).length > 0
      );
  }

  @computed('safeRecords.[]')
  get spec(): FlexTableSpec<CalendarEvent> {
    // Get the records:
    const records = this.safeRecords;

    // Build choices:
    const dateChoices = buildSimpleChoices(records, 'date').map((x) => ({
      ...x,
      label: dayjs(x.value as string).format('DD-MM-YYYY'),
    }));
    const instrumentChoices = buildSimpleChoices(
      // @ts-expect-error
      records.map((x) => x.resource),
      'name'
    );
    const instrumentTypeChoices = buildSimpleChoices(
      records.map((x) => x.resource),
      'ctype'
    );

    const accountChoices = buildSimpleChoices(
      records.map((x) => x.account),
      'name'
    );
    const portfolioChoices = buildSimpleChoices(
      records.map((x) => x.portfolio),
      'name'
    );
    const teamChoices = buildSimpleChoices(
      records.map((x) => x.team),
      'name'
    );
    const custodianChoices = buildSimpleChoices(
      records.map((x) => x.custodian),
      'name'
    );

    return {
      ident: 'cl-calendar-events',
      vfill: true,
      columns: [
        mkDateColumn({
          key: 'date',
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedDates', x, []),
          filterOptions: () => dateChoices,
          filterSelections: () => this.selectedDates,
        }),
        mkLinkColumn('resource.details', 'resource.id', {
          key: 'resource.name',
          label: 'Name',
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedInstruments', x, []),
          filterOptions: () => instrumentChoices,
          filterSelections: () => this.selectedInstruments,
        }),
        {
          key: 'resource.ctype',
          label: 'Type',
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedInstrumentTypes', x, []),
          filterOptions: () => instrumentTypeChoices,
          filterSelections: () => this.selectedInstrumentTypes,
        },
        mkNumberColumn({ key: 'quantity' }),
        mkLinkColumn('account.details', 'account.id', {
          key: 'account.name',
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedAccounts', x, []),
          filterOptions: () => accountChoices,
          filterSelections: () => this.selectedAccounts,
        }),
        mkLinkColumn('portfolio.details', 'portfolio.id', {
          key: 'portfolio.name',
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedPortfolios', x, []),
          filterOptions: () => portfolioChoices,
          filterSelections: () => this.selectedPortfolios,
        }),
        mkLinkColumn('team.details', 'team.id', {
          key: 'team.name',
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedTeams', x, []),
          filterOptions: () => teamChoices,
          filterSelections: () => this.selectedTeams,
        }),
        mkLinkColumn('institution.details', 'custodian.id', {
          key: 'custodian.name',
          onFilter: (x?: any[]) => this.filterChanged(this, 'selectedCustodians', x, []),
          filterOptions: () => custodianChoices,
          filterSelections: () => this.selectedCustodians,
        }),
      ],
    };
  }

  filterChanged<T extends { [key in P]: T[P] }, P extends keyof T>(
    of: T,
    key: P & keyof T,
    value: Maybe<T[P]>,
    def: T[P]
  ) {
    set(of, key, isNothing(value) ? def : value);
  }
}
