import { DecafClient, gql } from '@decafhub/decaf-client';
import { Guid, Id } from '../../commons/types';
import { Maybe } from '../../prelude/maybe';

export const query = gql`
  query {
    principals: principal {
      id
      guid
      username
      email
      firstName: first_name
      lastName: last_name
    }
  }
`;

/**
 * Type for a principal returned from the principals list function.
 */
export interface PrincipalsServiceListItem {
  id: Id;
  guid: Guid;
  username: string;
  email: Maybe<string>;
  firstName: Maybe<string>;
  lastLame: Maybe<string>;
}

/**
 * Attempts to retrieve remote [[PrincipalsServiceListItem]].
 *
 * @param client DECAF API clients.
 * @returns An array of [[PrincipalsServiceListItem]] instances.
 */
export async function getPrincipalsList(client: DecafClient): Promise<Array<PrincipalsServiceListItem>> {
  return client.microlot.query({ query }).then((result) => result.data?.principals || []);
}
