import Model, { attr } from '@ember-data/model';

export default class CurrencyModel extends Model {
  @attr()
  declare code: string;

  @attr()
  declare name: string;

  @attr('number')
  declare decimals: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    currency: CurrencyModel;
  }
}
