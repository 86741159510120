import { Service } from '../-service';
import { LedgerReport, LedgerReportItem, LedgerReportQuery, request } from './-internal';

// Export public symbols:
export { LedgerReport, LedgerReportItem, LedgerReportQuery };

/**
 * Provides the barista ledger report service.
 */
export class LedgerReportService extends Service {
  /**
   * Retrieves and compiles report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  getReport(query: LedgerReportQuery): Promise<LedgerReport> {
    return request(this.client, query);
  }
}
