import { action, get, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';
// @ts-expect-error
import { storageFor } from 'ember-local-storage';
import saver from 'file-saver';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @storageFor('appstate') declare appstate: any;

  queryParams = [
    'page',
    'page_size',
    'search',
    'ordering',
    'cflag',
    'cflag__isnull',
    'ctype',
    'ctype__isnull',
    'commitment__gte',
    'commitment__lte',
    'settlement__gte',
    'settlement__lte',
    'quantity__gte',
    'quantity__lte',
    'resource',
    'valccy',
    'valamt',
    'resource__ccymain',
    'resource__issuer__icontains',
    'resource__sector__icontains',
    'resource__country__icontains',
    'account',
    'account__custodian',
    'account__portfolio',
    'account__portfolio__team',
    'account__portfolio__groups',
    'resource__ctype',
    'resource__stype',
    'trade__ctype',
    'trade__stype',
    'trade__reference__iexact',
    'resource__isin',
    'refccy',
    'nojournal',
  ];

  queryParamsDefaults: any = {
    nojournal: 'True',
    refccy: 'USD',
  };

  @tracked refccy = 'USD';
  @tracked page = 1;
  @tracked nojournal = 'True';

  get page_size(): any {
    return get(this.appstate, 'routesQuantIndexPageSize');
  }

  set page_size(value) {
    if (!isEmpty(value) && value <= 500) {
      set(this.appstate, 'routesQuantIndexPageSize', value);
    }
  }

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('quant.details', record.id) },
    ]);
  }

  @action download() {
    // Attempt to get the data:
    this.ajax.request('/quants/exports/', { data: { refccy: this.refccy }, dataType: 'text' }).then((data) => {
      // Create a blob of data:
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

      // Save it, nicely:
      saver.saveAs(blob, 'transactions.csv');
    });
  }

  @action reset() {
    setProperties(this, {
      ...this.queryParams.reduce((o, k) => ({ ...o, [k]: '' }), {}),
      ...this.queryParamsDefaults,
      page: 1,
      search: '',
    });
  }
}
