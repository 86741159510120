/**
 * Type definition for nullable/undefined values.
 */

export type Maybe<T> = Nothing | Just<T>;

/**
 * Type definition for value type of `null` or `undefined`, to be used to define `Maybe<T>`.
 */
export type Nothing = null | undefined;

/**
 * Type definition for value type of `T`, to be used to define `Maybe<T>`.
 */
export type Just<T> = T;

/**
 * Type predicate to check if the value is `Nothing`, ie. `null` or `undefined`.
 *
 * @param x Value to check.
 * @returns `true` if the value is `null` or `undefined`, `false` otherwise.
 */
export function isNothing<T>(x: Maybe<T>): x is Nothing {
  return x === undefined || x === null;
}

/**
 * Type predicate to check if the value is not `Nothing`, ie. not `null` and not `undefined`.
 *
 * @param x Value to check.
 * @returns `true` if the value is not `null` and not `undefined`, `false` otherwise.
 */
export function isJust<T>(x: Maybe<T>): x is Just<T> {
  return !isNothing(x);
}

/**
 * Convenience function to deal with `Nothing` values.
 *
 * @param x Value.
 * @param def Value to return if `x` is `Nothing`.
 * @return `x` if `x` is `Just<T>`, `def` otherwise.
 */
export function getOrElse<T>(x: Maybe<T>, def: T): T {
  return isNothing(x) ? def : x;
}

/**
 * Convenience function to deal with `Nothing` values, lazily.
 *
 * @param x Value.
 * @param def No-arg function to return default value `x` is `Nothing`.
 * @return `x` if `x` is `Just<T>`, value of `def` function application otherwise.
 */
export function getOrElseLazy<T>(x: Maybe<T>, def: () => T): T {
  return isNothing(x) ? def() : x;
}

/**
 * Convenience function to deal with `Nothing` values, lazily, all in Promise context.
 *
 * @param x Value.
 * @param def No-arg function to return default value `x` is `Nothing`.
 * @return `x` if `x` is `Just<T>`, value of `def` function application otherwise, all in Promise context.
 */
export function getOrElseLazyPromise<T>(x: Maybe<T>, def: () => Promise<T>): Promise<T> {
  return isNothing(x) ? def() : Promise.resolve(x);
}

/**
 * Applies a function to a `Just<T>` value.
 *
 * @param f Function to apply.
 * @param x Maybe value.
 * @return `f(x)` if the value is a `Just<T>`, `Nothing` otherwise.
 */
export function mapMaybe<T, Z>(f: (x: T) => Z, x: Maybe<T>): Maybe<Z> {
  return isNothing(x) ? x : f(x);
}

/**
 * Applies a function to a `Just<T>` value or returns the default.
 *
 * @param f Function to apply.
 * @param def Value to return if `x` is `Nothing`.
 * @param x Maybe value.
 * @return `f(x)` if the value is a `Just<T>`, `def` otherwise.
 */
export function mapMaybeOrElse<T, Z>(f: (x: T) => Z, def: Z, x: Maybe<T>): Z {
  return isNothing(x) ? def : f(x);
}

/**
 * Applies a function to a `Just<T>` value or returns the default lazily.
 *
 * @param f Function to apply.
 * @param def No-arg function to return default value `x` is `Nothing`.
 * @param x Maybe value.
 * @return `f(x)` if the value is a `Just<T>`, value of `def` function application otherwise.
 */
export function mapMaybeOrElseLazy<T, Z>(f: (x: T) => Z, def: () => Z, x: Maybe<T>): Z {
  return isNothing(x) ? def() : f(x);
}
