import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { mkSimpleChoice, SimpleChoice } from 'cafelatte/libs/headless/prelude/simple-choice';
import CLAjaxService from 'cafelatte/services/-ts/ajax';
import CLSelectControl from '../cl-select/component';

export default class extends CLSelectControl<string> {
  @service declare ajax: CLAjaxService;

  @computed()
  get choices() {
    // @ts-expect-error
    const endpoint = this.options?.endpoint;

    const retval: SimpleChoice<string>[] = [];

    this.ajax
      .request(endpoint)
      .then((data) => retval.pushObjects(data.map((x: string) => mkSimpleChoice(x, x))))
      .catch((error) => {
        console.error(error);
      });

    return retval;
  }
}
