import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { konfiguration, Konfiguration } from 'cafelatte/libs/embered/config';

/**
 * Provides a configuration service for convenience.
 */
export default class KonfigService extends Service {
  /**
   * Application configuration.
   */
  @tracked konfiguration: Konfiguration = konfiguration;

  /**
   * Sanitized application version.
   */
  get version() {
    return /\+/.test(this.versionFull) ? this.versionFull.split('+')[0] : this.versionFull;
  }

  /**
   * Full application version.
   */
  get versionFull() {
    return this.konfiguration.APP.version;
  }

  //////////////////////////////
  // BEGIN: OTP CONFIGURATION //
  //////////////////////////////

  /**
   * Defines the OTP configuration, if enabled.
   */
  get otp() {
    return this.konfiguration.APP.publicConfiguration.otp;
  }

  /**
   * Indicates if OTP is enabled.
   */
  get otpEnabled() {
    return this.otp ? true : false;
  }

  /**
   * Indicates if OTP is enabled.
   */
  get otpDefaultMethod() {
    return this.otp?.default;
  }

  /**
   * Indicates if OTP is enabled.
   */
  get otpExtraMethods() {
    return this.otp?.extras || [];
  }

  /**
   * Indicates if OTP is enabled.
   */
  get otpAvailableMethods() {
    return [...(this.otpDefaultMethod ? [this.otpDefaultMethod] : []), ...this.otpExtraMethods];
  }

  ////////////////////////////
  // END: OTP CONFIGURATION //
  ////////////////////////////

  get views() {
    return this.konfiguration.APP.cafelatteConfiguration.views;
  }

  /**
   * Sets the konfiguration field to the given, updated konfiguration.
   *
   * @param konfiguration Updated konfiguration.
   */
  @action onKonfiugurationUpdated(konfiguration: Konfiguration): any {
    this.konfiguration = konfiguration;
  }
}
