import ApplicationAdapter from '../application';

// @ts-expect-error (TS2339)
export default class OhlcObservation extends ApplicationAdapter.extend({}) {
  pathForType(): string {
    return 'ohlcobservations';
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'ohlc/observation': OhlcObservation;
  }
}
