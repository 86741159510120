import APIKeyModel from 'cafelatte/models/apikey';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<APIKeyModel> {
  get spec(): FlexTableSpec<APIKeyModel> {
    return {
      ident: 'cl-apikey-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'name' },
        { key: 'expiry', component: '@date', options: { format: 'DD-MM-YYYY' } },
        {
          key: 'principal.username',
          label: 'Principal',
          component: '@link',
          options: { route: 'user.details', modelPath: 'principal.id' },
        },
        { key: 'description' },
        ...mkAuditFields<APIKeyModel>(this.me),
      ],
    };
  }
}
