import { action } from '@ember/object';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';

/**
 * Provides the login controller.
 */
export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @tracked username = '';
  @tracked password = '';

  /**
   * Provides an action to attempt to login the user.
   *
   * Note that there are two different possible actions as per OTP situation:
   *
   * 1. No OTP method for the tenant: Just login.
   * 2. Yes OTP method for the tenant: Reach the OTP endpoint and route to OTP input form.
   */
  @action login() {
    // Get the username and password:
    const { username, password } = this;

    // Cleanup username and password as we don't need them anymore:
    this.username = this.password = '';

    // Do we have the OTP enabled?
    if (!isEmpty(this.konfig.otp)) {
      // We have OTP enabled. This means that we should first attempt to get the OTP reference:
      this.ajax
        .request('/otp/send/', {
          type: 'POST',
          data: { username, password, method: this.konfig.otpDefaultMethod },
          dataType: 'json',
        })
        .then((data) =>
          run(() => {
            // Good, we will now transition to OTP verification route:
            this.router.transitionTo('auth.otp', { queryParams: { reference: data.reference, method: data.method } });

            // Notify the user:
            this.flashMessages.success(data.message);
          })
        )
        .catch((reason) => {
          console.error('Error while authenticating', reason);
          this.flashMessages.danger('Error during authentication. Please check your credentials.');
        });
    } else {
      // We do not have OTP enabled. Therefore, we will proceed with regular authentication process:
      this.session.authenticate('authenticator:decaf', { username, password }).catch((reason: any) => {
        console.error('Error while authenticating', reason);
        this.flashMessages.danger('Error during authentication. Please check your credentials.');
      });
    }
  }
}
