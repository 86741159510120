import { tagName } from '@ember-decorators/component';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  layout = layout;
}
