import { DecafClient } from '@decafhub/decaf-client';
import { Id } from '../../commons/types';
import { today } from '../../prelude/datetime';
import { Maybe } from '../../prelude/maybe';
import { ConsolidationContainers, ConsolidationReportData, ConsolidationReportQuery } from '../consolidation-report';
import {
  compileConsolidationReportData,
  query2params as consolidationQuery2Params,
} from '../consolidation-report/generic-report/-full';

/**
 * Pre-trade simulation query type.
 */
export interface PreTradeSimulationQuery {
  tradeOrders: Maybe<Array<number>>;
  tradeOrderGroups: Maybe<Array<number>>;
  query: {
    consolidation: ConsolidationReportQuery;
    profiles: Array<string | number>;
  };
}

/**
 * Pre-trade simulation response type.
 */
export interface PreTradeSimulationResult {
  consolidation: ConsolidationReportData;
  compliance: any;
}

/**
 * Attempts to map risk profile IDs to their names.
 *
 * @param client barista client.
 * @param ids Risk profile IDs.
 * @return An array of risk profile names.
 */
export function getProfileNames(client: DecafClient, ids: Array<number | string>): Promise<Array<string>> {
  return client.barista
    .get<Array<{ name: string }>>('/riskprofiles/', {
      params: { id__in: ids.map((x) => `${x}`).join(','), page_size: '-1' },
    })
    .then(({ data }) => data.map((x) => x.name));
}

/**
 * Attempts to find the account IDs for the given portfolio ID.
 *
 * @param client barista client.
 * @param portfolioId Portfolio ID.
 */
export function getPortfolioAccountIds(client: DecafClient, portfolioId: Id): Promise<Array<Id>> {
  return client.barista
    .get<Array<{ id: Id }>>('/accounts/', { params: { portfolio: portfolioId, page_size: '-1' } })
    .then(({ data }) => data.map((x) => x.id));
}

/**
 * Attempts to find the account IDs for consolidation container.
 *
 * @param client barista client.
 * @param elements Consolidation containers.
 */
export async function getAccountIds(client: DecafClient, elements: ConsolidationContainers): Promise<Array<Id>> {
  if (elements == '*') {
    return Promise.resolve([]);
  } else if (elements.container != 'portfolio') {
    return Promise.resolve([]);
  } else if (elements.instances.length != 1) {
    return Promise.resolve([]);
  }

  // @ts-expect-error ts2345
  return getPortfolioAccountIds(client, elements.instances[0]);
}

/**
 * Defines allocation states of interest.
 */
export const AllocationStatesOfInterest = ['PENDING', 'INSTRUCTED', 'PARTIALLY_EXECUTED', 'PARTIALLY_BOOKED'];

/**
 * Attempts to find all allocation IDs of interest.
 *
 * @param client barista client.
 * @param accountIds IDs of accounts of interest.
 * @param orderIds IDs of trade orders of interest.
 */
export async function getAllocationsForTradeOrders(
  client: DecafClient,
  accountIds: Array<Id>,
  orderIds: Array<Id>
): Promise<Array<Id>> {
  // Now, find and return all IDs of trade order allocations which are allocated to one of the accounts AND of interest:
  return client.barista
    .get<Array<{ id: Id; outstanding: number }>>('/tradeorderallocations/', {
      params: {
        order__in: orderIds.join(','),
        account__in: accountIds.join(','),
        state__in: AllocationStatesOfInterest.join(','),
        page_size: -1,
      },
    })
    .then(({ data }) => data.filter((x) => x.outstanding != 0).map((x) => x.id));
}

/**
 * Attempt to return all trade order IDs of trade order groups of interest.
 *
 * @param client barista client.
 * @param groupIds Trade order group IDs of interest.
 */
export async function getOrderIdsForGroups(client: DecafClient, groupIds: Array<Id>): Promise<Array<Id>> {
  if (groupIds.length == 0) {
    return Promise.resolve([]);
  }

  return client.barista
    .get<Array<{ id: Id }>>('/tradeorders/', { params: { group__in: groupIds.join(','), page_size: -1 } })
    .then(({ data }) => data.map((x) => x.id));
}

export type AllocationRecord = any;
export type OrderRecord = any;
export type ActionRecord = any;

export async function getAllocations(client: DecafClient, allocationIds: Array<Id>): Promise<Array<AllocationRecord>> {
  return client.barista
    .get<Array<AllocationRecord>>('/tradeorderallocations/', {
      params: { id__in: allocationIds.join(','), page_size: -1 },
    })
    .then(({ data }) => data);
}

export async function getOrders(client: DecafClient, orderIds: Array<Id>): Promise<Array<OrderRecord>> {
  return client.barista
    .get<Array<OrderRecord>>('/tradeorders/', {
      params: { id__in: orderIds.join(','), page_size: -1 },
    })
    .then(({ data }) => data);
}

export async function buildActionSpotFxTrade(
  _client: DecafClient,
  allocation: AllocationRecord,
  order: OrderRecord,
  directions: Record<number, { id: number; name: string; sign: number }>
): Promise<ActionRecord> {
  return Promise.resolve({
    ctype: order.ctype,
    commitment: today(),
    accmain: allocation.account,
    resmain: order.resmain,
    // @ts-expect-error ts2532
    qtymain: allocation.outstanding * directions[order.direction].sign,
    resaltn: order.resaltn,
    pxmain: order.pxmain,
    pxflip: order.pxflip,
  });
}

export async function buildActionShareTrade(
  _client: DecafClient,
  allocation: AllocationRecord,
  order: OrderRecord,
  directions: Record<number, { id: number; name: string; sign: number }>
): Promise<ActionRecord> {
  return Promise.resolve({
    ctype: order.ctype,
    commitment: today(),
    accmain: allocation.account,
    resmain: order.resmain,
    // @ts-expect-error ts2532
    qtymain: allocation.outstanding * directions[order.direction].sign,
    resaltn: order.resaltn,
    pxmain: order.pxmain,
  });
}

export async function buildActionFutureTrade(
  _client: DecafClient,
  allocation: AllocationRecord,
  order: OrderRecord,
  directions: Record<number, { id: number; name: string; sign: number }>
): Promise<ActionRecord> {
  return Promise.resolve({
    ctype: order.ctype,
    commitment: today(),
    accmain: allocation.account,
    resmain: order.resmain,
    // @ts-expect-error ts2532
    qtymain: allocation.outstanding * directions[order.direction].sign,
    pxmain: order.pxmain,
  });
}

export async function buildActionStructuredProductTrade(
  _client: DecafClient,
  allocation: AllocationRecord,
  order: OrderRecord,
  directions: Record<number, { id: number; name: string; sign: number }>
): Promise<ActionRecord> {
  return Promise.resolve({
    ctype: order.ctype,
    commitment: today(),
    accmain: allocation.account,
    resmain: order.resmain,
    // @ts-expect-error ts2532
    qtymain: allocation.outstanding * directions[order.direction].sign,
    resaltn: order.resaltn,
    pxmain: order.pxmain,
  });
}

export type ActionBuilder = (
  c: DecafClient,
  x: AllocationRecord,
  y: OrderRecord,
  z: Record<number, { id: number; name: string; sign: number }>
) => Promise<ActionRecord>;

export const actionBuilders: Record<number, ActionBuilder> = {
  60: buildActionSpotFxTrade,
  80: buildActionShareTrade,
  130: buildActionFutureTrade,
  170: buildActionStructuredProductTrade,
};

/**
 * Attempts to compile the actions list for the scenario to be posted.
 *
 * @param client barista client.
 * @param allocationIds Allocation IDs.
 */
export async function compileActionsForScenario(
  client: DecafClient,
  allocationIds: Array<Id>
): Promise<Array<ActionRecord>> {
  // Get directions database:
  const allDirections: Record<number, { id: number; name: string; sign: number }> = await client.barista
    .get('trading/directions/')
    .then(({ data }) =>
      data.reduce(
        (
          acc: Record<number, { id: number; name: string; sign: number }>,
          c: { id: number; name: string; sign: number }
        ) => ({ ...acc, [c.id]: c }),
        {} as Record<number, { id: number; name: string; sign: number }>
      )
    );

  // Get all allocations:
  const allocations = await getAllocations(client, allocationIds);

  // Get trade order IDs:
  const orderIds = allocations.map((x) => x.order);

  // Get all trade orders:
  const orders = await getOrders(client, orderIds);

  // Build order database:
  const orderDB = orders.reduce((p, c) => ({ ...p, [c.id]: c }), {});

  // Build actions and return:
  return Promise.all(
    allocations.map((x) => {
      // Get order:
      const order = orderDB[x.order];

      // Get action type from order:
      const actionType = order.ctype;

      // Attempt to get the action builder for the action type:
      const actionBuilder = actionBuilders[actionType];

      // If the action builder is not defined, die:
      if (!actionBuilder) {
        throw new Error(`Action builder for ${actionType} is not implemented yet.`);
      }

      // Build action and return:
      return actionBuilder(client, x, order, allDirections);
    })
  );
}

/**
 * Compiles the library representation of pre-trade simulation query into remote endpoint query parameters.
 *
 * @param client barista client.
 * @param query Query.
 * @returns Remote endpoint query parameters.
 */
export async function buildQuery(client: DecafClient, query: PreTradeSimulationQuery): Promise<any> {
  // Get profile names:
  const profileNames = await getProfileNames(client, query.query.profiles);

  // Get portfolio account IDs:
  const accountIds = await getAccountIds(client, query.query.consolidation.elements);

  // If no trade order or trade order group is provided, we are interested in all trade order allocations:
  let tradeOrderAllocationIds = [];
  if (!query.tradeOrderGroups?.length && !query.tradeOrders?.length) {
    tradeOrderAllocationIds = await getAllocationsForTradeOrders(client, accountIds, []);
  } else if (!query.tradeOrderGroups?.length) {
    tradeOrderAllocationIds = await getAllocationsForTradeOrders(client, accountIds, query.tradeOrders || []);
  } else {
    const orderIdsForGroups = await getOrderIdsForGroups(client, query.tradeOrderGroups || []);
    tradeOrderAllocationIds = await getAllocationsForTradeOrders(client, accountIds, orderIdsForGroups);
  }

  // If we have trade order allocations IDs, compile actions for the scenario:
  let actions = [];
  if (tradeOrderAllocationIds.length != 0) {
    actions = await compileActionsForScenario(client, tradeOrderAllocationIds);
  }

  // Build and return query:
  return {
    scenario: { data: { actions } },
    query: {
      consolidation: consolidationQuery2Params(query.query.consolidation),
      profiles: profileNames,
    },
  };
}

/**
 * Attempts to run pre-trade simulation query.
 *
 * @param client barista client.
 * @returns barista version resource.
 */
export async function simulatePreTrade(
  client: DecafClient,
  query: PreTradeSimulationQuery
): Promise<PreTradeSimulationResult> {
  // Rebuild the query:
  const rquery = await buildQuery(client, query);

  // Simulate and get the response data:
  const response = await client.barista.post<any>('/simulation/simple/', rquery).then(({ data }) => data);

  // Return the data:
  return {
    consolidation: await compileConsolidationReportData(client, response.consolidation),
    compliance: response.consolidation,
  };
}
