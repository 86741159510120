import { DecafClient, gql } from '@decafhub/decaf-client';
import { Id } from 'cafelatte/libs/headless/commons/types';

export const query = gql`
  query ($portfolio: Int, $date: date) {
    schedules: accrual_schedule(
      where: {
        _and: [
          { portfolio_id: { _eq: $portfolio } }
          {
            _or: [
              { _and: [{ since: { _lte: $date }, until: { _is_null: true } }] }
              { _and: [{ since: { _lte: $date }, until: { _is_null: false, _gte: $date } }] }
            ]
          }
        ]
      }
    ) {
      id
      account {
        id
        name
        analytical_type {
          name
        }
      }
      posting_account {
        id
        name
        analytical_type {
          name
        }
      }
      share_class {
        id
        name
      }
      analytical_type {
        name
      }
      since
      until
      percentage
      minimum_payable_currency
      minimum_payable_quantity
    }
  }
`;

/**
 * Type for a principal returned from the principals list function.
 */
export interface EffectiveAccrualSchedule {
  id: Id;
  account?: { id: Id; name: string; analytical_type?: { name: string } };
  share_class?: { id: Id; name: string };
  posting_account?: { id: Id; name: string; analytical_type?: { name: string } };
  analytical_type?: { name: string };
  since: string;
  until?: string;
  percentage: number;
  minimum_payable_currency: string;
  minimum_payable_quantity?: number;
}

export function fixAnalyticalType(x: EffectiveAccrualSchedule) {
  if (!x.analytical_type) {
    x.analytical_type = x.posting_account?.analytical_type;
  }
  return x;
}

/**
 * Attempts to retrieve remote [[EffectiveAccrualSchedule]]s.
 *
 * @param client DECAF API clients.
 * @param portfolio Portfolio ID.
 * @param date Effective date.
 * @returns An array of [[EffectiveAccrualSchedule]] values.
 */
export async function getEffectiveAccrualSchedules(
  client: DecafClient,
  portfolio: string,
  date: string
): Promise<Array<EffectiveAccrualSchedule>> {
  return client.microlot
    .query({ query, variables: { portfolio, date } })
    .then((result) => result.data?.schedules || [])
    .then((result) => result.map(fixAnalyticalType));
}
