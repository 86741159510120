import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ConsolidationReportData } from 'cafelatte/libs/headless/services/consolidation-report';

interface Args {
  data: ConsolidationReportData;
}

export default class extends Component<Args> {
  @tracked _tab = '0';
}
