import { action, computed } from '@ember/object';
import Component from '@glimmer/component';
import Cleave from 'cleave.js';
import Decimal from 'decimal.js';

function percentify(x: Decimal, asis: boolean, revert: boolean): Decimal {
  return asis ? x : revert ? x.dividedBy(100) : x.mul(100);
}

function toInternalNumber(x: number | string | null | undefined, percentage: boolean): Decimal | undefined {
  return x === null || x === undefined || x === '' ? undefined : percentify(new Decimal(`${x}`), !percentage, false);
}

function toExternalNumber(x: number | string | null | undefined, percentage: boolean): Decimal | undefined {
  return x === null || x === undefined || x === '' ? undefined : percentify(new Decimal(`${x}`), !percentage, true);
}

interface Args {
  value: any;
  label: string;
  class?: string;
  placeholder?: string;
  onChange?: Function;
  options?: Record<any, any>;
  required?: boolean;
  disabled?: boolean;
}

export default class extends Component<Args> {
  updateTimeout?: NodeJS.Timeout;

  @computed('args.value', 'isPercentage')
  get isPercentage() {
    return this.args.options?.percentage ? true : false;
  }

  @computed('args.value', 'isPercentage')
  get internalValue() {
    return toInternalNumber(this.args.value, this.isPercentage)?.toNumber();
  }

  @computed('args.value')
  get colorClasses(): string {
    const colorize = this.args.options?.colorize;
    const num = this.args.value;
    let className = '';
    if (colorize && !isNaN(num)) {
      if (num === 0) {
        className += ' blue';
      } else {
        className += num > 0 ? ' green' : ' red';
      }
      className += colorize === 'box' ? ' box' : '';
    }
    return className;
  }

  @action didInsertInput(element: HTMLElement): void {
    new Cleave(`#${element.id}`, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalScale: this.args?.options?.decimals || 12,
      onValueChanged: this.onChange,
    });
  }

  @action onChange(event: { target: { value: string; rawValue: string } }): void {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    const timeout = this.args.options?.drate || 300;

    this.updateTimeout = setTimeout(
      () => this.args.onChange?.(toExternalNumber(event.target.rawValue, this.isPercentage)?.toNumber()),
      timeout
    );
  }
}
