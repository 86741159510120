import { AccountResource } from 'cafelatte/libs/headless/commons/resources/account-resource';
import { ArtifactResource } from 'cafelatte/libs/headless/commons/resources/artifact-resource';
import { Currency, Id } from 'cafelatte/libs/headless/commons/types';
import { mapMaybeOrElse, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { ZERO } from 'cafelatte/libs/headless/prelude/numeric';
import Decimal from 'decimal.js';

export interface RawAccrual {
  name: string;
  value: Maybe<number>;
  accounts: Array<{
    account: { id: Id };
    value: Maybe<number>;
    accruals: Array<{
      artifact: { id: Id };
      ccy: Currency;
      value: Maybe<{
        org: Maybe<number>;
        ref: Maybe<number>;
      }>;
    }>;
  }>;
}

export interface AccountAccrualItem {
  artifact: ArtifactResource;
  currency: Currency;
  valueOrg: Decimal;
  valueRef: Decimal;
}

export interface AccountAccrual {
  account: AccountResource;
  value: Decimal;
  items: Array<AccountAccrualItem>;
}

export interface Accrual {
  name: string;
  value: Decimal;
  byAccounts: Array<AccountAccrual>;
}

export function buildAccrual(
  accounts: Record<Id, AccountResource>,
  artifacts: Record<Id, ArtifactResource>,
  raw: RawAccrual
): Accrual {
  return {
    name: raw.name,
    value: mapMaybeOrElse((x) => new Decimal(x), ZERO, raw.value),
    // @ts-expect-error ts2322
    byAccounts: raw.accounts.map((x) => ({
      account: accounts[x.account.id],
      value: mapMaybeOrElse((x) => new Decimal(x), ZERO, x.value),
      items: x.accruals.map((i) => ({
        artifact: artifacts[i.artifact.id],
        currency: i.ccy,
        valueOrg: mapMaybeOrElse((x) => new Decimal(x), ZERO, i.value?.org),
        valueRef: mapMaybeOrElse((x) => new Decimal(x), ZERO, i.value?.ref),
      })),
    })),
  };
}
