import { action, computed, notifyPropertyChange, setProperties } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController, queryParamAsNumberArray } from 'cafelatte/libs/embered';
import { DateType } from 'cafelatte/libs/headless/commons/date-type';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { isJust, isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import {
  ConsolidationContainerType,
  ConsolidationReportData,
  ConsolidationReportQuery,
} from 'cafelatte/libs/headless/services/consolidation-report';
import { downloadHoldings } from 'cafelatte/libs/headless/services/consolidation-report/commons/holding';
import DecafService from 'cafelatte/services/decaf';
import { resolve } from 'rsvp';
import { Query } from '../../components/c-l/reports/valuation/consolidation/query-form/component';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

function isQueryComplete(form: Query): boolean {
  return (form.date && form.datetype && form.container && form.instances?.length && form.currency && true) || false;
}

export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = ['c', 'i', 'date', 'type', 'ccy', 'sandbox'];

  @tracked c: Maybe<ConsolidationContainerType> = undefined;
  @tracked i: Maybe<string> = undefined;
  @tracked date: Maybe<string> = undefined;
  @tracked type: Maybe<DateType> = undefined;
  @tracked ccy: Maybe<string> = undefined;
  @tracked sandbox: Maybe<'include' | 'exclude'> = undefined;

  @computed('i')
  get instances() {
    return queryParamAsNumberArray(this.i);
  }

  @computed('date', 'type', 'c', 'instances.[]', 'ccy', 'sandbox')
  get formQuery(): Query {
    return {
      date: this.date || today(),
      datetype: this.type || 'commitment',
      container: this.c,
      instances: this.instances,
      currency: this.ccy || 'USD',
      sandbox: this.sandbox || 'include',
    };
  }

  @computed('c', 'instances.[]')
  get portfolio() {
    return this.c == 'portfolio' && this.instances.length == 1
      ? // @ts-expect-error ts2345
        this.store.findRecord('portfolio', this.instances[0])
      : undefined;
  }

  @computed('c', 'instances.[]')
  get account() {
    return this.c == 'account' && this.instances.length == 1
      ? // @ts-expect-error ts2345
        this.store.findRecord('account', this.instances[0])
      : undefined;
  }

  @computed('c', 'instances.[]')
  get showPerformanceButton() {
    return (this.c == 'portfolio' || this.c == 'account') && this.instances.length > 0;
  }

  @computed('c', 'instances.[]', 'portfolio', 'account')
  get performanceInstance() {
    return this.c === 'portfolio' ? this.portfolio : this.c === 'account' ? this.account : undefined;
  }

  @computed('formQuery')
  get query(): Maybe<ConsolidationReportQuery> {
    // @ts-expect-error
    return isQueryComplete(this.formQuery)
      ? {
          date: this.formQuery.date,
          datetype: this.formQuery.datetype,
          elements: { container: this.formQuery.container, instances: this.formQuery.instances },
          currency: this.formQuery.currency,
          sandbox: this.formQuery.sandbox,
        }
      : undefined;
  }

  @computed('query')
  get report(): Promise<Maybe<ConsolidationReportData>> {
    // Check if we have a query:
    if (isNothing(this.query)) {
      return ObjectPromiseProxy.create({ promise: resolve(undefined) });
    }

    return ObjectPromiseProxy.create({
      promise: resolve(this.decaf.services.consolidationReport.consolidate(this.query)),
    });
  }

  @action onQueryChanged(query: Query) {
    setProperties(this, {
      c: query.container,
      i: query.instances?.join(',') || '',
      date: query.date,
      type: query.datetype,
      ccy: query.currency,
      sandbox: query.sandbox,
    });
  }

  @action refresh() {
    notifyPropertyChange(this, 'query');
  }

  @action downloadReport() {
    if (isJust(this.query)) {
      this.decaf.services.consolidationReport.download(this.query, 'consolidation.pdf');
    }
  }

  @action downloadHoldings() {
    // @ts-expect-error;
    if (this.report.content) {
      // @ts-expect-error;
      downloadHoldings(this.report.content.positions.holdings);
    }
  }

  @action doExit() {
    setProperties(this, {
      c: undefined,
      i: undefined,
      date: undefined,
      type: undefined,
      ccy: undefined,
      sandbox: undefined,
      // @ts-expect-error
      'report.content': undefined,
    });
  }
}
