import { DecafClient } from '@decafhub/decaf-client';
import { DateType } from '../../commons/date-type';
import { Currency, Id } from '../../commons/types';
import { SDate } from '../../prelude/datetime';

export interface CashStatementQuery {
  currency: Currency;
  dateasof: SDate;
  datetype: DateType;
}

export type CashStatement = CashStatementItem[];

export interface CashStatementItem {
  dateasof: SDate;
  datetype: DateType;
  ccyorg: Currency;
  qtyorg: number;
  ccyref: Currency;
  qtyref: number;
  resid: Id;
  ressymbol: Id;
  resname: Id;
  ctype: string;
  htype: string;
  accid: Id;
  accname: string;
  custid: Id;
  custname: string;
  portid: Id;
  portname: string;
  teamid: Id;
  teamname: string;
  analytical?: string;
}

/**
 * Attempts to retrieve and compile remote report data.
 *
 * @param client barista client.
 * @returns Report data.
 */
export function request(client: DecafClient, query: CashStatementQuery): Promise<CashStatement> {
  return client.barista.get<CashStatement>('/cashstatements/', { params: query }).then(({ data }) => data);
}
