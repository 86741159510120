import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams(['page', 'page_size', 'search']);

  model(params: any) {
    return this.store.query('complianceexemption', cleanParams(params));
  }

  @action error(error: any) {
    return handle404OnNonExistingPage(this, error);
  }
}
