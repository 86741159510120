import { Service } from '../-service';
import { getPrincipalsList, PrincipalsServiceListItem } from './-internal';

/**
 * Provides a service which simply lists all accessible principals.
 */
export class PrincipalsService extends Service {
  list(): Promise<Array<PrincipalsServiceListItem>> {
    return getPrincipalsList(this.client);
  }
}

// Export other public symbols:
export { PrincipalsServiceListItem };
