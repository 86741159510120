import { Service } from '../-service';
import { getRemoteInformation, RemoteInformation } from './-internal';

// Export public symbols:
export { RemoteInformation };

/**
 * Provides the barista remote information service.
 */
export class RemoteInformationService extends Service {
  /**
   * Returns the [[RemoteInformation]] of barista.
   */
  get(): Promise<RemoteInformation> {
    return getRemoteInformation(this.client);
  }
}
