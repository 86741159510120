import Store from '@ember-data/store';
import { get, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { Id } from '../headless/commons/types';
import { Maybe } from '../headless/prelude/maybe';

/**
 * Predicate to check if the value is empty.
 *
 * This is NOT a general purpose method as we are not only checking if the value is `undefined`, `null` or an empty
 * string, but also their string representations, ie. `'undefined'` and `'null'`.
 *
 * @param x Value to check.
 * @returns `true` if the given value is *empty* (or a representation of empty), `false` otherwise.
 */
function _isEmpty(x: string | undefined | null): x is undefined | null {
  return x === undefined || x === null || x === '' || x === 'undefined' || x === 'null' || isEmpty(x);
}

/**
 * Cleans query parameters for null, empty and `"null"` values.
 */
export function cleanParams(params: Record<string, string | undefined | null>): Record<string, string> {
  // Initialize the return value:
  const retval: Record<string, string> = {};

  // Iterate over parameters and add to the return value if not empty:
  for (const [k, v] of Object.entries(params)) {
    if (!_isEmpty(v)) {
      retval[k] = v;
    }
  }

  // Done, return new parameters object:
  return retval;
}

/**
 * Type of typical detail route parameters (most generally).
 */
export type DetailRouteParams = { id: string | number };

/**
 * Type of typical index route parameters (most generally).
 */
export type IndexRouteParams = Record<string, any>;

/**
 * Attempts to find or create a model instance for the detail route.
 *
 * @param store Store to use.
 * @param model Model type name.
 * @param params Detail route parameters.
 */
export function getDetailRouteModel(store: Store, model: string, params: DetailRouteParams): any {
  if (params.id === '__new__') {
    return store.createRecord(model);
  } else {
    return store.findRecord(model, params.id);
  }
}

/**
 * Attempts to find model instances for the index route.
 *
 * @param store Store to use.
 * @param model Model type name.
 * @param params Index route parameters.
 */
export function getIndexRouteModel(store: Store, model: string, params: IndexRouteParams): any {
  return store.query(model, cleanParams(params));
}

export function parseIds<T extends { [key in P]: string }, P extends keyof T>(obj: T, key: P): Id[] {
  return get(obj, key)
    .split(',')
    .filter((x: any) => x);
}

export function buildIds<T extends { [key in P]: string }, P extends keyof T>(obj: T, key: P, ids: Maybe<Id[]>): void {
  // @ts-expect-error;
  set(obj, key, (ids || []).join(','));
}
