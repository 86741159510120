import Model, { attr, belongsTo } from '@ember-data/model';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';
import PortfolioModel from './portfolio';
import ShareClassModel from './shareclass';

const _fields = [
  'portfolio',
  'shareclass',
  'date',
  'ccy',
  'shares',
  'price',
  'nav',
  'aum',
  'hedgepnl',
  'feemngt',
  'feeperf',
  'otheraccrued',
  'totalaccrued',
  'subred',
  'perfdaily',
  'perfweekly',
  'perfmonthly',
  'perfytd',
  'perfstart',
  'coefficient',
];

export default class ExternalValuationModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @belongsTo('portfolio')
  declare portfolio?: BelongsTo<PortfolioModel>;

  @belongsTo('shareclass')
  declare shareclass?: BelongsTo<ShareClassModel>;

  @attr()
  declare date?: string;

  @attr()
  declare ccy?: string;

  @attr('number')
  declare shares?: number;

  @attr('number')
  declare price?: number;

  @attr('number')
  declare nav?: number;

  @attr('number')
  declare aum?: number;

  @attr('number')
  declare hedgepnl?: number;

  @attr('number')
  declare feemngt?: number;

  @attr('number')
  declare feeperf?: number;

  @attr('number')
  declare otheraccrued?: number;

  @attr('number')
  declare totalaccrued?: number;

  @attr('number')
  declare subred?: number;

  @attr('number')
  declare perfdaily?: number;

  @attr('number')
  declare perfweekly?: number;

  @attr('number')
  declare perfmonthly?: number;

  @attr('number')
  declare perfytd?: number;

  @attr('number')
  declare perfstart?: number;

  @attr('number')
  declare coefficient?: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    externalvaluation: ExternalValuationModel;
  }
}
