import { DecafClient } from '@decafhub/decaf-client';
import { DeploymentType } from '../../commons/deployment';
import { dayjs, Dayjs } from '../../prelude/datetime';

/**
 * Encoding for remote information resource.
 */
export interface InfoResource {
  code: string;
  type: DeploymentType;
  version: string;
  timeutc: string;
  timeloc: string;
  timezone: string;
  utcoffset: string;
}

/**
 * Encoding for library type for information.
 */
export interface RemoteInformation {
  code: string;
  type: DeploymentType;
  version: string;
  time: {
    utc: Dayjs;
    local: Dayjs;
    server: Dayjs;
  };
  zone: {
    local: string;
    server: string;
  };
}

/**
 * Converts [[InfoResource]] to [[RemoteoInformation]].
 *
 * @param x Remote information.
 * @return [[RemoteInformation]] instance compiled from the remote information.
 */
export function convertResource(x: InfoResource): RemoteInformation {
  // Attempt to parse server time:
  const serverTime = dayjs(x.timeloc).clone().tz(x.timezone);

  // Guess local timezone:
  // TODO: Shall we ignore cache?
  const localZone = dayjs.tz.guess();

  // Compile the data and return:
  return {
    code: x.code,
    type: x.type,
    version: x.version,
    time: {
      utc: dayjs(x.timeutc).clone().tz('UTC'),
      local: serverTime.clone().tz(localZone),
      server: serverTime,
    },
    zone: {
      local: localZone,
      server: x.timezone,
    },
  };
}

/**
 * Attempts to retrieve remote [[InfoResource]].
 *
 * @param client barista client.
 * @returns [[InfoResource]] instance.
 */
export async function getResource(client: DecafClient): Promise<InfoResource> {
  return (await client.barista.get<InfoResource>('/info/')).data;
}

/**
 * Attempts to retrieve remote [[RemoteInformation]].
 *
 * @param client barista client.
 * @returns [[RemoteInformation]] instance.
 */
export async function getRemoteInformation(client: DecafClient): Promise<RemoteInformation> {
  return await getResource(client).then(convertResource);
}
