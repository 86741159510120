import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { NumberResult } from 'cafelatte/libs/headless/services/phoneless';
import DecafService from 'cafelatte/services/decaf';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  @service declare decaf: DecafService;

  layout = layout;

  @tracked value!: any;
  @tracked result?: NumberResult = undefined;

  get isValid() {
    return this.result?.valid || false;
  }

  @action onValueChanged(value?: string | null): void {
    // @ts-expect-error
    this.onChange(value || undefined);

    // Attempt to validate:
    if (value) {
      this.decaf.services.phoneless.validate(value).then((x) => (this.result = x));
    } else {
      this.result = undefined;
    }
  }
}
