import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Id } from 'cafelatte/libs/headless/commons/types';
import DecafService from 'cafelatte/services/decaf';

interface CLPrincipalPasswordChangeArgs {
  principal: Id;
}

export default class CLPrincipalPasswordChange extends Component<CLPrincipalPasswordChangeArgs> {
  @service declare decaf: DecafService;
  @service declare flashMessages: any;

  @tracked showPassword = false;
  @tracked password1 = '';
  @tracked password2 = '';

  @action savePassword() {
    this.decaf.services.profile
      .changePassword(this.args.principal, { password1: this.password1, password2: this.password2 })
      .then(() => this.flashMessages.success('Password is successfully changed.'))
      .catch(this.flashMessages.danger)
      .finally(() => {
        this.password1 = '';
        this.password2 = '';
      });
  }
}
