import { computed } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { DateType } from 'cafelatte/libs/headless/commons/date-type';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { SDate, today } from 'cafelatte/libs/headless/prelude/datetime';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { StockAtHand, StocksAtHandQuery } from 'cafelatte/libs/headless/services/stocks';
import ArtifactModel from 'cafelatte/models/resource';
import DecafService from 'cafelatte/services/decaf';
import { resolve } from 'rsvp';

interface Args {
  groupBy: 'porrtfolio' | 'team' | 'custodian';
  artifact: Maybe<ArtifactModel>;
  date: Maybe<SDate>;
  datetype: Maybe<DateType>;
  squared: Maybe<boolean>;
  container: Maybe<'account' | 'portfolio' | 'team' | 'institution'>;
  instances: Maybe<Array<Id>>;
}

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

export default class extends Component<Args> {
  @service declare decaf: DecafService;

  @computed('args.{artifact,date,datetype,squared,container,instances.@each}')
  get query(): Maybe<StocksAtHandQuery> {
    if (isNothing(this.args.artifact)) {
      return undefined;
    }
    return {
      artifacts: [this.args.artifact.id],
      container: this.args.container,
      instances: this.args.instances || [],
      date: this.args.date || today(),
      datetype: this.args.datetype || 'commitment',
      squared: isNothing(this.args.squared) ? false : this.args.squared,
    };
  }

  @computed('query')
  get report(): Promise<Array<StockAtHand>> {
    // Check if we have a query:
    if (isNothing(this.query)) {
      return ObjectPromiseProxy.create({ promise: resolve(undefined) });
    }

    return ObjectPromiseProxy.create({
      promise: resolve(this.decaf.services.stocks.get(this.query)),
    });
  }
}
