import { DecafClient } from '@decafhub/decaf-client';

/**
 * Encoding for remote information resource.
 */
export interface DayCountDefinition {
  name: string;
  altn: Array<string>;
  currencies: Array<string>;
}

/**
 * Attempts to retrieve remote [[DayCountDefinition]] registry.
 *
 * @param client barista client.
 * @returns Array of [[DayCountDefinition]] instances.
 */
export async function getDayCountDefinitions(client: DecafClient): Promise<Array<DayCountDefinition>> {
  return (await client.barista.get<Array<DayCountDefinition>>('/daycounts/')).data;
}
