import StrategyModel from 'cafelatte/models/strategy';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<StrategyModel> {
  get spec(): FlexTableSpec<StrategyModel> {
    return {
      ident: 'cl-strategy-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [{ key: 'id' }, { key: 'name' }, { key: 'description' }, ...mkAuditFields<StrategyModel>(this.me)],
    };
  }
}
