import { Service } from '../-service';
import { Maybe } from '../../prelude/maybe';
import { ConsolidationContainers, ConsolidationContainerType, ConsolidationContainerTypes } from './commons/-shared';
import {
  AssetAllocation,
  AssetAllocationItem,
  compileAssetAllocation,
  GroupingExtractor,
  GroupingType,
  GroupingTypes,
  ValueExtractor,
  ValueType,
  ValueTypes,
} from './commons/asset-allocation';
import { FundReportData, FundReportQuery, retrieveFundReport } from './fund-report/-full';
import {
  ConsolidationReportData,
  ConsolidationReportQuery,
  download,
  retrieveConsolidationReport,
} from './generic-report/-full';
import { QuickValuationReportData, QuickValuationReportQuery, requestQuickValuation } from './generic-report/-quick';
import { PortfolioReportData, PortfolioReportQuery, retrievePortfolioReport } from './portfolio-report/-full';

export {
  AssetAllocation,
  AssetAllocationItem,
  compileAssetAllocation,
  ConsolidationContainers,
  ConsolidationContainerType,
  ConsolidationContainerTypes,
  ConsolidationReportData,
  ConsolidationReportQuery,
  GroupingExtractor,
  GroupingType,
  GroupingTypes,
  QuickValuationReportData,
  QuickValuationReportQuery,
  requestQuickValuation,
  ValueExtractor,
  ValueType,
  ValueTypes,
};

/**
 * Provides the barista consolidation report service.
 */
export class ConsolidationReportService extends Service {
  /**
   * Retrieves and compiles report data from the remote API endoint for quick consolidation.
   *
   * @param query Report query.
   * @returns Report data.
   */
  requestQuickValuation(query: QuickValuationReportQuery): Promise<QuickValuationReportData> {
    return requestQuickValuation(this.client, query);
  }

  /**
   * Retrieves and compiles generic consolidation eport.
   *
   * @param query Report query.
   * @returns Generic consolidation report.
   */
  consolidate(query: ConsolidationReportQuery): Promise<ConsolidationReportData> {
    return retrieveConsolidationReport(this.client, query);
  }

  /**
   * Downloads generic consolidation eport.
   *
   * @param query Report query.
   */
  download(query: ConsolidationReportQuery, filename: Maybe<string>): void {
    return download(this.client, query, filename);
  }

  /**
   * Retrieves and compiles fund eport.
   *
   * @param query Report query.
   * @returns Fund report.
   */
  getFundReport(query: FundReportQuery): Promise<FundReportData> {
    return retrieveFundReport(this.client, query);
  }

  /**
   * Retrieves and compiles portfolio eport.
   *
   * @param query Report query.
   * @returns Portfolio report.
   */
  getPortfolioReport(query: PortfolioReportQuery): Promise<PortfolioReportData> {
    return retrievePortfolioReport(this.client, query);
  }
}
