import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import PortfolioModel from 'cafelatte/models/portfolio';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
import { EffectiveCreditLine, getEffectiveCreditLines } from './-internal';

interface Args {
  portfolio: PortfolioModel;
  date?: string;
}

export default class extends Component<Args> {
  @service declare decaf: DecafService;

  @tracked records: Array<EffectiveCreditLine> = [];

  get date() {
    return this.args.date ? this.args.date : today();
  }

  @action getRecords() {
    getEffectiveCreditLines(this.decaf.client, get(this.args.portfolio, 'id'), this.date).then((x) =>
      this.records.pushObjects(x)
    );
  }

  get spec(): FlexTableSpec<EffectiveCreditLine> {
    return {
      ident: 'cl-portfolio-effective-credit-lines',
      vfill: true,
      columns: [
        { key: 'id' },
        { key: 'since', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'until', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'currency', label: 'CCY' },
        { key: 'quantity', component: '@number', options: { format: '0,0.00' }, align: 'right' },
        {
          key: 'account.name',
          label: 'Account',
          component: '@link',
          options: { route: 'account.details', modelPath: 'account.id' },
        },
      ],
    };
  }
}
