import { Service } from '../-service';
import {
  CalendarEvent,
  CalendarEventArtifact,
  CalendarEventContainer,
  CalendarEvents,
  CalendarEventsQuery,
  request,
} from './-internal';

// Export public symbols:
export { CalendarEvents, CalendarEventsQuery, CalendarEvent, CalendarEventArtifact, CalendarEventContainer };

/**
 * Provides a calendar events service.
 */
export class CalendarEventsService extends Service {
  /**
   * Retrieves and compiles calendar events data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  get(query: CalendarEventsQuery): Promise<CalendarEvents> {
    return request(this.client, query);
  }
}
