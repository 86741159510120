import { Service } from '../-service';
import {
  ExecutiveBreakdownType,
  ExecutiveLabeledItem,
  ExecutiveQuery,
  request as requestExecutive,
} from './-executive';
import {
  AmountChoices,
  AmountType,
  BreakdownChoices,
  BreakdownType,
  request as requestTimeline,
  VoucherTimelineItem,
  VoucherTimelineLabeledItem,
  VoucherTimelineQuery,
} from './-timeline';

// Export public symbols:
export {
  VoucherTimelineItem,
  VoucherTimelineQuery,
  AmountChoices,
  AmountType,
  BreakdownChoices,
  BreakdownType,
  VoucherTimelineLabeledItem,
  ExecutiveLabeledItem,
  ExecutiveQuery,
  ExecutiveBreakdownType,
};

/**
 * Provides a vocuhers service.
 */
export class VouchersService extends Service {
  /**
   * Retrieves and compiles vouchers timeline report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  timeline(query: VoucherTimelineQuery): Promise<VoucherTimelineItem[]> {
    return requestTimeline(this.client, query);
  }

  /**
   * Retrieves and compiles executive report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  executive(query: ExecutiveQuery): Promise<ExecutiveLabeledItem[]> {
    return requestExecutive(this.client, query);
  }
}
