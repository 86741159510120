import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default class RoleModel extends Model {
  @attr()
  declare code: string;

  @attr()
  declare name: string;

  @attr('boolean')
  declare privileged: boolean;

  @attr('boolean')
  declare internal: boolean;

  // TODO: Get rid of this property.
  @computed('name')
  get shortName(): string {
    switch (this.name) {
      case 'external asset manager':
        return 'EAM';
      case 'relationship manager':
        return 'RM';
      case 'administrator':
        return 'ADMIN';
      default:
        return this.name;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    role: RoleModel;
  }
}
