import { buildLookupTable, SimpleChoice, SimpleChoiceLookupTable, SimpleChoices } from '../prelude/simple-choice';

/**
 * FINREA date type.
 */
export type DateType = 'commitment' | 'settlement';

/**
 * FINREA date type choice.
 */
export type DateTypeChoice = SimpleChoice<DateType>;

/**
 * FINREA date type choices.
 */
export const DateTypeChoices: SimpleChoices<DateType> = [
  { value: 'commitment', label: 'Trade Date' },
  { value: 'settlement', label: 'Value Date' },
];

/**
 * FINREA date type choice lookup table.
 */
export const DateTypeChoiceLookupTable: SimpleChoiceLookupTable<DateType> = buildLookupTable(DateTypeChoices);
