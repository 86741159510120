import Model, { attr, belongsTo } from '@ember-data/model';
import OHLCSeriesModel from '../ohlc';
import { BelongsTo, DecafAuditFields, makeValidators } from '../_internal/machinery';

const _fields = ['series', 'date', 'open', 'high', 'low', 'close', 'volume', 'interest'];

export default class OHLCObservationModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @belongsTo('ohlc')
  series?: BelongsTo<OHLCSeriesModel>;

  @attr()
  symbol?: string;

  @attr()
  date?: string;

  @attr()
  open?: string;

  @attr()
  high?: string;

  @attr()
  low?: string;

  @attr()
  close?: string;

  @attr()
  volume?: string;

  @attr()
  interest?: string;

  @attr()
  source?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ohlc/observation': OHLCObservationModel;
  }
}
