import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import CountryModel from './country';
import OHLCSeriesModel from './ohlc';
import PortfolioModel from './portfolio';
import ShareClassFeeScheduleModel from './scfeeschedule';
import ActionModel from './trade';
import { BelongsTo, DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = [
  'name',
  'portfolio',
  'currency',
  'freqmngt',
  'freqperf',
  'isin',
  'bbgticker',
  'liquidity',
  'jurisdiction',
  'administrator',
  'mininvestment',
  'subredperiod',
  'description',
  'feeschedules',
  'subscriptions',
  'outstanding',
  'benchmark',
];

export default class ShareClassModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @belongsTo('portfolio')
  declare portfolio?: BelongsTo<PortfolioModel>;

  @attr()
  declare currency?: string;

  @attr('number')
  declare freqmngt?: number;

  @attr('number')
  declare freqperf?: number;

  @attr()
  declare isin?: string;

  @attr()
  declare bbgticker?: string;

  @attr()
  declare liquidity?: string;

  @belongsTo('country')
  declare jurisdiction?: BelongsTo<CountryModel>;

  @attr()
  declare administrator?: string;

  @attr('number')
  declare mininvestment?: number;

  @attr()
  declare subredperiod?: string;

  @belongsTo('ohlc')
  declare benchmark?: BelongsTo<OHLCSeriesModel>;

  @attr()
  declare description?: string;

  @hasMany('scfeeschedule')
  declare feeschedules?: HasMany<ShareClassFeeScheduleModel>;

  @hasMany('trade')
  declare subscriptions?: HasMany<ActionModel>;

  @attr('number')
  declare outstanding?: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    shareclass: ShareClassModel;
  }
}
