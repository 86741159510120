import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dayjs, getDate, SDate, SDateRange, today } from 'cafelatte/libs/headless/prelude/datetime';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';

interface Args {
  value: Maybe<SDateRange>;
  onChange?: (x?: SDateRange) => void;
  disableInput?: boolean;
  disableShortcuts?: boolean;
}

export default class extends Component<Args> {
  @tracked year = dayjs().year();
  @tracked asof = today();
  @tracked showShortcuts = false;

  @action onChange(what: 'start' | 'end', x: SDate) {
    // Get the current value and put new value on top of it:
    const value = { ...(this.args.value || {}), [what]: x };

    // Make sure that the start and end values are consistent:
    if (what == 'start' && value.start && value.end) {
      value.end = value.start > value.end ? value.start : value.end;
    } else if (what == 'end' && value.start && value.end) {
      value.start = value.end < value.start ? value.end : value.start;
    }

    // Propogate:
    this.args.onChange?.(value);
  }

  @action runShortcutForAsof(indicator: 'DTD' | 'WTD' | 'MTD' | 'QTD' | 'YTD' | '3M' | '6M' | '1Y' | '2Y') {
    this.showShortcuts = false;

    if (!this.asof) {
      return;
    }

    const asof = this.asof;

    switch (indicator) {
      case 'DTD':
        return this.args.onChange?.({ start: getDate('yesterday', asof), end: asof });
      case 'WTD':
        return this.args.onChange?.({ start: getDate('this-week-start', asof), end: asof });
      case 'MTD':
        return this.args.onChange?.({ start: getDate('this-month-start', asof), end: asof });
      case 'QTD':
        return this.args.onChange?.({ start: getDate('this-quarter-start', asof), end: asof });
      case 'YTD':
        return this.args.onChange?.({ start: getDate('this-year-start', asof), end: asof });
      case '3M':
        return this.args.onChange?.({ start: dayjs(asof).subtract(3, 'month').format('YYYY-MM-DD'), end: asof });
      case '6M':
        return this.args.onChange?.({ start: dayjs(asof).subtract(6, 'month').format('YYYY-MM-DD'), end: asof });
      case '1Y':
        return this.args.onChange?.({ start: dayjs(asof).subtract(1, 'year').format('YYYY-MM-DD'), end: asof });
      case '2Y':
        return this.args.onChange?.({ start: dayjs(asof).subtract(2, 'year').format('YYYY-MM-DD'), end: asof });
      default:
        break;
    }
  }

  @action runShortcutForYear(indicator: 'Y' | 'H1' | 'H2' | 'Q1' | 'Q2' | 'Q3' | 'Q4') {
    this.showShortcuts = false;

    if (!this.year) {
      return;
    }

    const year = this.year;

    switch (indicator) {
      case 'Y':
        return this.args.onChange?.({
          start: dayjs(`${year}-01-01`).format('YYYY-MM-DD'),
          end: dayjs(`${year}-12-31`).format('YYYY-MM-DD'),
        });
      case 'H1':
        return this.args.onChange?.({
          start: dayjs(`${year}-01-01`).format('YYYY-MM-DD'),
          end: dayjs(`${year}-06-30`).format('YYYY-MM-DD'),
        });
      case 'H2':
        return this.args.onChange?.({
          start: dayjs(`${year}-07-01`).format('YYYY-MM-DD'),
          end: dayjs(`${year}-12-31`).format('YYYY-MM-DD'),
        });
      case 'Q1':
        return this.args.onChange?.({
          start: dayjs(`${year}-01-01`).format('YYYY-MM-DD'),
          end: dayjs(`${year}-03-31`).format('YYYY-MM-DD'),
        });
      case 'Q2':
        return this.args.onChange?.({
          start: dayjs(`${year}-04-01`).format('YYYY-MM-DD'),
          end: dayjs(`${year}-06-30`).format('YYYY-MM-DD'),
        });
      case 'Q3':
        return this.args.onChange?.({
          start: dayjs(`${year}-07-01`).format('YYYY-MM-DD'),
          end: dayjs(`${year}-09-30`).format('YYYY-MM-DD'),
        });
      case 'Q4':
        return this.args.onChange?.({
          start: dayjs(`${year}-10-01`).format('YYYY-MM-DD'),
          end: dayjs(`${year}-12-31`).format('YYYY-MM-DD'),
        });
      default:
        break;
    }
  }
}
