import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { CommonController } from 'cafelatte/libs/embered';
import { FlexTableSpec, mkNumberColumn } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';
import Decimal from 'decimal.js';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @computed('model')
  get constraints() {
    return ((this.model?.data?.profiles as any[]) || []).reduce((accum: any[], profile) => {
      accum.pushObjects(profile.constraints.map((c: any) => ({ profileName: profile.name, name: c.name, object: c })));
      accum.push({ profileName: profile.name, name: 'Total', object: profile, aggregate: true });
      return accum;
    }, []);
  }

  get tableSpecCategories(): FlexTableSpec<any> {
    return {
      ident: 'cl-compliance-profile-categories',
      vfill: false,
      columns: [
        { key: 'name' },
        { key: 'vtype', label: 'Value Type', value: ({ record }) => this.model.vtypes[record.type] },
        { key: 'avtype', label: 'Aggregate Value Type', value: ({ record }) => this.model.vtypes[record.ctype] },
        { key: 'order', align: 'center' },
        { key: 'fallback', align: 'center', component: '@bool' },
        { key: 'rule' },
      ],
    };
  }

  get tableSpecConstraints(): FlexTableSpec<any> {
    return {
      ident: 'cl-compliance-profile-category-constraints',
      vfill: true,
      rowClasses: [({ record }) => (record.aggregate ? ['table-light', 'table-separate-after'] : [])],
      columns: [
        { key: 'profileName', label: 'Profile Name' },
        { key: 'name', label: 'Constraint Name' },
        mkNumberColumn({
          key: 'object.smin',
          label: 'Single Asset Minimum',
          options: { format: '0,0.00[00]%' },
          action: ({ record }) => this.willChangePercentage(record.object, 'smin'),
        }),
        mkNumberColumn({
          key: 'object.smax',
          label: 'Single Asset Maximum',
          options: { format: '0,0.00[00]%' },
          action: ({ record }) => this.willChangePercentage(record.object, 'smax'),
        }),
        mkNumberColumn({
          key: 'object.lmin',
          label: 'Group Minimum',
          options: { format: '0,0.00[00]%' },
          action: ({ record }) => this.willChangePercentage(record.object, 'lmin'),
        }),
        mkNumberColumn({
          key: 'object.lmax',
          label: 'Group Maximum',
          options: { format: '0,0.00[00]%' },
          action: ({ record }) => this.willChangePercentage(record.object, 'lmax'),
        }),
      ],
    };
  }

  @action willChangePercentage(constraint: any, ptype: any) {
    // Attempt to get the new value:
    const newValue = prompt('Enter new percentage');

    // Check the value:
    if (isEmpty(newValue)) {
      this.flashMessages.warning('Cancelled.');
      return;
    }

    try {
      // Convert the value to decimal:
      // @ts-expect-error
      const value = new Decimal(newValue).div(100);

      // Set the value:
      set(constraint, ptype, parseFloat(value.toFixed()));

      // Save the data:
      this.ajax
        .request('compliance/', { method: 'POST', dataType: 'json', data: JSON.stringify(this.model.data) })
        .then(() => {
          // Inform the user:
          this.flashMessages.success('Successfully saved.');

          // Reset the model data:
          this.send('refreshModel');
        })
        .catch((error) => {
          console.error(error);
          this.flashMessages.danger('Error while saving data.');
        });
    } catch (error) {
      console.error(error);
      this.flashMessages.danger('Invalid value');
    }
  }
}
