import { Service } from '../-service';
import { PConsolidationItem, PConsolidationQuery, request } from './-internal';

// Export public symbols:
export { PConsolidationQuery, PConsolidationItem };

/**
 * Provides the barista persisted consolidation service.
 */
export class PConsolidationService extends Service {
  /**
   * Retrieves and compiles report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  query(query: PConsolidationQuery): Promise<Array<PConsolidationItem>> {
    return request(this.client, query);
  }
}
