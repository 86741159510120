import { A } from '@ember/array';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { DateType } from 'cafelatte/libs/headless/commons/date-type';
import { Promise } from 'rsvp';
import CLAjaxService from './ajax';

export type ContainerType = 'account' | 'portfolio' | 'institution' | 'team';

export interface Stock {
  artifact: number;
  account: number;
  quantity: number;
}

export interface StockExtended {
  artifact: StockArtifact;
  account: StockAccount;
  quantity: number;
}

export interface StockArtifact {
  id: number;
  guid: string;
  symbol: string;
  name: string;
  ctype: string;
  stype: string | undefined;
}

export interface StockAccount {
  id: number;
  guid: string;
  name: string;
  portfolio: StockAccountPortfolio;
  custodian: StockAccountCustodian;
}

export interface StockAccountPortfolio {
  id: number;
  guid: string;
  name: string;
  team: StockAccountPortfolioTeam;
}

export interface StockAccountPortfolioTeam {
  id: number;
  guid: string;
  name: string;
}

export interface StockAccountCustodian {
  id: number;
  guid: string;
  name: string;
}

interface StockExtendedAPI {
  artifact: number;
  artifact_guid: string;
  artifact_ctype: string;
  artifact_stype: string | null;
  artifact_symbol: string;
  artifact_name: string;

  account: number;
  account_guid: string;
  account_name: string;

  portfolio: number;
  portfolio_guid: string;
  portfolio_name: string;

  team: number;
  team_guid: string;
  team_name: string;

  custodian: number;
  custodian_guid: string;
  custodian_name: string;

  quantity: number;
}

function compileExtendedStock(stock: StockExtendedAPI): StockExtended {
  return {
    artifact: {
      id: stock.artifact,
      guid: stock.artifact_guid,
      ctype: stock.artifact_ctype,
      stype: stock.artifact_stype || undefined,
      symbol: stock.artifact_symbol,
      name: stock.artifact_name,
    },
    account: {
      id: stock.account,
      guid: stock.account_guid,
      name: stock.account_name,
      portfolio: {
        id: stock.portfolio,
        guid: stock.portfolio_guid,
        name: stock.portfolio_name,
        team: {
          id: stock.team,
          guid: stock.team_guid,
          name: stock.team_name,
        },
      },
      custodian: {
        id: stock.custodian,
        guid: stock.custodian_guid,
        name: stock.custodian_name,
      },
    },
    quantity: stock.quantity,
  };
}

export interface StocksQuery {
  date: string;
  type: 'commitment' | 'settlement';
  zero: boolean;
  artifacts: Array<number | string>;
  containers: Array<number | string>;
  containerType: 'account' | 'portfolio' | 'team' | 'institution';
}

function compileQuery(query: StocksQuery, rich: boolean): Record<string, any> {
  // Define the return value:
  const retval: Record<string, any> = {};

  // Add query parameters:
  retval.date = query.date;
  retval.type = query.type;
  retval.zero = query.zero;
  retval.rich = rich;
  retval.c = query.containerType;
  if (query.artifacts.length > 0) {
    retval['a'] = query.artifacts;
  }
  if (query.containers.length > 0) {
    retval['i'] = query.containers;
  }

  // Done return:
  return retval;
}

/**
 * Defines the comparison buffer key type.
 */
export type ComparisonKey = string;

/**
 * Defines the comparison instance for a given artifact and account.
 */
export interface Comparison {
  artifact: StockArtifact;
  account: StockAccount;
  a: number;
  b: number;
  diff: number;
}

/**
 * Defines a type alias for the comparison buffer.
 */
export type ComparisonBuffer = Map<ComparisonKey, Comparison>;

/**
 * Creates a comparison buffer key from a given extended stock.
 *
 * @param x An extended stock instance.
 * @returns Comparison buffer key.
 */
export function makeComparisonKey(x: StockExtended): ComparisonKey {
  return `${x.artifact.id}/${x.account.id}`;
}

/**
 * Compiles a comparison buffer by aligning two given stocks information.
 *
 * @param a Stocks 1.
 * @param b Stocks 2.
 * @returns Comparison buffer.
 */
export function compileComparisonBuffer(a: Stocks, b: Stocks): ComparisonBuffer {
  // Create the repository:
  const repository = new Map<ComparisonKey, Comparison>();

  // Iterate over stocks A:
  a.records.forEach((x) => {
    // Get the key:
    const key = makeComparisonKey(x);

    // Update repository:
    let item = repository.get(key);

    // If we don't have the element, create it:
    if (item === undefined) {
      item = { artifact: x.artifact, account: x.account, a: 0, b: 0, diff: 0 };
      repository.set(key, item);
    }

    item.a = x.quantity;
    item.diff = item.b - item.a;
  });

  // Iterate over stocks B:
  b.records.forEach((x) => {
    // Get the key:
    const key = makeComparisonKey(x);

    // Update repository:
    let item = repository.get(key);

    // If we don't have the element, create it:
    if (item === undefined) {
      item = { artifact: x.artifact, account: x.account, a: 0, b: 0, diff: 0 };
      repository.set(key, item);
    }

    item.b = x.quantity;
    item.diff = item.b - item.a;
  });

  return repository;
}

/**
 * Provides encoding for stock records.
 */
export interface Stocks {
  date: string;
  records: Array<StockExtended>;
}

export function getComparisons(buffer: ComparisonBuffer, zeros: boolean): Array<Comparison> {
  return Array.from(buffer.values())
    .filter((x) => zeros || x.diff != 0)
    .sortBy('artifact.ctype', 'artifact.name', 'artifact.symbol', 'account.portfolio.name', 'account.name');
}

/**
 * Provides an stocks retrieval service.
 */
export default class StockService extends Service {
  @service declare ajax: CLAjaxService;

  fetch(query: StocksQuery): Promise<Array<Stock>> {
    return this.ajax.request('stocks/', { data: compileQuery(query, false), traditional: true });
  }

  fetchExtended(query: StocksQuery): Promise<Array<StockExtended>> {
    return this.ajax
      .request('stocks/', { data: compileQuery(query, true), traditional: true })
      .then((data) => data.map(compileExtendedStock));
  }

  fetchComparison(
    date1: string,
    date2: string,
    type: DateType,
    containerType: ContainerType,
    containers: Array<number | string>,
    artifacts: Array<number | string>,
    zeros: boolean
  ): Promise<Array<Comparison>> {
    // If parametrs are missing, return:
    if (!date1 || !date2 || !type || !containerType || !containers || !containers.length) {
      return Promise.resolve([]);
    }

    // Get promises for the both stocks retrieval:
    const a = this.fetchExtended({ date: date1, zero: false, type, artifacts, containers, containerType });
    const b = this.fetchExtended({ date: date2, zero: false, type, artifacts, containers, containerType });

    // Attempt to resolve both promises and get comparisons:
    return Promise.all([a, b]).then(([s1, s2]) =>
      getComparisons(compileComparisonBuffer({ date: date1, records: s1 }, { date: date2, records: s2 }), zeros)
    );
  }

  /**
   * Provides the main workhorse for retrieving inventories.
   * @deprecated
   */
  retrieve(
    artifacts = null,
    container = null,
    ids = null,
    date = null,
    type = 'commitment',
    zero = false
  ): Array<number> {
    // Define the return value:
    const retval = A([]);

    // Define the stocks query:
    const query: Record<string, any> = { type, zero, rich: true };

    // Add artifacts if defined:
    if (!isEmpty(artifacts)) {
      query.a = artifacts;
    }

    // Add the container if defined:
    if (!isEmpty(container)) {
      query.c = container;
    }

    // Add container element identifiers if defined:
    if (!isEmpty(ids)) {
      query.i = ids;
    }

    // Add the date if defined:
    if (!isEmpty(date)) {
      query.date = date;
    }

    // Get the stocks:
    this.ajax.request('stocks/', { data: query, traditional: true }).then((data) => {
      retval.pushObjects(data);
    });

    // Return the stocks:
    return retval;
  }
}
