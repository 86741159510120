import PortfolioGroupModel from 'cafelatte/models/portfoliogroup';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<PortfolioGroupModel> {
  get spec(): FlexTableSpec<PortfolioGroupModel> {
    return {
      ident: 'cl-portfolio-groups-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id', component: '@link', options: { route: 'portfoliogroup.details', modelPath: 'id' } },
        { key: 'name', component: '@link', options: { route: 'portfoliogroup.details', modelPath: 'id' } },
        { key: 'control', align: 'center' },
        {
          key: 'portfoliosCount',
          label: 'Portfolios',
          align: 'right',
          component: '@number',
          options: { format: '0,0' },
        },
        {
          key: 'membersCount',
          label: 'Members',
          align: 'right',
          component: '@number',
          options: { format: '0,0' },
        },
        {
          key: 'uniqueMemberDomains',
          label: 'Domains',
          value: ({ record }) => record.uniqueMemberDomains?.join(),
        },
        ...mkAuditFields<PortfolioGroupModel>(this.me),
      ],
    };
  }
}
