import Model, { attr } from '@ember-data/model';

export default class TradingTypeModel extends Model {
  @attr()
  name!: string;

  @attr()
  description!: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trading/type': TradingTypeModel;
  }
}
