import Model, { attr, belongsTo } from '@ember-data/model';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import AccountModel from '../account';
import { BelongsTo, DecafAuditFields, makeValidators } from '../_internal/machinery';

const _fields = ['started', 'account', 'value', 'rebate', 'style'];

export default class AccountManagementFeeSchemeModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr({ defaultValue: today })
  declare started: string;

  @belongsTo('account', { inverse: 'mfpercentages' })
  declare account?: BelongsTo<AccountModel>;

  @attr('number', { defaultValue: 0.01 })
  declare value: number;

  @attr('number', { defaultValue: 0 })
  declare rebate: number;

  @attr({ defaultValue: 'AUM' })
  declare style: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account/mfeescheme': AccountManagementFeeSchemeModel;
  }
}
