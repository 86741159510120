import Model, { attr } from '@ember-data/model';
import { DecafAuditFields } from './_internal/machinery';

export default class ComplianceCheckModel extends Model.extend(DecafAuditFields) {
  @attr()
  declare date?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    compliancecheck: ComplianceCheckModel;
  }
}
