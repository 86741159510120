import { gql } from '@decafhub/decaf-client';

const query = gql`
  query ($ids: [Int!]) {
    records: team(where: { id: { _in: $ids } }) {
      id
      label: name
    }
  }
`;

export default query;
