import { inject as service } from '@ember/service';
import { CommonRoute } from 'cafelatte/libs/embered';
import DecafService from 'cafelatte/services/decaf';

export default class extends CommonRoute {
  @service declare decaf: DecafService;

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);

    // Make sure the we have private configuration:
    this.checkKonfigChanged();
  }

  model() {
    // Get beanbag from the configuration:
    const beanbag = this.konfig.konfiguration.APP.privateConfiguration?.beanbag;

    // Create catalogue and return:
    return beanbag ? this.decaf.services.beanbag.getBeanbagCatalogue(beanbag) : [];
  }

  checkKonfigChanged() {
    if (this.konfig.konfiguration.APP.privateConfiguration) {
      this.refresh();
    } else {
      setTimeout(() => this.checkKonfigChanged(), 1000);
    }
  }
}
