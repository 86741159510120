import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { CommonController } from 'cafelatte/libs/embered';
import AccountModel from 'cafelatte/models/account';

export default class extends CommonController {
  @action onSave(record: AccountModel): void {
    this.router.transitionTo('account.details.profile', record.id);
  }

  @action onError(_message: string, error: any): void {
    console.error(error);
  }

  @action onCancel(_record: AccountModel, _reverted: boolean): void {
    if (isEmpty(this.model.id)) {
      this.router.transitionTo('account');
    }
  }

  @action onDelete(): void {
    this.router.transitionTo('account');
  }
}
