import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import CLAjaxService from 'cafelatte/services/ajax';
import AgentModel from './agent';
import ArtifactModel from './resource';
import ActionTypeModel from './trade/type';
import TradeOrderAllocationModel from './tradeorderallocation';
import TradeOrderGroup from './tradeordergroup';
import TradeTeamModel from './tradeteam';
import TradeDirectionModel from './trading/direction';
import TradingStateModel from './trading/state';
import TradeTimeToLiveModel from './trading/ttl';
import TradingTypeModel from './trading/type';
import { BelongsTo, DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = [
  'team',
  'group',
  'date',
  'validuntil',
  'time',
  'instructed',
  'cancelled',
  'ctype',
  'otype',
  'ttype',
  'trader',
  'resmain',
  'resaltn',
  'direction',
  'pxmain',
  'pxfact',
  'pxaltn',
  'pxflip',
];

export default class TradeOrderModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @service declare ajax: CLAjaxService;

  @belongsTo('tradeteam')
  declare team?: BelongsTo<TradeTeamModel>;

  @belongsTo('tradeordergroup')
  declare group?: BelongsTo<TradeOrderGroup>;

  @attr({ defaultValue: today })
  declare date: string;

  @attr()
  declare time?: string;

  @attr()
  declare validuntil?: string;

  @belongsTo('trade/type')
  declare ctype?: BelongsTo<ActionTypeModel>;

  @belongsTo('trading/type')
  declare otype?: BelongsTo<TradingTypeModel>;

  @belongsTo('trading/ttl')
  declare ttype?: BelongsTo<TradeTimeToLiveModel>;

  @belongsTo('trading/direction')
  declare direction?: BelongsTo<TradeDirectionModel>;

  @belongsTo('agent')
  declare trader?: BelongsTo<AgentModel>;

  @belongsTo('resource')
  declare resmain?: BelongsTo<ArtifactModel>;

  @belongsTo('resource')
  declare resaltn?: BelongsTo<ArtifactModel>;

  @attr('number', { defaultValue: 1 })
  declare pxmain: number;

  @attr('number', { defaultValue: 1 })
  declare pxfact: number;

  @attr('number')
  declare pxaltn?: number;

  @attr('boolean', { defaultValue: false })
  declare pxflip: boolean;

  @attr()
  declare notes?: string;

  @attr()
  declare remarks?: string;

  @attr()
  declare instructed?: string;

  @attr('number')
  declare allocated?: number;

  @attr('number')
  declare cancelled?: number;

  @attr('number')
  declare quantity?: number;

  @attr('number')
  declare executed?: number;

  @attr('number')
  declare outstanding?: number;

  @attr()
  declare narrative?: string;

  @belongsTo('trading/state')
  declare cstate?: BelongsTo<TradingStateModel>;

  @hasMany('tradeorderallocation', { inverse: 'order' })
  declare allocations?: HasMany<TradeOrderAllocationModel>;

  @computed('allocations')
  get allocationCount(): number {
    // @ts-expect-error
    return this.hasMany('allocations').ids().length;
  }

  @computed('allocations.@each.monetary')
  get monetary(): number {
    // Initialize the value:
    let retval = 0;

    // Iterate over allocations and compute the value:
    this.allocations?.forEach((a: any) => {
      retval += parseFloat(a.get('monetary.qty') || 0);
    });

    // Done, return:
    return retval;
  }

  // TODO: Refactor for return value type (or get rid of the method itself).
  @computed('id')
  get instructions(): object {
    // @ts-expect-error
    return DS.PromiseObject.create({
      promise: this.ajax.request(`tradeorders/${this.id}/instructions/?as=md`, {
        dataType: 'text',
      }),
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tradeorder: TradeOrderModel;
  }
}
