import SharingModel from 'cafelatte/models/sharing';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../../commons/record/list';

export default class extends RecordListComponent<SharingModel> {
  get spec(): FlexTableSpec<SharingModel> {
    return {
      ident: 'cl-institution-sharing-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'started', component: '@date', options: { format: 'DD-MM-YYYY' } },
        {
          key: 'percentage',
          label: 'Sharing %',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]%' },
        },
        {
          key: 'kickback',
          label: 'Rebate %',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]%' },
        },
        ...mkAuditFields<SharingModel>(this.me),
      ],
    };
  }
}
