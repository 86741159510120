// @ts-expect-error
import StorageArray from 'ember-local-storage/local/array';

/**
 * Create the cultulator history.
 */
const CultulatorHistory = StorageArray.extend();

/**
 * Defines the cultulator history class.
 */
CultulatorHistory.reopenClass({
  /**
   * Defines the initial state.
   */
  initialState() {
    return [];
  },
});

export default CultulatorHistory;
// XREVIEW=TODO
// TODO: Can we provide typesafety?
