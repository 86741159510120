import axios from 'axios';
import environment from 'cafelatte/config/environment';
import { CafelatteConfiguration, Konfiguration, PrivateConfiguration, PublicConfiguration } from './-types';

/**
 * Attempts to inject remote public configurations into the global
 * [[Konfiguration]] (environment) and returns the updated global
 * [[Konfiguration]] (environment).
 *
 * @returns [[Konfiguration]] that is updated with remote public configurations.
 */
export async function injectPublicConfiguration(): Promise<Konfiguration> {
  // Get the DECAF Barista API URL:
  const barista = environment.APP.API_BASEURL;

  // Define remote API call for retrieving remote global public configuration:
  const publicConfCall = axios.get<PublicConfiguration>(`${barista}/conf/public/`).then((r) => r.data);

  // Define remote API call for retrieving remote tenant-specific cafelatte configuration:
  const cafelatteConfCall = axios.get<CafelatteConfiguration>(`${barista}/webapps/cafelatte/`).then((r) => r.data);

  // Attempt to get all remote public configurations:
  const [publicConf, cafelatteConf] = await Promise.all([publicConfCall, cafelatteConfCall]);

  // Inject configurations:
  environment.APP.publicConfiguration = publicConf;
  environment.APP.cafelatteConfiguration = cafelatteConf;

  // Return new environment:
  return environment;
}

/**
 * Attempts to inject remote private configuration into the global
 * [[Konfiguration]] (environment) and returns the updated global
 * [[Konfiguration]] (environment).
 *
 * @returns [[Konfiguration]] that is updated with remote private configuration.
 */
export async function injectPrivateConfiguration(token: string): Promise<Konfiguration> {
  // Get the DECAF Barista API URL:
  const barista = environment.APP.API_BASEURL;

  // Define headers:
  const headers = { Authorization: `Token ${token}` };

  // Define remote API call for retrieving remote global public configuration:
  const conf = await axios.get<PrivateConfiguration>(`${barista}/conf/private/`, { headers }).then((r) => r.data);

  // Inject configuration:
  environment.APP.privateConfiguration = conf;

  // Return new environment:
  return environment;
}

// Re-export everything from types:
export * from './-types';
// Export environment.
export { environment as konfiguration };
