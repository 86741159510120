import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { CommonRoute } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  @service declare router: RouterService;

  model(params: { id: string | number }) {
    return params.id === '__new__' ? this.store.createRecord('account') : this.store.findRecord('account', params.id);
  }

  afterModel(model: any): void {
    if (model.get('isNew')) {
      this.router.transitionTo('account.details.profile', '__new__');
    }
  }
}
