import { isNothing, Maybe } from './maybe';

/**
 * Predicate to check if the value is `Nothing` or an empty string (once trimmed).
 *
 * @param x Value to check.
 * @return `true` if the value is `Nothing` or empty string once trimmed, `false` otherwise.
 */
export function isEmptyString(x: Maybe<string>): boolean {
  return isNothing(x) || x.trim() == '';
}

/**
 * Convenience function to sanitize strings.
 *
 * @param x A string.
 * @return `Nothing` if the trimmed string is empty, trimmed string otherwise.
 */
export function sanitizeString(x: string): Maybe<string> {
  return x.trim() || undefined;
}
