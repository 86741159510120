import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  queryParams = ['prcode'];

  @tracked prcode = '';
  @tracked password1 = '';
  @tracked password2 = '';

  @action resetPassword() {
    // Get slots:
    const { prcode, password1, password2 } = this;

    // Cleanup password data:
    this.password1 = this.password2 = '';

    // Reset the password and handle the response:
    this.ajax
      .request('/users/passwordreset/', {
        method: 'POST',
        dataType: 'json',
        data: {
          code: prcode,
          password1: password1,
          password2: password2,
        },
      })
      .then(() => {
        // Inform the user that the password reset was successful
        // and she can proceed:
        this.flashMessages.success('Your password is reset. You can login now.');

        // Redirect to the login route for convenience:
        this.router.transitionTo('auth.login');
      })
      .catch((errors) => {
        // Log errors to the console for debugging:
        console.error(errors);

        // Inform the user that there was a problem:
        this.flashMessages.danger('An error occured. Try again...');
      });
  }
}
