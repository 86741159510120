import CLSelectControl from '../cl-select/component';

export default class extends CLSelectControl<string> {
  get choices() {
    return [
      {
        value: 'commitment',
        label: 'Trade Date',
      },
      {
        value: 'settlement',
        label: 'Value Date',
      },
    ];
  }
}
