import Model, { attr, belongsTo } from '@ember-data/model';
import SettlementModel from '../settlement';
import TeamModel from '../team';
import { BelongsTo, DecafAuditFields, makeValidators } from '../_internal/machinery';

const _fields = ['settlement', 'team', 'amount'];

export default class SettlementBreakdownModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @belongsTo('settlement')
  settlement?: BelongsTo<SettlementModel>;

  @belongsTo('team')
  team?: BelongsTo<TeamModel>;

  @attr('number')
  amount?: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'settlement/breakdown': SettlementBreakdownModel;
  }
}
