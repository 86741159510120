import { action } from '@ember/object';
import { CommonController } from 'cafelatte/libs/embered';

export default class extends CommonController {
  @action cancel() {
    this.model.rollbackAttributes();
    this.router.transitionTo('trading.tradeteam');
  }

  @action save() {
    this.model.save().then(
      () => this.router.transitionTo('trading.tradeteam'),
      () => console.error('Error while saving data.')
    );
  }
}
