import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { CommonController } from 'cafelatte/libs/embered';
import ArtifactModel from 'cafelatte/models/resource';
import DecafService from 'cafelatte/services/decaf';

export default class extends CommonController {
  @service declare decaf: DecafService;

  @action onSave(artifact: ArtifactModel) {
    this.router.transitionTo('resource.details', artifact.id);
  }

  @action onError() {
    console.error('error');
  }

  // TODO: Redirect?
  @action onCancel() {
    console.log('cancelled');
  }

  @action onDelete() {
    this.router.transitionTo('resource');
  }

  @action toggleWatch() {
    this.decaf.client.barista
      .post(`/resources/${this.model.id}/watch/`, { status: !this.model.watched?.status })
      .then(() => {
        this.model.notifyPropertyChange('watched');
      });
  }
}
