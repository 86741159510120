import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import Store from '@ember-data/store';
import EmberArray from '@ember/array';
import EmberObject, { computed, get, set } from '@ember/object';
import { alias, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { SDate, today } from 'cafelatte/libs/headless/prelude/datetime';
import DecafService from 'cafelatte/services/decaf';
import AccountModel from './account';
import AccrualScheduleModel from './accrualschedule';
import CountryModel from './country';
import OHLCSeriesModel from './ohlc';
import PortfolioGroupModel from './portfoliogroup';
import RiskProfileModel from './riskprofile';
import ShareClassModel from './shareclass';
import TeamModel from './team';
import { BelongsTo, createEmptyPromiseArray, DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = [
  'name',
  'isin',
  'domicile',
  'inception',
  'rccy',
  'sandbox',
  'manager',
  'objective',
  'team',
  'benchmark',
  'riskprofile',
  'data_source',
];

export default class PortfolioModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @service declare decaf: DecafService;
  @service declare store: Store;

  @attr()
  declare name?: string;

  @attr()
  declare isin?: string;

  @belongsTo('country')
  declare domicile?: BelongsTo<CountryModel>;

  @attr()
  declare inception?: string;

  @attr()
  declare rccy?: string;

  @attr('boolean', { defaultValue: false })
  declare sandbox: boolean;

  @attr()
  declare advisor?: string;

  @attr()
  declare asset_manager?: string;

  @attr()
  declare manager?: string;

  @attr()
  declare marketing_agent?: string;

  @attr()
  declare mandate_profile?: string;

  @attr()
  declare objective?: string;

  @belongsTo('team')
  declare team?: BelongsTo<TeamModel>;

  @belongsTo('ohlc')
  declare benchmark?: BelongsTo<OHLCSeriesModel>;

  @belongsTo('riskprofile')
  declare riskprofile?: BelongsTo<RiskProfileModel>;

  @attr()
  declare data_source?: string;

  @hasMany('account')
  declare accounts?: HasMany<AccountModel>;

  @hasMany('shareclass')
  declare shareclasses?: HasMany<ShareClassModel>;

  @hasMany('portfoliogroup', { inverse: 'portfolios' })
  declare groups?: HasMany<PortfolioGroupModel>;

  @hasMany('accrualschedule')
  declare accrualschedules?: HasMany<AccrualScheduleModel>;

  @notEmpty('shareclasses')
  declare isFund: boolean;

  @alias('name')
  declare label?: string;

  @computed('isNew', 'id')
  get pgs() {
    return get(this, 'isNew')
      ? createEmptyPromiseArray()
      : this.store.query('portfoliogroup', { portfolios: this.id, page_size: -1 });
  }

  // TODO: Get rid of this function. We can do this on the call-site.
  // TODO: Learn how to deal with DS.PromiseArray#map return values.
  @computed('pgs.[]')
  get pgNames(): EmberArray<string | undefined> {
    return this.pgs.map((x: any) => get(x, 'name'));
  }

  @computed('id')
  get valuation(): EmberObject | null {
    // Can we?
    if (isEmpty(this.id)) {
      return null;
    }

    // Define the return value:
    const retval = EmberObject.create({});

    // Get consolidation:
    this.decaf.services.consolidationReport
      .getPortfolioReport({ date: today(), datetype: 'commitment', portfolio: this.id, currency: this.rccy || 'USD' })
      .then((x) => retval.setProperties(x));

    // Done, return:
    return retval;
  }

  @computed('id', 'rccy')
  get quickvaluation() {
    // Can we?
    if (isEmpty(this.id) || isEmpty(this.rccy)) {
      return undefined;
    }

    // Define the return value:
    const retval = EmberObject.create({});

    // Get consolidation:
    this.decaf.services.consolidationReport
      .requestQuickValuation({
        date: today(),
        datetype: 'commitment',
        elements: { container: 'portfolio', instances: [this.id] },
        currency: this.rccy || 'USD',
        sandbox: 'include',
      })
      .then((x) => retval.setProperties(x));

    // Done, return:
    return retval;
  }

  @computed('id')
  get last() {
    if (isEmpty(this.id)) {
      return undefined;
    }

    const retval = EmberObject.create({ tx: undefined } as {
      tx: { id: number; commitment: SDate; action: number } | undefined;
    });

    this.decaf.services.portfolios.getLastQuant(this.id).then((x) => set(retval, 'tx', x));

    return retval;
  }

  @computed('id')
  get watched() {
    if (isEmpty(this.id)) {
      return undefined;
    }
    const retval = EmberObject.create({ status: false } as { status: boolean });

    this.decaf.client.barista.get(`/portfolios/${this.id}/watch/`).then(({ data }) => {
      set(retval, 'status', data.status);
    });
    return retval;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    portfolio: PortfolioModel;
  }
}
