import Model, { attr, hasMany } from '@ember-data/model';
import EmberObject, { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import CLAjaxService from 'cafelatte/services/ajax';
import AccountModel from './account';
import SharingModel from './sharing';
import TeamModel from './team';
import { DecafAuditFields, ExecutiveSummary, HasMany, makeValidators } from './_internal/machinery';

const _fields = ['name'];

export default class InstitutionModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @service declare ajax: CLAjaxService;

  @attr()
  declare name?: string;

  @attr('number')
  declare sharing?: number;

  @attr('number')
  declare kickback?: number;

  @hasMany('account')
  declare accounts?: HasMany<AccountModel>;

  @hasMany('sharing')
  declare sharings?: HasMany<SharingModel>;

  @alias('name')
  label?: string;

  @computed('accounts')
  get accountsCount(): number {
    // @ts-expect-error
    return this.hasMany('accounts').ids().length;
  }

  // TODO: Refactor this method into a service method or lib function.
  executiveSummary(team: TeamModel, asof: string, refccy: string): ExecutiveSummary {
    // Declare the return value:
    const retval: ExecutiveSummary = EmberObject.create({
      pending: 0,
      settled: 0,
      totaled: 0,
      nav: 0,
      aum: 0,
      roi: 0,
    });

    // Get the executive from remote:
    team
      .get('ajax')
      .request('institutions/' + team.get('id') + '/executive/', {
        data: { refccy: refccy, asof: asof },
      })
      .then((data: ExecutiveSummary) => {
        set(retval, 'pending', data.pending);
        set(retval, 'settled', data.settled);
        set(retval, 'totaled', data.totaled);
        set(retval, 'nav', data.nav);
        set(retval, 'aum', data.aum);
        set(retval, 'roi', Math.abs(data.aum) < 1 ? null : data.totaled / data.aum);
      });

    // Done, return:
    return retval;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    institution: InstitutionModel;
  }
}
