import { Service } from '../-service';
import { getCategoriesList, getGroupsList, getTypeList } from './-internal';

/**
 * Provides a document service.
 */
export class DocumentService extends Service {
  /**
   * Returns available document types.
   *
   * @returns List of available document types.
   */
  getTypes(): Promise<string[]> {
    return getTypeList(this.client);
  }

  /**
   * Returns available document categories.
   *
   * @returns List of available document categories.
   */
  getCategories(): Promise<string[]> {
    return getCategoriesList(this.client);
  }

  /**
   * Returns available document groups.
   *
   * @returns List of available document groups.
   */
  getGroups(): Promise<string[]> {
    return getGroupsList(this.client);
  }
}
