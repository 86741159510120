import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import StockService from 'cafelatte/services/stocks';

export default class extends CommonController {
  @service declare stocks: StockService;

  @tracked date = today();

  get accountStocks() {
    return this.stocks.fetchExtended({
      date: this.date,
      type: 'commitment',
      zero: false,
      artifacts: [],
      containers: [this.model.id],
      containerType: 'account',
    });
  }

  get links(): { text: string; route: string; params: Record<string, any> }[] {
    const { id, rccy } = this.model;
    const date = this.date;
    const c = 'account';
    const type = 'commitment';

    return [
      { text: 'Consolidation', route: 'reports.consolidation', params: { c, i: id, ccy: rccy, type, date } },
      { text: 'Trades', route: 'trade', params: { acc_any: id, commitment__lte: date } },
      { text: 'Transactions', route: 'quant', params: { account: id, commitment__lte: date } },
      { text: 'Ledgers', route: 'ledger', params: { accounts: id, datespanMax: date } },
    ];
  }
}
