import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { FlexTableColumnSpec, FlexTableSpec } from '../components/x/flex-table';

function mkMoneyColumn({ key, label }: { key: string; label: string }): FlexTableColumnSpec<any> {
  return { key, label, align: 'right', component: '@number', options: { format: '0,0.00', colorize: true } };
}

function mkPriceColumn({ key, label }: { key: string; label: string }): FlexTableColumnSpec<any> {
  return { key, label, align: 'right', component: '@number', options: { format: '0,0.00[00]', colorize: true } };
}

function mkPercentageColumn({ key, label }: { key: string; label: string }): FlexTableColumnSpec<any> {
  return { key, label, align: 'right', component: '@number', options: { format: '0,0.00%', colorize: true } };
}

export default class extends CommonController {
  queryParams = ['portfolio', 'ccy', 'asof', 'dtype'];

  @tracked ccy = 'USD';
  @tracked asof = today();
  @tracked dtype = 'commitment';
  @tracked portfolio: any = undefined;

  get spec(): FlexTableSpec<any> {
    return {
      ident: 'cl-pnlbook',
      vfill: true,
      columns: [
        { key: 'code', label: 'Symbol', action: ({ record }) => this.gotoInstrument(record) },
        { key: 'name', action: ({ record }) => this.gotoInstrument(record) },
        { key: 'atype', label: 'Type' },
        { key: 'country' },
        { key: 'sector' },
        {
          key: 'quantity',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[00]', colorize: true },
          action: ({ record }) => this.gotoLedger(record),
        },
        { key: 'ccyorg', label: 'CCY' },
        mkMoneyColumn({ key: 'valuation.net_value_ref.qty', label: 'NAV (Ref)' }),
        mkMoneyColumn({ key: 'valuation.abs_value_ref.qty', label: 'AuM (Ref)' }),
        mkMoneyColumn({ key: 'valuation.net_exposure_ref.qty', label: 'Exposure (Ref)' }),
        mkPriceColumn({ key: 'investment.px_org.qty', label: 'PX Cost (Org)' }),
        mkPriceColumn({ key: 'investment.px_ref.qty', label: 'PX Cost (Ref)' }),
        mkPriceColumn({ key: 'valuation.px_org.qty', label: 'PX Last (Org)' }),
        mkPriceColumn({ key: 'valuation.px_ref.qty', label: 'PX Last (Ref)' }),
        mkPriceColumn({ key: 'pnl_statement_yesterday.px_cost_org.qty', label: 'PX T-1 (Org)' }),
        mkPriceColumn({ key: 'pnl_statement_yesterday.px_cost_ref.qty', label: 'PX T-1 (Ref)' }),
        mkPercentageColumn({ key: 'pnl_statement_yesterday.px_change_org', label: 'Δ PX T-1 (Org)' }),
        mkPercentageColumn({ key: 'pnl_statement_yesterday.px_change_ref', label: 'Δ PX T-1 (Ref)' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_realized_org.qty', label: 'Realized PNL T-1 (Org)' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_realized_ref.qty', label: 'Realized PNL T-1 (Ref)' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_unrealized_org.qty', label: 'Unrealized PNL T-1 (Org)' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_unrealized_ref.qty', label: 'Unrealized PNL T-1 (Ref)' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_total_org.qty', label: 'Total PNL T-1 (Org)' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_total_ref.qty', label: 'Total PNL T-1 (Ref)' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_bps_realized', label: 'Realized PNL T-1 BPS' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_bps_unrealized', label: 'Unrealized PNL T-1 BPS' }),
        mkMoneyColumn({ key: 'pnl_statement_yesterday.pnl_bps_total', label: 'Total PNL T-1 BPS' }),
        mkPriceColumn({ key: 'pnl_statement_month_start.px_cost_org.qty', label: 'PX Month Start (Org)' }),
        mkPriceColumn({ key: 'pnl_statement_month_start.px_cost_ref.qty', label: 'PX Month Start (Ref)' }),
        mkPercentageColumn({ key: 'pnl_statement_month_start.px_change_org', label: 'Δ PX MTD (Org)' }),
        mkPercentageColumn({ key: 'pnl_statement_month_start.px_change_ref', label: 'Δ PX MTD (Ref)' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_realized_org.qty', label: 'Realized PNL MTD (Org)' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_realized_ref.qty', label: 'Realized PNL MTD (Ref)' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_unrealized_org.qty', label: 'Unrealized PNL MTD (Org)' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_unrealized_ref.qty', label: 'Unrealized PNL MTD (Ref)' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_total_org.qty', label: 'Total PNL MTD (Org)' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_total_ref.qty', label: 'Total PNL MTD (Ref)' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_bps_realized', label: 'Realized PNL MTD BPS' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_bps_unrealized', label: 'Unrealized PNL MTD BPS' }),
        mkMoneyColumn({ key: 'pnl_statement_month_start.pnl_bps_total', label: 'Total PNL MTD BPS' }),
      ],
    };
  }

  gotoLedger(record: any) {
    window.open(
      this.router.urlFor('ledger', {
        queryParams: {
          artifact: record.artifact,
          portfolios: this.portfolio,
          datespanMax: this.model.dateasof,
          datetype: this.model.datetype,
        },
      })
    );
  }

  gotoInstrument(record: any) {
    window.open(this.router.urlFor('resource.details', record.artifact));
  }
}
