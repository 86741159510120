import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import DocumentModel from 'cafelatte/models/document';
import { FlexTableRowAction, mkCommonRowActions } from '../components/x/flex-table';

export default class extends ControllerWithCurrentRecord<DocumentModel> {
  queryParams = [
    'page',
    'page_size',
    'search',
    'name__icontains',
    'extref__icontains',
    'date__gte',
    'date__lte',
    'portfolio',
    'account',
    'ctype',
    'category',
    'group',
  ];

  @tracked page = 1;
  @tracked page_size = 100;

  get attachmentOperations(): FlexTableRowAction<DocumentModel>[] {
    return [
      ...(mkCommonRowActions([
        { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
        { type: 'delete', onDelete: this.onDelete },
      ]) as FlexTableRowAction<DocumentModel>[]),
      { icon: 'download', label: 'Download', type: 'success', action: ({ record }): void => record.download() },
    ];
  }

  createNewRecord(): DocumentModel {
    return this.store.createRecord('document');
  }

  @action onSave(_record: DocumentModel): void {
    this.doResetCurrentRecordRefreshModel();
    this.flashMessages.success('Record saved successfully.');
  }

  @action onError(message: string, error: any): void {
    console.error(error);
    this.flashMessages.danger(message);
  }

  @action onCancel(_record: DocumentModel, _reverted: boolean): void {
    this.doResetCurrentRecordRefreshModel();
  }

  @action onDelete(): void {
    this.doResetCurrentRecordRefreshModel();
    this.flashMessages.warning('Record is deleted successfully');
  }
}
