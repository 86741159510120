import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ConsolidationReportData } from 'cafelatte/libs/headless/services/consolidation-report';
import {
  BaseHolding,
  Holding,
  isTopHolding,
  SubHolding,
} from 'cafelatte/libs/headless/services/consolidation-report/commons/holding';
import { HoldingsNode } from 'cafelatte/libs/headless/services/consolidation-report/commons/holdings-tree';

interface Args {
  report: ConsolidationReportData;
  node: HoldingsNode;
  holding: Holding;
  showAccountNames: boolean;
}

export default class extends Component<Args> {
  @service declare router: RouterService;

  @tracked subsOpen = false;

  generateUrl(route: string, params: Record<string, string | number>): string {
    return this.router.urlFor(route, { queryParams: params });
  }

  noPriceArtifactTypes = ['CCY', 'DEPO', 'LOAN'];

  @action goToLedger(holding: Holding | SubHolding) {
    // Get the artifact id:
    const artifactId = holding.artifact.id;

    // Get account IDs:
    let accountIds;
    if (isTopHolding(holding)) {
      accountIds = holding.accounts.map((x) => x.id);
    } else {
      accountIds = [holding.account.id];
    }

    // Construct the url:
    const url = this.router.urlFor('ledger', {
      queryParams: {
        artifact: artifactId,
        accounts: accountIds.join(','),
        datespanMax: this.args.report.date,
        datetype: this.args.report.type,
      },
    });

    // Open the url:
    window.open(url);
  }

  @action goToPrices(holding: BaseHolding) {
    // Get the OHLC symbol:
    const symbol = holding.artifact.ohlccode || holding.artifact.symbol;

    // Get the last date filter value:
    const until = this.args.report.date;

    // Open the url:
    window.open(this.router.urlFor('ohlc.observation', { queryParams: { symbol, until } }));
  }
}
