import { DecafClient, gql } from '@decafhub/decaf-client';
import { action, set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DateType } from 'cafelatte/libs/headless/commons/date-type';
import { Currency, Id } from 'cafelatte/libs/headless/commons/types';
import { sortElements, uniqElements } from 'cafelatte/libs/headless/prelude/arrays';
import { SDate, today } from 'cafelatte/libs/headless/prelude/datetime';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { ConsolidationContainerType } from 'cafelatte/libs/headless/services/consolidation-report/commons/-shared';
import DecafService from 'cafelatte/services/decaf';

export const queryAccountCurrencies = gql`
  query ($ids: [Int!]) {
    currencies: account(where: { id: { _in: $ids } }) {
      currency
    }
  }
`;

export const queryPortfolioCurrencies = gql`
  query ($ids: [Int!]) {
    currencies: portfolio(where: { id: { _in: $ids } }) {
      currency
    }
  }
`;

export interface Query {
  date: Maybe<SDate>;
  datetype: Maybe<DateType>;
  container: Maybe<ConsolidationContainerType>;
  instances: Maybe<Array<Id>>;
  currency: Maybe<Currency>;
  sandbox: Maybe<'include' | 'exclude'>;
}

interface Args extends Query {
  onQuery: (query: Maybe<Query>) => any;
}

function getSelectionCurrencies(
  client: DecafClient,
  container: 'account' | 'portfolio',
  ids: Array<Id>
): Promise<Array<Currency>> {
  // Get the query:
  const query = container == 'account' ? queryAccountCurrencies : queryPortfolioCurrencies;

  // Get and return:
  return client.microlot
    .query({ query, variables: { ids } })
    .then((result) => sortElements(uniqElements(result.data?.currencies.map((x: any) => x.currency) || [])));
}

export default class extends Component<Args> {
  @service declare decaf: DecafService;

  @tracked value = {
    date: this.args.date || today(),
    datetype: this.args.datetype || 'commitment',
    container: this.args.container || 'account',
    instances: [...(this.args.instances || [])],
    currency: this.args.currency || 'USD',
    sandbox: this.args.sandbox || 'include',
  };

  @tracked currencies: Array<Currency> = [];

  _queryChanged() {
    this.args.onQuery(this.value);
  }

  _updateSelectionCurrencies(selections: Array<Id>): Promise<Array<Currency>> {
    // Shall we update selections?
    if (selections.length == 0 || !(this.value.container == 'account' || this.value.container == 'portfolio')) {
      return Promise.resolve([]);
    }

    // Attempt to get selection currencies, update component and return:
    return getSelectionCurrencies(this.decaf.client, this.value.container, selections).then((currencies) => {
      this.currencies = currencies;
      return currencies;
    });
  }

  @action doUpdateSelectionCurrencies() {
    this._updateSelectionCurrencies(this.value.instances);
  }

  @action onChangeContainer(value: ConsolidationContainerType) {
    // Clear instances:
    this.value.instances.clear();

    // Set query properties:
    setProperties(this.value, {
      container: value,
      instances: this.value.instances,
    });

    // Fire event:
    this._queryChanged();
  }

  @action onChangeInstances(value: Array<Id>) {
    this._updateSelectionCurrencies(value).then((currencies) => {
      if (currencies.length > 0) {
        // @ts-expect-error ts2769
        set(this.value, 'currency', currencies[0]);
      }
      this.value.instances.setObjects(value);
      this._queryChanged();
    });
  }

  @action onChangeDate(value: SDate) {
    set(this.value, 'date', value);
    this._queryChanged();
  }

  @action onChangeDateType(value: DateType) {
    set(this.value, 'datetype', value);
    this._queryChanged();
  }

  @action onChangeCurrency(value: Currency) {
    set(this.value, 'currency', value);
    this._queryChanged();
  }

  @action onChangeSandbox(value: 'include' | 'exclude') {
    set(this.value, 'sandbox', value);
    this._queryChanged();
  }
}
