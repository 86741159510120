import { action } from '@ember/object';
import { CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'name__icontains',
    'extref__icontains',
    'date__gte',
    'date__lte',
    'portfolio',
    'account',
    'ctype',
    'category',
    'group',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('document', params);
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
