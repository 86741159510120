// TODO: We may use plain vanilla library module instead of this service.
import Store from '@ember-data/store';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { download } from 'cafelatte/libs/headless/prelude/downloading';
import { Promise } from 'rsvp';
import DecafService from './decaf';

/**
 * Provides the trading service.
 */
export default class TradingService extends Service {
  @service declare decaf: DecafService;
  @service declare store: Store;

  /**
   * Trade types of interest.
   */
  tradeTypes = new Map([
    [60, [['CCY'], ['CCY']]], //  "Spot FX Trade"
    [80, [['SHRE'], ['CCY']]], //  "Share Trade"
    [90, [['ZCPN'], ['CCY']]], //  "Zero Coupon Trade"
    [100, [['BOND'], ['CCY']]], //  "Bond Trade"
    [110, [['OPT'], ['CCY']]], //  "Option Trade"
    [120, [['FXOPT'], ['CCY']]], //  "FX Option Trade"  XXXX
    [130, [['FUT'], ['CCY']]], //  "Future Trade"
    [140, [['FXFUT'], ['CCY']]], //  "FX Future Trade"  XXXX
    [150, [['FWD'], ['CCY']]], //  "Forward Trade" XXXX
    [160, [['FXFWD'], ['CCY']]], //  "FX Forward Trade"
    [170, [['SP'], ['CCY']]], //  "Structured Product Trade"
    [180, [['DCIP'], ['CCY']]], //  "DCI Trade"  XXXX
    [200, [['ELNP'], ['CCY']]], //  "ELN Trade"  XXXX
    [500, [['SPMF'], ['CCY']]], //  "FX Mini-Future Contract Trade"
    [600, [['FXMF'], ['CCY']]], //  "Mini-Future Contract Trade"
  ]);

  /**
   * Trade type keys.
   */
  tradeTypeKeys = Array.from(this.tradeTypes.keys());

  /**
   * Returns the trade type by the given id.
   */
  getTradeType(id: any) {
    return this.tradeTypes.get(parseInt(id));
  }

  /**
   * Creates and returns a new trade order.
   */
  createTradeOrder() {
    // We want to create a trade order with sane defaults.
    //
    // First collect necessary auxiliary data:
    const aux = Promise.all([
      this.store.query('tradeteam', { page_size: 1 }).then((r) => (r ? r.firstObject : null)),
      this.store.query('agent', { page_size: 1, ctype: 'TRADER' }).then((r) => (r ? r.firstObject : null)),
      this.store.findRecord('trade/type', 80),
      this.store.findRecord('trading/type', 1),
      this.store.findRecord('trading/direction', 1),
      this.store.findRecord('trading/ttl', 1),
    ]);

    // With the new promise, try to initialize the trade order instance and return:
    return aux.then(([team, trader, ctype, otype, direction, ttype]) => {
      const order: any = this.store.createRecord('tradeorder', {
        team,
        trader,
        ctype,
        otype,
        direction,
        ttype,
      });

      // Add the first allocation:
      order.get('allocations').addObject(
        this.store.createRecord('tradeorderallocation', {
          order,
          allocated: 1,
        })
      );

      // Done, return the instance:
      return order;
    });
  }

  /**
   * Creates and returns a new trade order for the given group.
   */
  createTradeOrderForGroup(group: any) {
    // We want to create a trade order with sane defaults.
    //
    // First collect necessary auxiliary data:
    const aux = Promise.all([
      this.store.query('tradeteam', { page_size: 1 }).then((r) => (r ? r.firstObject : null)),
      this.store.query('agent', { page_size: 1, ctype: 'TRADER' }).then((r) => (r ? r.firstObject : null)),
      this.store.findRecord('trade/type', 80),
      this.store.findRecord('trading/type', 1),
      this.store.findRecord('trading/direction', 1),
      this.store.findRecord('trading/ttl', 1),
    ]);

    // With the new promise, try to initialize the trade order instance and return:
    return aux.then(([team, trader, ctype, otype, direction, ttype]) => {
      const order: any = this.store.createRecord('tradeorder', {
        team,
        trader,
        ctype,
        otype,
        direction,
        ttype,
        group,
      });

      // Add the first allocation:
      order.get('allocations').addObject(
        this.store.createRecord('tradeorderallocation', {
          order,
          allocated: 1,
        })
      );

      // Done, return the instance:
      return order;
    });
  }

  /**
   * Creates and return a new trade order allocation.
   */
  createTradeOrderAllocation(order: any) {
    return this.store.createRecord('tradeorderallocation', { order });
  }

  /**
   * Creates and return a new trade order execution.
   */
  createTradeOrderExecution(allocation: any) {
    return this.store.createRecord('tradeorderexecution', { allocation });
  }

  /**
   * Attempts to download trade order instructions.
   */
  @action downloadTradeOrderInstructions(record: any, kind: any) {
    // Get the record id:
    const id = record.get('id');

    // Check if applicable:
    if (isEmpty(id)) {
      return;
    }

    // Attempt to download:
    download(
      // @ts-expect-error
      this.decaf.client.barista,
      { url: `tradeorders/${id}/instructions/?as=${kind}` },
      `trader_order_instructions_${id}.${kind}`,
      true
    ).catch(console.error);
  }

  /**
   * Attempts to download trade order group instructions.
   */
  @action downloadTradeOrderGroupInstructions(record: any, kind: any) {
    // Get the record id:
    const id = record.get('id');

    // Check if applicable:
    if (isEmpty(id)) {
      return;
    }

    // Attempt to download:
    download(
      // @ts-expect-error
      this.decaf.client.barista,
      { url: `tradeordergroups/${id}/instructions/?as=${kind}` },
      `trader_order_group_instructions_${id}.${kind}`,
      true
    ).catch(console.error);
  }
}
