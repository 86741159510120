import { Service } from '../-service';
import { SDate } from '../../prelude/datetime';
import { getLastQuant } from './-internal';

/**
 * Provides a service for portfolios.
 */
export class PortfoliosService extends Service {
  getLastQuant(id: string | number): Promise<{ id: number; commitment: SDate; action: number } | undefined> {
    return getLastQuant(this.client, id);
  }
}
