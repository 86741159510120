import InstitutionModel from 'cafelatte/models/institution';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<InstitutionModel> {
  get spec(): FlexTableSpec<InstitutionModel> {
    return {
      ident: 'cl-institution-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'name' },
        { key: 'sharing', align: 'right', component: '@number', options: { format: '0,0.00[0000]%' } },
        {
          key: 'kickback',
          label: 'Rebate',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]%' },
        },
        {
          key: 'accountsCount',
          label: 'No. of Accounts',
          align: 'right',
          component: '@number',
          options: { format: '0,0' },
        },
        ...mkAuditFields<InstitutionModel>(this.me),
      ],
    };
  }
}
