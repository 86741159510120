import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { HistoricalValuationItem } from 'cafelatte/libs/headless/services/historical-valuation';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

// @ts-expect-error
export default class extends RecordListComponent<HistoricalValuationItem> {
  @tracked page = 1;
  @tracked page_size = 20;

  @computed('args.records', 'page', 'page_size')
  get records(): HistoricalValuationItem[] {
    // @ts-expect-error
    return (this.args.records || []).slice((this.page - 1) * this.page_size, this.page * this.page_size);
  }

  get spec(): FlexTableSpec<HistoricalValuationItem> {
    return {
      ident: 'cl-historical-valuations-table',
      vfill: true,
      columns: [
        { key: 'date', label: 'Date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'ccy', label: 'CCY' },
        {
          key: 'portfolio.name',
          label: 'Portfolio',
          action: ({ record }) => this.gotoRoute('portfolio.details', record.portfolio.id),
        },
        {
          key: 'shareclass.name',
          label: 'Share Class',
        },
        { key: 'nav', label: 'NAV', align: 'right', component: '@number', options: { format: '0,0.00' } },
        { key: 'price', align: 'right', component: '@number', options: { format: '0,0.0000' } },
        { key: 'shares', align: 'right', component: '@number', options: { format: '0,0.00[00]' } },
        {
          key: 'internal.nav',
          label: 'NAV (Internal)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00' },
        },
        {
          key: 'internal.price',
          label: 'Price (Internal)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.0000' },
        },
        {
          key: 'internal.shares',
          label: 'Shares (Internal)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[00]' },
        },
        {
          key: 'external.nav',
          label: 'NAV (External)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00' },
        },
        {
          key: 'external.price',
          label: 'Price (External)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.0000' },
        },
        {
          key: 'external.shares',
          label: 'Shares (External)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[00]' },
        },
      ],
    };
  }

  @action gotoRoute(route: string, id: Id): void {
    window.open(this.router.urlFor(route, id));
  }
}
