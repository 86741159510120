import { buildDecafClient, DecafClient } from '@decafhub/decaf-client';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { makeServiceCollection, ServiceCollection } from 'cafelatte/libs/headless/services';
import { Versions } from 'cafelatte/libs/headless/services/versions';
import CLSessionService from './-ts/session';
import KonfigService from './konfig';

/**
 * Provides the DECAF service based on a DECAF API client.
 */
export default class DecafService extends Service {
  @service declare konfig: KonfigService;
  @service declare session: CLSessionService;

  /**
   * DECAF API client.
   */
  @tracked declare client: DecafClient;

  /**
   * DECAF API service collection.
   */
  @tracked declare services: ServiceCollection;

  /**
   * Attempts to retrieve and return the version of remote DECAF deployment.
   */
  get versions(): Promise<Versions> {
    return this.services.versions.get();
  }

  /**
   * Prepare DECAF client and services.
   *
   * @param token Authentication token.
   */
  @action setup(token: string) {
    this.client = buildDecafClient(this.konfig.konfiguration.APP.API_HOST, { token });
    this.services = makeServiceCollection(this.client);
  }
}
