import ShareClassFeeScheduleModel from 'cafelatte/models/scfeeschedule';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../../commons/record/list';

export default class extends RecordListComponent<ShareClassFeeScheduleModel> {
  get spec(): FlexTableSpec<ShareClassFeeScheduleModel> {
    return {
      ident: 'cl-share-class-fee-schedule-list-table',
      actions: this.args.operations || [],
      vfill: true,
      columns: [
        { key: 'id' },
        { key: 'shareclass.portfolio.name', label: 'Fund' },
        { key: 'shareclass.name', label: 'Share Class' },
        { key: 'started', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'mngtstyle', label: 'Mngt Fee Style' },
        { key: 'mngtdcc', label: 'Mngt Fee DCC' },
        {
          key: 'mngtperc',
          label: 'Mngt Fee %',
          align: 'right',
          component: '@number',
          options: { format: '0.00[00]%' },
        },
        { key: 'perfdcc', label: 'Perf Fee DCC' },
        {
          key: 'perfperc',
          label: 'Perf Fee %',
          align: 'right',
          component: '@number',
          options: { format: '0.00[00]%' },
        },
        { key: 'perfhwm', label: 'Perf HWM+', align: 'right', component: '@number', options: { format: '0.00[00]' } },
        {
          key: 'perffact',
          label: 'Perf Factor',
          align: 'right',
          component: '@number',
          options: { format: '0.00[00]' },
        },
        {
          key: 'perfcnst',
          label: 'Perf Constant',
          align: 'right',
          component: '@number',
          options: { format: '0.00[00]' },
        },
        {
          key: 'perfbmrate',
          label: 'Perf BM Rate',
          align: 'right',
          component: '@number',
          options: { format: '0.00[00]' },
        },
        { key: 'perfbmcode', label: 'Perf BM Code' },
        ...mkAuditFields<ShareClassFeeScheduleModel>(this.me),
      ],
    };
  }
}
