import OHLCObservationModel from 'cafelatte/models/ohlc/observation';
import { FlexTableColumnSpec, FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

const numericOptions: Partial<FlexTableColumnSpec<OHLCObservationModel>> = {
  align: 'right',
  component: '@number',
  options: { format: '0,0.00000000' },
};

export default class extends RecordListComponent<OHLCObservationModel> {
  get spec(): FlexTableSpec<OHLCObservationModel> {
    return {
      ident: 'cl-price-list-table',
      actions: this.args.operations || [],
      vfill: true,
      columns: [
        { key: 'id' },
        { key: 'symbol' },
        { key: 'date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'open', ...numericOptions },
        { key: 'high', ...numericOptions },
        { key: 'low', ...numericOptions },
        { key: 'close', ...numericOptions },
        { key: 'volume', ...numericOptions },
        { key: 'interest', label: 'Open Interest', ...numericOptions },
        { key: 'source' },
        { key: 'created', component: '@date', options: { format: 'human' } },
        { key: 'creator.username', label: 'Created By' },
        { key: 'updated', component: '@date', options: { format: 'human' } },
        { key: 'updater.username', label: 'Updated By' },
      ],
    };
  }
}
