import TradeOrderAllocationModel from 'cafelatte/models/tradeorderallocation';
import { FlexTableSpec, mkAuditFields, mkLinkColumn, mkNumberColumn } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../../commons/record/list';

export default class extends RecordListComponent<TradeOrderAllocationModel> {
  get spec(): FlexTableSpec<TradeOrderAllocationModel> {
    const componentValuePercentage = 'c-l/trading/trade-order-allocation/list/vp';
    return {
      ident: 'cl-trade-order-allocation-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        mkLinkColumn('trading.tradeorder.details', 'id', { key: 'order.id', label: 'Trade Order' }),
        mkLinkColumn('account.details', 'account.id', { key: 'account.name', label: 'Internal Account' }),
        { key: 'extacct', label: 'External Account' },
        mkNumberColumn({ key: 'allocated', options: { format: '0,0.00[0000]' } }),
        mkNumberColumn({ key: 'cancelled', options: { format: '0,0.00[0000]' } }),
        mkNumberColumn({ key: 'quantity', options: { format: '0,0.00[0000]' } }),
        mkNumberColumn({ key: 'executed', options: { format: '0,0.00[0000]' } }),
        mkNumberColumn({ key: 'outstanding', options: { format: '0,0.00[0000]' } }),
        mkNumberColumn({ key: 'monetary.qty', label: 'Monetary Value', options: { format: '0,0.00[0000]' } }),
        { key: 'account.ratio', align: 'right', component: componentValuePercentage, options: { of: 'account' } },
        { key: 'portfolio.ratio', align: 'right', component: componentValuePercentage, options: { of: 'portfolio' } },
        { key: 'cstate.name', label: 'State' },
        ...mkAuditFields<TradeOrderAllocationModel>(this.me),
      ],
    };
  }
}
