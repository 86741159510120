// TODO: Refactor this at some point.
import ArrayProxy from '@ember/array/proxy';
import { action, computed, notifyPropertyChange, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { FrequencyOptionsLookup } from 'cafelatte/libs/headless/prelude/frequency';
import ShareClassModel from 'cafelatte/models/shareclass';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

export default class extends CommonController {
  frequencies = FrequencyOptionsLookup;

  @tracked currentShareClass: any = undefined;
  @tracked currentShareClassFeeSchedule: any = undefined;

  @computed('model.id')
  get shareclasses(): DS.PromiseArray<ShareClassModel, ArrayProxy<ShareClassModel>> {
    return this.store.query('shareclass', { portfolio: this.model.id, page_size: -1 });
  }

  @computed('shareclass.[]')
  get feeschedules() {
    return this.store.query('scfeeschedule', { shareclass__portfolio: this.model.id, page_size: -1 });
  }

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.setCurrentShareClass(record) },
      { type: 'delete', onDelete: this.onShareClassDelete },
    ]);
  }

  get feeschedulesTableOperations() {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.setCurrentShareClassFeeSchedule(record) },
      { type: 'delete', onDelete: this.onShareClassFeeScheduleDelete },
    ]);
  }

  @action setCurrentShareClass(instance: any) {
    this.currentShareClass = instance;
  }

  @action createShareClass() {
    this.setCurrentShareClass(
      this.store.createRecord('shareclass', {
        portfolio: this.model,
        name: `${this.model.name} Share Class`,
        currency: this.model.rccy,
      })
    );
  }

  @action onShareClassSave() {
    this.setCurrentShareClass(null);
    notifyPropertyChange(this, 'shareclasses');
  }

  @action onShareClassError(_message: string, error: any) {
    console.error(error);
  }

  @action onShareClassCancel() {
    this.setCurrentShareClass(null);
    notifyPropertyChange(this, 'shareclasses');
  }

  @action onShareClassDelete() {
    this.setCurrentShareClass(null);
    notifyPropertyChange(this, 'shareclasses');
  }

  ///////////////////////////////////
  // FOR SHARE CLASS FEE SCHEDULES //
  ///////////////////////////////////

  @action setCurrentShareClassFeeSchedule(instance: any) {
    this.currentShareClassFeeSchedule = instance;
  }

  @action createShareClassFeeSchedule() {
    const record = this.store.createRecord('scfeeschedule');

    // If we have only one shareclass, set it:
    if (this.shareclasses.length === 1) {
      // @ts-expect-error
      set(record, 'shareclass', this.shareclasses.firstObject);
    }

    this.setCurrentShareClassFeeSchedule(record);
  }

  @action onShareClassFeeScheduleSave() {
    this.setCurrentShareClassFeeSchedule(null);
    notifyPropertyChange(this, 'feeschedules');
  }

  @action onShareClassFeeScheduleCancel() {
    this.setCurrentShareClassFeeSchedule(null);
    notifyPropertyChange(this, 'feeschedules');
  }

  @action onShareClassFeeScheduleError(err: any) {
    console.error(err);
  }

  @action onShareClassFeeScheduleDelete() {
    this.setCurrentShareClassFeeSchedule(null);
    notifyPropertyChange(this, 'feeschedules');
  }
}
