import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dayjs } from 'cafelatte/libs/headless/prelude/datetime';
import CLAjaxService from 'cafelatte/services/ajax';
import MeService from 'cafelatte/services/me';
import CLSessionService from 'cafelatte/services/session';
// @ts-expect-error
import { storageFor } from 'ember-local-storage';

/**
 * Defines an array of examples.
 */
const Examples = [
  'Basic Arithmetics: (81 + 3) / 2',
  'Show latest EURUSD: /px EURUSD',
  'Load last command: /last',
  'Who am I? /me',
  'Log me out: /logout',
  'Clear widget history: /clear',
];

/**
 * Returns a random example.
 */
function getRandomExample() {
  return Examples[Math.floor(Math.random() * Examples.length)];
}

interface HistoryItem {
  input: string;
  rdata: string | undefined;
  value: string | undefined;
  error: boolean;
}

function isKeyArrowDown(event: KeyboardEvent): boolean {
  return event && event.key ? event.key === 'Down' || event.key === 'ArrowDown' : false;
}

function isKeyArrowUp(event: KeyboardEvent): boolean {
  return event && event.key ? event.key === 'Up' || event.key === 'ArrowUp' : false;
}

export default class extends Component {
  @service declare ajax: CLAjaxService;
  @service declare flashMessages: any;
  @service declare me: MeService;
  @service declare session: CLSessionService;

  declare MathJS: any;

  @storageFor('cultulator') declare history: HistoryItem[] & { content: HistoryItem[] };

  @tracked prompt = '';
  @tracked opened = false;
  @tracked example = getRandomExample();

  _heap = {};
  _hisOffset = -1;

  loadLastCommand(index: number) {
    // Get the history contents:
    const history = this.history.content;

    // Create the index of interest:
    index = history.length - index - 1;

    // Check:
    if (index < 0 || index >= history.length) {
      return;
    }

    // Get the last:
    const last = this.history.content[index];

    // Check:
    if (isEmpty(last)) {
      return;
    }

    // Get the input to prompt:
    // @ts-expect-error ts2532
    this.prompt = last.input;
  }

  evaluateCommand(command: string) {
    // Get the command:
    const func = command.substr(1);

    // List of available commands:
    const allCommands = ['clear', 'help', 'last', 'logout', 'me', 'px'];

    if (func === 'clear') {
      this.history.clear();
    } else if (func === 'help') {
      this.history.addObject({
        input: command,
        rdata: `List of available commands: ${allCommands.join(', ')}`,
        value: `List of available commands:<br/>${allCommands.join('<br/>')}`,
        error: false,
      });
    } else if (func === 'logout') {
      this.session.invalidate('authenticator:decaf', false);
    } else if (func === 'me') {
      this.history.addObject({ input: command, rdata: this.me.username, value: this.me.username, error: false });
    } else if (func === 'last') {
      this.loadLastCommand(0);
    } else if (func.startsWith('px ')) {
      // Get the stuff we are looking for:
      const symbol = command.substr(3);

      // Attempt to get the price:
      this.ajax
        .request(`ohlcobservations/?page_size=1&ordering=-date&series__symbol__iexact=${symbol}`)
        .then((data: any) => {
          // Define the history item:
          const item: HistoryItem = { input: command, rdata: undefined, value: undefined, error: false };

          // Do we have data:
          if (isEmpty(data) || isEmpty(data.results)) {
            // Mark the result as eror:
            item.rdata = 'Price not found';
            item.value = item.rdata;
            item.error = true;
          } else {
            // Get the observation:
            const obs = data.results[0];
            item.rdata = obs.close;
            item.value = `${obs.close} <span class="text-muted">[${dayjs(obs.date).format(
              'DD-MM-YYYY'
            )}]</span><br/><small class="text-muted">(Last updated ${dayjs(obs.updated).format(
              'DD-MM-YYYY HH:mm:ss'
            )})</small>`;
            item.error = false;
          }

          // Done add to the history:
          this.history.addObject(item);
        });
    } else {
      this.history.addObject({ input: command, rdata: 'Unknown command', value: 'Unknown command', error: true });
    }
  }

  evaluateSymbolic(expression: string) {
    // Initialise the return value:
    const result: HistoryItem = { input: expression, value: '', rdata: '', error: false };

    // Attempt to evaluate:
    try {
      // Remove comma chars
      const cleanExp = expression.replace(/,/g, '');

      // Get the evaluation result:
      const evaled = this.MathJS.evaluate(cleanExp, this._heap);

      // Check the type of the evaled:
      if (typeof evaled === 'object') {
        result.rdata = evaled.toString().trim();
        result.value = (result.rdata || '')
          .replace(/\n/g, '<br/>')
          .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
          .replace(/ /g, '&nbsp');
      } else if (typeof evaled === 'function') {
        result.value = '[new function]';
        result.rdata = result.value;
      } else {
        result.value = evaled;
        result.rdata = result.value;
      }
    } catch (err: any) {
      result.value = err.message;
      result.error = true;
    }

    // Add to the history:
    this.history.addObject(result);
  }

  evaluate(expression: string) {
    // Is it a system command?
    if (expression.startsWith('/')) {
      // Run system command:
      this.evaluateCommand(expression);
    } else {
      // Evaluate symbolic expression:
      this.evaluateSymbolic(expression);
    }

    // We can now change the example
    this.example = getRandomExample();
  }

  updateHistoryIndex(offset?: number | undefined) {
    // Make sure that we have a proper history index.
    offset = offset || 0;

    // Get the length of history:
    const hislen = this.history.content.length;

    // Compute the new offset:
    offset = isEmpty(this._hisOffset) ? -1 : this._hisOffset + offset;

    // Update the history index:
    if (hislen === 0) {
      this._hisOffset = 0;
    } else if (offset < 0) {
      this._hisOffset = 0;
    } else if (hislen <= offset) {
      this._hisOffset = hislen - 1;
    } else {
      this._hisOffset = offset;
    }
  }

  @action toggle(newState?: boolean | undefined) {
    this.opened = newState === undefined ? !this.opened : newState;
  }

  @action onEscape() {
    this.toggle(false);
  }

  @action onKey(event: KeyboardEvent) {
    if (isKeyArrowDown(event)) {
      this.updateHistoryIndex(-1);
    } else if (isKeyArrowUp(event)) {
      this.updateHistoryIndex(1);
    } else {
      this._hisOffset = -1;
    }
    this.loadLastCommand(this._hisOffset);
  }

  @action onExpression(expression: string) {
    // Get the expression:
    expression = (expression || '').trim();

    // Empty the prompt:
    this.prompt = '';

    // Evaluate and return?
    if (!isEmpty(expression)) {
      this.evaluate(expression);
    }
  }

  @action copied() {
    this.flashMessages.success('Text is copied to the clipboard.');
  }

  @action scrollDown(element: HTMLElement) {
    // @ts-expect-error
    element.parentElement.scrollTop = element?.parentElement?.scrollHeight;
  }

  @action focusPrompt(prompt: HTMLElement) {
    prompt.focus();
  }

  @action loadMathJS() {
    import('mathjs').then(({ all, create }) => (this.MathJS = create(all, { number: 'BigNumber', precision: 12 })));
  }
}
