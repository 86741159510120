import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import ActionTypeModel from 'cafelatte/models/trade/type';

interface Args {
  options?: Record<string, any>;
  onSelect?: (x: Record<string, any>) => void;
}

export default class extends Component<Args> {
  @service declare store: Store;

  initialOptions = this.onSearch('');

  @action onSearch(keyword: string | undefined) {
    return keyword ? this.store.query('trade/type', { search: keyword }) : this.store.findAll('trade/type');
  }

  @action onSelect(x: ActionTypeModel) {
    this.args.onSelect?.({ ...(this.args.options || {}), ctype: x });
  }
}
