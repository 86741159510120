import { CommonRoute, getIndexRouteModel, IndexRouteParams } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = {
    page: { refreshModel: true },
    page_size: { refreshModel: true },
    search: { refreshModel: true },
    cstate: { refreshModel: true },
    created__gte: { refreshModel: true },
    created__lte: { refreshModel: true },
    resmain: { refreshModel: true },
    ctype: { refreshModel: true },
    date__gte: { refreshModel: true },
    date__lte: { refreshModel: true },
    team: { refreshModel: true },
  };

  model(params: IndexRouteParams) {
    return getIndexRouteModel(this.store, 'tradeorder', params);
  }
}
