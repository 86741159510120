import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import DecafService from 'cafelatte/services/decaf';
// @ts-expect-error
import pace from 'pace-js';

export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = ['debug'];

  @tracked debug?: string = undefined;

  @action setupPace() {
    pace.start();
  }
}
