import Store from '@ember-data/store';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class extends Component {
  @service declare store: Store;

  @computed()
  get _rawStates() {
    return this.store.findAll('trading/state');
  }

  _sortBy = ['order:asc'];

  @sort('_rawStates', '_sortBy')
  states: any;
}
