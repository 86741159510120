import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import KonfigService from 'cafelatte/services/konfig';
import MeService from 'cafelatte/services/me';
import { canDisplay, NavItem } from '../../../machinery';

interface Args {
  nav: any;
  item: NavItem;
}

export default class extends Component<Args> {
  @service declare konfig: KonfigService;
  @service declare me: MeService;

  get show() {
    return canDisplay(this.args.item, this.me, this.konfig);
  }
}
