import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import AccountModel from './account';
import TradeOrderModel from './tradeorder';
import TradeOrderExecutionModel from './tradeorderexecution';
import TradingStateModel from './trading/state';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['order', 'account', 'extacct', 'allocated', 'cancelled', 'notes', 'remarks'];

export default class TradeOrderAllocationModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @belongsTo('tradeorder')
  declare order?: BelongsTo<TradeOrderModel>;

  @belongsTo('account')
  declare account?: BelongsTo<AccountModel>;

  @attr()
  declare extacct?: string;

  @attr('number')
  declare allocated?: number;

  @attr('number', { defaultValue: 0 })
  declare cancelled: number;

  @attr()
  declare notes?: string;

  @attr()
  declare remarks?: string;

  @attr('number')
  declare quantity?: number;

  @attr('number')
  declare executed?: number;

  @attr('number')
  declare outstanding?: number;

  @attr()
  declare monetary?: object;

  @belongsTo('trading/state')
  declare cstate?: BelongsTo<TradingStateModel>;

  @hasMany('tradeorderexecution', { inverse: 'allocation' })
  declare executions?: BelongsTo<TradeOrderExecutionModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tradeorderallocation: TradeOrderAllocationModel;
  }
}
