import { DecafClient } from '@decafhub/decaf-client';
import { AxiosResponse } from 'axios';
import { getDate, getPreviousWorkday, today } from 'cafelatte/libs/headless/prelude/datetime';
import OHLCObservationModel from 'cafelatte/models/ohlc/observation';
import ArtifactModel from 'cafelatte/models/resource';
import { Chart, ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import numbro from 'numbro';
export type ClientResponse<T> = Promise<AxiosResponse<T>>;

export interface WatchedInstrument {
  id: number;
  type: string;
  symbol: string;
  ohlcCode?: string;
  name: string;
  ccy: string;
}

export interface InstrumentPrice {
  id: string;
  symbol: string;
  date: string;
  close: string;
}

export interface WatchedInstrumentWithPrices extends WatchedInstrument {
  // instrument: WatchedInstrument;
  pricesRaw: InstrumentPrice[];
  prices: Record<'x' | 'y', any>;
  trend?: 'falling' | 'rising' | 'stable';
  trendValue?: number;
  trendDescription?: string;
}

export async function fetchWatchedInstruments(client: DecafClient): Promise<WatchedInstrument[]> {
  return client.barista.get('/resources/?watched=true').then(({ data }) => {
    const results = data.results.map((obj: ArtifactModel) => {
      return {
        id: Number(obj.id),
        type: obj.type,
        symbol: obj.symbol,
        name: obj.name,
        ohlcCode: obj.ohlccode,
        ccy: obj.ccymain,
      } as WatchedInstrument;
    });
    return results;
  });
}

export async function fetchInstrumentPrices(
  client: DecafClient,
  i: WatchedInstrument
): ClientResponse<OHLCObservationModel[]> {
  const dateRange = `${getPreviousWorkday(getDate('this-year-start'))},${today()}`;
  return client.barista.get(
    `/ohlcobservations/?ordering=-date&date__range=${dateRange}&page_size=-1&series__symbol=${i.ohlcCode ?? i.symbol}`
  );
}

export async function watchOrUnwatchInstrument(
  client: DecafClient,
  id: number,
  watch: boolean
): ClientResponse<Record<'status', boolean>> {
  return client.barista.post(`/resources/${id}/watch/`, { status: watch });
}

function buildChartDataFromPrices(prices: InstrumentPrice[]) {
  const retval = prices.reduce(
    (acc, v) => {
      acc.y.push(v.close);
      acc.x.push(dayjs(v.date).toDate());
      return acc;
    },
    { x: [], y: [] } as any
  );
  retval.x = [...retval.x].reverse();
  retval.y = [...retval.y].reverse();
  return retval;
}

export async function buildWatchedInstrumentsWithPrices(
  client: DecafClient,
  instruments: WatchedInstrument[]
): Promise<WatchedInstrumentWithPrices[]> {
  const pricePromises = instruments.map((i) => fetchInstrumentPrices(client, i));

  const objects: WatchedInstrumentWithPrices[] = [];
  return Promise.all(pricePromises).then((values) => {
    values.forEach(({ data }, index) => {
      const inst = instruments[index];
      if (inst !== undefined) {
        const result: WatchedInstrumentWithPrices = {
          ...inst,
          pricesRaw: [],
          prices: { x: null, y: null },
        };
        if (result) {
          result.pricesRaw = data.map(
            (x) => ({ date: x.date, close: x.close, symbol: x.symbol, id: x.id } as InstrumentPrice)
          );

          result.prices = buildChartDataFromPrices(result.pricesRaw);
          const index = result.prices.y?.length - 1;
          const priceOne = result.prices.y?.[0];
          const priceTwo = result.prices.y?.[index];

          if (priceOne !== undefined && priceTwo !== undefined && priceOne !== priceTwo) {
            result.trend = priceOne < priceTwo ? 'rising' : 'falling';
            result.trendValue = Number(numbro(Math.abs((priceTwo - priceOne) / priceOne) * 100).format('0,0.00'));
            const dateOne = dayjs(result.prices.x?.[0]).format('DD-MM-YYYY');
            const dateTwo = dayjs(result.prices.x?.[index]).format('DD-MM-YYYY');
            result.trendDescription = `% change between ${dateOne} and ${dateTwo}`;
          }
        }
        objects.push(result);
      }
    });
    return objects;
  });
}

// chart
export function chartNoDataHandler(chart: Chart) {
  if (chart.data.datasets.length === 0 || chart.data.datasets[0]?.data?.[1] === undefined) {
    // No data is present
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;
    chart.clear();

    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = "0.875em normal 'Helvetica Nueue'";
    ctx.fillStyle = '#fff';
    ctx.fillText('No data', width / 2, height / 2);
    ctx.restore();
  }
}

export function buildChartOptions(): ChartOptions {
  return {
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        position: 'nearest',
        intersect: false,
        callbacks: {
          title: (ctx) => {
            return dayjs(ctx[0]?.label)?.format('YYYY-MM-DD');
          },
          label: (context) => {
            return numbro(context.parsed.y).format('0,0.00[0000]');
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };
}
