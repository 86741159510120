import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonRoute } from 'cafelatte/libs/embered';

const nipActionType = ['ctype'];
const nipStrategy = ['strategy'];
const nipInstitution = ['feeagt'];
const nipAccount = ['accaltn', 'acccntr', 'acccomp', 'accintr', 'accmain', 'accprin', 'accundr'];
const nipShareClass = ['shrcls'];
const nipResource = ['resaltn', 'rescntr', 'rescomp', 'resintr', 'resmain', 'resprin', 'resundr'];
const nipResourceSym = nipResource.map((x) => x + '_symbol');
const nipOrdinary = [
  'qtyaltn',
  'qtycntr',
  'qtycomp',
  'qtyintr',
  'qtymain',
  'qtyprin',
  'qtyundr',
  'commitment',
  'settlement',
  'pxcost',
  'pxmain',
  'pxnavs',
  'feeccy',
  'feeqty',
  'grouping1',
  'grouping2',
  'grouping3',
  'grouping4',
  'notes',
  'reference',
  'remarks',
];

const extraParams = ['poweruser'];

const qparamKeys = [
  ...extraParams,
  ...nipActionType,
  ...nipStrategy,
  ...nipInstitution,
  ...nipAccount,
  ...nipResource,
  ...nipResourceSym,
  ...nipOrdinary,
  ...nipShareClass,
];

const qparams: any = {};
qparamKeys.forEach((x) => {
  qparams[x] = {
    refreshModel: true,
  };
});

export default class extends CommonRoute {
  queryParams = qparams;

  @tracked poweruser: any = undefined;
  @tracked ctype: any = undefined;

  model(params: any) {
    // If we are in the power user mode, set the flag:
    this.poweruser = params.poweruser === '1';

    // If provided set the trade type:
    this.ctype = params.ctype;

    // Act according to id:
    if (params.id === '__new__') {
      // Create the new record:
      const record = this.store.createRecord('trade', {});

      // Set the ctype if specified:
      if (!isEmpty(this.ctype)) {
        this.store.findRecord('trade/type', params.ctype).then((data) => record.set('ctype', data));
      }

      // Define not empty function:
      const nempty = (x: any) => !isEmpty(x);

      // Iterate over ordinary:
      nipOrdinary.filter((x: any) => nempty(params[x])).forEach((x) => record.set(x, params[x]));

      // Iterate over object references:
      nipShareClass
        .filter((x) => nempty(params[x]))
        .forEach((x) => this.store.findRecord('shareclass', params[x]).then((data) => record.set(x, data)));
      nipAccount
        .filter((x) => nempty(params[x]))
        .forEach((x) => this.store.findRecord('account', params[x]).then((data) => record.set(x, data)));
      nipStrategy
        .filter((x) => nempty(params[x]))
        .forEach((x) => this.store.findRecord('strategy', params[x]).then((data) => record.set(x, data)));
      nipInstitution
        .filter((x) => nempty(params[x]))
        .forEach((x) => this.store.findRecord('institution', params[x]).then((data) => record.set(x, data)));
      nipResource
        .filter((x) => nempty(params[x]))
        .forEach((x) => this.store.findRecord('resource', params[x]).then((data) => record.set(x, data)));
      nipResource
        .filter((x) => nempty(params[x + '_symbol']))
        .forEach((x) =>
          this.store.query('resource', { symbol: params[x + '_symbol'] }).then((data) => {
            if (nempty(data)) {
              record.set(x, data.objectAt(0));
            }
          })
        );

      // Done, return the new type:
      return record;
    }

    // Get record finder promise:
    const p = this.store.findRecord('trade', params.id);

    // Stupid requirement due to shrcls load bug:
    p.then((x: any) => {
      x.get('shrcls').then(() => x.reload());
    });

    return p;
  }
}
