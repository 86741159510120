import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked scrollOffset: number = window.scrollY;

  eventPoolingTimeout?: NodeJS.Timeout;

  get isTop(): boolean {
    return this.scrollOffset == 0;
  }

  get isBottom(): boolean {
    return window.innerHeight + this.scrollOffset >= document.body.offsetHeight;
  }

  @action listen(_event?: Event): void {
    this.scrollOffset = window.scrollY;
  }

  onPageHeightChange(callback: (e?: Event) => void): void {
    if (this.eventPoolingTimeout !== undefined) {
      clearTimeout(this.eventPoolingTimeout);
    }
    this.eventPoolingTimeout = setTimeout(() => this.onPageHeightChange(callback), 2000);
  }

  @action registerListener(_element: HTMLElement): void {
    window.addEventListener('scroll', this.listen);
    this.onPageHeightChange(this.listen);
  }

  @action removeListener(_element: HTMLElement): void {
    window.removeEventListener('scroll', this.listen);
  }

  @action toTop(): void {
    this._scroll(0);
  }

  @action toBottom(): void {
    this._scroll(document.body.scrollHeight);
  }

  _scroll(to: number): void {
    document.body.scrollTop = to; // For Safari ???
    document.documentElement.scrollTop = to; // For Chrome, Firefox, IE and Opera ???
  }
}
