import { action } from '@ember/object';
import { CommonController } from 'cafelatte/libs/embered';
import ShareClassModel from 'cafelatte/models/shareclass';

export default class extends CommonController {
  @action onSave(record: ShareClassModel): void {
    this.router.transitionTo('shareclass.details', record.id);
  }

  @action onError(_message: any, error: any): void {
    console.error(error);
  }

  @action onCancel(record: ShareClassModel): void {
    // @ts-expect-error
    if (record.isDestroyed || record.isDeleted) {
      this.router.transitionTo('shareclass');
    }
  }

  @action onDelete(): void {
    this.router.transitionTo('shareclass');
  }
}
