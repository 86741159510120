import Store from '@ember-data/store';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ActionModel from 'cafelatte/models/trade';
import VoucherModel from 'cafelatte/models/voucher';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/-ts/ajax';

interface Args {
  record: ActionModel;
  onSave?: (x: ActionModel) => void;
  onError?: (e: any) => void;
  onCancel?: (x: ActionModel) => void;
  onDelete?: () => void;
}

export default class extends Component<Args> {
  @service declare store: Store;
  @service declare ajax: CLAjaxService;
  @service declare router: RouterService;

  @tracked voucherInstance: any = undefined;

  get voucherTableOperations(): FlexTableRowAction<VoucherModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.setVoucherInstance(record) },
      { type: 'delete', onDelete: this.voucherActionHandler },
    ]);
  }

  @action newVoucher() {
    this.setVoucherInstance(
      this.store.createRecord('voucher', {
        trade: this.args.record,
        institution: this.args.record.get('accmain.custodian'),
      })
    );
  }

  @action setVoucherInstance(x?: VoucherModel) {
    this.voucherInstance = x;
  }

  @action voucherActionHandler() {
    this.setVoucherInstance(undefined);
    this.args.record.notifyPropertyChange('_dummy');
  }

  @action onSave(x: ActionModel) {
    this.args.onSave?.(x);
    this.args.record.get('quants')?.reload();
    this.args.record.notifyPropertyChange('_dummy');
  }

  @action onError(e: any) {
    this.args.onError?.(e);
  }

  @action onCancel(x: ActionModel) {
    this.args.onCancel?.(x);
  }

  @action onDelete() {
    this.args.onDelete?.();
  }
}
