import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  layout = layout;

  value!: Maybe<number>;
  onChange!: Function;

  @computed('value')
  get flag(): Maybe<number> {
    return this.value;
  }

  @action onFlagEnumeratorClicked() {
    this.onChange(((this.flag || 0) + 1) % 6 || null);
  }
}
