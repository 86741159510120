import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'name__icontains',
    'closed__isnull',
    'created__gte',
    'created__lte',
    'opened__gte',
    'opened__lte',
    'closed__gte',
    'closed__lte',
    'portfolio',
    'portfolio__team',
    'custodian',
    'atype',
    'atype__isnull',
    'riskprofile',
    'rccy',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('account', cleanParams(params));
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
