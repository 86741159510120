import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import KonfigService from 'cafelatte/services/konfig';

export default class extends Component {
  @service declare konfig: KonfigService;

  /**
   * Available deployment types.
   *
   * TODO: Attention! Deployment type root URLs may change in the future. Let's keep it in mind.
   */
  get available(): Record<string, { name: string; led: string; url: string; show: boolean }> {
    return {
      development: {
        name: 'Development Version',
        led: 'red',
        url: '/',
        show: this.konfig.konfiguration.environment === 'development',
      },
      testing: {
        name: 'Testing Version',
        led: 'orange',
        url: '/webapps/cafelatte/testing/',
        show: true,
      },
      staging: {
        name: 'Staging Version',
        led: 'yellow',
        url: '/webapps/cafelatte/staging/',
        show: true,
      },
      production: {
        name: 'Production Version',
        led: 'green',
        url: '/', // May change to "webapps/cafelatte/production/" later.
        show: true,
      },
    };
  }

  /**
   * Current application information.
   */
  get current() {
    return {
      // Version of the current application:
      version: this.konfig.version,

      // Full version of the current application:
      fullVersion: this.konfig.versionFull,

      // Root URL of the current application:
      rooturl: this.konfig.konfiguration.rootURL,

      // Deployment type of the current application:
      deptype: this.available[this.konfig.konfiguration.APP.deploytype],

      // Environment type of the current application:
      environ: this.konfig.konfiguration.environment,
    };
  }
}
