import EmberObject, { action, computed, setProperties } from '@ember/object';
import { Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Currency } from 'cafelatte/libs/headless/commons/types';
import { getDate, SDate, today } from 'cafelatte/libs/headless/prelude/datetime';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { BreakdownType, Node, ParentNode, ReportQuery } from 'cafelatte/libs/headless/services/assets-evolution-report';
import { QuickValuationReportData } from 'cafelatte/libs/headless/services/consolidation-report';
import DecafService from 'cafelatte/services/decaf';
import Decimal from 'decimal.js';

type ValueType = 'NAV' | 'AUM';
type PlotlySeries = { name: string; x: Array<SDate>; y: Array<Maybe<number>> };
type ReportSeries = { index: Array<SDate>; values: Array<Maybe<Decimal>> };

function transformSeries(name: string, series: ReportSeries): PlotlySeries {
  return { name, x: series.index, y: series.values.map((x) => x?.toNumber()) };
}

function compilePlotlySeries(ptype: 'NAV' | 'AUM', nodes: Array<Node>): Array<PlotlySeries> {
  return nodes.map((x) => transformSeries(x.instance.name, ptype === 'NAV' ? x.series.nav : x.series.aum));
}

export default class extends Component {
  @service declare router: Router;
  @service declare decaf: DecafService;

  @tracked daterange = { start: getDate('this-year-start'), end: today() };
  @tracked sandbox = false;
  @tracked currency: Currency = 'USD';
  @tracked breakdown1: BreakdownType = 'team';
  @tracked breakdown2: BreakdownType = 'portfolio';
  @tracked plotType: ValueType = 'NAV';
  @tracked showBreakdowns = false;
  @tracked selectedNode?: ParentNode | undefined = undefined;
  @tracked reportLoaded = false;

  get breakdowns() {
    return this.decaf.services.assetsEvolutionReport.breakdowns;
  }

  @computed('sandbox', 'currency', 'daterange', 'breakdown1', 'breakdown2')
  get report() {
    const report: any = EmberObject.create({});

    const args: ReportQuery = {
      sandbox: this.sandbox,
      currency: this.currency,
      daterange: [this.daterange.start, this.daterange.end],
      breakdown1: this.breakdown1,
      breakdown2: this.breakdown2,
    };

    this.decaf.services.assetsEvolutionReport.getReport(args).then((x) => setProperties(report, x));

    return report;
  }

  get _live(): Promise<QuickValuationReportData> {
    return this.decaf.services.consolidationReport.requestQuickValuation({
      date: today(),
      datetype: 'commitment',
      elements: '*',
      currency: this.currency,
      sandbox: this.sandbox ? 'include' : 'exclude',
    });
  }

  get live(): Promise<{ nav: number; aum: number }> {
    return this._live.then((x) => ({ nav: x.nav.toNumber(), aum: x.aum.toNumber() }));
  }

  get plotData(): Maybe<Array<PlotlySeries>> {
    if (isEmpty(this.report)) {
      return undefined;
    }
    return this.selectedNode === undefined
      ? compilePlotlySeries(this.plotType, this.report.nodes)
      : compilePlotlySeries(this.plotType, this.selectedNode.breakdown);
  }

  get plotLayout(): any {
    return {
      title: 'Evolution of Assets',
      xaxis: { title: 'Timeline' },
      yaxis: { title: this.plotType },
      margin: { b: 100 },
    };
  }

  @action onParentSelected(node: ParentNode | undefined): void {
    this.selectedNode = node;
  }
}
