import Store from '@ember-data/store';
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import ArtifactModel from 'cafelatte/models/resource';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

interface CLRemoteSelectControlOptions {
  modelLabel?: string;
  modelById?: boolean;
  modelIdProperty?: string;
  searchKey?: string;
  multiple?: boolean;
  filter?: any;
  extraPayload?: any;
  onCreate?: any;
  itemComponent?: any;
  addGlimpseButton?: boolean;
  addLinkButton?: boolean;
  addSearchButton?: boolean;
  addNewButton?: boolean;
  defaultsForNew?: any;
  hideWatchedToggle?: boolean;
}

@tagName('')
export default class CLRemoteSelectControl extends Control {
  @service declare router: RouterService;
  @service declare store: Store;

  /**
   * Fix the layout.
   */
  layout = layout;

  /**
   * Value object.
   */
  value!: Maybe<any>;

  /**
   * Declare on change function.
   */
  onChange!: Function;

  /**
   * Current record to be created.
   */
  @tracked currentRecord: Maybe<ArtifactModel> = undefined;

  /**
   * Chosen record to be looked at.
   */
  @tracked chosenRecord: Maybe<ArtifactModel> = undefined;

  /**
   * Options passed to this component on the call-site.
   */
  @tracked options!: Maybe<CLRemoteSelectControlOptions>;

  /**
   * Keep track of toggle status of filtering watched instruments.
   */
  @tracked toggleWatched = false;

  /**
   * Options which can be overridden by classes inheriting this control class.
   */
  defaultOptions: CLRemoteSelectControlOptions = {};

  /**
   * Combined, effective options.
   */
  @computed('options.*')
  get controlOptions(): CLRemoteSelectControlOptions {
    return { ...this.options, ...this.defaultOptions };
  }

  @computed('options.extraPayload.*')
  get hideWatchedToggle(): boolean {
    return this.options?.extraPayload?.watched !== undefined || this.options?.hideWatchedToggle === true;
  }

  @computed('toggleWatched')
  get extraPayload() {
    return {
      ...(this.options?.extraPayload || {}),
      ...(this.toggleWatched === true ? { watched: this.toggleWatched } : {}),
    };
  }

  @action onValueGlimpseClick() {
    this.chosenRecord = this.value;
  }

  @action onValueLinkClick() {
    const id = this.controlOptions?.modelById ? this.value : this.value.get('id');
    const url = this.router.urlFor('resource.details', id);
    window.open(url);
  }

  @action onNewRecord() {
    this.currentRecord = this.store.createRecord('resource', this.controlOptions?.defaultsForNew || {});
  }

  @action onCurrentRecordSave() {
    this.onChange(this.currentRecord);
    this.currentRecord = undefined;
  }

  @action onCurrentRecordCancel() {
    this.onChange(this.currentRecord);
    this.currentRecord?.rollbackAttributes();
    this.currentRecord = undefined;
  }
}
