import { isNothing, Maybe } from './maybe';

export function cleanParams<T>(record: Record<string, Maybe<T>>): Record<string, T> {
  return Object.entries(record).reduce((a, [k, v]) => (isNothing(v) ? a : { ...a, [k]: v }), {});
}

export function cleanEmptyParams<T>(record: Record<string, Maybe<T> | string>): Record<string, T> {
  return Object.entries(record).reduce((a, [k, v]) => (isNothing(v) || v == '' ? a : { ...a, [k]: v }), {});
}

/**
 * Simple object check.
 *
 * @param x
 * @returns {boolean}
 */
function isRecord(x: any): x is Record<string, any> {
  return x && typeof x === 'object' && !Array.isArray(x);
}

/**
 * Deep merge two objects.
 *
 * Taken from: https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
 *
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: Record<string, any>, ...sources: Record<string, any>[]): Record<string, any> {
  if (!sources.length) {
    return target;
  }

  const source = sources.shift();

  if (isRecord(target) && isRecord(source)) {
    for (const key in source) {
      if (isRecord(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
