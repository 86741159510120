import { DecafClient, gql } from '@decafhub/decaf-client';
import { Id } from 'cafelatte/libs/headless/commons/types';

export const query = gql`
  query ($portfolio: Int, $date: date) {
    portfolio_credit_lines: portfolio_credit_line(
      where: {
        _and: [
          { portfolio_id: { _eq: $portfolio } }
          {
            _or: [
              { _and: [{ since: { _lte: $date }, until: { _is_null: true } }] }
              { _and: [{ since: { _lte: $date }, until: { _is_null: false, _gte: $date } }] }
            ]
          }
        ]
      }
    ) {
      id
      since
      until
      currency
      quantity
    }

    portfolio_account_credit_lines: account_credit_line(
      where: {
        _and: [
          { account: { portfolio_id: { _eq: $portfolio } } }
          {
            _or: [
              { _and: [{ since: { _lte: $date }, until: { _is_null: true } }] }
              { _and: [{ since: { _lte: $date }, until: { _is_null: false, _gte: $date } }] }
            ]
          }
        ]
      }
    ) {
      id
      since
      until
      currency
      quantity
      account {
        id
        name
      }
    }
  }
`;

export interface EffectiveCreditLine {
  id: Id;
  since: string;
  until?: string;
  currency?: string;
  quantity?: number;
  account?: { id: Id; name: string };
}

/**
 * Attempts to retrieve remote [[EffectiveCreditLine]]s.
 *
 * @param client DECAF API clients.
 * @param portfolio Portfolio ID.
 * @param date Effective date.
 * @returns An array of [[EffectiveCreditLine]] values.
 */
export async function getEffectiveCreditLines(
  client: DecafClient,
  portfolio: string,
  date: string
): Promise<Array<EffectiveCreditLine>> {
  return client.microlot
    .query({ query, variables: { portfolio, date } })
    .then((result) =>
      (result.data?.portfolio_credit_lines || []).concat(result.data?.portfolio_account_credit_lines || [])
    );
}
