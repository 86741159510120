import { DecafClient, gql } from '@decafhub/decaf-client';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DateType } from 'cafelatte/libs/headless/commons/date-type';
import { Currency, Id } from 'cafelatte/libs/headless/commons/types';
import { sortElements, uniqElements } from 'cafelatte/libs/headless/prelude/arrays';
import { KnownDates, SDate, today } from 'cafelatte/libs/headless/prelude/datetime';
import { isJust, isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import DecafService from 'cafelatte/services/decaf';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import RSVP from 'rsvp';

export const query = gql`
  query ($id: Int!) {
    portfolio: portfolio_by_pk(id: $id) {
      currency
      others: share_classes {
        currency
      }
    }
  }
`;

export interface Query {
  portfolio: Maybe<Id>;
  date: Maybe<SDate>;
  datetype: Maybe<DateType>;
  currency: Maybe<Currency>;
}

interface Args extends Query {
  onQuery: (query: Maybe<Query>) => any;
}

function getSelectionCurrencies(client: DecafClient, id: Id): Promise<[Currency, Array<Currency>]> {
  return client.microlot.query({ query, variables: { id } }).then((result) => {
    return [result.data?.portfolio.currency, result.data?.portfolio?.others?.map((x: any) => x.currency) || []];
  });
}

export default class extends Component<Args> {
  @service declare decaf: DecafService;

  @tracked value = {
    portfolio: this.args.portfolio,
    date: this.args.date || today(),
    datetype: this.args.datetype || 'commitment',
    currency: this.args.currency || 'USD',
  };

  @tracked currencies: Array<Currency> = [];

  @computed('args.portfolio')
  get inception() {
    if (!this.args.portfolio) {
      return undefined;
    }

    const promise = new RSVP.Promise<object | null>((resolve, _reject) => {
      this.decaf.client.barista.get(`portfolios/${this.args.portfolio}/`).then(({ data }) => resolve(data.inception));
    });

    return DS.PromiseObject.create({ promise });
  }

  @computed('inception.content')
  get dates() {
    const asof = today();
    return {
      Today: KnownDates['today'](asof),
      Yesterday: KnownDates['yesterday'](asof),
      'This Week Start': KnownDates['this-week-start'](asof),
      'Last Week End': KnownDates['last-week-end'](asof),
      'This Quarter Start': KnownDates['this-quarter-start'](asof),
      'Last Quarter End': KnownDates['last-quarter-end'](asof),
      'This Month Start': KnownDates['this-month-start'](asof),
      'Last Month End': KnownDates['last-month-end'](asof),
      'This Year Start': KnownDates['this-year-start'](asof),
      'Last Year End': KnownDates['last-year-end'](asof),
    };
  }

  _queryChanged() {
    this.args.onQuery(this.value);
  }

  _updateSelectionCurrencies(selection: Maybe<Id>): Promise<Maybe<Currency>> {
    return isNothing(selection)
      ? Promise.resolve(undefined)
      : getSelectionCurrencies(this.decaf.client, selection).then(([currency, currencies]) => {
          this.currencies = sortElements(uniqElements([currency, ...currencies]));
          return currency;
        });
  }

  @action doUpdateSelectionCurrencies() {
    this._updateSelectionCurrencies(this.value.portfolio);
  }

  @action onChangePortfolio(value: Id) {
    this._updateSelectionCurrencies(value).then((currency) => {
      if (isJust(currency)) {
        set(this.value, 'currency', currency);
      }
      set(this.value, 'portfolio', value);
      this._queryChanged();
    });
  }

  @action onChangeDate(value: SDate) {
    set(this.value, 'date', value);
    this._queryChanged();
  }

  @action onChangeDateType(value: DateType) {
    set(this.value, 'datetype', value);
    this._queryChanged();
  }

  @action onChangeCurrency(value: Currency) {
    set(this.value, 'currency', value);
    this._queryChanged();
  }
}
