import { DecafClient } from '@decafhub/decaf-client';
import { Currency, Id } from '../../commons/types';
import { SDate } from '../../prelude/datetime';
import { compileConsolidationReportData, ConsolidationReportData } from '../consolidation-report/generic-report/-full';

/**
 * Simple simulation query type.
 */
export interface SimpleSimulationQuery {
  portfolio: Id;
  dateasof: SDate;
  currency: Currency;
  profiles: Array<Id>;
  data: any;
}

/**
 * Simple simulation response type.
 */
export interface SimpleSimulationResult {
  consolidation: ConsolidationReportData;
  compliance: any;
}

export function buildQuery(query: SimpleSimulationQuery): any {
  return {
    scenario: {
      data: query.data,
    },
    query: {
      consolidation: {
        c: 'portfolio',
        i: [query.portfolio],
        date: query.dateasof,
        ccy: query.currency,
      },
      profiles: query.profiles,
    },
  };
}

/**
 * Attempts to run pre-trade simulation query.
 *
 * @param client barista client.
 * @returns barista version resource.
 */
export async function simulateSimple(
  client: DecafClient,
  query: SimpleSimulationQuery
): Promise<SimpleSimulationResult> {
  // Rebuild the query:
  const rquery = buildQuery(query);

  // Simulate and get the response data:
  const response = await client.barista.post<any>('/simulation/simple/', rquery).then(({ data }) => data);

  // Return the data:
  return {
    consolidation: await compileConsolidationReportData(client, response.consolidation),
    compliance: response.consolidation,
  };
}
