import { inject as service } from '@ember/service';
import { CommonRoute } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonRoute {
  @service declare ajax: CLAjaxService;

  model() {
    const getComplianceTypes = this.ajax.request('compliance/vtypes/').then((data: { id: any; name: any }[]) => {
      return data.reduce((o, v) => ({ ...o, [v.id]: v.name }), {});
    });

    const getComplianceValues = this.ajax.request('compliance/');

    return Promise.all([getComplianceTypes, getComplianceValues]).then((values) => {
      return { vtypes: values[0], data: values[1] };
    });
  }
}
