import { Service } from '../-service';
import { CashStatement, CashStatementItem, CashStatementQuery, request } from './-internal';

// Export public symbols:
export { CashStatement, CashStatementQuery, CashStatementItem };

/**
 * Provides a cash statement service.
 */
export class CashStatementService extends Service {
  /**
   * Retrieves and compiles cash statements data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  get(query: CashStatementQuery): Promise<CashStatement> {
    return request(this.client, query);
  }
}
