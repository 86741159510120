import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import ArtifactModel from 'cafelatte/models/resource';
import KonfigService from 'cafelatte/services/konfig';
import { RecordFormArgs } from '../../commons/record/form';

export default class extends Component<RecordFormArgs<ArtifactModel>> {
  @service declare flashMessages: any;
  @service declare konfig: KonfigService;

  checkArtifact(artifact: any) {
    // Get relevant configuration:
    const specs =
      this.konfig.konfiguration.APP.cafelatteConfiguration.components?.cl?.artifact?.form?.warnOnMissingFields || [];

    // Iterate over specs and get warnings:
    const warnings = specs
      .map((s: any) => (isEmpty(artifact.get(s.field)) ? `${s.label} information is missing.` : undefined))
      .filter(Boolean);

    // Inform the user as per warnings:
    if (warnings.length !== 0) {
      this.flashMessages.warning('There are some warnings!', { sticky: true });
      warnings.forEach((x: any) => this.flashMessages.warning(`Warning: ${x}`, { sticky: true }));
    }
  }

  @action onFormSaved(record: ArtifactModel): void {
    this.checkArtifact(record);
    this.args.onSave?.(record);
  }
}
