import Model, { attr, belongsTo } from '@ember-data/model';
import PortfolioModel from './portfolio';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['since', 'portfolio', 'currency', 'quantity'];

export default class PortfolioCreditLineModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare since?: string;

  @attr()
  declare until?: string;

  @belongsTo('portfolio')
  declare portfolio?: BelongsTo<PortfolioModel>;

  @attr()
  declare currency?: string;

  @attr()
  declare quantity?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    portfoliocreditline: PortfolioCreditLineModel;
  }
}
