import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'date_joined__gte',
    'date_joined__lte',
    'last_login__gte',
    'last_login__lte',
    'username__icontains',
    'first_name__icontains',
    'last_name__icontains',
    'email__icontains',
    'mobile__icontains',
    'is_active',
    'teams',
    'groups',
    'portfolio_groups',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('user', cleanParams(params));
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
