import { isArray } from '@ember/array';
import { helper } from '@ember/component/helper';

export type ColorMap = [string, string, string];

export function buildClasses(size: string, spec: any): string[] {
  let count: number | string | undefined;
  let offset: string | undefined;
  const classes: string[] = [];

  if (!spec) {
    count = undefined;
    offset = undefined;
  } else if (isArray(spec)) {
    count = spec[0];
    offset = spec[1];
  } else {
    count = spec;
    offset = undefined;
  }

  if (count) {
    classes.push(`col-${size}-${count}`);
  }

  if (offset) {
    classes.push(`offset-${size}-${offset}`);
  }

  return classes;
}

export default helper(([size, spec]) => buildClasses(size, spec).join(' '));
