import { DecafClient } from '@decafhub/decaf-client';
import { Guid, Id } from '../../commons/types';
import { SDate } from '../../prelude/datetime';

export interface CalendarEventsQuery {
  start: SDate;
  end: SDate;
}

export type CalendarEvents = CalendarEvent[];

export interface CalendarEvent {
  date: SDate;
  quantity: number;
  resource: CalendarEventArtifact;
  account: CalendarEventContainer;
  custodian: CalendarEventContainer;
  portfolio: CalendarEventContainer;
  team: CalendarEventContainer;
}

export interface CalendarEventArtifact {
  id: Id;
  guid: Guid;
  symbol: string;
  name?: string;
  ctype: string;
  ceased?: SDate;
  expiry?: SDate;
  last_tradable?: SDate;
  first_notice?: SDate;
}

export interface CalendarEventContainer {
  id: Id;
  guid: Guid;
  name: string;
}

/**
 * Attempts to retrieve and compile remote report data.
 *
 * @param client barista client.
 * @returns Report data.
 */
export async function request(client: DecafClient, query: CalendarEventsQuery): Promise<CalendarEvents> {
  // Prepare parameters:
  const params = { start: query.start, end: query.start > query.end ? query.start : query.end };

  // Get raw items:
  const { data } = await client.barista.get<CalendarEvents>('/calendars/', { params });

  // Done, return:
  return data;
}
