import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';
import { FlexTableSpec } from '../../components/x/flex-table';

interface QueueItem {
  name: string;
  size: number;
  form: FormData;
  status: 'queued' | 'started' | 'finished' | 'error';
  created: Date;
  processed: Date | undefined;
  res: { series: number; records: number } | undefined;
}

function file2form(file: File): FormData {
  const formData = new FormData();
  formData.append('file', file);
  return formData;
}

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @tracked queue: Array<QueueItem> = [];
  @tracked tolerate = true;

  get spec(): FlexTableSpec<QueueItem> {
    return {
      ident: 'ohlc-upload-status',
      vfill: true,
      emptyTableMessage: 'No files selected/uploaded yet.',
      columns: [
        { key: 'name', label: 'File Name', align: 'left' },
        { key: 'size', label: 'File Size', align: 'right', component: '@number', options: { format: '0.00b' } },
        { key: 'status', align: 'center' },
        { key: 'res.series', label: '#Series', align: 'right', component: '@number', options: { format: '0,0' } },
        { key: 'res.records', label: '#Records', align: 'right', component: '@number', options: { format: '0,0' } },
        { key: 'created', align: 'left', component: '@date', options: { format: 'LTS' } },
        { key: 'processed', label: 'Finished', align: 'left', component: '@date', options: { format: 'LTS' } },
      ],
    };
  }

  @action upload(files: Iterable<File>, resetInput: () => void): void {
    // Get new queue items:
    const items: Array<QueueItem> = Array.from(files).map((f) => ({
      name: f.name,
      size: f.size,
      form: file2form(f),
      status: 'queued',
      created: new Date(),
      processed: undefined,
      res: undefined,
    }));

    // Any items?
    if (!(items.length > 0)) {
      return;
    }

    // Add new items to the queue:
    this.queue.pushObjects(items);

    // Get first and rest:
    const [first, ...rest] = items;

    // Start uploading:
    // @ts-expect-error ts2345
    this.start(first, rest);

    // Reset input:
    resetInput();
  }

  @action start(item: QueueItem, rest: Array<QueueItem>): void {
    this.ajax
      .request(`/ohlcobservations/updatebulk/?tolerate=${this.tolerate ? '1' : '0'}`, {
        method: 'POST',
        contentType: false,
        processData: false,
        data: item.form,
      })
      .then((rs: { series: number; records: number }) => this.onSuccess(item, rs))
      .catch((err: any) => this.onError(item, err))
      .finally(() => {
        set(item, 'processed', new Date());
        if (!(rest.length == 0)) {
          const [first, ...x] = rest;
          // @ts-expect-error ts2345
          this.start(first, x);
        }
      });
    set(item, 'status', 'started');
  }

  @action onSuccess(item: QueueItem, result: { series: number; records: number }): void {
    set(item, 'status', 'finished');
    set(item, 'res', result);
    this.flashMessages.success(`File "${item.name}" is uploaded successfully.`);
  }

  @action onError(item: QueueItem, errors: any): void {
    console.error(errors);
    set(item, 'status', 'error');
    this.flashMessages.danger(`Error encountered while uploading file "${item.name}". Please check your file.`);
  }

  @action filesSelected(x: any) {
    const files = x?.target?.files || [];
    if (files) {
      this.upload(files, () => {
        if (x?.target?.files) {
          x.target.value = '';
        }
      });
    }
  }
}
