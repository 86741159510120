import Store from '@ember-data/store';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import DecafService from 'cafelatte/services/decaf';
import { Chart } from 'chart.js';
import { buildChartData, buildChartOptions, WatchedPortfolioWithNAV } from '../machinery';

interface Args {
  record: WatchedPortfolioWithNAV;
}
export default class extends Component<Args> {
  @service declare decaf: DecafService;
  @service declare router: RouterService;
  @service declare store: Store;

  @action didInsertChartSection(element: HTMLCanvasElement): any {
    return new Chart(element, {
      type: 'line',
      data: buildChartData(this.args.record),
      options: buildChartOptions(this.args.record),
    });
  }

  @action gotoConsolidation(): void {
    this.router.transitionTo(this.args.record.isFund ? 'reports.fund' : 'reports.portfolio', {
      queryParams: { ccy: this.args.record.ccy, portfolio: this.args.record.id, type: 'commitment', date: today() },
    });
  }
}
