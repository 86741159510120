import { Service } from '../-service';
import { Id } from '../../commons/types';
import {
  APITokenFrequency,
  changePassword,
  getAPITokenFrequency,
  PasswordChangeForm,
  PasswordChangeResponse,
} from './-internal';

// Export public symbols:
export { PasswordChangeForm, PasswordChangeResponse, APITokenFrequency };

/**
 * Provides the barista principal profile service.
 */
export class ProfileService extends Service {
  /**
   * Attempts to change the password of the given principal.
   */
  changePassword(id: Id, form: PasswordChangeForm): Promise<PasswordChangeResponse> {
    return changePassword(this.client, id, form);
  }

  /**
   * Retrieves API token retrieval frequencies.
   */
  getAPITokenFrequency(params?: Record<string, string>): Promise<APITokenFrequency> {
    return getAPITokenFrequency(this.client, params);
  }
}
