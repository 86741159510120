import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import EmberObject, { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { dayjs, today } from 'cafelatte/libs/headless/prelude/datetime';
import CLAjaxService from 'cafelatte/services/ajax';
import DecafService from 'cafelatte/services/decaf';
import AJAXPromise from 'ember-ajax/-private/promise';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import AccountManagementFeeSchemeModel from './account/mfeescheme';
import AnalyticalTypeModel from './analyticaltype';
import CountryModel from './country';
import InstitutionModel from './institution';
import PortfolioModel from './portfolio';
import RiskProfileModel from './riskprofile';
import { BelongsTo, DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = [
  'name',
  'portfolio',
  'custodian',
  'rccy',
  'atype',
  'opened',
  'domicile',
  'horizontype',
  'mandatetype',
  'data_source',
  'riskprofile',
];

export default class AccountModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  // TODO: Replace AjaxService usage with headless DECAF client.
  @service declare ajax: CLAjaxService;
  @service declare decaf: DecafService;

  @attr()
  declare name?: string;

  @belongsTo('portfolio')
  declare portfolio?: BelongsTo<PortfolioModel>;

  @belongsTo('institution')
  declare custodian?: BelongsTo<InstitutionModel>;

  @attr({ defaultValue: (account: AccountModel) => account.defaultCurrency })
  declare rccy: string;

  @attr({ defaultValue: (account: AccountModel) => account.defaultOpenDate })
  declare opened: string;

  @belongsTo('analyticaltype')
  declare atype?: BelongsTo<AnalyticalTypeModel>;

  @belongsTo('country')
  declare domicile?: BelongsTo<CountryModel>;

  @attr()
  declare horizontype?: string;

  @attr()
  declare mandatetype?: string;

  @attr()
  declare data_source?: string;

  @belongsTo('riskprofile')
  declare riskprofile?: BelongsTo<RiskProfileModel>;

  @attr()
  declare htype?: string;

  @attr()
  declare inception?: string;

  @attr()
  declare closed?: string;

  @attr('boolean', { defaultValue: true })
  declare isActive: boolean;

  @attr('boolean', { defaultValue: false })
  declare isClosed: boolean;

  @belongsTo('account/mfeescheme')
  declare mfpercentage?: BelongsTo<AccountManagementFeeSchemeModel>;

  @hasMany('account/mfeescheme')
  declare mfpercentages?: HasMany<AccountManagementFeeSchemeModel>;

  /**
   * Default currency of the account.
   *
   * If we have a portfolio, use its reference currency as default, otherwise use USD.
   */
  get defaultCurrency(): string {
    // Get the portfolio currency:
    const portfolioCurrency = this.portfolio?.get('rccy');

    // Check the portfolio currency and return:
    return isEmpty(portfolioCurrency) || portfolioCurrency === undefined ? 'USD' : portfolioCurrency;
  }

  /**
   * Default currency of the account.
   *
   * If we have a portfolio and its inception date, use it as the default, otherwise use today.
   */
  get defaultOpenDate(): string {
    // Get the portfolio inception:
    const portfolioInception = this.portfolio?.get('inception');

    // Check the portfolio inception and return:
    return isEmpty(portfolioInception) || portfolioInception === undefined ? today() : portfolioInception;
  }

  // TODO: Consider removing `label` property.
  @computed('name', 'custodian.name', 'closed')
  get label(): string {
    // Closed suffix, if any:
    const closed = !isEmpty(this.closed) ? ` (Closed on ${dayjs(this.closed).format('DD-MM-YYYY')})` : '';

    // Get custodian name:
    const custodian = isEmpty(get(this, 'custodian.name')) ? '' : ` - ${get(this, 'custodian.name')}`;

    // Return:
    return `${this.name}${custodian}${closed}`;
  }

  // TODO: Consider getting rid of this property.
  @computed('id', 'rccy')
  get quickvaluation(): object | undefined {
    // Can we?
    if (isEmpty(this.id) || isEmpty(this.rccy)) {
      return undefined;
    }

    // Define the return value:
    const retval = EmberObject.create({});

    // Get consolidation:
    this.decaf.services.consolidationReport
      .requestQuickValuation({
        date: today(),
        datetype: 'commitment',
        elements: { container: 'account', instances: [this.id] },
        currency: this.rccy,
        sandbox: 'include',
      })
      .then((x) => retval.setProperties(x));

    // Done, return:
    return retval;
  }

  // TODO: Consider getting rid of this property.
  @computed('id')
  get last() {
    return isEmpty(this.id)
      ? undefined
      : DS.PromiseObject.create({
          promise: this.ajax.request(`accounts/${this.id}/updates/`),
        });
  }

  doClose(date: string): AJAXPromise<AccountModel> {
    return this.ajax.post(`/accounts/${this.id}/close/?asof=${date}`);
  }

  doTransfer(date: string, to: AccountModel): AJAXPromise<object> {
    return this.ajax.post(`/accounts/${this.id}/transfer/?asof=${date}&account=${to.id}`);
  }

  doFlush(): AJAXPromise<object> {
    return this.ajax.post(`/accounts/${this.id}/flush/`);
  }

  doTriggerConsolidations(start: string): AJAXPromise<object> {
    return this.ajax.request(`/jobs/valuations/accounts/${this.id}/?start=${start}`);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: AccountModel;
  }
}
