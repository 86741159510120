import { action, computed, notifyPropertyChange } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import PortfolioCreditLineModel from 'cafelatte/models/portfoliocreditline';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends ControllerWithCurrentRecord<PortfolioCreditLineModel> {
  @tracked page = 1;
  @tracked page_size = 20;

  @computed('model.id', 'page', 'page_size')
  get records() {
    return this.store.query('portfoliocreditline', {
      portfolio: this.model.id,
      page: this.page,
      page_size: this.page_size,
    });
  }

  createNewRecord(): PortfolioCreditLineModel {
    return this.store.createRecord('portfoliocreditline', { portfolio: this.model });
  }

  get operations(): FlexTableRowAction<PortfolioCreditLineModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doReset },
    ]);
  }

  @action doReset(): void {
    this.doResetCurrentRecord();
    notifyPropertyChange(this, 'records');
  }

  @action onError(_message: string, error: any): void {
    console.error(error);
  }
}
