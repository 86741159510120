import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { HUNDRED, safeDivideOrZero, sumDecimalDefaultZero } from 'cafelatte/libs/headless/prelude/numeric';
import { ConsolidationReportData } from 'cafelatte/libs/headless/services/consolidation-report';
import { Holding } from 'cafelatte/libs/headless/services/consolidation-report/commons/holding';
import {
  addressers,
  AvailableAddresserKeys,
  buildHoldingsTree,
  HoldingsNode,
} from 'cafelatte/libs/headless/services/consolidation-report/commons/holdings-tree';
import { Decimal } from 'decimal.js';
import numbro from 'numbro';

interface Args {
  report: ConsolidationReportData;
}

type PropertyType = 'value' | 'exposure';

type ValueType = 'net' | 'absolute';

export default class extends Component<Args> {
  /**
   * Available addresser options.
   */
  // @ts-expect-error
  addresserOptions: Array<AvailableAddresserKeys> = Object.keys(addressers);

  /**
   * Available property type options.
   */
  propertyTypeOptions: Array<PropertyType> = ['value', 'exposure'];

  /**
   * Available value type options.
   */
  valueTypeOptions: Array<ValueType> = ['net', 'absolute'];

  /**
   * All holdings from the report.
   */
  @tracked holdings = this.args.report.positions.holdings;

  /**
   * Filtered holdings from the report.
   */
  @tracked filteredHoldings = this.holdings;

  /**
   * Selected addresser key.
   */
  @tracked addresserKey: AvailableAddresserKeys = 'classification';

  /**
   * Property type.
   */
  @tracked propertyType: PropertyType = 'value';

  /**
   * Value type.
   */
  @tracked valueType: ValueType = 'net';

  /**
   * Holdings tree.
   */
  @computed('addresserKey')
  get holdingsTree() {
    return buildHoldingsTree(this.args.report, this.holdings, addressers[this.addresserKey], false);
  }

  /**
   * Plot data.
   */
  @computed('holdingsTree', 'propertyType', 'valueType')
  get plotData() {
    // Functions:
    const functions: Record<string, [(h: Holding) => Maybe<Decimal>, (n: HoldingsNode) => Decimal]> = {
      'net-value': [(h: Holding) => h.valuation.value.net.ref, (n: HoldingsNode) => n.totals.netValue],
      'absolute-value': [(h: Holding) => h.valuation.value.abs.ref, (n: HoldingsNode) => n.totals.absValue],
      'net-exposure': [(h: Holding) => h.valuation.exposure.net.ref, (n: HoldingsNode) => n.totals.netExposure],
      'absolute-exposure': [(h: Holding) => h.valuation.exposure.abs.ref, (n: HoldingsNode) => n.totals.absExposure],
    };

    // Get extractors:
    // @ts-expect-error ts2448
    const [hExt, nExt] = functions[`${this.valueType}-${this.propertyType}`];

    // Get holding totals:
    const holdingTotals = sumDecimalDefaultZero(this.holdingsTree.holdings, hExt);

    // Get children totals:
    const totals = this.holdingsTree.children.map((x) => ({ label: x.address[0]?.label || 'Unknown', value: nExt(x) }));

    // Add holding totals, too:
    if (!holdingTotals.isZero()) {
      totals.pushObject({ label: 'Unclassified', value: holdingTotals });
    }

    // Return as per value type:
    if (this.valueType == 'net') {
      // Compute the total totals:
      const total = sumDecimalDefaultZero(totals, (x) => x.value);

      // Calculate and return:
      return totals.map((x) => {
        const percentage = safeDivideOrZero(x.value, total).times(HUNDRED);
        const percentageFormatted = numbro(percentage.toNumber()).format('0,0.00');
        const valueFormatted = numbro(x.value.toNumber()).format('0,0');
        return {
          x: ['Stack'],
          y: [percentage.toNumber()],
          text: `${x.label} (${percentageFormatted}% / ${valueFormatted})`,
          textposition: 'auto',
          name: `${x.label} (${percentageFormatted}%)`,
          type: 'bar',
        };
      });
    } else {
      return [
        {
          type: 'pie',
          values: totals.map((x) => x.value.toNumber()),
          labels: totals.map((x) => x.label),
          textinfo: 'label+percent',
          textposition: 'outside',
          automargin: true,
        },
      ];
    }
  }

  /**
   * Plot layout.
   */
  @computed('addresserKey', 'propertyType', 'valueType')
  get plotLayout() {
    if (this.valueType == 'net') {
      return {
        // autosize: false,
        // width: 700,
        // height: 450,
        title: `${this.valueType} ${this.propertyType} by ${this.addresserKey}`.toUpperCase(),
        barmode: 'relative',
        xaxis: {
          title: '',
        },
        yaxis: {
          title: '%',
          zeroline: false,
        },
      };
    } else {
      return {
        title: `${this.valueType} ${this.propertyType} by ${this.addresserKey}`.toUpperCase(),
      };
    }
  }
}
