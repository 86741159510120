import Model, { attr, belongsTo } from '@ember-data/model';
import AccountModel from './account';
import ShareClassModel from './shareclass';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = [
  'started',
  'shareclass',
  'mngtreg',
  'mngtdcc',
  'mngtstyle',
  'mngtperc',
  'perfreg',
  'perfdcc',
  'perfperc',
  'perfhwm',
  'perffact',
  'perfcnst',
  'perfbmrate',
  'perfbmcode',
];

export default class ShareClassFeeScheduleModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare started?: string;

  @belongsTo('shareclass')
  declare shareclass?: BelongsTo<ShareClassModel>;

  @belongsTo('account')
  declare mngtreg?: BelongsTo<AccountModel>;

  @attr()
  declare mngtdcc?: string;

  @attr()
  declare mngtstyle?: string;

  @attr('number')
  declare mngtperc?: number;

  @belongsTo('account')
  declare perfreg?: BelongsTo<AccountModel>;

  @attr()
  declare perfdcc?: string;

  @attr('number')
  declare perfperc?: number;

  @attr('number')
  declare perfhwm?: number;

  @attr('number')
  declare perffact?: number;

  @attr('number')
  declare perfcnst?: number;

  @attr('number')
  declare perfbmrate?: number;

  @attr()
  declare perfbmcode?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    scfeeschedule: ShareClassFeeScheduleModel;
  }
}
