import CLSelectControl from '../cl-select/component';

export default class extends CLSelectControl<string> {
  get choices() {
    return [
      {
        value: 'FVLM',
        label: 'Fixed Linear Deprecation',
      },
      {
        value: 'NAVB',
        label: 'Based on NAV',
      },
      {
        value: 'AUMB',
        label: 'Based on AuM',
      },
    ];
  }
}
