import { action, get, set } from '@ember/object';
import Component from '@glimmer/component';
import ArtifactModel from 'cafelatte/models/resource';

interface Args {
  record: ArtifactModel;
  form: any;
}

export default class extends Component<Args> {
  @action onScheduleItemAdd() {
    // Get the extension data:
    const data = this.args.record?.extdata || { schedule: [] };

    // Create new item:
    const item = { date: undefined, symbol: undefined, strike: undefined };

    // Add the the schedule items:
    // @ts-expect-error
    data.schedule.pushObject(item);

    // Re-set back:
    // @ts-expect-error
    set(this, 'args.record.extdata', data);
  }

  @action onScheduleItemRemove(index: number) {
    // @ts-expect-error
    get(this, 'args.record.extdata.schedule').removeAt(index);
  }
}
