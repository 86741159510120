/**
 * This is an internal module that describes and provides Flex Table
 * specification.
 *
 * These definitions are different than the runtime definitions. The objective
 * is to provide more relaxed definitions of Flex Table and its constituents so
 * that call-site is not tired of providing an extensive Flex Table definition.
 *
 * @module
 */

import { get } from '@ember/object';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { SimpleChoices } from 'cafelatte/libs/headless/prelude/simple-choice';
import {
  FlexTable,
  FlexTableCellActionFunction,
  FlexTableCellAlignment,
  FlexTableCellClassFunction,
  FlexTableCellValueFunction,
  FlexTableColumn,
  FlexTableRowAction,
  FlexTableRowClassFunction,
} from './runtime';

/**
 * Type encoding for Flex Table specification.
 */
export interface FlexTableSpec<T> {
  /**
   * Table identifier.
   *
   * We will store table's config based on this identifier.
   */
  ident: string;

  /**
   * Table column specifications.
   */
  columns?: FlexTableColumnSpec<T>[];

  /**
   * Table row action triggers, if any.
   */
  actions?: FlexTableRowAction<T>[];

  /**
   * Table row classes.
   */
  rowClasses?: (string | FlexTableRowClassFunction<T>)[];

  /**
   * Indicates if the table should fill the remaining vertical visible space.
   */
  vfill?: boolean;

  /**
   * Custom message to show when the table data is empty.
   */
  emptyTableMessage?: string;

  /**
   * Disables footer.
   */
  disableFooter?: boolean;

  /**
   * Disables default footer actions.
   */
  disableFooterActions?: boolean;

  /**
   * Extra options that are not interpreted by Flex Table machinery, but to be
   * passed to and used by user-defined functions.
   */
  options?: Record<string, any>;

  /**
   * Lifecycle hook called when the table element inserted to the DOM.
   */
  didInsert?: (element: HTMLElement) => void;

  /**
   * Lifecycle hook called when the just before the table element is removed from the DOM.
   */
  willDestroy?: () => void;
}

/**
 * Type encoding for column specifications.
 */
export interface FlexTableColumnSpec<T> {
  key: string;
  label?: string;
  help?: string;
  align?: FlexTableCellAlignment;
  hidden?: boolean;
  exclude?: boolean;
  classes?: (string | FlexTableCellClassFunction<T>)[];

  value?: string | FlexTableCellValueFunction<T>;
  dataValue?: string | FlexTableCellValueFunction<T>;

  component?: string;
  options?: Record<string, any>;

  action?: FlexTableCellActionFunction<T>;

  onSort?: (x: -1 | 0 | 1) => void;
  sorted?: () => -1 | 0 | 1;

  onFilter?: (x?: Maybe<any>) => void;
  filterOptions?: () => SimpleChoices<any>;
  filterSelections?: () => Maybe<any[]>;
}

/**
 *
 * @param spec Consumes a call-site specification of a [[FlexTable]] and returns a materialized [[FlexTable]] definition.
 * @returns [[FlexTable]] definition.
 */
export function mkFlexTable<T>(spec: FlexTableSpec<T>): FlexTable<T> {
  return {
    ...spec,
    columns: (spec.columns || []).filter((c) => !c.exclude).map(mkColumn),
    actions: spec.actions || [],
    rowClasses: (spec.rowClasses || []).map(mkRowClassFunction),
    vfill: spec.vfill ? true : false,
    emptyTableMessage: spec.emptyTableMessage || 'No records are found to tabulate.',
    disableFooter: spec.disableFooter || false,
    disableFooterActions: spec.disableFooterActions || false,
    options: spec.options || {},
  };
}

/**
 * Consumes a call-site specification of a [[FlexTableColumn]] and returns a
 * materialized [[FlexTableColumn]] definition.
 *
 * @param spec Flex table column specification.
 * @returns [[FlexTableColumn]] definition.
 */
function mkColumn<T>(spec: FlexTableColumnSpec<T>): FlexTableColumn<T> {
  return {
    key: spec.key,
    label: spec.label || mkLabel(spec.key),
    help: spec.help || undefined,
    align: spec.align,
    hidden: spec.hidden || false,
    value: mkCellValueFunction(spec.value || spec.key),
    dataValue: spec.dataValue ? mkCellValueFunction(spec.dataValue) : undefined,
    classes: [
      ...(spec.classes || []),
      ...(spec.align ? [`text-${spec.align}`] : []),
      ...(spec.hidden ? ['d-none'] : []),
    ].map(mkCellClassFunction),
    component: sanitizeComponentPath(isNothing(spec.component) ? '@basic' : spec.component),
    options: spec.options,
    action: spec.action,
    onSort: spec.onSort,
    sorted: spec.sorted,
    onFilter: spec.onFilter,
    filterOptions: spec.filterOptions,
    filterSelections: spec.filterSelections,
  };
}

export function sanitizeComponentPath(x: string) {
  return x.startsWith('@') ? `x/flex-table/-components/t-body/row/cell/dom/${x.substring(1)}` : x;
}

/**
 * Converts a given string to a label to be displayed.
 *
 * It replaces consecutive `.`, `-`, `_` or ` ` characters with a single space
 * and returns the title case version of the result.
 *
 * @param x String to make a label from.
 * @returns A label.
 */
export function mkLabel(x: string): string {
  return x
    .replace(/[.\-_ ]+/g, ' ')
    .trim()
    .replace(/\w\S*/g, (w) => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase());
}

/**
 * Converts a user given cell value specification into a table cell value function.
 *
 * @param spec A string or a table cell value function.
 * @returns A table cell value function.
 */
export function mkCellValueFunction<T>(spec: string | FlexTableCellValueFunction<T>): FlexTableCellValueFunction<T> {
  return typeof spec === 'function' ? spec : ({ record }) => get(record, spec as keyof T);
}

/**
 * Converts a user given cell class specification into a table cell class function.
 *
 * @param spec A string or a table cell class function.
 * @returns A table cell class function.
 */
export function mkCellClassFunction<T>(spec: string | FlexTableCellClassFunction<T>): FlexTableCellClassFunction<T> {
  return typeof spec === 'function' ? spec : () => [spec];
}

/**
 * Converts a user given row class specification into a table row class function.
 *
 * @param spec A string or a table row class function.
 * @returns A table row class function.
 */
export function mkRowClassFunction<T>(spec: string | FlexTableRowClassFunction<T>): FlexTableRowClassFunction<T> {
  return typeof spec === 'function' ? spec : () => [spec];
}

/**
 * Provides customization configuration for [[FlexTable]].
 */
export interface FlexTableCustomConfig {
  size?: number;
}
