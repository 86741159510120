import { action } from '@ember/object';
import { CommonRoute, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = {
    page: { refreshModel: true },
    page_size: { refreshModel: true },
    search: { refreshModel: true },
  };

  model(params: any) {
    return this.store.query('agent', params);
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
