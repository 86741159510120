import { action, notifyPropertyChange } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import PortfolioModel from 'cafelatte/models/portfolio';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @tracked revaluateStartDate = today();

  /**
   * 1. Is there any currency given in the xternal valuations but NOT exists in
   *    the share classes ?
   *
   * 2. For each row in xternal valuations: Are the entries in the Xternal
   *    Valuations which have a different currency for a Share Class compared to
   *    the currency stated in the Share Class itself ?
   */
  _validateAgainstShareClasses = (xternals: any, shareClasses: any) => {
    return xternals.some((item: any) => {
      // find this external valuation's share class.
      const ownedShareClass = shareClasses.find((c: any) => c.id === item.shareclass);

      // check if item xternal val.'s currency is not the same as share class's currency
      return ownedShareClass && item.ccy !== ownedShareClass.currency;
    });
  };

  /**
   * Is any of the currency stated in the Xternal Valuations different than the portfolio currency?
   */
  _validateAgainstPortfolio = (xternals: any, portfolio: any) => {
    return xternals.map((x: any) => x.ccy).some((curr: any) => curr !== portfolio.rccy);
  };

  /**
   * Fetches external valuations and share classes based on the Portfolio ID.
   */
  _fetchDataByPortfolioID(portfolioID: any) {
    // TODO: do not use async here
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let externals, shareClasses;
      try {
        externals = await this.ajax.request(`/api/externalvaluations/?portfolio=${portfolioID}&page_size=-1`); // get external valuations.
        if (externals.length > 0) {
          // external valuations is not empty. Continue with other checks...
          shareClasses = await this.ajax.request(`/api/shareclasses/?portfolio=${portfolioID}&page_size=-1`); // get share classes attached to this portfolio
        }
        resolve({ externals: externals, shareClasses: shareClasses });
      } catch (_error) {
        const reason = new Error('An error has occured. Contact your systems administrator.');
        reject(reason);
      }
    });
  }

  isInconsistent(externals: any, shareClasses: any) {
    let cause,
      warn = false;
    if (externals.length) {
      // external valuations is not empty. Continue with other checks...

      if (shareClasses.length) {
        // There are share classes attached to this portfolio. Continue with other checks...
        warn = this._validateAgainstShareClasses(externals, shareClasses);
        // inconsistency at share classes level.
        cause = 'Share Classes';
      } else {
        warn = this._validateAgainstPortfolio(externals, this.model);
        // inconsistency at portfolio level.
        cause = 'Portfolio';
      }
    }
    return { warn: warn, rootCause: cause };
  }

  createJob = () => {
    // Attempt to create a job for revaluation:
    this.ajax
      .request('jobs/valuations/portfolios/' + this.model.id + '/', { data: { start: this.revaluateStartDate } })
      .then(
        (res: { id: string }) => {
          // Inform the user:
          this.flashMessages.success(
            `Job has been queued successfully. It may take a while until completion. (Job ID: ${res.id})`
          );
        },
        (err: any) => {
          // Inform the user:
          this.flashMessages.danger('An error has occured. Contact your systems administrator.');

          // Log the error:
          console.error(err);
        }
      );
  };

  /**
   * Handles the event that the portfolio revaluation should be performed.
   */
  @action revaluate() {
    this._fetchDataByPortfolioID(this.model.id)
      // @ts-expect-error ts2345
      .then(({ externals, shareClasses }) => {
        const { warn, rootCause } = this.isInconsistent(externals, shareClasses);
        // if there is any inconsistency warn the user
        if (warn) {
          const sure = confirm(
            `The currency information in the External Valuation seems inconsistent with the currency information given in the ${rootCause}.
          \n\nAre you sure you want to continue?`
          );
          // if accepted, create the job.
          return sure && this.createJob();
        }
        // there is no incosistency. just create the job.
        this.createJob();
      })
      .catch((err) => {
        this.flashMessages.danger(err.message);
      });
  }

  @action onSave(record: PortfolioModel) {
    record.reload();
    this.router.transitionTo('portfolio.details.profile', record.get('id'));
  }

  @action onCancel(record: PortfolioModel) {
    if (record.get('isDeleted')) {
      this.router.transitionTo('portfolio.index');
    }
    notifyPropertyChange(this, 'model');
  }

  @action onDelete() {
    this.router.transitionTo('portfolio');
  }
}
