import Model from '@ember-data/model';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

/**
 * Provides a component argument interface for standard Cafelatte record form
 * components.
 */
export interface RecordFormArgs<T extends Model> {
  /**
   * Record of the model.
   *
   * This is not an optional field.
   */
  record: T;

  /**
   * Further options passed.
   */
  options?: Record<string, any>;

  /**
   * Indicates if the form should be disabled.
   */
  disabled?: boolean;

  /**
   * Indicates if the delete action should be disabled/removed.
   */
  noDelete?: boolean;

  /**
   * Function to be called after the record is successfully saved.
   */
  onSave?: (record: T) => void;

  /**
   * Function to be called in case of errors while saving the record.
   */
  onError?: (record: T, message: string, error: any) => void;

  /**
   * Function to be called if the form is cancelled.
   */
  onCancel?: (record: T, reverted: boolean) => void;

  /**
   * Function to be called after the record is successfully deleted.
   */
  onDelete?: () => void;
}

/**
 * Provides a component class for standard Cafelatte record forms.
 */
export abstract class RecordFormComponent<T extends Model> extends Component<RecordFormArgs<T>> {
  /**
   * Injects user notification service.
   */
  @service declare flashMessages: any;

  /**
   * Handles the event that the form is submitted.
   *
   * @param record Record to be saved.
   */
  @action onFormSubmit(record: T): void {
    record
      .save()
      .then((savedRecord) => {
        this.flashMessages.success('Record has been saved successfuly.');
        this.args.onSave?.(savedRecord);
      })
      .catch((error) => {
        this.flashMessages.danger('Error while attempting to save the record.');
        this.args.onError?.(record, 'Error while attempting to save the record', error);
      });
  }

  /**
   * Handles the event that the form is cancelled.
   *
   * @param record Record of the form that is cancelled.
   */
  @action onFormCancel(record: T): void {
    const reverted = record.hasDirtyAttributes;
    record.rollbackAttributes();
    this.flashMessages.warning('All changes to the record has been cancelled.');
    // @ts-expect-error
    this.args.onCancel?.(record, reverted);
  }

  /**
   * Handles the event that the record is to be deleted.
   *
   * @param record Record to be deleted.
   */
  @action onFormDelete(record: T): void {
    if (confirm('Are you sure you want to proceed with this operation?')) {
      record.destroyRecord().then(() => {
        this.flashMessages.success('Record has been deleted successfully');
        this.args.onDelete?.();
      });
    }
  }
}
