import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import ActionModel from 'cafelatte/models/trade';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<ActionModel> {
  @service declare ajax: CLAjaxService;

  get spec(): FlexTableSpec<ActionModel> {
    return {
      ident: 'cl-actions-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'notes', label: 'N', component: '@note', options: { title: 'Internal Notes' } },
        { key: 'remarks', label: 'R', component: '@note', options: { title: 'External Remarks' } },
        { key: 'id', component: '@link', options: { route: 'trade.details', modelPath: 'id' } },
        { key: 'cflag', label: 'Flag', component: '@flag', action: ({ record }) => this.onFlagClicked(record) },
        { key: 'commitment', label: 'Trade Date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'settlement', label: 'Value Date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        {
          key: 'accmain.name',
          label: 'Account',
          component: '@link',
          options: { route: 'account.details', modelPath: 'accmain.id' },
        },
        {
          key: 'accmain.portfolio.name',
          label: 'Portfolio',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'accmain.portfolio.id' },
        },
        {
          key: 'accmain.portfolio.team.name',
          label: 'Team',
          component: '@link',
          options: { route: 'team.details', modelPath: 'accmain.portfolio.team.id' },
        },
        {
          key: 'accmain.custodian.name',
          label: 'Custodian',
          component: '@link',
          options: { route: 'institution.details', modelPath: 'accmain.custodian.id' },
          hidden: true,
        },
        { key: 'resmain.type', label: 'Instrument (Type)' },
        {
          key: 'resmain.symbol',
          label: 'Instrument (Symbol)',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resmain.id' },
        },
        {
          key: 'qtymain',
          label: 'Quantity',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]', colorize: true },
        },
        { key: 'type', label: 'Trade (Type)' },
        { key: 'stype', label: 'Trade (Subtype)' },
        { key: 'reference', label: 'Trade (Reference)' },
        { key: 'resmain.ccymain', label: 'Instrument (CCY)' },
        {
          key: 'resmain.name',
          label: 'Instrument (Name)',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resmain.id' },
        },
        {
          key: 'resundr.symbol',
          label: 'Underlying',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resundr.id' },
        },
        {
          key: 'strategy.name',
          label: 'Strategy',
        },
        {
          key: 'resmain.pxmain',
          label: 'Instrument (PX)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        {
          key: 'accaltn.name',
          label: 'Account (Altn)',
          component: '@link',
          options: { route: 'account.details', modelPath: 'accaltn.id' },
        },
        {
          key: 'resaltn.symbol',
          label: 'Instrument (Altn)',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resaltn.id' },
        },
        {
          key: 'qtyaltn',
          label: 'Quantity (Altn)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]', colorize: true },
        },
        {
          key: 'pxmain',
          label: 'Trade PX (Main)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        {
          key: 'pxcost',
          label: 'Trade PX (Cost)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'executedat', label: 'Executed' },
        { key: 'pseudorder', label: 'Ordering' },
        ...mkAuditFields<ActionModel>(this.me),
      ],
    };
  }

  onFlagClicked(record: ActionModel): void {
    // Attempt to get the next flag:
    const next = ((record.cflag || 0) + 1) % 6 || null;

    // Update remote:
    this.ajax
      .patch(`/trades/${record.get('id')}/`, {
        data: { cflag: next },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.flashMessages.success(`Record is ${isEmpty(next) ? 'un' : ''}flagged.`);
        record.reload();
      })
      .catch(() => {
        this.flashMessages.danger('Record could not be flagged.');
      });
  }
}
