import { DecafClient } from '@decafhub/decaf-client';
import { Decimal } from 'decimal.js';
import { DateType } from '../../../commons/date-type';
import { Currency } from '../../../commons/types';
import { SDate } from '../../../prelude/datetime';
import { isNothing, mapMaybeOrElse, Maybe } from '../../../prelude/maybe';
import { ZERO } from '../../../prelude/numeric';
import { ConsolidationContainers } from '../commons/-shared';

/**
 * Quick valuation query type.
 */
export interface QuickValuationReportQuery {
  date: SDate;
  datetype: DateType;
  elements: ConsolidationContainers;
  currency: Currency;
  sandbox: Maybe<'include' | 'exclude'>;
}

/**
 * Quick valuation response data type.
 */
export interface QuickValuationResponseData {
  ccy: string;
  nav: Maybe<number>;
  aum: Maybe<number>;
  exp: Maybe<number>;
}

/**
 * Quick valuation report data type.
 */
export interface QuickValuationReportData {
  currency: Currency;
  nav: Decimal;
  aum: Decimal;
  exp: Decimal;
}

/**
 * Converts quick valuation response value to quick valuation report value.
 *
 * @param x Response data from quick valuation endpoint.
 * @returns Quick valuation report value.
 */
export function quickVal2Report(x: QuickValuationResponseData): QuickValuationReportData {
  return {
    currency: x.ccy,
    nav: mapMaybeOrElse((x) => new Decimal(x), ZERO, x.nav),
    aum: mapMaybeOrElse((x) => new Decimal(x), ZERO, x.aum),
    exp: mapMaybeOrElse((x) => new Decimal(x), ZERO, x.exp),
  };
}

/**
 * Compiles the library representation of report query into remote endpoint query parameters.
 *
 * @param q Query.
 * @returns Remote endpoint query parameters.
 */
export function query2params(q: QuickValuationReportQuery): { [key: string]: string | Array<string> } {
  // Get part of the queryparams as per elements:
  const elements: { [key: string]: string | Array<string> } =
    q.elements === '*' ? {} : { c: q.elements.container, i: q.elements.instances.map((x) => `${x}`) };

  // Get part of the queryparams as per sandbox:
  const sandbox: { [key: string]: string } = isNothing(q.sandbox) || q.sandbox == 'include' ? {} : { sandbox: 'false' };

  // Construct params and return:
  return {
    ...elements,
    ...sandbox,
    ccy: q.currency,
    date: q.date,
    type: q.datetype,
  };
}

/**
 * Gets quick valuation.
 *
 * @param client barista client.
 * @returns Quick validation report data.
 */
export function requestQuickValuation(
  client: DecafClient,
  query: QuickValuationReportQuery
): Promise<QuickValuationReportData> {
  return client.barista
    .get<QuickValuationResponseData>('/consolidation/quick/', { params: query2params(query) })
    .then(({ data }) => quickVal2Report(data));
}
