import { action, computed, notifyPropertyChange } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { ExecutiveBreakdownType, ExecutiveLabeledItem } from 'cafelatte/libs/headless/services/vouchers';
import { ExecutiveBreakdownChoices } from 'cafelatte/libs/headless/services/vouchers/-executive';
import { FlexTableSpec, mkNumberColumn } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = ['refccy', 'bymodel', 'asof'];

  @tracked refccy = 'USD';
  @tracked bymodel: ExecutiveBreakdownType = 'team';
  @tracked asof = today();

  choices = ExecutiveBreakdownChoices;

  get spec(): FlexTableSpec<ExecutiveLabeledItem> {
    return {
      ident: 'cl-executive-report',
      vfill: true,
      rowClasses: [({ record }) => (record.label == 'Total' ? ['table-dark'] : [])],
      columns: [
        { key: 'label' },
        { key: 'rccy', label: 'Currency', align: 'center' },
        mkNumberColumn({ key: 'pending', options: { format: '0,0' } }),
        mkNumberColumn({ key: 'settled', options: { format: '0,0' } }),
        mkNumberColumn({ key: 'totaled', label: 'Total', options: { format: '0,0' } }),
        mkNumberColumn({ key: 'nav', label: 'NAV', options: { format: '0,0' } }),
        mkNumberColumn({ key: 'aum', label: 'AuM', options: { format: '0,0' } }),
        mkNumberColumn({ key: 'roaum', label: 'RoAuM', options: { format: '0,0.0000%' } }),
      ],
    };
  }

  @computed('bymodel', 'refccy', 'asof')
  get records() {
    return DS.PromiseArray.create({
      // @ts-expect-error
      promise: this.decaf.services.vouchers.executive({
        breakdown: this.bymodel,
        refccy: this.refccy,
        date: this.asof,
      }),
    });
  }

  @action doRefreshModel() {
    notifyPropertyChange(this, 'records');
  }
}
