import DocumentModel from 'cafelatte/models/document';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<DocumentModel> {
  get spec(): FlexTableSpec<DocumentModel> {
    return {
      ident: 'cl-attachment-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'name' },
        { key: 'external', component: '@bool' },
        { key: 'ctype', label: 'Type' },
        { key: 'category' },
        { key: 'group' },
        { key: 'extref', label: 'Reference' },
        { key: 'date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'time' },
        { key: 'filename', label: 'File Name' },
        ...mkAuditFields<DocumentModel>(this.me),
        { key: 'mimetype', label: 'Mime Type' },
      ],
    };
  }
}
