import ExternalValuationModel from 'cafelatte/models/externalvaluation';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<ExternalValuationModel> {
  get spec(): FlexTableSpec<ExternalValuationModel> {
    return {
      ident: 'cl-external-account-valuation-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        {
          key: 'account.portfolio.name',
          label: 'Portfolio',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'portfolio.id' },
        },
        {
          key: 'account.name',
          label: 'Account',
          component: '@link',
          options: { route: 'account.details', modelPath: 'account.id' },
        },
        { key: 'date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'ccy', label: 'Currency' },
        { key: 'nav', label: 'NAV', align: 'right', component: '@number', options: { format: '0,0.00[00]' } },
        { key: 'aum', label: 'AUM', align: 'right', component: '@number', options: { format: '0,0.00[00]' } },
        { key: 'price', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        { key: 'shares', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        { key: 'subred', label: 'Net I/O', align: 'right', component: '@number', options: { format: '0,0.00' } },
        {
          key: 'hedgepnl',
          label: 'Unreal. Hedge PnL',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00' },
        },
        { key: 'feemngt', label: 'Fee (Mngt)', align: 'right', component: '@number', options: { format: '0,0.00' } },
        { key: 'feeperf', label: 'Fee (Perf)', align: 'right', component: '@number', options: { format: '0,0.00' } },
        {
          key: 'otheraccrued',
          label: 'Other Accrued',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00' },
        },
        {
          key: 'totalaccrued',
          label: 'Total Accrued',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00' },
        },
        {
          key: 'perfdaily',
          label: 'Perf. (Day)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00%' },
        },
        {
          key: 'perfweekly',
          label: 'Perf. (Week)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00%' },
        },
        {
          key: 'perfmonthly',
          label: 'Perf. (Month)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00%' },
        },
        { key: 'perfytd', label: 'Perf. (YTD)', align: 'right', component: '@number', options: { format: '0,0.00%' } },
        {
          key: 'perfstart',
          label: 'Perf. (Start)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00%' },
        },
        { key: 'coefficient', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        ...mkAuditFields<ExternalValuationModel>(this.me),
      ],
    };
  }
}
