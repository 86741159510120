import { action, computed, notifyPropertyChange } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { DateType } from 'cafelatte/libs/headless/commons/date-type';
import { dayjs, today } from 'cafelatte/libs/headless/prelude/datetime';
import {
  AssetAllocation,
  compileAssetAllocation,
  ConsolidationContainerType,
  ConsolidationReportData,
  GroupingTypes,
  ValueTypes,
} from 'cafelatte/libs/headless/services/consolidation-report';
import DecafService from 'cafelatte/services/decaf';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = ['c', 'i', 'ccy', 'date', 'type', 'vt', 'gt'];

  @tracked c: ConsolidationContainerType = 'portfolio';
  @tracked i = '';
  @tracked ccy = 'USD';
  @tracked date = today();
  @tracked type: DateType = 'commitment';
  // @ts-expect-error ts2532
  @tracked vt = ValueTypes[0].value;
  // @ts-expect-error ts2532
  @tracked gt = GroupingTypes[0].value;

  valueTypes = ValueTypes;
  groupTypes = GroupingTypes;

  get selections(): string[] {
    return ((this.i?.split(',') || []) as string[]).filter((x) => x);
  }

  set selections(x: any) {
    this.i = (x || []).filter((x: any) => x).join(',');
  }

  @computed('c')
  get instanceSelector() {
    if (this.c == 'account') {
      return 'cl-rsel-account';
    } else if (this.c == 'portfolio') {
      return 'cl-rsel-portfolio';
    } else if (this.c == 'custodian') {
      return 'cl-rsel-institution';
    } else if (this.c == 'team') {
      return 'cl-rsel-team';
    } else if (this.c == 'portfoliogroup') {
      return 'cl-rsel-portfoliogroup';
    } else {
      return undefined;
    }
  }

  @computed('c', 'i', 'ccy', 'date', 'type')
  get consolidations() {
    // Do we have something to consolidate?
    if (isEmpty(this.i)) {
      // Nope, return empty:
      return { dates: [], items: [] };
    }

    // Define an array of dates:
    const dates = [] as string[];

    // Define the current date:
    const current = this.date;

    // Create N previous months:
    for (let i = 11; i > 0; i--) {
      dates.push(dayjs(current).subtract(i, 'months').endOf('month').format('YYYY-MM-DD'));
    }

    // Add current date, too:
    dates.push(current);

    // Done, return:
    return DS.PromiseObject.create({
      // @ts-expect-error
      promise: Promise.all(
        dates.map((date) =>
          this.decaf.services.consolidationReport.consolidate({
            date,
            datetype: this.type,
            elements: { container: this.c, instances: this.selections },
            currency: this.ccy,
            sandbox: 'include',
          })
        )
      ).then((items) => {
        const retval = {} as Record<string, ConsolidationReportData>;
        for (let i = 0; i < dates.length; i++) {
          // @ts-expect-error ts2532
          retval[dates[i]] = items[i];
        }
        return retval;
      }),
    });
  }

  @computed('consolidations.content.[]', 'vt', 'gt')
  get plotData(): { name: string; type: 'bar'; x: string[]; y: number[] }[] {
    // @ts-expect-error
    const data = (this.consolidations?.content || {}) as Record<string, ConsolidationReportData>;

    // Get holdings:
    const allHoldings = Object.entries(data).reduce(
      (acc, [date, x]) => ({
        ...acc,
        [dayjs(date).format("MMM 'YY")]: compileAssetAllocation(x.positions.holdings, this.gt, this.vt),
      }),
      {} as Record<string, AssetAllocation>
    );

    // Declare return value:
    const retval = {} as Record<string, { name: string; type: 'bar'; x: string[]; y: number[] }>;

    // Prepare the data:
    Object.entries(allHoldings).forEach(([date, items]) => {
      items.forEach((x) => {
        if (!(x.group in retval)) {
          retval[x.group] = { name: x.group, type: 'bar', x: [], y: [] };
        }
        // @ts-expect-error ts2532
        retval[x.group].x.push(date);
        // @ts-expect-error ts2532
        retval[x.group].y.push(x.ratio * 100);
      });
    });

    // Done:
    return Object.values(retval);
  }

  plotLayout = {
    title: 'Exposure over Time',
    barmode: 'relative',
    xaxis: { tickangle: -45, title: 'Timeline' },
    yaxis: { zeroline: false, gridwidth: 2, title: 'Percentage' },
    bargap: 0.05,
  };

  @action refresh() {
    notifyPropertyChange(this, 'ccy');
  }
}
