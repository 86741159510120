import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { ArtifactActionCatalogue } from './_internal';
import { ActionHandlerFactory } from './_machinery';

export default class ArtifactTypeModel extends Model {
  @attr()
  name!: string;

  @attr('number')
  order!: number;

  @attr()
  fields!: object;

  @computed('id')
  get actions(): ActionHandlerFactory[] {
    return ArtifactActionCatalogue.get(this.id) || [];
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'resource/type': ArtifactTypeModel;
  }
}
