import Model, { attr, belongsTo } from '@ember-data/model';
import Store from '@ember-data/store';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import TradingStateModel from './trading/state';
import { BelongsTo, createEmptyPromiseArray, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['instructed', 'notes', 'remarks', 'cstate'];

export default class TradeOrderGroup extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @service declare store: Store;

  @attr()
  declare notes?: string;

  @attr()
  declare remarks?: string;

  @attr()
  declare instructed?: string;

  @belongsTo('trading/state')
  declare cstate?: BelongsTo<TradingStateModel>;

  @computed('isNew', 'id')
  get orders() {
    return get(this, 'isNew')
      ? createEmptyPromiseArray()
      : this.store.query('tradeorder', { group: this.id, page_size: -1 });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tradeordergroup: TradeOrderGroup;
  }
}
