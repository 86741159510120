import EmberObject, { action, computed, notifyPropertyChange, setProperties } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import ArtifactModel from 'cafelatte/models/resource';
import CLAjaxService from 'cafelatte/services/-ts/ajax';

interface Args {
  record: ArtifactModel;
  onSave?: (x: ArtifactModel) => void;
  onError?: (e: any) => void;
  onCancel?: (x: ArtifactModel) => void;
  onDelete?: () => void;
}

export default class extends Component<Args> {
  @service declare ajax: CLAjaxService;
  @service declare router: RouterService;

  @tracked stocksDate = today();

  @computed('args.record.id')
  get ohlcSeries() {
    // Initialize return value:
    const retval = EmberObject.create({ x: null, y: null });

    // Setup the promise to retrieve and set x/y:
    this.ajax.request('/resources/' + this.args.record.id + '/series/').then((x: any) => {
      setProperties(retval, {
        x: x.map((o: any) => o.date),
        y: x.map((o: any) => o.close),
      });
    });

    // Done, return:
    return retval;
  }

  @computed('args.record.{ohlccode,symbol}')
  get plotLayout() {
    const ohlccode = isEmpty(this.args.record.ohlccode) ? this.args.record.symbol : this.args.record.ohlccode;

    return {
      title: ohlccode,
      xaxis: { title: 'Timeline' },
      yaxis: { title: 'PX' },
    };
  }

  @action refreshStocks() {
    notifyPropertyChange(this, 'stocksDate');
  }

  @action onOpenChangePosition(artaction: any, artifact: any, stock: any) {
    // Build the parameters builder promise:
    const promise = artaction.getParams(artifact, stock?.account, -stock?.quantity, null);

    // Open new window for the new trade once the window is opened:
    promise.then((queryParams: any) => window.open(this.router.urlFor('trade.details', '__new__', { queryParams })));
  }

  @action onSave(x: ArtifactModel) {
    this.args.onSave?.(x);
  }

  @action onError(e: any) {
    this.args.onError?.(e);
  }

  @action onCancel(x: ArtifactModel) {
    this.args.onCancel?.(x);
  }

  @action onDelete() {
    this.args.onDelete?.();
  }
}
