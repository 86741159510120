import Store from '@ember-data/store';
import { action, computed, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { LedgerReport, LedgerReportItem } from 'cafelatte/libs/headless/services/ledger-report';
import { ActionHandlerFactory } from 'cafelatte/models/resource/_machinery';
import AjaxService from 'cafelatte/services/-ts/ajax';
import MeService from 'cafelatte/services/me';
import Decimal from 'decimal.js';
import { FlexTableSpec, mkAuditFields, mkColumn, mkDateColumn, mkNumberColumn } from '../../x/flex-table';

interface Args {
  ledger?: LedgerReport;
  artifact?: Id;
}

interface RowData {
  item: LedgerReportItem;
  queryBalance: Decimal;
}

export default class extends Component<Args> {
  @service declare router: RouterService;
  @service declare ajax: AjaxService;
  @service declare me: MeService;
  @service declare store: Store;

  @tracked page = 1;
  @tracked page_size = 20;
  @tracked _selectedTradeTypes: Set<string> = new Set();
  @tracked _selectedTradeSubtypes: Set<string> = new Set();

  @computed('args.artifact')
  get artifact() {
    return !this.args.artifact ? undefined : this.store.findRecord('resource', this.args.artifact);
  }

  @computed('args.ledger.[]')
  get ledger(): LedgerReport {
    return !this.args.ledger ? [] : this.args.ledger;
  }

  get tradeTypes(): Array<string> {
    return [...new Set(this.ledger.map((x) => x.action.htype))];
  }

  get selectedTradeTypes(): Array<string> {
    return this._selectedTradeTypes.size == 0 ? this.tradeTypes : [...this._selectedTradeTypes];
  }

  get tradeSubtypes(): Array<string> {
    return [...new Set(this.ledger.filter((x) => x.action.stype).map((x) => x.action.stype as string)), '<EMPTY>'];
  }

  get selectedTradeSubtypes(): Array<string> {
    return this._selectedTradeSubtypes.size == 0 ? this.tradeSubtypes : [...this._selectedTradeSubtypes];
  }

  @computed('ledger.[]', '_selectedTradeTypes.[]', '_selectedTradeSubtypes.[]')
  get filteredLedger(): LedgerReport {
    const types = this._selectedTradeTypes.size == 0 ? new Set(this.tradeTypes) : this._selectedTradeTypes;
    const subtypes = this._selectedTradeSubtypes.size == 0 ? new Set(this.tradeSubtypes) : this._selectedTradeSubtypes;
    return this.ledger
      .filter((x) => types.has(x.action.htype))
      .filter((x) => (!x.action.stype && subtypes.has('<EMPTY>')) || subtypes.has(x.action.stype as string));
  }

  @computed('filteredLedger.[]')
  get runningLedger(): Array<RowData> {
    return this.filteredLedger.reduce((p: Array<RowData>, c) => {
      // @ts-expect-error ts2532
      return [{ item: c, queryBalance: p.length ? c.quant.quantity.plus(p[0].queryBalance) : c.quant.quantity }].concat(
        p
      );
    }, []);
  }

  @computed('runningLedger.[]', 'page', 'page_size')
  get tableData(): Array<RowData> {
    return this.runningLedger.slice((this.page - 1) * this.page_size, this.page * this.page_size);
  }

  get tableSpec(): FlexTableSpec<RowData> {
    return {
      ident: 'cl-ledger-table',
      vfill: true,
      columns: [
        {
          key: 'item.quant.id',
          label: 'Action',
          component: 'c-l/ledger/ocposition',
          options: { artifact: this.artifact, onOpenChangePosition: this.onOpenChangePosition },
        },
        {
          key: 'item.quant.cflag',
          label: 'Flag',
          component: '@flag',
          action: ({ record }) => this.flagChanged(record),
        },
        {
          key: 'item.quant.id',
          label: 'TXN ID',
          action: ({ record }) => this.gotoRoute('quant.details', record.item.quant.id),
        },
        mkColumn('item.quant.htype', { label: 'Type' }),
        mkDateColumn({ key: 'item.quant.commitment', label: 'Trade Date' }),
        mkDateColumn({ key: 'item.quant.settlement', label: 'Value Date' }),
        mkColumn('item.quant.valccy', { label: 'Value CCY' }),
        mkNumberColumn({ key: 'item.quant.valamt', label: 'Value Amount', options: { colorize: false } }),
        mkNumberColumn({ key: 'item.quant.quantity', label: 'Quantity', options: { format: '0,0.00[00]' } }),
        mkNumberColumn({ key: 'item.balance', label: 'Balance', options: { format: '0,0.00[00]' } }),
        mkNumberColumn({ key: 'queryBalance', label: 'Query Balance', options: { format: '0,0.00[00]' } }),
        mkColumn('item.action.id', {
          label: 'Trade ID',
          component: 'c-l/ledger/tradelink',
          action: ({ record }) => this.gotoRoute('trade.details', record.item.action.id),
        }),
        mkColumn('item.action.htype', { label: 'Trade Type' }),
        mkColumn('item.action.stype', { label: 'Trade Subtype' }),
        mkColumn('item.action.reference', { label: 'Trade Reference' }),
        mkColumn('item.action.pxmain', { label: 'Trade PX' }),
        mkColumn('item.account.name', {
          label: 'Account',
          action: ({ record }): void => this.gotoRoute('account.details', record.item.account.id),
        }),
        mkColumn('item.portfolio.name', {
          label: 'Portfolio',
          action: ({ record }): void => this.gotoRoute('portfolio.details', record.item.portfolio.id),
        }),
        mkColumn('item.custodian.name', {
          label: 'Custodian',
          action: ({ record }) => this.gotoRoute('institution.details', record.item.custodian.id),
        }),
        mkColumn('item.team.name', { label: 'Team' }),
        mkColumn('item.action.artifact.symbol', {
          label: 'Trade Instrument',
          action: ({ record }): void => this.gotoRoute('resource.details', record.item.action.artifact.id),
        }),
        mkColumn('item.action.artifact.name', { label: 'Trade Instrument Name' }),
        mkColumn('item.action.artifact.isin', { label: 'Trade Instrument ISIN' }),
        mkColumn('item.action.artifact.underlyingSymbol', { label: 'Trade Instrument Underlying Symbol' }),
        mkColumn('item.action.artifact.underlyingIsin', { label: 'Trade Instrument Underlying ISIN' }),
        mkColumn('item.quant.executedat', { label: 'Executed' }),
        mkColumn('item.quant.pseudorder', { label: 'Ordering' }),
        mkColumn('item.action.notes', { label: 'Notes' }),
        mkColumn('item.action.remarks', { label: 'Remarks' }),
        ...mkAuditFields<RowData>(this.me, 'item.quant'),
      ],
    };
  }

  @action gotoRoute(route: string, id: Id): void {
    window.open(this.router.urlFor(route, id));
  }

  // TODO: Move this implementation to headless under `QuantService`.
  @action flagChanged(record: RowData): void {
    // Base URI for the quant:
    const baseuri = `/quants/${record.item.quant.id}/`;

    // Save the record:
    this.ajax.request(baseuri).then(({ cflag }) => {
      const flag = cflag ? (cflag + 1) % 6 || null : 1;
      this.ajax
        .post(`${baseuri}flag/`, { data: { cflag: flag }, headers: { 'Content-Type': 'application/json' } })
        .then(({ flag }) => {
          set(record.item.quant, 'cflag', flag || undefined);
        });
    });
  }

  @action typesSelected(selected: Array<string>) {
    this.page = 1;
    this._selectedTradeTypes = new Set(selected);
  }

  @action subtypesSelected(selected: Array<string>) {
    this.page = 1;
    this._selectedTradeSubtypes = new Set(selected);
  }

  @action onOpenChangePosition(artaction: ActionHandlerFactory, item: LedgerReportItem): void {
    // Build the parameters builder promise:
    const promise = artaction.getParams(
      // @ts-expect-error
      this.artifact,
      item.account.id,
      item.balance.negated().toNumber(),
      null,
      item.quant.commitment
    );

    // Open new window for the new trade once the window is opened:
    promise.then((queryParams) => window.open(this.router.urlFor('trade.details', '__new__', { queryParams })));
  }
}
