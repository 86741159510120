import { action, get, set, setProperties } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { SimpleChoices } from 'cafelatte/libs/headless/prelude/simple-choice';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
// @ts-expect-error
import { storageFor } from 'ember-local-storage';

export default class extends CommonController {
  @storageFor('appstate') appstate: any;

  queryParams = [
    'page',
    'page_size',
    'search',
    'created__gte',
    'created__lte',
    'updated__gte',
    'updated__lte',
    'name__icontains',
    'domicile',
    'team',
    'riskprofile',
    'sandbox',
    'isin__icontains',
    'rccy',
    'manager__icontains',
    'objective__icontains',
    'mandate_profile__icontains',
    'advisor__icontains',
    'asset_manager__icontains',
    'marketing_agent__icontains',
    'watched',
  ];

  @tracked page = 1;
  @tracked sandbox = '';
  @tracked watched = '';

  get page_size(): any {
    return get(this.appstate, 'routesPortfolioIndexPageSize');
  }

  set page_size(value) {
    if (!isEmpty(value) && value <= 500) {
      set(this.appstate, 'routesPortfolioIndexPageSize', value);
    }
  }

  choicesSandbox: SimpleChoices<string> = [
    { value: '', label: 'All' },
    { value: 'True', label: 'Yes' },
    { value: 'False', label: 'No' },
  ];

  choicesWathced: SimpleChoices<string> = [
    { value: '', label: 'All' },
    { value: 'True', label: 'Watched' },
    { value: 'False', label: 'Not Watched' },
  ];

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('portfolio.details', record.id) },
      { type: 'edit', action: ({ record }) => this.router.transitionTo('portfolio.details.profile', record.id) },
      { type: 'delete', onDelete: this.doRefreshModel },
    ]);
  }

  @action reset() {
    setProperties(this, {
      ...this.queryParams.reduce((o, k) => ({ ...o, [k]: '' }), {}),
      page: 1,
    });
  }
}
