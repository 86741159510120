import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { gt, lt } from '@ember/object/computed';
import AccountModel from './account';
import ArtifactModel from './resource';
import ActionModel from './trade';
import { BelongsTo, DecafAuditFields } from './_internal/machinery';

interface QuantValue {
  ccy: string;
  qty: number;
}

export default class QuantModel extends Model.extend(DecafAuditFields) {
  @attr('number')
  declare cflag?: number;

  @attr()
  declare commitment?: string;

  @attr()
  declare settlement?: string;

  @attr()
  declare executedat?: string;

  @attr('number')
  declare pseudorder?: number;

  @attr('number')
  declare quantity?: number;

  @belongsTo('account')
  declare account?: BelongsTo<AccountModel>;

  @belongsTo('resource')
  declare resource?: BelongsTo<ArtifactModel>;

  @belongsTo('trade', { inverse: 'quants' })
  declare trade?: BelongsTo<ActionModel>;

  @attr('number')
  declare ctype: number;

  @attr()
  declare type: string;

  @attr()
  declare valccy: string;

  @attr('number')
  declare valamt: number;

  @attr()
  declare refccy?: string;

  @attr('number')
  declare refamt?: number;

  @computed('valccy', 'valamt')
  get value(): QuantValue {
    return {
      ccy: this.valccy,
      qty: this.valamt,
    };
  }

  @gt('quantity', 0)
  isPositive!: boolean;

  @lt('quantity', 0)
  isNegative!: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    quant: QuantModel;
  }
}
