import { computed } from '@ember/object';
import { alias, mapBy } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import CLSessionService from './-ts/session';
import KonfigService from './konfig';

/**
 * Defines a service which provides current user information.
 */
export default class MeService extends Service {
  @service declare konfig: KonfigService;
  @service declare session: CLSessionService;

  /**
   * Principal information.
   */
  @alias('session.data.authenticated.principal') principal: any;

  /**
   * Current principal ID.
   */
  @alias('principal.id') cuid: any;

  /**
   * User name of the principal.
   */
  @alias('principal.username') username: any;

  /**
   * Full name of the principal.
   */
  @alias('principal.fullname') fullname: any;

  /**
   * Email address of the principal.
   */
  @alias('principal.email') email: any;

  /**
   * Roles of the principal.
   */
  @alias('principal.roles') roles: any;

  /**
   * Roles codes of the principal.
   */
  @mapBy('roles', 'code') roleCodes: any;

  /**
   * Teams of the principal.
   */
  @alias('principal.teams') teams: any;

  /**
   * Names of the teams the principal belongs to.
   */
  @mapBy('teams', 'name') teamNames: any;

  /**
   * Indicates if the user has internal privileges.
   */
  @computed('principal.internal')
  get internal() {
    return this.principal?.internal ? true : false;
  }

  /**
   * Indicates if the user has external privileges.
   */
  @computed('principal.external')
  get external() {
    return this.principal?.external ? true : false;
  }

  /**
   * Indicates if the user has super user privileges.
   */
  @computed('principal.privileged')
  get privileged() {
    return this.principal?.privileged ? true : false;
  }

  /**
   * Display name of the principal.
   */
  @computed('fullname', 'username')
  get displayName() {
    return isEmpty(this.fullname) || this.fullname === '' ? this.username : this.fullname;
  }
}
