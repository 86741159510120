import { Service } from '../-service';
import {
  InstanceStatistics,
  Performance,
  PerformancePeriod,
  PerformanceQuery,
  PerformanceResult,
  PerformanceSummary,
  PerformanceSummaryItem,
  PerformanceSummaryItemValue,
  PeriodStatistics,
  request,
  ReturnStatistics,
  ReturnStatisticsMetric,
  TimeSeries,
  TimeSeriesFrequencies,
  TimeSeriesFrequency,
} from './-internal';

// Export public symbols:
export {
  InstanceStatistics,
  Performance,
  PerformancePeriod,
  PerformanceQuery,
  PerformanceResult,
  PerformanceSummary,
  PerformanceSummaryItem,
  PerformanceSummaryItemValue,
  PeriodStatistics,
  ReturnStatistics,
  ReturnStatisticsMetric,
  TimeSeries,
  TimeSeriesFrequencies,
  TimeSeriesFrequency,
};

/**
 * Provides a performance service.
 */
export class PerformanceService extends Service {
  /**
   * Retrieves and compiles performance report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  get(query: PerformanceQuery): Promise<Performance> {
    return request(this.client, query);
  }
}
