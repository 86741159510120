import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'search',
    'date__gte',
    'date__lte',
    'updated__gte',
    'updated__lte',
  ]);

  model(params: any) {
    return this.store.query('compliancecheck', cleanParams(params));
  }

  @action error(error: any) {
    return handle404OnNonExistingPage(this, error);
  }
}
