import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import QuantModel from 'cafelatte/models/quant';
import CLAjaxService from 'cafelatte/services/-ts/ajax';

interface Args {
  record: QuantModel;
}

export default class extends Component<Args> {
  @service declare ajax: CLAjaxService;
  @service declare flashMessages: any;

  @action onFlagChange(flag: any) {
    // Save the record:
    this.ajax
      .post(`/quants/${this.args.record.id}/flag/`, {
        data: { cflag: flag },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.flashMessages.success('Record is flagged.');
        this.args.record.reload();
      })
      .catch(() => {
        this.flashMessages.danger('Record could not be flagged.');
      });
  }
}
