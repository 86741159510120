import { Maybe } from './maybe';

export interface Frequency {
  id: number;
  value: string;
}

export const FrequencyOptions: Array<Frequency> = [
  { id: 1, value: 'Yearly' },
  { id: 2, value: 'Semiannually' },
  { id: 4, value: 'Quarterly' },
  { id: 6, value: 'Bimonthly' },
  { id: 12, value: 'Monthly' },
  { id: 3, value: '3 times a year' },
  { id: 5, value: '5 times a year' },
  { id: 7, value: '7 times a year' },
  { id: 8, value: '8 times a year' },
  { id: 9, value: '9 times a year' },
  { id: 10, value: '10 times a year' },
  { id: 11, value: '11 times a year' },
];

export const FrequencyOptionsLookup: Record<number, Frequency> = FrequencyOptions.reduce(
  (p, c) => ({ ...p, [c.id]: c }),
  {}
);

export function getFrequencyById(id: number): Maybe<Frequency> {
  return FrequencyOptionsLookup[id];
}
