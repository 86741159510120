import { CommonController } from 'cafelatte/libs/embered';
import TradeTeamModel from 'cafelatte/models/tradeteam';
import { FlexTableSpec, mkAuditFields, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  queryParams = ['page', 'page_size', 'search', 'name__icontains', 'created__gte', 'created__lte', 'members'];
  page = 1;
  page_size = 20;

  get tableSpec(): FlexTableSpec<TradeTeamModel> {
    return {
      ident: 'cl-trade-team-list',
      vfill: true,
      columns: [
        { key: 'id', component: '@link', options: { route: 'trading.tradeteam.details', modelPath: 'id' } },
        { key: 'name', component: '@link', options: { route: 'trading.tradeteam.details', modelPath: 'id' } },
        {
          key: 'members',
          value: ({ record }) =>
            record
              .get('members')
              ?.map((x) => x.get('username'))
              .join(', '),
        },
        ...mkAuditFields<TradeTeamModel>(this.me),
      ],
      actions: mkCommonRowActions([
        { type: 'details', action: ({ record }) => this.router.transitionTo('trading.tradeteam.details', record.id) },
      ]),
    };
  }
}
