import { inject as service } from '@ember/service';
// @ts-expect-error
import DRFAdapter from 'cafelatte/adapters/drf';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import CLSessionService from 'cafelatte/services/session';

/**
 * Provides Ember Django Rest Framework data adapter.
 */
export default class Application extends DRFAdapter {
  /**
   * Session service.
   */
  @service declare session: CLSessionService;

  /**
   * Override headers with authorization information.
   */
  get headers(): Record<string, string> {
    return this.session.token ? { Authorization: `Token ${this.session.token}` } : {};
  }

  /**
   * Adjust the ajax call to the API requirements.
   *
   * In particular, we are passing the `traditional: true` to AJAX options so that querystring array parameters are sent
   * in traditional params.
   */
  ajax(url: string, type: any, options: Maybe<Record<string, any>>) {
    return super.ajax(url, type, { traditional: true, ...(options || {}) });
  }

  /**
   * Handles the response, in particular 401 errors.
   *
   * @param status Status code.
   * @param args Further arguments to the method.
   * @returns Either result of invalidation in case of a 401 error, or the
   * result of the DRF's ``handleResponse`` method.
   */
  handleResponse(status: number, ...args: any) {
    return status === 401
      ? this.session.invalidate('authenticator:decaf', false)
      : super.handleResponse(status, ...args);
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    application: Application;
  }
}
// XREVIEW=TODO
// TODO: Should we replace DRF with our own implementation?
