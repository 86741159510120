import { DecafClient } from '@decafhub/decaf-client';
import { Currency, Id } from '../../commons/types';
import { SDate } from '../../prelude/datetime';
import { SimpleChoices } from '../../prelude/simple-choice';
import queryInstitutions from './-query-institutions-all';
import queryTeams from './-query-teams-all';

export type ExecutiveBreakdownType = 'team' | 'institution';

export interface ExecutiveItem {
  asof: SDate;
  rccy: Currency;
  pending: number;
  settled: number;
  totaled: number;
  nav: number;
  aum: number;
}

export interface ExecutiveLabeledItem extends ExecutiveItem {
  label: string;
  roaum: number;
}

export interface ExecutiveQuery {
  breakdown: ExecutiveBreakdownType;
  refccy: string;
  date: SDate;
}

export const ExecutiveBreakdownChoices: SimpleChoices<string> = [
  { value: 'team', label: 'Team' },
  { value: 'institution', label: 'Institution' },
];

/**
 * Attempts to retrieve and compile remote report data.
 *
 * @param client barista client.
 * @returns Report data.
 */
export async function request(client: DecafClient, query: ExecutiveQuery): Promise<ExecutiveLabeledItem[]> {
  const queryql = query.breakdown == 'team' ? queryTeams : queryInstitutions;
  const urlpath = query.breakdown == 'team' ? 'teams' : 'institutions';

  // Get instances:
  const instances: [Id, string][] = await getRemoteLabeledItems(client, queryql);

  // Get raw items:
  const data: ExecutiveLabeledItem[] = await Promise.all(
    instances.map(([x, y]) =>
      client.barista
        .get<ExecutiveItem>(`/${urlpath}/${x}/executive/`, { params: { refccy: query.refccy, asof: query.date } })
        .then(({ data }) => ({ ...data, label: y, roaum: data.aum == 0 ? 0 : data.totaled / data.aum }))
    )
  ).then((x) => x.sort((a, b) => a.label.localeCompare(b.label)));

  // Compute totals:
  const totalVouchers = data.map((x) => x.totaled).reduce((acc, x) => acc + x, 0);
  const totalAum = data.map((x) => x.aum).reduce((acc, x) => acc + x, 0);

  // Add totals to data:
  data.push({
    label: 'Total',
    asof: query.date,
    rccy: query.refccy,
    pending: data.map((x) => x.pending).reduce((acc, x) => acc + x, 0),
    settled: data.map((x) => x.settled).reduce((acc, x) => acc + x, 0),
    totaled: totalVouchers,
    nav: data.map((x) => x.nav).reduce((acc, x) => acc + x, 0),
    aum: totalAum,
    roaum: totalAum == 0 ? 0 : totalVouchers / totalAum,
  });

  // Sort and return:
  return data;
}

export async function getRemoteLabeledItems(client: DecafClient, query: any): Promise<[Id, string][]> {
  const records: { id: Id; label: string }[] = await client.microlot
    .query({ query })
    .then((result) => result.data?.records || []);

  return Object.entries(records.reduce((acc, x) => ({ ...acc, [x.id]: x.label }), {}));
}
