import { AccountResource } from 'cafelatte/libs/headless/commons/resources/account-resource';
import { ArtifactResource } from 'cafelatte/libs/headless/commons/resources/artifact-resource';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { identity } from 'cafelatte/libs/headless/prelude';
import { getOrElse, mapMaybeOrElse, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { parseDecimal, safeDivideOrZero, sumDecimal, toDecimal, ZERO } from 'cafelatte/libs/headless/prelude/numeric';
import Decimal from 'decimal.js';
import { ConsolidationReportValuation } from './-shared';
import { buildAccrual, RawAccrual } from './accrual';
import { buildHolding, RawHolding } from './holding';
import { Positions } from './positions';

export function computeValuation(data: {
  holdings: RawHolding[];
  nav?: Maybe<number>;
  gav?: Maybe<number>;
  aum?: Maybe<number>;
  valuation_net?: Maybe<number>;
  valuation_abs?: Maybe<number>;
  accrued?: Maybe<number>;
  liabilities?: Maybe<number>;
  investment?: Maybe<number>;
  pnl?: Maybe<number>;
  pnl_to_investment?: Maybe<number>;
}): ConsolidationReportValuation {
  // Compute exposure:
  const [netExposure, absExposure] = computeTotalExposure(data);

  // Compute total cash:
  const cash = computeTotalByType(data, 'CCY');
  const loan = computeTotalByType(data, 'LOAN');
  const deposit = computeTotalByType(data, 'DEPO');
  const totalCashDeposit = cash.add(deposit);

  // Get total NAV:
  const nav = mapMaybeOrElse((x) => new Decimal(x), ZERO, data.nav);

  return {
    gav: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.gav),
    nav,
    aum: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.aum),
    cash,
    loan,
    deposit,
    totalCashDeposit,
    totalCashDepositPercentage: safeDivideOrZero(totalCashDeposit, nav).times(new Decimal(100)),
    netValue: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.valuation_net),
    absValue: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.valuation_abs),
    netExposure,
    netExposureRatio: safeDivideOrZero(netExposure, nav),
    absExposure,
    absExposureRatio: safeDivideOrZero(absExposure, nav),
    accrued: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.accrued),
    liabilities: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.liabilities),
    investment: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.investment),
    unrealizedPNL: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.pnl),
    unrealizedPNLRatio: mapMaybeOrElse((x) => new Decimal(x), ZERO, data.pnl_to_investment),
  };
}

/**
 * Computes the total/net exposure for the given holdings container.
 *
 * @param data Holdings container.
 * @returns A two-tuple of total net/absolute exposure.
 */
export function computeTotalExposure(data: { holdings: RawHolding[] }): [netExposure: Decimal, absExposure: Decimal] {
  return data.holdings
    .filter((h) => !(h.artifact.type.id == 'CCY' || h.artifact.type.id == 'DEPO' || h.artifact.type.id == 'LOAN'))
    .reduce(
      ([n, a], h) => {
        return [
          n.add(toDecimal(h?.valuation?.exposure?.net?.ref || 0)),
          a.add(toDecimal(h?.valuation?.exposure?.abs?.ref || 0)),
        ];
      },
      [ZERO, ZERO]
    );
}

export function computeTotalByType(data: { holdings: RawHolding[] }, type: string): Decimal {
  return getOrElse(
    sumDecimal(
      data.holdings
        .filter((h) => h.artifact.type.id == type)
        .map((h) => mapMaybeOrElse(parseDecimal, ZERO, h?.valuation?.value?.net?.ref)),
      identity
    ),
    ZERO
  );
}

export function computePositions(
  data: { holdings: RawHolding[]; accruals: RawAccrual[] },
  accounts: Record<Id, AccountResource>,
  artifacts: Record<Id, ArtifactResource>,
  valuation: ConsolidationReportValuation
): Positions {
  return {
    holdings: data.holdings.map((x) => buildHolding(accounts, artifacts, valuation, x)),
    accruals: data.accruals.map((x) => buildAccrual(accounts, artifacts, x)),
  };
}
