import { Service } from '../-service';
import { getVersions, Versions } from './-internal';

/**
 * Service for remote DECAF API versions.
 */
export class VersionsService extends Service {
  /**
   * Returns remote DECAF API versions.
   */
  get(): Promise<Versions> {
    return getVersions(this.client);
  }
}

// Further exports from internal modules:
export * from './-internal';
