import Control from '../cl-rsel/component';

export default class extends Control {
  defaultOptions = {
    modelName: 'currency',
    modelLabel: 'code',
    modelById: true,
    modelIdProperty: 'code',
  };
}
