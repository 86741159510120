import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { getDate } from 'cafelatte/libs/headless/prelude/datetime';
import StockService, { Comparison } from 'cafelatte/services/stocks';

export default class extends CommonController {
  @service declare stocks: StockService;

  @tracked date1 = getDate('last-month-end');
  @tracked date2 = getDate('today');
  @tracked toggle = false;

  get comparison(): Promise<Comparison[]> {
    return this.stocks.fetchComparison(
      this.date1,
      this.date2,
      'commitment',
      'account',
      [this.model.id],
      [],
      this.toggle
    );
  }
}
