import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DecafService from 'cafelatte/services/decaf';
import { Chart, registerables } from 'chart.js';
import {
  WatchedInstrumentWithPrices,
  buildChartOptions,
  buildWatchedInstrumentsWithPrices,
  chartNoDataHandler,
  fetchWatchedInstruments,
  watchOrUnwatchInstrument,
} from './machinery';

Chart.register(...registerables);
Chart.register({
  id: 'no-data-message',
  afterDraw: chartNoDataHandler,
});

export default class extends Component {
  @service declare decaf: DecafService;
  @service declare router: RouterService;

  @tracked watchedInstruments: WatchedInstrumentWithPrices[] = [];
  @tracked itemCount = 4;
  @tracked watchModalOpen = false;
  @tracked selectedInstruments: number[] = [];
  @tracked loading = true;

  @action async didInsert(): Promise<void> {
    await this.getAndBuildWathcedInstruments();
    this.loading = false;
  }

  @action didInsertInner() {
    return false;
  }

  @action didInsertChartSection(element: HTMLCanvasElement, instrument: WatchedInstrumentWithPrices[]): any {
    const inst = instrument[0];
    if (!inst) return;
    return new Chart(element, {
      type: 'line',
      data: {
        labels: inst.prices.x,
        datasets: [
          {
            data: inst.prices.y,
            borderColor: '#377dff',
            borderWidth: 2,
            pointRadius: 0,
            pointHoverRadius: 0,
            tension: 0.4,
          },
        ],
      },
      options: buildChartOptions(),
    });
  }

  @action async getAndBuildWathcedInstruments() {
    const _watchedInstruments = await fetchWatchedInstruments(this.decaf.client);
    this.itemCount = _watchedInstruments.length;
    const r = await buildWatchedInstrumentsWithPrices(this.decaf.client, _watchedInstruments);
    this.watchedInstruments = r;
  }

  @action async watchInstruments() {
    const promises = this.selectedInstruments.map((id) => watchOrUnwatchInstrument(this.decaf.client, id, true));
    Promise.all(promises)
      .then(() => {
        this.getAndBuildWathcedInstruments();
        this.toggleSelectionModal();
      })
      .catch((err) => {
        alert('Failed to watch the instrument: ' + err.message);
      });
  }

  @action unWatchInstrument(id: number, event: Event) {
    event.stopPropagation();

    const obj = this.watchedInstruments.find((x) => id === x.id);
    if (obj !== undefined) {
      if (confirm(`You are about to unwatch Instrument "${obj?.name}". Are you sure about this?`)) {
        return watchOrUnwatchInstrument(this.decaf.client, id, false)
          .then(() => {
            this.watchedInstruments.removeObject(obj);
          })
          .catch((err) => {
            alert('Failed to watch the instrument: ' + err.message);
          });
      }
    }
  }

  @action toggleSelectionModal(e?: Event) {
    e?.preventDefault();
    this.watchModalOpen = !this.watchModalOpen;
    this.selectedInstruments = [];
  }
}
