import { CommonRoute } from 'cafelatte/libs/embered';

/**
 * Provides the top-level route.
 *
 * This implementation has 2 important tasks:
 *
 * 1. Ensure that we are authenticated as long as the transition is NOT to `auth.*` routes.
 * 2. Setup `pace` load indicator.
 */
export default class extends CommonRoute {
  async beforeModel(transition: any) {
    await this.session.setup();

    if (transition.to.name.startsWith('auth')) {
      return;
    }

    this.session.requireAuthentication(transition, 'auth.login');
  }
}
