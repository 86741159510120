import AgentModel from 'cafelatte/models/agent';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<AgentModel> {
  get spec(): FlexTableSpec<AgentModel> {
    return {
      ident: 'cl-agent-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'name' },
        { key: 'ctype.name', label: 'Type' },
        { key: 'institution.name', label: 'Institution' },
        ...mkAuditFields<AgentModel>(this.me),
      ],
    };
  }
}
