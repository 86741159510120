import { gql } from '@decafhub/decaf-client';

export const PORTFOLIO_SEARCH_QUERY = gql`
  query P($filter: portfolio_bool_exp!) {
    results: portfolio(where: $filter, limit: 25) {
      id
      name
    }
  }
`;

export const INSTRUMENT_SEARCH_QUERY = gql`
  query P($filter: artifact_bool_exp!) {
    results: artifact(where: $filter, limit: 25) {
      id
      name
      symbol
    }
  }
`;

export const ACCOUNT_SEARCH_QUERY = gql`
  query P($filter: account_bool_exp!) {
    results: account(where: $filter, limit: 25) {
      id
      name
      portfolio {
        id
        name
      }
      custodian {
        id
        name
      }
      analytical_type {
        id
        name
      }
    }
  }
`;

export const TRADE_SEARCH_QUERY = gql`
  query P($filter: action_bool_exp!) {
    results: action(where: $filter, limit: 25) {
      id
      main_quantity
      main_price
      main_account {
        id
        name
      }
      main_artifact {
        id
        name
      }
    }
  }
`;

export const TRANSACTION_SEARCH_QUERY = gql`
  query P($filter: quant_bool_exp!) {
    results: quant(where: $filter, limit: 25) {
      id
      value_quantity
      artifact {
        id
        name
      }
      account {
        id
        name
      }
    }
  }
`;
