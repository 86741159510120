import { DecafClient } from '@decafhub/decaf-client';
import { AssetsEvolutionReportService } from './assets-evolution-report';
import { BeanbagService } from './beanbag';
import { CalendarEventsService } from './calendar-events';
import { CashStatementService } from './cash-statement';
import { ConsolidationReportService } from './consolidation-report';
import { DayCountService } from './daycount';
import { DocumentService } from './document-library';
import { ExternalValuationService } from './external-valuation';
import { HistoricalValuationService } from './historical-valuation';
import { RemoteInformationService } from './info';
import { LedgerReportService } from './ledger-report';
import { PerformanceService } from './performance';
import { PConsolidationService } from './persisted-consolidation';
import { PhonelessService } from './phoneless';
import { PortfoliosService } from './portfolios';
import { PrincipalsService } from './principals';
import { ProfileService } from './profile';
import { ReturnsGridService } from './returns-grid';
import { SimulationService } from './simulation';
import { StocksService } from './stocks';
import { VersionsService } from './versions';
import { VouchersService } from './vouchers';

/**
 * Provides an interface for barista services collection.
 */
export interface ServiceCollection {
  /**
   * Version service.
   */
  versions: VersionsService;

  /**
   * Assets evolution report service.
   */
  assetsEvolutionReport: AssetsEvolutionReportService;

  /**
   * Consolidation report service.
   */
  consolidationReport: ConsolidationReportService;

  /**
   * Remote information service.
   */
  remoteInformation: RemoteInformationService;

  /**
   * Ledger report service.
   */
  ledgerReport: LedgerReportService;

  /**
   * Day count convention service.
   */
  daycount: DayCountService;

  /**
   * Profile service.
   */
  profile: ProfileService;

  /**
   * Persisted consolidation service.
   */
  persistedConsolidation: PConsolidationService;

  /**
   * External valuation service.
   */
  externalValuation: ExternalValuationService;

  /**
   * Historival valuation service.
   */
  historicalValuation: HistoricalValuationService;

  /**
   * Simulation service.
   */
  simulation: SimulationService;

  /**
   * Principals service.
   */
  principals: PrincipalsService;

  /**
   * Stocks-at-hand service.
   */
  stocks: StocksService;

  /**
   * Beanbag service.
   */
  beanbag: BeanbagService;

  /**
   * Vouchers service.
   */
  vouchers: VouchersService;

  /**
   * Returns grid service.
   */
  returnsgrid: ReturnsGridService;

  /**
   * Performance service.
   */
  performance: PerformanceService;

  /**
   * Calendar events service.
   */
  calendarEvents: CalendarEventsService;

  /**
   * Cash statement service.
   */
  cashStatement: CashStatementService;

  /**
   * Document service.
   */
  document: DocumentService;

  /**
   * Document service.
   */
  phoneless: PhonelessService;

  /**
   * Portfolios service.
   */
  portfolios: PortfoliosService;
}

/**
 * Compiles a [[ServiceCollection]] with the given [[Client]] instance.
 *
 * @param client barista client which the collection will use to communicate with the remote API.
 * @returns A [[ServiceCollection]] object.
 */
export function makeServiceCollection(client: DecafClient): ServiceCollection {
  return {
    versions: new VersionsService(client),
    assetsEvolutionReport: new AssetsEvolutionReportService(client),
    consolidationReport: new ConsolidationReportService(client),
    remoteInformation: new RemoteInformationService(client),
    ledgerReport: new LedgerReportService(client),
    daycount: new DayCountService(client),
    profile: new ProfileService(client),
    persistedConsolidation: new PConsolidationService(client),
    externalValuation: new ExternalValuationService(client),
    historicalValuation: new HistoricalValuationService(client),
    simulation: new SimulationService(client),
    principals: new PrincipalsService(client),
    stocks: new StocksService(client),
    beanbag: new BeanbagService(client),
    vouchers: new VouchersService(client),
    returnsgrid: new ReturnsGridService(client),
    performance: new PerformanceService(client),
    calendarEvents: new CalendarEventsService(client),
    cashStatement: new CashStatementService(client),
    document: new DocumentService(client),
    phoneless: new PhonelessService(client),
    portfolios: new PortfoliosService(client),
  };
}
