import Store from '@ember-data/store';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { sumDecimal, ZERO } from 'cafelatte/libs/headless/prelude/numeric';
import { ConsolidationReportData } from 'cafelatte/libs/headless/services/consolidation-report';
import {
  AccountAccrual,
  AccountAccrualItem,
  Accrual,
} from 'cafelatte/libs/headless/services/consolidation-report/commons/accrual';
import ArtifactModel from 'cafelatte/models/resource';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import Decimal from 'decimal.js';

interface RowData {
  type: 'Total' | 'Analytical Type' | 'Sub Total' | 'Recorded';
  label: string;
  indent: number;
  accounts: Array<Id>;
  currency: Maybe<string>;
  valueOrg: Maybe<Decimal>;
  valueRef: Decimal;
}

function fromAccountAccrualItem(account: Id, label: string, indent: number, accrual: AccountAccrualItem): RowData {
  return {
    type: 'Recorded',
    label,
    indent,
    accounts: [account],
    currency: accrual.currency,
    valueOrg: accrual.valueOrg,
    valueRef: accrual.valueRef,
  };
}

function fromAccountAccrual(accrual: AccountAccrual): RowData {
  return {
    type: 'Sub Total',
    label: accrual.account.name,
    indent: 5,
    accounts: [accrual.account.id],
    currency: undefined,
    valueOrg: undefined,
    valueRef: accrual.value,
  };
}

function fromAccrual(accrual: Accrual): RowData {
  return {
    type: 'Analytical Type',
    label: accrual.name,
    indent: 3,
    accounts: accrual.byAccounts.map((accrual) => accrual.account.id),
    currency: undefined,
    valueOrg: undefined,
    valueRef: accrual.value,
  };
}

function compileRowData(report: ConsolidationReportData): Array<RowData> {
  const buffer: Array<RowData> = [];
  const accruals = report.positions.accruals;
  const accrualsTotal = sumDecimal(accruals, (x) => x.value) || ZERO;
  const totalRow: RowData = {
    type: 'Total',
    label: 'Total Accounting Accruals',
    indent: 0,
    accounts: [],
    currency: undefined,
    valueOrg: undefined,
    valueRef: accrualsTotal,
  };

  buffer.pushObject(totalRow);
  for (const accrual of accruals) {
    buffer.pushObject(fromAccrual(accrual));
    for (const accountAccrual of accrual.byAccounts) {
      buffer.pushObject(fromAccountAccrual(accountAccrual));

      for (const accountAccrualItem of accountAccrual.items) {
        buffer.pushObject(
          fromAccountAccrualItem(accountAccrual.account.id, accountAccrual.account.name, 6, accountAccrualItem)
        );
      }
    }
  }

  return buffer;
}

interface Args {
  report: ConsolidationReportData;
}

export default class extends Component<Args> {
  @service declare router: RouterService;
  @service declare store: Store;

  tableData = compileRowData(this.args.report);

  get tableSpec(): FlexTableSpec<RowData> {
    return {
      ident: 'cl-report-valuation-accruals-table',
      vfill: false,
      columns: [
        { key: 'type', classes: [({ record }) => [`ps-${record.indent}`]] },
        { key: 'label', classes: [({ record }) => [`ps-${record.indent}`]] },
        { key: 'currency' },
        {
          key: 'valueOrg',
          label: 'Value (Org)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00', colorize: true },
          action: ({ record }) => this.goToLedger(record),
        },
        {
          key: 'valueRef',
          label: 'Value (Ref)',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00', colorize: true },
        },
      ],
    };
  }

  @action goToLedger(record: RowData) {
    // Get accounts IDs:
    const accountIds: Id[] = record.accounts;

    // Get the currency code:
    const currency = record.currency;

    // Get artifact:
    this.store.query('resource', { ctype: 'CCY', symbol: currency, page_size: 1 }).then((data: any) => {
      // Attempt to get the artifact:
      const artifact: Maybe<ArtifactModel> = data.content[0];

      // Do we have an artifact?
      if (isNothing(artifact)) {
        // Nothing to be done:
        console.error('Can not find the artifact for the accrual information!', record);
        return;
      }

      // Get the artifact identifier:
      const artifactId = artifact.id;

      // Construct the url:
      const url = this.router.urlFor('ledger', {
        queryParams: {
          artifact: artifactId,
          accounts: accountIds.join(','),
          datespanMax: this.args.report.date,
          datetype: this.args.report.type,
        },
      });

      // Open the url:
      window.open(url);
    });
  }
}
