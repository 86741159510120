import Store from '@ember-data/store';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import OHLCSeriesModel from 'cafelatte/models/ohlc';

export default class extends Component {
  @service declare store: Store;

  @action onSeriesCreate(symbol: any) {
    this.store
      .createRecord('ohlc', { symbol })
      .save()
      // @ts-expect-error
      .then((x: OHLCSeriesModel) => set(this, 'args.record.series', x));
  }
}
