import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import AgentModel from 'cafelatte/models/agent';
import { FlexTableRowAction, mkCommonRowActions } from '../components/x/flex-table';

export default class extends CommonController {
  queryParams = ['page', 'page_size', 'search'];

  @tracked page = 1;
  @tracked page_size = 10;
  @tracked search = '';

  get tableOperations(): FlexTableRowAction<AgentModel>[] {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('agent.details', record.id) },
    ]);
  }
}
