import { BaristaClient } from '@decafhub/decaf-client';
import { Decimal } from 'decimal.js';
import saver from 'file-saver';
import PapaParse from 'papaparse';
import { DateType } from '../../commons/date-type';
import { Guid, Id, IdGuidName } from '../../commons/types';
import { SDate } from '../../prelude/datetime';
import { Maybe } from '../../prelude/maybe';

/**
 * Remote definition of stock-at-hand.
 */
export interface RemoteStockAtHand {
  artifact: Id;
  artifact_guid: Guid;
  artifact_ctype: string;
  artifact_stype: Maybe<string>;
  artifact_symbol: string;
  artifact_name: Maybe<string>;
  account: Id;
  account_guid: Guid;
  account_name: string;
  portfolio: Id;
  portfolio_guid: Guid;
  portfolio_name: string;
  team: Id;
  team_guid: Guid;
  team_name: string;
  custodian: Id;
  custodian_guid: Guid;
  custodian_name: string;
  quantity: number;
}

/**
 * Definition of stock-at-hand.
 */
export interface StockAtHand {
  artifact: StockAtHandArtifact;
  account: StocksAtHandAccount;
  quantity: Decimal;
}

/**
 * Definition of artifact summary for stock-at-hand.
 */
export interface StockAtHandArtifact {
  id: Id;
  guid: Guid;
  ctype: string;
  stype: Maybe<string>;
  symbol: string;
  name: Maybe<string>;
}

/**
 * Definition of account summary for stock-at-hand.
 */
export interface StocksAtHandAccount extends IdGuidName {
  custodian: IdGuidName;
  portfolio: StocksAtHandPortfolio;
}

/**
 * Definition of portfolio summary for stock-at-hand.
 */
export interface StocksAtHandPortfolio extends IdGuidName {
  team: IdGuidName;
}

/**
 * Stocks at hand query type.
 */
export interface StocksAtHandQuery {
  artifacts: Array<Id>;
  container: Maybe<'account' | 'portfolio' | 'team' | 'institution'>;
  instances: Maybe<Array<Id>>;
  date: SDate;
  datetype: DateType;
  squared: boolean;
}

/**
 * Compiles raw endpoint response data to report data.
 *
 * @param response Response data received from the API endpoint.
 * @returns Final report data encoded for library client usage.
 */
export function compileReport(data: Array<RemoteStockAtHand>): Array<StockAtHand> {
  return data.map((x) => ({
    artifact: {
      id: x.artifact,
      guid: x.artifact_guid,
      ctype: x.artifact_ctype,
      stype: x.artifact_stype,
      symbol: x.artifact_symbol,
      name: x.artifact_name,
    },
    account: {
      id: x.account,
      guid: x.account_guid,
      name: x.account_name,
      custodian: { id: x.custodian, guid: x.custodian_guid, name: x.custodian_name },
      portfolio: {
        id: x.portfolio,
        guid: x.portfolio_guid,
        name: x.portfolio_name,
        team: { id: x.team, guid: x.team_guid, name: x.team_name },
      },
    },
    quantity: new Decimal(x.quantity),
  }));
}

/**
 * Compiles the library representation of report query into remote endpoint query parameters.
 *
 * @param query Query.
 * @returns Remote endpoint query parameters.
 */
export function query2params(query: StocksAtHandQuery): { [key: string]: any } {
  return {
    a: query.artifacts,
    c: query.container,
    i: query.instances || [],
    date: query.date,
    type: query.datetype,
    zero: query.squared,
    rich: true,
  };
}

/**
 * Attempts to retrieve and compile remote report data.
 *
 * @param client barista client.
 * @returns Report data.
 */
export function request(client: BaristaClient, query: StocksAtHandQuery): Promise<Array<StockAtHand>> {
  return client
    .get<Array<RemoteStockAtHand>>('/stocks/', { params: query2params(query) })
    .then(({ data }) => compileReport(data));
}

/**
 * Renders given stocks into a CSV file and downloads it.
 *
 * @param client barista client.
 */
export function downloadCsv(stocks: Array<StockAtHand>): void {
  saver.saveAs(toCSV(stocks), 'stocks.csv');
}

export function toCSV(stocks: Array<StockAtHand>): Blob {
  // If no stocks, return as is:
  if (!stocks.length) {
    return new Blob([PapaParse.unparse({ fields: [], data: [] })], {
      type: 'text/csv;charset=utf-8',
    });
  }

  // Initialize the content buffer:
  const content: Array<Array<any>> = [
    [
      'Instrument Type',
      'Instrument Symbol',
      'Instrument Name',
      'Account',
      'Portfolio',
      'Team',
      'Custodian',
      'Quantity',
    ],
  ];

  // Iterate over stocks and populate contents:
  for (const stock of stocks) {
    content.pushObject([
      stock.artifact.ctype,
      stock.artifact.symbol,
      stock.artifact.name,
      stock.account.name,
      stock.account.portfolio.name,
      stock.account.portfolio.team.name,
      stock.account.custodian.name,
      stock.quantity.toString(),
    ]);
  }

  // Done, return the CSV
  return new Blob([PapaParse.unparse(content)], {
    type: 'text/csv;charset=utf-8',
  });
}
