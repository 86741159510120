import { action } from '@ember/object';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  queryParams = ['method', 'reference'];

  @tracked code = '';
  @tracked method = '';
  @tracked reference = '';

  @action verifyOTP() {
    // Get parameters:
    const { reference, code, method } = this;

    // Cleanup fields:
    this.code = '';

    // We will not attempt to reach the server if any of the above is missing:
    if (isEmpty(reference) || isEmpty(code) || isEmpty(method)) {
      console.error('Some form input is empty or cleared earlier. Do not proceed.');
      return;
    }

    // Attempt to authenticate:
    this.session.authenticate('authenticator:decaf', { reference, code, method }).catch((reason: any) => {
      console.error('Error while authenticating', reason);
      this.flashMessages.danger('Error during authentication. Please check your credentials.');
    });
  }

  @action resendOTP(method: any) {
    // Get parameters:
    const reference = this.reference;

    // Cleanup fields:
    this.code = this.reference = '';

    // Request the password reset email and handle the response:
    this.ajax
      .request('/otp/resend/', { type: 'POST', data: { reference, method }, dataType: 'json' })
      .then((data) =>
        run(() => {
          // Good, we will now transition to OTP verification route (again):
          this.router.transitionTo('auth.otp', { queryParams: { reference: data.reference, method: data.method } });

          // Notify the user:
          this.flashMessages.success(data.message);
        })
      )
      .catch((errors) => {
        // Log errors to the console for debugging:
        console.error(errors);

        // Inform the user that there was a problem:
        this.flashMessages.danger('An error occured. Try again or start over...');
      });
  }

  @action startOver() {
    this.code = this.reference = '';
    this.router.transitionTo('auth.login');
  }
}
