import { inject as service } from '@ember/service';
import { CommonController } from 'cafelatte/libs/embered';
import DecafService from 'cafelatte/services/decaf';

export default class extends CommonController {
  @service declare decaf: DecafService;

  get records() {
    return this.decaf.services.historicalValuation.query({
      internal: {
        portfolio: `${this.model.id}`,
        account__isnull: 'true',
        shareclass__isnull: this.model.isFund ? 'false' : 'true',
      },
      external: {
        portfolio: `${this.model.id}`,
        shareclass__isnull: this.model.isFund ? 'false' : 'true',
      },
    });
  }
}
