import { getOrElse } from '../../../prelude/maybe';
import { ZERO } from '../../../prelude/numeric';
import { SimpleChoices } from '../../../prelude/simple-choice';
import { Holding } from './holding';

export type AssetAllocation = AssetAllocationItem[];

export interface AssetAllocationItem {
  group: string;
  value: number;
  ratio: number;
}

export type ValueType = 'net-value' | 'abs-value' | 'net-exp' | 'abs-exp';

export const ValueTypes: SimpleChoices<ValueType> = [
  { value: 'net-value', label: 'Net Asset Value' },
  { value: 'abs-value', label: 'Gross Asset Value' },
  { value: 'net-exp', label: 'Net Exposure' },
  { value: 'abs-exp', label: 'Gross Exposure' },
];

export const ValueExtractor: Record<ValueType, (holding: Holding) => number> = {
  'net-value': (holding) => getOrElse(holding.valuation.value.net.ref, ZERO).toNumber(),
  'abs-value': (holding) => getOrElse(holding.valuation.value.abs.ref, ZERO).toNumber(),
  'net-exp': (holding) => getOrElse(holding.valuation.exposure.net.ref, ZERO).toNumber(),
  'abs-exp': (holding) => getOrElse(holding.valuation.exposure.abs.ref, ZERO).toNumber(),
};

export type GroupingType = 'asset-class' | 'type' | 'subtype' | 'currency' | 'country' | 'issuer' | 'sector';

export const GroupingTypes: SimpleChoices<GroupingType> = [
  { value: 'asset-class', label: 'Asset Class' },
  { value: 'type', label: 'Instrument Type' },
  { value: 'subtype', label: 'Sub-Type' },
  { value: 'currency', label: 'Currency' },
  { value: 'country', label: 'Country' },
  { value: 'issuer', label: 'Issuer' },
  { value: 'sector', label: 'Sector' },
];

export const GroupingExtractor: Record<GroupingType, (holding: Holding) => string> = {
  'asset-class': (holding) => getOrElse(holding.classification[0]?.name, '#N/A'),
  type: (holding) => getOrElse(holding.artifact.type, '#N/A'),
  subtype: (holding) => getOrElse(holding.artifact.stype, '#N/A'),
  currency: (holding) => getOrElse(holding.artifact.ccymain, '#N/A'),
  country: (holding) => getOrElse(holding.artifact.country, '#N/A'),
  issuer: (holding) => getOrElse(holding.artifact.issuer, '#N/A'),
  sector: (holding) => getOrElse(holding.artifact.sector, '#N/A'),
};

export function compileAssetAllocation(holdings: Holding[], grouping: GroupingType, value: ValueType): AssetAllocation {
  // Extract groups and values:
  const data = holdings.map((h) => ({ group: GroupingExtractor[grouping](h), value: ValueExtractor[value](h) }));

  // Get total value:
  const total = data.reduce((acc, x) => acc + x.value, 0);

  // Prepare the groups:
  const groups = data.reduce(
    (acc, x) => ({ ...acc, [x.group]: { value: (acc[x.group]?.value || 0) + x.value, ratio: 0 } }),
    {} as Record<string, { value: number; ratio: number }>
  );

  // Recompute ratios:
  if (total != 0) {
    Object.values(groups).forEach((v) => {
      v.ratio = v.value / total;
    });
  }

  // Reshape the data and return:
  return Object.entries(groups).map(([k, v]) => ({ ...v, group: k }));
}
