import Model, { attr, belongsTo } from '@ember-data/model';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import ActionModel from './trade';
import TradeOrderAllocationModel from './tradeorderallocation';
import TradingStateModel from './trading/state';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = [
  'allocation',
  'date',
  'time',
  'quantity',
  'pricenet',
  'pricegrs',
  'accrdccy',
  'accrdqty',
  'feestyp1',
  'feesccy1',
  'feesqty1',
  'feestyp2',
  'feesccy2',
  'feesqty2',
  'feestyp3',
  'feesccy3',
  'feesqty3',
  'feestyp4',
  'feesccy4',
  'feesqty4',
  'reference',
  'notes',
  'remarks',
  'booking',
];

export default class TradeOrderExecutionModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @belongsTo('tradeorderallocation')
  declare allocation?: BelongsTo<TradeOrderAllocationModel>;

  @attr({ defaultValue: today })
  declare date: string;

  @attr()
  declare time?: string;

  @attr('number')
  declare quantity?: number;

  @attr()
  declare pricenet?: string;

  @attr()
  declare pricegrs?: string;

  @attr()
  declare accrdccy?: string;

  @attr()
  declare accrdqty?: string;

  @attr()
  declare feestyp1?: string;

  @attr()
  declare feesccy1?: string;

  @attr()
  declare feesqty1?: string;

  @attr()
  declare feestyp2?: string;

  @attr()
  declare feesccy2?: string;

  @attr()
  declare feesqty2?: string;

  @attr()
  declare feestyp3?: string;

  @attr()
  declare feesccy3?: string;

  @attr()
  declare feesqty3?: string;

  @attr()
  declare feestyp4?: string;

  @attr()
  declare feesccy4?: string;

  @attr()
  declare feesqty4?: string;

  @attr()
  declare reference?: string;

  @attr()
  declare notes?: string;

  @attr()
  declare remarks?: string;

  @belongsTo('trade')
  declare booking?: BelongsTo<ActionModel>;

  @attr('boolean')
  declare booked?: boolean;

  @attr()
  declare monetary?: string;

  @belongsTo('trading/state')
  declare cstate?: BelongsTo<TradingStateModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tradeorderexecution: TradeOrderExecutionModel;
  }
}
