import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { ControllerWithCurrentRecord, QueryParamDefaultsFactory } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import OHLCObservationModel from 'cafelatte/models/ohlc/observation';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export const QueryParamDefaults: QueryParamDefaultsFactory = {
  page: '1',
  page_size: '20',
  search: '',
  symbol: '',
  since: '',
  until: '',
  source: '',
};

export default class extends ControllerWithCurrentRecord<OHLCObservationModel> {
  queryParams = Object.keys(QueryParamDefaults);

  @tracked page = 1;
  @tracked page_size = 20;

  get tableOperations(): FlexTableRowAction<OHLCObservationModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doResetCurrentRecordRefreshModel },
    ]);
  }

  createNewRecord(): OHLCObservationModel {
    return this.store.createRecord('ohlc/observation', { date: today() });
  }

  @action onError(_message: string, error: any) {
    console.error(error);
  }
}
