import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ActionModel from 'cafelatte/models/trade';
import DecafService from 'cafelatte/services/decaf';

interface Args {
  record: ActionModel;
  onSuccess?: () => void;
}

export default class extends Component<Args> {
  @service declare decaf: DecafService;
  @service declare flashMessages: any;

  @tracked prompt?: number;

  @action migrateActionType(ctype: number) {
    this.prompt = ctype;
  }

  @action cancel() {
    this.prompt = undefined;
  }

  @action migrate(to: number, underlying?: number) {
    this.decaf.client.barista
      .post(`/trades/${this.args.record.id}/migrate/`, { to, underlying })
      .then(() => {
        this.flashMessages.success('Operation is successful.');
        if (this.args.onSuccess) {
          this.args.onSuccess();
        }
      })
      .catch((err) => {
        console.error(err);
        this.flashMessages.danger('Operation has failed.');
      })
      .finally(() => {
        this.prompt = undefined;
      });
    this.prompt = undefined;
  }
}
