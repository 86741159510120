import { DecafClient } from '@decafhub/decaf-client';
import Decimal from 'decimal.js';
import { Id, IdName, Lookup } from '../../commons/types';
import { SDate, SDateTime } from '../../prelude/datetime';
import { mapMaybe, Maybe } from '../../prelude/maybe';
import { toDecimal } from '../../prelude/numeric';

/**
 * Report query type.
 */
export type ExternalValuationQuery = Record<string, string>;

/**
 * Response data item type.
 */
export interface ExternalValuationResponseItem {
  id: Id;
  created: SDateTime;
  creator: Maybe<Id>;
  updated: SDateTime;
  updater: Maybe<Id>;
  guid: string;
  portfolio: Id;
  shareclass: Maybe<Id>;
  date: SDate;
  ccy: string;
  shares: Maybe<number>;
  price: Maybe<number>;
  nav: Maybe<number>;
  aum: Maybe<number>;
  hedgepnl: Maybe<number>;
  feemngt: Maybe<number>;
  feeperf: Maybe<number>;
  otheraccrued: Maybe<number>;
  totalaccrued: Maybe<number>;
  subred: Maybe<number>;
  perfdaily: Maybe<number>;
  perfweekly: Maybe<number>;
  perfmonthly: Maybe<number>;
  perfytd: Maybe<number>;
  perfstart: Maybe<number>;
  coefficient: Maybe<number>;
}

/**
 * Treated external valuation item.
 */
export interface ExternalValuationItem {
  id: Id;
  created: SDateTime;
  creator: Maybe<Id>;
  updated: SDateTime;
  updater: Maybe<Id>;
  guid: string;
  portfolio: IdName;
  shareclass: Maybe<IdName>;
  date: SDate;
  ccy: string;
  shares: Maybe<Decimal>;
  price: Maybe<Decimal>;
  nav: Maybe<Decimal>;
  aum: Maybe<Decimal>;
  hedgepnl: Maybe<Decimal>;
  feemngt: Maybe<Decimal>;
  feeperf: Maybe<Decimal>;
  otheraccrued: Maybe<Decimal>;
  totalaccrued: Maybe<Decimal>;
  subred: Maybe<Decimal>;
  perfdaily: Maybe<Decimal>;
  perfweekly: Maybe<Decimal>;
  perfmonthly: Maybe<Decimal>;
  perfytd: Maybe<Decimal>;
  perfstart: Maybe<Decimal>;
  coefficient: Maybe<Decimal>;
}

/**
 * Attempts to query and return external valuations.
 *
 * @param client barista client.
 * @returns External valuations.
 */
export async function request(
  client: DecafClient,
  query: ExternalValuationQuery
): Promise<Array<ExternalValuationItem>> {
  // Define request parameters:
  const params = { ...query, page_size: -1 };

  // Perform the data request:
  const response = await client.barista.get<Array<ExternalValuationResponseItem>>('/externalvaluations/', { params });

  // Get the response data:
  const items = response.data;

  // Get lookup tables:
  const portfolios = await getPortfolios(client, items);
  const shareclasses = await getShareClasses(client, items);

  // Compile the data and return:
  // @ts-expect-error ts2322
  return items.map((x) => ({
    id: x.id,
    created: x.created,
    creator: x.creator,
    updated: x.updated,
    updater: x.updater,
    guid: x.guid,
    portfolio: portfolios[x.portfolio],
    shareclass: x.shareclass ? shareclasses[x.shareclass] : undefined,
    date: x.date,
    ccy: x.ccy,
    shares: mapMaybe(toDecimal, x.shares),
    price: mapMaybe(toDecimal, x.price),
    nav: mapMaybe(toDecimal, x.nav),
    aum: mapMaybe(toDecimal, x.aum),
    hedgepnl: mapMaybe(toDecimal, x.hedgepnl),
    feemngt: mapMaybe(toDecimal, x.feemngt),
    feeperf: mapMaybe(toDecimal, x.feeperf),
    otheraccrued: mapMaybe(toDecimal, x.otheraccrued),
    totalaccrued: mapMaybe(toDecimal, x.totalaccrued),
    subred: mapMaybe(toDecimal, x.subred),
    perfdaily: mapMaybe(toDecimal, x.perfdaily),
    perfweekly: mapMaybe(toDecimal, x.perfweekly),
    perfmonthly: mapMaybe(toDecimal, x.perfmonthly),
    perfytd: mapMaybe(toDecimal, x.perfytd),
    perfstart: mapMaybe(toDecimal, x.perfstart),
    coefficient: mapMaybe(toDecimal, x.coefficient),
  }));
}

export async function getPortfolios(
  cli: DecafClient,
  xs: Array<ExternalValuationResponseItem>
): Promise<Lookup<Id, IdName>> {
  const ids = xs.map((x) => x.portfolio).uniq();
  if (ids.length === 0) {
    return {};
  }
  const response = await cli.barista.get<Array<IdName>>('/portfolios/', {
    params: { page_size: -1, id__in: ids.join(',') },
  });
  return response.data.reduce((p, i) => ({ ...p, [i.id]: { id: i.id, name: i.name } }), {});
}

export async function getShareClasses(
  cli: DecafClient,
  xs: Array<ExternalValuationResponseItem>
): Promise<Lookup<Id, IdName>> {
  const ids = xs
    .map((x) => x.shareclass)
    .filter((x) => x)
    .uniq();
  if (ids.length === 0) {
    return {};
  }
  const response = await cli.barista.get<Array<IdName>>('/shareclasses/', {
    params: { page_size: -1, id__in: ids.join(',') },
  });
  return response.data.reduce((p, i) => ({ ...p, [i.id]: { id: i.id, name: i.name } }), {});
}
