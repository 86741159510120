import Store from '@ember-data/store';
import { action, computed, notifyPropertyChange, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isJust, isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import AccountModel from 'cafelatte/models/account';
import DocumentModel from 'cafelatte/models/document';
import PortfolioModel from 'cafelatte/models/portfolio';
import ArtifactModel from 'cafelatte/models/resource';
import ActionModel from 'cafelatte/models/trade';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

interface Args {
  record: AccountModel | PortfolioModel | ActionModel | ArtifactModel;
}

/**
 * This component is a wrapper arount attachment listing component.
 *
 * In addition to listing attachments for a "generic" model type, it also
 * displays a header and allows users to add a new attachment.
 */
export default class extends Component<Args> {
  @service declare flashMessages: any;
  @service declare store: Store;

  @tracked page = 1;
  @tracked page_size = 20;
  @tracked currentRecord?: DocumentModel = undefined;

  get modelType(): Maybe<string> {
    const value = this.args.record;
    if (value instanceof AccountModel) {
      return 'account';
    } else if (value instanceof PortfolioModel) {
      return 'portfolio';
    } else if (value instanceof ActionModel) {
      return 'trade';
    } else if (value instanceof ArtifactModel) {
      return 'resource';
    } else {
      return undefined;
    }
  }

  @computed('args.record.id', 'modelType', 'page', 'page_size')
  get attachments() {
    return isNothing(this.modelType)
      ? []
      : this.store.query('document', {
          [this.modelType]: this.args.record.id,
          page: this.page,
          page_size: this.page_size,
        });
  }

  get attachmentOperations(): FlexTableRowAction<DocumentModel>[] {
    return [
      ...mkCommonRowActions<DocumentModel>([
        { type: 'edit', action: ({ record }) => this.setCurrentRecord(record) },
        { type: 'delete', onDelete: this.onDelete },
      ]),
      { icon: 'download', label: 'Download', type: 'success', action: ({ record }): void => record.download() },
    ];
  }

  /**
   * Unsets current attachment and refreshes the list of attachments (if
   * requested).
   *
   * @param refresh Whether to refresh the list of attachments.
   */
  _unsetCurrentRecord(refresh?: boolean) {
    this.currentRecord = undefined;
    if (refresh) {
      notifyPropertyChange(this, 'attachments');
    }
  }

  @action newAttachment(): void {
    if (isJust(this.modelType)) {
      set(this, 'currentRecord', this.store.createRecord('document', { [this.modelType]: this.args.record }));
    }
  }

  @action setCurrentRecord(x: any) {
    this.currentRecord = x;
  }

  @action onSave(_record: DocumentModel): void {
    this._unsetCurrentRecord(true);
    this.flashMessages.success('Record saved successfully.');
  }

  @action onError(message: string, error: any): void {
    console.error(error);
    this.flashMessages.danger(message);
  }

  @action onCancel(_record: DocumentModel, reverted: boolean): void {
    this._unsetCurrentRecord(true);
    if (reverted) {
      this.flashMessages.warning('Changes to record has been cancelled.');
    }
  }

  @action onDelete(): void {
    this._unsetCurrentRecord(true);
    this.flashMessages.warning('Record is deleted successfully');
  }
}
