import { DecafClient, gql } from '@decafhub/decaf-client';
import { Id } from '../../commons/types';
import { SDate } from '../../prelude/datetime';

export interface ReturnsGrid {
  columns: string[];
  records: ReturnsGridItem[];
}

export interface ReturnsGridRawItem {
  label: string;
  model: string;
  ident: number;
  symbol: string;
  stats: { label: string; value: number }[];
}

export interface ReturnsGridItem extends ReturnsGridRawItem {
  artifact?: ReturnsGridItemArtifact;
  results: Record<string, { value: number; decile: number }>;
  assetClass?: any;
}

export interface ReturnsGridItemArtifact {
  id: number;
  ctype: string;
  symbol: string;
  name?: string;
  currency?: string;
  country?: string;
  assetClass?: { id: number; name: string };
}

export interface ReturnsGridQuery {
  date: SDate;
  since?: SDate;
  until?: SDate;
  lookback: number;
  accounts?: Id[];
  portfolios?: Id[];
  portfoliogroups?: Id[];
  teams?: Id[];
  custodians?: Id[];
  artifacts?: Id[];
  ohlcs?: Id[];
}

/**
 * Attempts to retrieve and compile remote report data.
 *
 * @param client barista client.
 * @returns Report data.
 */
export async function requestGrid(client: DecafClient, query: ReturnsGridQuery): Promise<ReturnsGrid> {
  // Get raw items:
  const { data } = await client.barista.get<ReturnsGridRawItem[]>('/returnsgrid/', { params: query });

  // Find artifact IDs of interest:
  const artifactIds = data.filter((x) => x.model === 'artifact').map((x) => x.ident);

  // Fetch artifacts of interest:
  const artifacts = await getArtifactsOfInterest(client, artifactIds);

  // Get columns:
  // @ts-expect-error ts2532
  const columns = data.length > 0 ? data[0].stats.map((c) => c.label) : [];

  // Inject results:
  const records: ReturnsGridItem[] = data
    .map((x) => ({
      ...x,
      artifact: artifacts.filter((a) => a.id === x.ident)?.[0], // Inject artifact of interest
      results: x.stats.reduce((acc, c) => ({ ...acc, [c.label]: { value: c.value, decile: 0 } }), {}),
    }))
    .sort((a, b) => a.symbol.localeCompare(b.symbol));

  // Get the mins and maxes for each column:
  const minmaxes = columns.reduce((acc, c) => {
    // @ts-expect-error ts2532
    const values = records.map((x) => x.results[c].value);
    return { ...acc, [c]: { min: Math.min(...values), max: Math.max(...values) } };
  }, {} as Record<string, { min: number; max: number }>);

  // Recompute deciles:
  Object.entries(minmaxes).forEach(([column, { min, max }]) => {
    const range = max - min;
    records.forEach((record) => {
      const result = record.results[column];
      // @ts-expect-error ts2532
      result.decile = range == 0 ? 0 : Math.floor(((result.value - min) / range) * 10);
    });
  });

  // Done, return:
  return { columns, records };
}

export async function getArtifactsOfInterest(client: DecafClient, ids: number[]): Promise<ReturnsGridItemArtifact[]> {
  const query = gql`
    query ($ids: [Int!]) {
      artifact(where: { id: { _in: $ids } }) {
        id
        ctype: type
        symbol
        name
        currency: main_currency
        country
        assetClass: asset_class {
          id
          name
        }
      }
    }
  `;

  return client.microlot.query({ query, variables: { ids } }).then((result) => result.data?.artifact || []);
}
