import ApplicationAdapter from '../application';

// @ts-expect-error (TS2339)
export default class ResourceType extends ApplicationAdapter.extend({}) {
  pathForType(): string {
    return 'resources/types';
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'resource/type': ResourceType;
  }
}
