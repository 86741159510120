import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  layout = layout;

  value!: Maybe<Array<String>>;
  onChange!: Function;

  @action onTagAdd(value: string) {
    this.onChange([...(this.value || []), value]);
  }

  @action onTagRemove(index: number) {
    this.onChange((this.value || []).filter((_, i) => i !== index));
  }
}
