import Store from '@ember-data/store';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ArtifactModel from 'cafelatte/models/resource';
import ArtifactTypeModel from 'cafelatte/models/resource/type';
import { FlexTableRowAction } from 'cafelatte/pods/components/x/flex-table';

interface Args {
  onSelect?: (artifact: ArtifactModel) => void;
  payload?: Record<string, string | number | boolean>;
}

export default class extends Component<Args> {
  @service declare store: Store;

  @tracked page_size = 50;
  @tracked ctype?: ArtifactTypeModel = undefined;
  @tracked keyword?: string = undefined;

  get operations(): FlexTableRowAction<ArtifactModel>[] {
    return [{ type: 'success', icon: 'bullseye', action: ({ record }): void => this.onSelect(record) }];
  }

  @computed('ctype', 'keyword')
  get itemsWithSymbol() {
    return !this.keyword
      ? []
      : this.store.query('resource', {
          page_size: this.page_size,
          symbol: this.keyword,
          ...(this.ctype ? { ctype: this.ctype?.id } : {}),
        });
  }

  @computed('ctype', 'keyword')
  get items() {
    return this.store.query('resource', {
      page_size: this.page_size,
      ...(this.keyword ? { search: this.keyword } : {}),
      ...(this.ctype ? { ctype: this.ctype?.id } : {}),
    });
  }

  @action onSelect(artifact: ArtifactModel): void {
    this.args.onSelect?.(artifact);
  }
}
