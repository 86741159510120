import { BaristaClient } from '@decafhub/decaf-client';
import { Decimal } from 'decimal.js';
import { Currency } from '../../commons/types';
import { last } from '../../prelude/arrays';
import { DateRange, DateSeries } from '../../prelude/datetime';
import { isJust, mapMaybe, Maybe } from '../../prelude/maybe';
import { sumDecimal } from '../../prelude/numeric';

/**
 * Breakdown type.
 */
export type BreakdownType = 'team' | 'institution' | 'portfolio' | 'account';

/**
 * Available breakdown types.
 */
export const BreakdownTypes: Array<BreakdownType> = ['team', 'institution', 'portfolio', 'account'];

/**
 * Object model type.
 */
export type ObjectModelType = 'team' | 'institution' | 'portfolio' | 'account';

/**
 * Asset evolution query type.
 */
export interface ReportQuery {
  sandbox: boolean;
  currency: Currency;
  daterange: DateRange;
  breakdown1: BreakdownType;
  breakdown2: BreakdownType;
}

/**
 * Response data type.
 */
export type ResponseData = { [key: string]: ResponseDataParentItem };

/**
 * Response data parent item time.
 */
export interface ResponseDataParentItem extends ResponseDataChildItem {
  breakdown: { [key: string]: ResponseDataChildItem };
}

/**
 * Response data child (breakdown) item type.
 */
export interface ResponseDataChildItem {
  id: number;
  name: string;
  model: ObjectModelType;
  series: DateSeries<Maybe<RawObservation>>;
}

export interface RawObservation {
  nav: Maybe<number>;
  aum: Maybe<number>;
}

export interface Observation {
  nav: Maybe<Decimal>;
  aum: Maybe<Decimal>;
}

/**
 * Model instance encoding for named model instances.
 *
 * TODO: Think about this encoding and try to generalise.
 */
export interface NamedModelInstance {
  id: number;
  name: string;
  model: ObjectModelType;
}

/**
 * Report data type.
 */
export interface ReportData {
  nodes: Array<ParentNode>;
  totalNAV: Maybe<Decimal>;
  totalAUM: Maybe<Decimal>;
}

/**
 * Common type for report breakdown nodes.
 */
export interface Node {
  instance: NamedModelInstance;
  series: { nav: DateSeries<Maybe<Decimal>>; aum: DateSeries<Maybe<Decimal>> };
  lastNAV: Maybe<Decimal>;
  lastAUM: Maybe<Decimal>;
}

/**
 * Parent node type.
 */
export interface ParentNode extends Node {
  breakdown: Array<ChildNode>;
}

/**
 * Child node type.
 */
export type ChildNode = Node;

/**
 * Compiles raw endpoint response data to report date.
 *
 * @param response Response data received from the API endpoint.
 * @returns Final report data encoded for library client usage.
 */
export function compileReport(response: ResponseData): ReportData {
  // Compile node data:
  const nodes = Object.values(response).map(responseParent2reportParent);

  // Compile the data and return:
  return {
    nodes,
    totalNAV: sumDecimal(nodes, (x) => x.lastNAV),
    totalAUM: sumDecimal(nodes, (x) => x.lastAUM),
  };
}

export function safeObs(x: RawObservation | null): Observation {
  return x === null
    ? { nav: undefined, aum: undefined }
    : {
        nav: mapMaybe((i) => new Decimal(i), x.nav),
        aum: mapMaybe((i) => new Decimal(i), x.aum),
      };
}

export function responseChild2reportChild(child: ResponseDataChildItem): ChildNode {
  // Ensure that we do not have nulls:
  // @ts-expect-error ts2345
  const seriesValues = child.series.values.map(safeObs);

  // Get NAV/AuM series:
  const navSeries = { index: child.series.index, values: seriesValues.map((x) => x.nav) };
  const aumSeries = { index: child.series.index, values: seriesValues.map((x) => x.aum) };

  // Build the child node and return:
  return {
    instance: { id: child.id, name: child.name, model: child.model },
    series: { nav: navSeries, aum: aumSeries },
    lastNAV: last(navSeries.values.filter(isJust)),
    lastAUM: last(aumSeries.values.filter(isJust)),
  };
}

export function responseParent2reportParent(parent: ResponseDataParentItem): ParentNode {
  // Get as if the node is a child:
  const node = responseChild2reportChild(parent);

  // Get all children:
  const children = Object.values(parent.breakdown).map(responseChild2reportChild);

  // Build the parent value and return:
  return {
    instance: node.instance,
    series: node.series,
    lastNAV: node.lastNAV,
    lastAUM: node.lastAUM,
    breakdown: children,
  };
}

/**
 * Compiles the library representation of report query into remote endpoint query parameters.
 *
 * @param query Query.
 * @returns Remote endpoint query parameters.
 */
export function query2params(query: ReportQuery): { [key: string]: string } {
  return {
    currency: query.currency,
    datesmin: query.daterange[0],
    datesmax: query.daterange[1],
    breakdown1: query.breakdown1,
    breakdown2: query.breakdown2,
    sandbox: query.sandbox ? 'true' : 'false',
  };
}

/**
 * Finds and returns the barista version resource.
 *
 * @param client barista client.
 * @returns barista version resource.
 */
export function request(client: BaristaClient, query: ReportQuery): Promise<ReportData> {
  return client
    .get<ResponseData>('/assetevolves/', { params: query2params(query) })
    .then(({ data }) => compileReport(data));
}
