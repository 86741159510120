import EmberRouter from '@ember/routing/router';
import config from 'cafelatte/config/environment';

/**
 * Provides the router.
 */
export default class Router extends EmberRouter {
  /**
   * Defines the location type.
   */
  location = config.locationType;

  /**
   * Defines the root URL:
   */
  rootURL = config.rootURL;
}

// Define routes:
Router.map(function () {
  ////////////////////////////
  // UNAUTHENTICATED ROUTES //
  ////////////////////////////

  this.route('auth', function () {
    this.route('login');
    this.route('otp');
    this.route('passwordreset');
    this.route('passwordresetrequest');
  });

  //////////////////////////
  // AUTHENTICATED ROUTES //
  //////////////////////////

  this.route('profile');

  this.route('user', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('team', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('institution', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('portfolio', function () {
    this.route('details', { path: 'details/:id' }, function () {
      this.route('accounts');
      this.route('attachments');
      this.route('stockcomparison');
      this.route('accrualschedules');
      this.route('externalvaluations');
      this.route('shareclasses');
      this.route('investments');
      this.route('creditlines');
      this.route('profile');
      this.route('historicalvaluations');
    });
  });
  this.route('portfoliogroup', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('account', function () {
    this.route('details', { path: 'details/:id' }, function () {
      this.route('stockcomparison');
      this.route('attachments');
      this.route('mfeeschemes');
      this.route('operations');
      this.route('profile');
      this.route('creditlines');
      this.route('historicalvaluations');
      this.route('externalvaluations');
    });
  });
  this.route('analyticaltype');
  this.route('accrualschedule', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('resource', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('trade', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('shareclass', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('agent', { path: 'agents' }, function () {
    this.route('details', {
      path: '/:id',
    });
  });
  this.route('artifactattribute');
  this.route('document', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('strategy', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('riskprofile', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('quant', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('reports', function () {
    this.route('consolidation');
    this.route('portfolio');
    this.route('fund');
  });
  this.route('permissions');
  this.route('daycounts');
  this.route('pnlbook');
  this.route('ledger');
  this.route('cashstatement');
  this.route('dashboard', function () {
    this.route('allocation');
    this.route('assets');
    this.route('calendar');
    this.route('executive');
    this.route('impmoves');
    this.route('latest');
    this.route('mdrecency');
    this.route('performance');
    this.route('returnsgrid');
    this.route('stockcomparison');
    this.route('vouchertimeline');
  });
  this.route('scfeeschedule', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('ohlc', function () {
    this.route('observation');
    this.route('pxupload');
  });
  this.route('voucher', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('settlement', function () {
    this.route('details', {
      path: 'details/:id',
    });
  });
  this.route('compliance', function () {
    this.route('profiles');
    this.route('quickreport');
  });
  this.route('trading', function () {
    this.route('simulation');
    this.route('tradeorder', function () {
      this.route('details', {
        path: 'details/:id',
      });
    });
    this.route('tradeordergroup', function () {
      this.route('details', {
        path: 'details/:id',
      });
    });
    this.route('tradeteam', function () {
      this.route('details', {
        path: 'details/:id',
      });
    });
  });

  ////////////////////
  // GENERIC ROUTES //
  ////////////////////

  this.route('404', { path: '/*path' });
  this.route('apikey');
  this.route('compliancecheck');
  this.route('complianceexemption');
  this.route('beanbag', function () {
    this.route('detail', {
      path: 'detail/:id',
    });
  });
  this.route('assetclass');
  this.route('externalvaluation');
  this.route('externalaccountvaluation');
});
