/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import { Promise } from 'rsvp';
import ArtifactModel from '../resource';
import { BelongsTo } from '../_internal/machinery';

const nipActionType = ['ctype'];
const nipStrategy = ['strategy'];
const nipInstitution = ['feeagt'];
const nipAccount = ['accaltn', 'acccntr', 'acccomp', 'accintr', 'accmain', 'accprin', 'accundr'];
const nipResource = ['resaltn', 'rescntr', 'rescomp', 'resintr', 'resmain', 'resprin', 'resundr'];
const nipResourceSym = nipResource.map((x) => x + '_symbol');
const nipOrdinary = [
  'qtyaltn',
  'qtycntr',
  'qtycomp',
  'qtyintr',
  'qtymain',
  'qtyprin',
  'qtyundr',
  'commitment',
  'settlement',
  'pxcost',
  'pxmain',
  'pxnavs',
  'feeccy',
  'feeqty',
  'grouping1',
  'grouping2',
  'grouping3',
  'grouping4',
  'tags',
  'notes',
  'reference',
  'remarks',
];

const extraParams = ['poweruser'];

const qparamKeys = [
  ...extraParams,
  ...nipActionType,
  ...nipStrategy,
  ...nipInstitution,
  ...nipAccount,
  ...nipResource,
  ...nipResourceSym,
  ...nipOrdinary,
];

/**
 * Cleans query parameters for null, empty and `"null"` values.
 */
export function cleanParams(params: Record<string, any>): Record<string, any> {
  // Initialize the return value:
  const retval: Record<string, any> = {};

  // Iterate over parameters:
  Object.keys(params).forEach((k) => {
    // Add the key/value pair if the value is acceptable:
    if (!isEmpty(params[k]) && params[k] !== 'null' && params[k] !== '') {
      retval[k] = params[k];
    }
  });

  // Done, return new parameters object:
  return retval;
}

export function treatRouteParams(params: Record<string, any>): Record<string, any> {
  // First clean params:
  params = cleanParams(params);

  // Create return value:
  const retval: Record<string, any> = {};

  // Iterate over parameters and treat parameters for the route.
  qparamKeys.forEach((x) => {
    retval[x] = params[x] || '';
  });

  // Done, return:
  return retval;
}

export function getToday(date?: Maybe<string>): string {
  return isNothing(date) ? today() : date;
}

export function isCurrency(artifact: BelongsTo<ArtifactModel>): boolean {
  return get(get(artifact, 'ctype'), 'id') === 'CCY';
}

export interface ActionHandlerParamsBuilderArgs {
  artifact: ArtifactModel & DS.PromiseObject<ArtifactModel>;
  account: Id;
  quantity?: Maybe<number>;
  price?: Maybe<number>;
  commitment?: Maybe<string>;
  settlement?: Maybe<string>;
}

export type ActionHandlerParamsBuilderResult = Promise<Record<string, any>>;

export type ArtifactTypes =
  | 'BOND'
  | 'CCY'
  | 'CFD'
  | 'CMPO'
  | 'COD'
  | 'COMM'
  | 'DCIP'
  | 'DEPO'
  | 'ELNP'
  | 'FUT'
  | 'FWD'
  | 'FXFUT'
  | 'FXFWD'
  | 'FXMF'
  | 'FXOPT'
  | 'INSR'
  | 'LOAN'
  | 'OPT'
  | 'OTHER'
  | 'SHRE'
  | 'SP'
  | 'SPMF'
  | 'ZCPN';

export const ArtifactTypeCodeList = [
  'BOND',
  'CCY',
  'CFD',
  'CMPO',
  'COD',
  'COMM',
  'DCIP',
  'DEPO',
  'ELNP',
  'FUT',
  'FWD',
  'FXFUT',
  'FXFWD',
  'FXMF',
  'FXOPT',
  'INSR',
  'LOAN',
  'OPT',
  'OTHER',
  'SHRE',
  'SP',
  'SPMF',
  'ZCPN',
];

export abstract class ActionHandlerFactory {
  abstract types?: Array<ArtifactTypes>;
  abstract ctype: number;
  abstract display: string;
  abstract opening: boolean;
  abstract closing: boolean;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) => {
      resolve({
        commitment: getToday(commitment || undefined),
        settlement: settlement || getToday(commitment || undefined),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        pxmain: isCurrency(artifact) ? 1 : price || null,
      });
    });
  }

  @action
  getParams(
    artifact: ArtifactModel & DS.PromiseObject<ArtifactModel>,
    account: Id,
    quantity?: Maybe<number>,
    price?: Maybe<number>,
    commitment?: Maybe<string>,
    settlement?: Maybe<string>
  ): ActionHandlerParamsBuilderResult {
    // Make parameters:
    const params = this.makeParams({
      artifact,
      account,
      quantity,
      price,
      commitment,
      settlement,
    });
    const ctype = this.ctype;
    // Inject ctype, treat parameters for route usage and return:
    const anam = params.then((x) => {
      x.ctype = ctype;
      return treatRouteParams(x);
    });

    // @ts-expect-error
    return DS.PromiseObject.create({ promise: anam });
  }
}
