import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { dayjs } from 'cafelatte/libs/headless/prelude/datetime';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import { Comparison } from 'cafelatte/services/stocks';

interface Args {
  date1: string;
  date2: string;
  comparison: Comparison[];
  noshows?: string[];
}

export default class extends Component<Args> {
  @service declare router: RouterService;

  get spec(): FlexTableSpec<Comparison> {
    return {
      ident: 'cl-stocks-comparison-table',
      vfill: true,
      columns: [
        { key: 'artifact.id', label: 'Instrument ID', hidden: true },
        { key: 'artifact.guid', label: 'Instrument GUID', hidden: true },
        {
          key: 'artifact.symbol',
          label: 'Instrument Symbol',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'artifact.id' },
        },
        {
          key: 'artifact.name',
          label: 'Instrument Name',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'artifact.id' },
        },
        { key: 'artifact.ctype', label: 'Instrument Type' },
        { key: 'artifact.stype', label: 'Instrument Sub-Type' },
        { key: 'account.id', label: 'Account ID', hidden: true },
        { key: 'account.guid', label: 'Account GUID', hidden: true },
        {
          key: 'account.name',
          label: 'Account Name',
          component: '@link',
          options: { route: 'account.details', modelPath: 'account.id' },
        },
        { key: 'account.portfolio.id', label: 'Portfolio ID', hidden: true },
        { key: 'account.portfolio.guid', label: 'Portfolio GUID', hidden: true },
        {
          key: 'account.portfolio.name',
          label: 'Portfolio Name',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'account.portfolio.id' },
        },
        { key: 'account.portfolio.team.id', label: 'Team ID', hidden: true },
        { key: 'account.portfolio.team.guid', label: 'Team GUID', hidden: true },
        {
          key: 'account.portfolio.team.name',
          label: 'Team Name',
          component: '@link',
          options: { route: 'team.details', modelPath: 'account.portfolio.team.id' },
        },
        { key: 'account.custodian.id', label: 'Custodian ID', hidden: true },
        { key: 'account.custodian.guid', label: 'Custodian GUID', hidden: true },
        {
          key: 'account.custodian.name',
          label: 'Custodian Name',
          component: '@link',
          options: { route: 'institution.details', modelPath: 'account.custodian.id' },
        },
        {
          key: 'a',
          label: dayjs(this.args.date1).format('DD-MM-YYYY'),
          align: 'right',
          component: '@number',
          options: { format: '0,0.00', colorize: true },
        },
        {
          key: 'b',
          label: dayjs(this.args.date2).format('DD-MM-YYYY'),
          align: 'right',
          component: '@number',
          options: { format: '0,0.00', colorize: true },
        },
        {
          key: 'diff',
          label: 'Change',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00', colorize: true },
          action: ({ record }): void => this.gotoLedger(record),
        },
      ],
    };
  }

  gotoLedger(record: Comparison): void {
    window.open(
      this.router.urlFor('ledger', {
        queryParams: {
          datespanMin: this.args.date1,
          datespanMax: this.args.date2,
          accounts: record.account.id,
          artifact: record.artifact.id,
        },
      })
    );
  }
}
