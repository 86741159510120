import { action, computed, notifyPropertyChange } from '@ember/object';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import AccrualScheduleModel from 'cafelatte/models/accrualschedule';
import { FlexTableRowAction, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends ControllerWithCurrentRecord<AccrualScheduleModel> {
  createNewRecord(): AccrualScheduleModel {
    return this.store.createRecord('accrualschedule', { portfolio: this.model });
  }

  @computed('model')
  get records() {
    return this.store.query('accrualschedule', { portfolio: this.model.id, page_size: -1 });
  }

  get tableOperations(): FlexTableRowAction<AccrualScheduleModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doReset },
    ]);
  }

  @action doReset(): void {
    this.doResetCurrentRecord();
    notifyPropertyChange(this, 'records');
  }

  @action onError(_message: string, error: any): void {
    console.error(error);
  }
}
