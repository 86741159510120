import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import VoucherModel from 'cafelatte/models/voucher';
import Decimal from 'decimal.js';

interface Args {
  record: VoucherModel;
  onSave?: Function;
  onError?: Function;
  onCancel?: Function;
  onDelete?: Function;
}

export default class extends Component<Args> {
  _computeGrsNet(sharing?: number, kickback?: number, amount?: number) {
    if (isEmpty(amount)) {
      return { amount: 0, gross: 0, net: 0 };
    }

    // Compute gross and net:
    const grs = new Decimal(`${amount}`).mul(new Decimal(`${sharing}`)).toDP(2);
    const net = grs.mul(new Decimal(1).minus(new Decimal(`${kickback}`))).toDP(2);

    // Prepare the return value, limit decimals and return:
    return { amount, gross: `${grs}`, net: `${net}` };
  }

  @action onInstitutionChange(value: any) {
    // Get sharing/kickback:
    // NOTE:
    // If the user clears the Institution selection, `value` becomes null.
    // null does not have property `sharing` or `kickback`.
    // We make sure it fallbacks to default values with optional chaining.
    const sharing = value?.sharing || 1;
    const kickback = value?.kickback || 0;

    // Get the gross and net amounts, if applicable:
    const { amount, gross, net } = this._computeGrsNet(sharing, kickback, this.args.record.amount);

    // Set record properties:
    this.args.record.setProperties({ sharing, kickback, amount, gross, net, institution: value });
  }
}
