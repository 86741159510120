import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import AccountManagementFeeSchemeModel from 'cafelatte/models/account/mfeescheme';
import { FlexTableRowAction, FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import MeService from 'cafelatte/services/me';

interface Args {
  records?: AccountManagementFeeSchemeModel[];
  operations?: FlexTableRowAction<AccountManagementFeeSchemeModel>[];
}

export default class extends Component<Args> {
  @service declare me: MeService;

  get spec(): FlexTableSpec<AccountManagementFeeSchemeModel> {
    return {
      ident: 'cl-account-mfpercentage-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'started', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'style' },
        {
          key: 'value',
          label: 'Fee %',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]%' },
        },
        {
          key: 'rebate',
          label: 'Rebate %',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]%' },
        },
        ...mkAuditFields<AccountManagementFeeSchemeModel>(this.me),
      ],
    };
  }
}
