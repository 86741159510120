import { helper } from '@ember/component/helper';
import { isNothing } from 'cafelatte/libs/headless/prelude/maybe';

export function getAttributeVal(records: { code: string; value: any }[], code: string) {
  if (isNothing(records)) {
    return undefined;
  } else {
    return records.find((r) => r.code === code)?.value;
  }
}

export default helper(([r, k]) => getAttributeVal(r, k));
