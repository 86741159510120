import EmberObject, { computed, get, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { CommonController } from 'cafelatte/libs/embered';
import { FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @computed()
  get rdata() {
    const retval: any = EmberObject.create({});
    this.ajax
      .request('/ohlcobservations/recencynew/', { data: { nupdate: 7 } })
      .then((result) => setProperties(retval, result));
    return retval;
  }

  @computed('rdata.wohlc')
  get wohlc() {
    // Get the data:
    const data: any = get(this, 'rdata.wohlc');

    // Empty?
    if (isEmpty(data)) {
      return [];
    }

    // Declare the initial array:
    const retval: any = [];

    // Iterate over values:
    data.forEach((series: any) => {
      series.resources.forEach((resource: any) => {
        retval.push({
          id: series.id,
          symbol: series.symbol,
          date: series.date,
          value: series.value,
          resid: resource.id,
          ressym: resource.symbol,
          resname: resource.name && resource.name.length > 64 ? resource.name.substr(0, 61) + '...' : resource.name,
          resexpiry: resource.expiry,
        });
      });
    });

    // Done, return:
    return retval;
  }

  @computed('rdata.nohlc')
  get nohlc() {
    // Get the data:
    const data: any = get(this, 'rdata.nohlc');

    // Empty?
    if (isEmpty(data)) {
      return [];
    }

    // Iterate over values:
    return data.map((item: any) => {
      return {
        id: item.id,
        symbol: item.symbol,
        ohlccode: item.ohlccode,
        name: item.name && item.name.length > 64 ? item.name.substr(0, 61) + '...' : item.name,
        expiry: item.expiry,
        stock: item.stock ? 'YES' : 'NO',
      };
    });
  }

  get tableWOHLC(): FlexTableSpec<any> {
    return {
      ident: 'cl-market-data-recency-wohlc-table',
      vfill: true,
      columns: [
        { key: 'id' },
        { key: 'symbol', label: 'Series Symbol' },
        { key: 'date', label: 'Last Available', component: '@date', options: { format: 'DD-MM-YYYY' } },
        {
          key: 'value',
          label: 'Last Price',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        {
          key: 'ressym',
          label: 'Instrument Symbol',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'resid' },
        },
        { key: 'resname', label: 'Instrument Name' },
        {
          key: 'resexpiry',
          label: 'Instrument Expiry',
          align: 'center',
          component: '@date',
          options: { format: 'DD-MM-YYYY' },
        },
      ],
    };
  }

  get tableNOHLC(): FlexTableSpec<any> {
    return {
      ident: 'cl-market-data-recency-nohlc-table',
      vfill: true,
      columns: [
        { key: 'id' },
        { key: 'symbol', component: '@link', options: { route: 'resource.details', modelPath: 'id' } },
        { key: 'ohlccode', label: 'OHLC Code' },
        { key: 'name' },
        { key: 'expiry', align: 'center', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'stock', label: 'Has Stock', align: 'center' },
      ],
    };
  }
}
