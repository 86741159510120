import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DecafService from 'cafelatte/services/decaf';
import { highlightMatchedText, search, SearchItem } from './machinery';

export default class extends Component {
  @service declare router: RouterService;
  @service declare decaf: DecafService;

  @tracked items: SearchItem[] = [];
  @tracked loaded = false;
  @tracked resultSelectionIndex = -1;
  @tracked loading = false;
  @tracked pressedKeys: Record<string, boolean> = {};
  @tracked keyword = '';

  searchTimeout?: NodeJS.Timeout;

  reset() {
    this.resultSelectionIndex = -1;
    this.items = [];
  }

  handlePressEnter(event: any) {
    const item = this.items[this.resultSelectionIndex];
    if (item?.route && event.code === 'Enter') {
      this.reset();
      this.onClickResult(item);
    }
  }

  updateSelection() {
    document.querySelectorAll('.csb-result').forEach((r, i) => {
      if (i === this.resultSelectionIndex && !r.hasAttribute('aria-disabled')) {
        r.classList.add('selected');
        r.scrollIntoView(false);
      } else {
        r.classList.remove('selected');
      }
    });
  }

  // actions

  @action handleKeyDownOnInput(event: KeyboardEvent) {
    if (event.code === 'ArrowDown') {
      if (this.resultSelectionIndex === this.items.length - 1) {
        this.resultSelectionIndex = 0;
      } else {
        this.resultSelectionIndex = this.resultSelectionIndex + 1;
      }
      this.updateSelection();
      event.preventDefault();
    } else if (event.code === 'ArrowUp') {
      if (this.resultSelectionIndex === 0) {
        this.resultSelectionIndex = this.items.length - 1;
      } else {
        this.resultSelectionIndex = this.resultSelectionIndex - 1;
      }
      this.updateSelection();
      event.preventDefault();
    } else if (event.code === 'Enter') {
      this.handlePressEnter(event);
    }
  }

  @action handleKeyDownRoot(event: KeyboardEvent) {
    const isMeta = event.metaKey || event.key === 'Meta' ? 'Meta' : undefined;
    if (event.key === 'Control' || isMeta || event.key === 'k') {
      this.pressedKeys[isMeta ?? event.key] = true;
    }
    if ((this.pressedKeys['Control'] || this.pressedKeys['Meta']) && event.key === 'k') {
      this.loaded = !this.loaded;
      event.preventDefault();
    } else if (this.loaded && event.key === 'Escape') {
      this.loaded = false;
      event.preventDefault();
    }
  }

  @action handleKeyUpRoot(event: KeyboardEvent) {
    const isMeta = event.metaKey || event.key === 'Meta' ? 'Meta' : undefined;
    if (event.key === 'Control' || isMeta || event.key === 'k') {
      delete this.pressedKeys[isMeta ?? event.key];
    }
  }

  @action handleClickRoot(event: MouseEvent) {
    if (this.loaded) {
      const container = document.getElementById('csb-container');
      if (!container?.contains(event.target as Node)) {
        this.loaded = false;
      }
    }
  }

  @action onSearchChange(event: InputEvent) {
    if (this.searchTimeout !== undefined) {
      clearTimeout(this.searchTimeout);
    }
    const target = event.target as HTMLInputElement;
    this.resultSelectionIndex = -1;
    document.querySelectorAll('.csb-result').forEach((r) => {
      r.classList.remove('selected');
    });
    this.keyword = target.value;
    this.searchTimeout = setTimeout(async () => {
      this.loading = true;
      const [items, value] = await search(this.decaf.client, target.value);
      this.items = items;
      this.loading = false;
      setTimeout(() => {
        this.resultSelectionIndex = items.length > 0 ? 0 : -1;
        this.updateSelection();
        highlightMatchedText(value);
      }, 0);
    }, 500);
  }

  @action onClickResult(item: SearchItem) {
    const path = item.route;
    this.reset();
    this.loaded = false;
    if (path?.id) {
      this.router.transitionTo(path.path, path.id);
    } else {
      this.router.transitionTo(path.path);
    }
  }

  @action handleClickFooterBtn(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.loaded = !this.loaded;
  }

  // lifecycle
  @action didInsertInput(input: HTMLInputElement) {
    this.reset();
    input.focus();
    input.addEventListener('keydown', this.handleKeyDownOnInput);
  }

  @action willDestroyInput(input: HTMLInputElement): void {
    input.removeEventListener('keydown', this.handleKeyDownOnInput);
  }

  @action willDestroyRoot(): void {
    document.removeEventListener('keydown', this.handleKeyDownRoot);
    document.removeEventListener('keyup', this.handleKeyUpRoot);
    document.removeEventListener('click', this.handleClickRoot);
    document.getElementById('toggle-csb')?.removeEventListener('click', this.handleClickFooterBtn);
  }

  @action didInsertRoot(): void {
    document.addEventListener('keydown', this.handleKeyDownRoot);
    document.addEventListener('keyup', this.handleKeyUpRoot);
    document.addEventListener('click', this.handleClickRoot);
    document.getElementById('toggle-csb')?.addEventListener('click', this.handleClickFooterBtn);
  }
}
