import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import Store from '@ember-data/store';
import EmberArray, { A } from '@ember/array';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import AccountModel from './account';
import AgentModel from './agent';
import AnalyticalTypeModel from './analyticaltype';
import InstitutionModel from './institution';
import QuantModel from './quant';
import ArtifactModel from './resource';
import ShareClassModel from './shareclass';
import StrategyModel from './strategy';
import ActionTypeModel from './trade/type';
import { BelongsTo, DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = [
  'ctype',
  'stype',
  'atype',
  'cflag',
  'commitment',
  'settlement',
  'executedat',
  'pseudorder',
  'accmain',
  'resmain',
  'qtymain',
  'accaltn',
  'resaltn',
  'qtyaltn',
  'acccomp',
  'rescomp',
  'qtycomp',
  'accundr',
  'resundr',
  'qtyundr',
  'acccntr',
  'rescntr',
  'qtycntr',
  'accprin',
  'resprin',
  'qtyprin',
  'accintr',
  'resintr',
  'qtyintr',
  'pxmain',
  'pxcost',
  'pxaux1',
  'pxaux2',
  'pxaux3',
  'pxnavs',
  'shrcls',
  'shrcnt',
  'agent',
  'promoter',
  'feeagt',
  'feeccy',
  'feeqty',
  'reference',
  'notes',
  'remarks',
  'strategy',
  'tags',
  'grouping1',
  'grouping2',
  'grouping3',
  'grouping4',
  'extfld1tag',
  'extfld1typ',
  'extfld1val',
  'extfld2tag',
  'extfld2typ',
  'extfld2val',
  'extfld3tag',
  'extfld3typ',
  'extfld3val',
  'extfld4tag',
  'extfld4typ',
  'extfld4val',
  'related_action',
  'reverse_action',
];

export default class ActionModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @service declare store: Store;

  @belongsTo('trade/type')
  declare ctype?: BelongsTo<ActionTypeModel>;

  @attr()
  declare type?: string;

  @attr()
  declare stype?: string;

  @belongsTo('analyticaltype')
  declare atype?: BelongsTo<AnalyticalTypeModel>;

  @attr('number')
  declare cflag?: number;

  @attr()
  declare commitment?: string;

  @attr()
  declare settlement?: string;

  @attr()
  declare executedat?: string;

  @attr('number')
  declare pseudorder?: number;

  @belongsTo('account')
  declare accmain?: BelongsTo<AccountModel>;

  @belongsTo('resource')
  declare resmain?: BelongsTo<ArtifactModel>;

  @attr('number')
  declare qtymain?: number;

  @belongsTo('account')
  declare accaltn?: BelongsTo<AccountModel>;

  @belongsTo('resource')
  declare resaltn?: BelongsTo<ArtifactModel>;

  @attr('number')
  declare qtyaltn?: number;

  @belongsTo('account')
  declare acccomp?: BelongsTo<AccountModel>;

  @belongsTo('resource')
  declare rescomp?: BelongsTo<ArtifactModel>;

  @attr('number')
  declare qtycomp?: number;

  @belongsTo('account')
  declare accundr?: BelongsTo<AccountModel>;

  @belongsTo('resource')
  declare resundr?: BelongsTo<ArtifactModel>;

  @attr('number')
  declare qtyundr?: number;

  @belongsTo('account')
  declare acccntr?: BelongsTo<AccountModel>;

  @belongsTo('resource')
  declare rescntr?: BelongsTo<ArtifactModel>;

  @attr('number')
  declare qtycntr?: number;

  @belongsTo('account')
  declare accprin?: BelongsTo<AccountModel>;

  @belongsTo('resource')
  declare resprin?: BelongsTo<ArtifactModel>;

  @attr('number')
  declare qtyprin?: number;

  @belongsTo('account')
  declare accintr?: BelongsTo<AccountModel>;

  @belongsTo('resource')
  declare resintr?: BelongsTo<ArtifactModel>;

  @attr('number')
  declare qtyintr?: number;

  @attr('number')
  declare pxmain?: number;

  @attr('number')
  declare pxcost?: number;

  @attr('number')
  declare pxaux1?: number;

  @attr('number')
  declare pxaux2?: number;

  @attr('number')
  declare pxaux3?: number;

  @attr('number')
  declare pxnavs?: number;

  @belongsTo('shareclass')
  declare shrcls?: BelongsTo<ShareClassModel>;

  @attr('number')
  declare shrcnt?: number;

  @belongsTo('agent')
  declare agent?: BelongsTo<AgentModel>;

  @belongsTo('agent')
  declare promoter?: BelongsTo<AgentModel>;

  @belongsTo('institution')
  declare feeagt?: BelongsTo<InstitutionModel>;

  @attr()
  declare feeccy?: string;

  @attr('number')
  declare feeqty?: number;

  @attr('boolean')
  declare is_auto?: boolean;

  @attr('boolean')
  declare is_approved?: boolean;

  @attr('boolean')
  declare is_locked?: boolean;

  @attr()
  declare reference?: string;

  @attr()
  declare notes?: string;

  @attr()
  declare remarks?: string;

  @belongsTo('strategy')
  declare strategy?: BelongsTo<StrategyModel>;

  @attr({ defaultValue: () => A([]) })
  declare tags: EmberArray<string>;

  @attr()
  declare grouping1?: string;

  @attr()
  declare grouping2?: string;

  @attr()
  declare grouping3?: string;

  @attr()
  declare grouping4?: string;

  @attr()
  declare extfld1tag?: string;

  @attr()
  declare extfld1typ?: string;

  @attr()
  declare extfld1val?: string;

  @attr()
  declare extfld2tag?: string;

  @attr()
  declare extfld2typ?: string;

  @attr()
  declare extfld2val?: string;

  @attr()
  declare extfld3tag?: string;

  @attr()
  declare extfld3typ?: string;

  @attr()
  declare extfld3val?: string;

  @attr()
  declare extfld4tag?: string;

  @attr()
  declare extfld4typ?: string;

  @attr()
  declare extfld4val?: string;

  @belongsTo('trade', { inverse: null })
  declare related_action?: BelongsTo<ActionModel>;

  @belongsTo('trade', { inverse: null })
  declare reverse_action?: BelongsTo<ActionModel>;

  @hasMany('quant', { inverse: 'trade' })
  declare quants?: HasMany<QuantModel>;

  @computed('qtymain')
  get isBuy(): boolean {
    return !isNone(this.qtymain) && this.qtymain >= 0;
  }

  @not('isBuy')
  declare isSell: boolean;

  @computed('_dummy')
  get vouchers() {
    return this.store.query('voucher', { page_size: 1000, trade: this.id });
  }

  @computed('shrcnt', 'qtymain', 'pxnavs')
  get sharecount(): number {
    if (isNone(this.qtymain)) {
      return 0;
    }
    if (isNone(this.shrcnt)) {
      if (isNone(this.pxnavs)) {
        return 0;
      }
      return this.qtymain / this.pxnavs;
    }

    return this.shrcnt * (this.qtymain < 0 ? -1 : 1);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    trade: ActionModel;
  }
}
