import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import AnalyticalTypeModel from 'cafelatte/models/analyticaltype';
import { FlexTableRowAction, mkCommonRowActions } from '../components/x/flex-table';

export default class extends ControllerWithCurrentRecord<AnalyticalTypeModel> {
  createNewRecord(): AnalyticalTypeModel {
    return this.store.createRecord('analyticaltype');
  }

  get tableOperations(): FlexTableRowAction<AnalyticalTypeModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doResetCurrentRecordRefreshModel },
    ]);
  }
}
