import { isEmpty } from '@ember/utils';
import { CommonController } from 'cafelatte/libs/embered';

export default class extends CommonController {
  get hasBeanbag(): boolean {
    return !isEmpty(this.model);
  }

  get icon() {
    return this.hasBeanbag ? 'info-circle' : 'exclamation-circle';
  }

  get text() {
    return this.hasBeanbag ? 'Choose an extension action or report from the submenu.' : 'No custom reports are found.';
  }

  get type() {
    return this.hasBeanbag ? 'info' : 'warning';
  }
}
