import { CommonRoute } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = {
    page: { refreshModel: true },
    page_size: { refreshModel: true },
    date__gte: { refreshModel: true },
    date__lte: { refreshModel: true },
    account__portfolio: { refreshModel: true },
    account: { refreshModel: true },
  };

  model(params: any) {
    return this.store.query('externalaccountvaluation', params);
  }
}
