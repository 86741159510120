import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { getOrElse } from 'cafelatte/libs/headless/prelude/maybe';
import { ZERO } from 'cafelatte/libs/headless/prelude/numeric';
import { ConsolidationReportData } from 'cafelatte/libs/headless/services/consolidation-report';

interface Args {
  report: ConsolidationReportData;
}

export default class extends Component<Args> {
  /**
   * All holdings from the report.
   */
  @tracked holdings = this.args.report.positions.holdings;

  /**
   * Filtered holdings from the report.
   */
  @tracked filteredHoldings = this.holdings;

  /**
   * Plot data.
   */
  @computed('filteredHoldings')
  get plotData() {
    // Get `x` labels and hover texts:
    const x = this.filteredHoldings.map((x) => x.artifact.symbol);
    const text = this.filteredHoldings.map((x) => `[${x.artifact.symbol}] ${x.artifact.name}`);

    // Get series:
    const seriesNetVal = this.filteredHoldings.map((x) => getOrElse(x.valuation.value.net.ref, ZERO).toNumber());
    const seriesNetExp = this.filteredHoldings.map((x) => getOrElse(x.valuation.exposure.net.ref, ZERO).toNumber());
    const seriesAbsExp = this.filteredHoldings.map((x) => getOrElse(x.valuation.exposure.abs.ref, ZERO).toNumber());

    // Build data and return:
    return [
      { x, y: seriesNetVal, text, type: 'bar', name: 'Net Value' },
      { x, y: seriesNetExp, text, type: 'bar', name: 'Net Exposure' },
      { x, y: seriesAbsExp, text, type: 'bar', name: 'Absolute Exposure' },
    ];
  }

  /**
   * Plot layout.
   */
  plotLayout = {
    title: 'Net Value, Net Exposure and Absolute Exposures',
    autosize: true,
    height: 500,
    xaxis: {
      title: ' ',
    },
    yaxis: {
      title: 'Value',
    },
    margin: {
      l: 50,
      r: 50,
      b: 150,
      t: 50,
    },
  };
}
