import { action } from '@ember/object';
import { handleFlexTableResize } from 'cafelatte/helpers/flex-table-resize-handler';
import { CommonController } from 'cafelatte/libs/embered';

export default class extends CommonController {
  @action onInsertElement() {
    handleFlexTableResize();
    window.addEventListener('resize', handleFlexTableResize);
  }

  @action onDestroyElement(): void {
    super.willDestroy();
    window.removeEventListener('resize', handleFlexTableResize); // remove listeners
  }
}
