import { tagName } from '@ember-decorators/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import CLAjaxService from 'cafelatte/services/ajax';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  @service declare ajax: CLAjaxService;

  layout = layout;

  @tracked options?: any = undefined;
  @tracked initialOptions?: any = undefined;

  @action doSearch(x?: Maybe<string>) {
    this.initialOptions = undefined;
    return this.ajax.request('ohlcobservations/sources/', {
      data: { ...(this?.options?.payload || {}), search: x || '' },
    });
  }

  @action onOpen() {
    if ((this.initialOptions || []).length === 0) {
      set(this, 'initialOptions', this.doSearch());
    }
  }
}
