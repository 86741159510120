import EmberObject, { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { ReturnsGrid, ReturnsGridItem, ReturnsGridQuery } from 'cafelatte/libs/headless/services/returns-grid';
import { FlexTableSpec, mkNumberColumn } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

class TrackedValue extends EmberObject {
  @tracked value = null;
}

export default class extends CommonController {
  @service declare decaf: DecafService;

  @tracked date = today();
  @tracked lookback = 3;
  @tracked since: any = undefined;
  @tracked until: any = undefined;
  @tracked accounts: any = undefined;
  @tracked portfolios: any = undefined;
  @tracked teams: any = undefined;
  @tracked custodians: any = undefined;
  @tracked portfoliogroups: any = undefined;
  @tracked artifacts: any = undefined;
  @tracked ohlcs: any = undefined;

  @tracked ordercolumn: any = undefined;

  @computed(
    'data',
    'lookback',
    'since',
    'until',
    'accounts.[]',
    'portfolios.[]',
    'teams.[]',
    'custodians.[]',
    'portfoliogroups.[]',
    'artifacts.[]',
    'ohlcs.[]'
  )
  get query(): ReturnsGridQuery {
    return {
      date: this.date,
      since: this.since || undefined,
      until: this.until || undefined,
      lookback: Math.max(1, this.lookback),
      accounts: this.accounts || [],
      portfolios: this.portfolios || [],
      portfoliogroups: this.portfoliogroups || [],
      teams: this.teams || [],
      custodians: this.custodians || [],
      artifacts: this.artifacts || [],
      ohlcs: this.ohlcs || [],
    };
  }

  @computed('query')
  get rawdata(): Maybe<{ content?: ReturnsGridQuery }> {
    // @ts-expect-error
    return DS.PromiseObject.create({ promise: this.decaf.services.returnsgrid.grid(this.query) });
  }

  @computed('rawdata.content')
  get spec(): FlexTableSpec<ReturnsGridItem> {
    // Get the data:
    const data = (this.rawdata?.content as ReturnsGrid | undefined) || { columns: [], records: [] };

    // Also, fill asset class:
    data.records.forEach((x) => {
      const assetClassId = x.artifact?.assetClass?.id;

      if (!assetClassId) {
        return;
      }

      x.assetClass = new TrackedValue();

      this.decaf.client.barista.get(`/assetclasses/${assetClassId}/`).then(({ data }: { data: any }) => {
        x.assetClass.value = data.path.join(', ');
      });
    });

    // Prepare the table and return:
    return {
      ident: 'cl-returns-grid',
      columns: [
        {
          key: 'symbol',
          action: ({ record }) => this.gotoRecord(record),
        },
        { key: 'name', value: (x) => (x.record.artifact || { name: '<MARKETDATA>' }).name, options: { truncate: 64 } },
        { key: 'artifact.id', label: 'Instrument ID', hidden: true },
        { key: 'artifact.ctype', label: 'Instrument Type', hidden: true },
        { key: 'artifact.currency', label: 'Instrument Currency', hidden: true },
        { key: 'artifact.country', label: 'Instrument Country', hidden: true },
        { key: 'artifact.assetClass.name', label: 'Instrument Asset Class', hidden: true },
        { key: 'assetClass.value', label: 'Instrument Asset Class (Full)', hidden: true },
        ...data.columns.map((c) =>
          mkNumberColumn<ReturnsGridItem>({
            key: `results.${c}.value`,
            label: c.toUpperCase(),
            options: { format: '0,0.00%', colorize: false },
            // @ts-expect-error ts2532
            classes: [({ record }) => [`heatmap-decile-${record.results[c].decile}`]],
          })
        ),
      ],
    };
  }

  @action gotoRecord(record: ReturnsGridItem): void {
    if (record.model === 'artifact') {
      window.open(this.router.urlFor('resource.details', record.ident));
    } else if (record.model === 'ohlc') {
      window.open(this.router.urlFor('ohlc.observation', { queryParams: { symbol: record.symbol } }));
    }
  }
}
// XREVIEW=TODO
// TODO: Implement ordering
