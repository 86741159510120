import Store from '@ember-data/store';
import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import ArtifactModel from 'cafelatte/models/resource';

interface Args {
  record: ArtifactModel;
  form: any;
}

export default class extends Component<Args> {
  @service declare store: Store;

  /**
   * Path to artifact type specific content components.
   */
  typeSpecificComponentPath = 'c-l/artifact/form/content/-types';

  /**
   * Default/generic artifact form content component.
   */
  genericArtifactTypeComponent = `${this.typeSpecificComponentPath}/gx`;

  /**
   * Attempts to return the component path.
   *
   * If the component is defined for the artifact subtype, return it, otherwise
   * return the generic component.
   */
  get contentComponentPath() {
    // Attempt to get the lower case type:
    const ctype = (this.args.record.get('ctype.id') || '').toLowerCase();

    // If we do not have any ctype (for whatever reason), return the default component:
    if (!ctype) {
      return this.genericArtifactTypeComponent;
    }

    // Get the component path:
    const component = `${this.typeSpecificComponentPath}/${ctype}`;

    // If we do not have such a component (for whatever reason), return the
    // default component, otherwise itself:
    //
    // TODO: Try to work with `component:`, not `template:` (due to Glimmer).
    return getOwner(this).hasRegistration(`template:components/${component}`)
      ? component
      : this.genericArtifactTypeComponent;
  }

  /**
   * Artifact attributes cataloque.
   */
  @computed()
  get attributes() {
    return this.store.query('artifactattribute', { page_size: -1 });
  }
}
