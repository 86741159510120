import { set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { CommonRoute } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = {
    ecopy: { refreshModel: true },
    ctype: { refreshModel: true },
  };

  model(params: any) {
    if (params.id === '__new__') {
      if (!isEmpty(params.ecopy)) {
        return this._cloneRecord(params.ecopy);
      } else if (!isEmpty(params.ctype)) {
        return this._freshRecord(params.ctype);
      } else {
        throw new Error('Can not create new instrument with missing information.');
      }
    }
    return this.store.findRecord('resource', params.id);
  }

  _cloneRecord(cid: any) {
    const excludes: string[] = [
      'id',
      'guid',
      'created',
      'creator',
      'updated',
      'updater',
      'incomplete',
      'type',
      'horizon',
    ];

    return this.store.findRecord('resource', cid).then((record) => {
      const serialized: any = record.serialize({ includeId: false });
      excludes.forEach((x) => delete serialized[x]);
      serialized.symbol = `${serialized.symbol} [copied]`;
      const cloned = this.store.createRecord('resource', serialized);
      const normalized: any = this.store.normalize('resource', serialized);
      Object.entries(normalized?.data?.relationships || {}).map(([attr, desc]: any) => {
        if (desc.data) {
          this.store.findRecord(desc.data.type, desc.data.id).then((o) => set(cloned, attr, o));
        }
      });
      return cloned;
    });
  }

  _freshRecord(ctype: any) {
    return this.store
      .findRecord('resource/type', ctype)
      .then((x) => this.store.createRecord('resource', { ctype: x }))
      .catch(() => {
        throw new Error(`Can not find the type "${ctype}" to create a new instrument`);
      });
  }

  resetController(controller: any) {
    controller.setProperties({
      ecopy: undefined,
      ctype: undefined,
    });
  }
}
