import { action } from '@ember/object';
import {
  cleanParams,
  CommonRoute,
  compileQueryParams,
  handle404OnNonExistingPage,
  resetControllerParams,
} from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'cflag',
    'cflag__isnull',
    'search',
    'ordering',
    'commitment__gte',
    'commitment__lte',
    'settlement__gte',
    'settlement__lte',
    'valccy',
    'valamt',
    'resource',
    'resource__ccymain',
    'resource__ctype',
    'resource__stype',
    'resource__issuer__icontains',
    'resource__sector__icontains',
    'resource__country__icontains',
    'account',
    'account__portfolio',
    'account__portfolio__team',
    'account__portfolio__groups',
    'account__custodian',
    'quantity__gte',
    'quantity__lte',
    'trade__ctype',
    'trade__stype',
    'trade__reference__iexact',
    'resource__isin',
    'nojournal',
    'refccy',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('quant', cleanParams(params));
  }

  resetController(controller: any, isExiting: boolean): void {
    if (isExiting) {
      resetControllerParams(controller, { page: 1, page_size: 10 }, Object.keys(this.queryParams));
    }
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
