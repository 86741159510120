import { helper } from '@ember/component/helper';
import { setProperties } from '@ember/object';

export function dateRangeSetter(obj: any, start: any, end: any): any {
  return (x?: { start: any; end: any }) => {
    if (!x) {
      x = { start: undefined, end: undefined };
    }
    setProperties(obj, { [start]: x.start, [end]: x.end });
  };
}

export default helper(([obj, start, end]) => dateRangeSetter(obj, start, end));
