import Model, { attr, belongsTo } from '@ember-data/model';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import InstitutionModel from './institution';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['started', 'institution', 'percentage', 'kickback'];

export default class SharingModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr({ defaultValue: today })
  declare started: string;

  @belongsTo('institution')
  declare institution?: BelongsTo<InstitutionModel>;

  @attr('number', { defaultValue: 1 })
  declare percentage: number;

  @attr('number', { defaultValue: 0 })
  declare kickback: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    sharing: SharingModel;
  }
}
