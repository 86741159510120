import { inject as service } from '@ember/service';
import { Headers, RequestData } from 'ember-ajax/-private/types';
import { AjaxError, UnauthorizedError } from 'ember-ajax/errors';
import AjaxService from 'ember-ajax/services/ajax';
import config from '../../config/environment';
import CLSessionService from './session';

/**
 * Extends the stock AJAX service for cafelatte application.
 */
export default class CLAjaxService extends AjaxService {
  @service declare session: CLSessionService;

  /**
   * Host URI of the remote API.
   */
  // @ts-expect-error
  get host(): string {
    return config.APP.API_HOST;
  }

  /**
   * Namespace of the remote API.
   */
  // @ts-expect-error
  get namespace(): string {
    return `/${config.APP.API_NAMESPACE}`.replace(/\/+/, '/');
  }

  /**
   * Headers to be injected to each API request.
   */
  // @ts-expect-error (I don't know why `headers` is defined as to be `undefined` in base class)
  get headers(): Record<string, string> {
    return this.session.token ? { Authorization: `Token ${this.session.token}` } : {};
  }

  /**
   * Handles (unauthorized) responses.
   *
   * In particular, we are not expecting any unauthorized response
   * (not Forbidden). This happens when our authentication token has
   * expired. In such cases, we rather want to invalidate the session
   * which is more or less identical to a "logout" action.
   */
  handleResponse<T>(status: number, headers: Headers, payload: T, requestData: RequestData): AjaxError | T {
    if (status === 401) {
      this.session['invalidate']('authenticator:decaf', false);
      return new UnauthorizedError(payload);
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
