import Control from '../cl-rsel/component';

export default class extends Control {
  defaultOptions = {
    modelName: 'vouchers/type',
    modelLabel: 'name',
    modelById: true,
    modelIdProperty: 'id',
  };
}
