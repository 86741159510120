import { DecafClient } from '@decafhub/decaf-client';

export type NumberResult = InvalidNumberResult | ValidNumberResult;

export interface InvalidNumberResult {
  valid: false;
  details: string;
}

export interface ValidNumberResult {
  valid: true;
  details: {
    region: string;
    type: {
      number: number;
      code: string;
      text: string;
    };
    input: string;
    formatted: {
      e164: string;
      national: string;
      international: string;
      rfc3966: string;
    };
  };
}

export function isValidNumberResult(x: NumberResult): x is ValidNumberResult {
  return x.valid;
}

/**
 * Attempts to query the remote endpoint.
 *
 * @param client bare client.
 * @returns Report data.
 */
export function validate(client: DecafClient, p: string): Promise<NumberResult> {
  return client.bare
    .get<NumberResult>('/apis/function/phoneless/validate/', { params: { p } })
    .then(({ data }) => data);
}
