// @ts-expect-error
import StorageObject from 'ember-local-storage/local/object';

/**
 * Create the application state storage class.
 */
const ApplicationStateStorage = StorageObject.extend();

/**
 * Defines the application state storage class.
 */
ApplicationStateStorage.reopenClass({
  /**
   * Defines the initial state.
   */
  initialState() {
    return {
      showTermsAndConditions: true,
      routesTradeIndexPageSize: 20,
      routesQuantIndexPageSize: 20,
      routesResourceIndexPageSize: 20,
      routesPortfolioIndexPageSize: 20,
      routesAccountIndexPageSize: 20,
      routesVoucherIndexPageSize: 20,
      routesOhlcObservationIndexPageSize: 20,
      routesUserIndexPageSize: 20,
    };
  },
});

export default ApplicationStateStorage;
// XREVIEW=TODO
// TODO: Can we provide typesafety?
