import Model, { attr, belongsTo } from '@ember-data/model';
import AccountModel from './account';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['since', 'account', 'currency', 'quantity'];

export default class AccountCreditLineModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare since?: string;

  @attr()
  declare until?: string;

  @belongsTo('account')
  declare account?: BelongsTo<AccountModel>;

  @attr()
  declare currency?: string;

  @attr()
  declare quantity?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    accountcreditline: AccountCreditLineModel;
  }
}
