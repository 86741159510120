import { FrequencyOptionsLookup } from 'cafelatte/libs/headless/prelude/frequency';
import AccrualScheduleModel from 'cafelatte/models/accrualschedule';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<AccrualScheduleModel> {
  frequencies = FrequencyOptionsLookup;

  calculationTypes = {
    FVLM: 'Fixed Linear Deprecation',
    NAVB: 'Based on NAV',
    AUMB: 'Based on AuM',
  };

  get spec(): FlexTableSpec<AccrualScheduleModel> {
    return {
      ident: 'cl-accrual-schedule-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id', component: '@link', options: { route: 'accrualschedule.details', modelPath: 'id' } },
        {
          key: 'portfolio.name',
          label: 'Portfolio',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'portfolio.id' },
        },
        {
          key: 'account.name',
          label: 'Account',
          component: '@link',
          options: { route: 'account.details', modelPath: 'account.id' },
        },
        {
          key: 'shareclass.name',
          label: 'Share Class',
          component: '@link',
          options: { route: 'shareclass.details', modelPath: 'shareclass.id' },
        },
        {
          key: 'registry.name',
          label: 'Registry',
          component: '@link',
          options: { route: 'account.details', modelPath: 'registry.id' },
        },
        { key: 'start', component: '@date', align: 'center' },
        { key: 'until', component: '@date', align: 'center' },
        // @ts-expect-error
        { key: 'type', label: 'Type', value: ({ record }) => this.calculationTypes[record.type] },
        { key: 'dcccode', label: 'DCC' },
        { key: 'percentage', component: '@number', options: { format: '0.00[0000]%', colorize: true } },
        // @ts-expect-error ts2532
        { key: 'frequency', value: ({ record }) => this.frequencies[record.frequency].value },
        { key: 'mpaccy', label: 'Min CCY', align: 'center' },
        {
          key: 'mpaval',
          label: 'Min AMT',
          align: 'center',
          component: '@number',
          options: { colorize: true, format: '0,0.00' },
        },
        ...mkAuditFields<AccrualScheduleModel>(this.me),
      ],
    };
  }
}
