import { Decimal } from 'decimal.js';
import { getOrElse, isNothing, mapMaybe, Maybe } from './maybe';

export type PossiblyNumeric = Decimal | number | string;

export type Sign = -1 | 0 | 1;

export const ZERO: Decimal = new Decimal('0');

export const HUNDRED: Decimal = new Decimal('100');

export const THOUSAND: Decimal = new Decimal('1000');

export const MILLION: Decimal = new Decimal('1000000');

export function toDecimal(x: number): Decimal {
  return new Decimal(x);
}

export function parseDecimal(x: PossiblyNumeric): Maybe<Decimal> {
  try {
    return new Decimal(x);
  } catch {
    return undefined;
  }
}

export function parseSign(x: PossiblyNumeric): Maybe<Sign> {
  return mapMaybe((x) => x.comparedTo(ZERO) as Sign, parseDecimal(x));
}

export function safeDivide(x: Decimal, y: Decimal): Maybe<Decimal> {
  return y.isZero() ? undefined : x.dividedBy(y);
}

export function safeDivideOrDefault(x: Decimal, y: Decimal, def: Decimal): Decimal {
  return getOrElse(safeDivide(x, y), def);
}

export function safeDivideOrZero(x: Decimal, y: Decimal): Decimal {
  return safeDivideOrDefault(x, y, ZERO);
}

export function sum<T>(xs: Array<T>, f: (x: T) => Maybe<number>): Maybe<number> {
  return xs.reduce(fadd(f), undefined);
}

export function fadd<X>(f: (x: X) => Maybe<number>): (p: Maybe<number>, c: X) => Maybe<number> {
  return function (p: Maybe<number>, c: X): Maybe<number> {
    // Get the value:
    const value = f(c);

    // If the value is undefined, skip. Otherwise add:
    return isNothing(value) ? p : value + getOrElse(p, 0);
  };
}

export function sumDecimal<T>(xs: Array<T>, f: (x: T) => Maybe<Decimal>): Maybe<Decimal> {
  return xs.reduce(faddDecimal(f), undefined);
}

export function sumDecimalDefault<T>(xs: Array<T>, f: (x: T) => Maybe<Decimal>, def: Decimal): Decimal {
  return getOrElse(sumDecimal(xs, f), def);
}

export function sumDecimalDefaultZero<T>(xs: Array<T>, f: (x: T) => Maybe<Decimal>): Decimal {
  return sumDecimalDefault(xs, f, ZERO);
}

export function faddDecimal<X>(f: (x: X) => Maybe<Decimal>): (p: Maybe<Decimal>, c: X) => Maybe<Decimal> {
  return function (p: Maybe<Decimal>, c: X): Maybe<Decimal> {
    // Get the value:
    const value = f(c);

    // If the value is undefined, skip. Otherwise add:
    return isNothing(value) ? p : value.plus(getOrElse(p, ZERO));
  };
}
