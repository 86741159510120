import { Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { FlexTableRowAction, FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import { StockExtended } from 'cafelatte/services/stocks';

interface Args {
  records?: StockExtended[];
  operations?: FlexTableRowAction<StockExtended>[];
  ledgerDate?: string;
}

export default class extends Component<Args> {
  @service declare router: Router;

  get spec(): FlexTableSpec<StockExtended> {
    return {
      ident: 'cl-stocks-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'artifact.id', label: 'Instrument ID', hidden: true },
        { key: 'artifact.guid', label: 'Instrument GUID', hidden: true },
        {
          key: 'artifact.symbol',
          label: 'Instrument Symbol',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'artifact.id' },
        },
        {
          key: 'artifact.name',
          label: 'Instrument Name',
          component: '@link',
          options: { route: 'resource.details', modelPath: 'artifact.id' },
        },
        { key: 'artifact.ctype', label: 'Instrument Type' },
        { key: 'artifact.stype', label: 'Instrument Sub-Type' },
        { key: 'account.id', hidden: true },
        { key: 'account.guid', label: 'Account GUID', hidden: true },
        { key: 'account.name', component: '@link', options: { route: 'account.details', modelPath: 'account.id' } },
        { key: 'account.portfolio.id', label: 'Portfolio ID', hidden: true },
        { key: 'account.portfolio.guid', label: 'Portfolio GUID', hidden: true },
        {
          key: 'account.portfolio.name',
          label: 'Portfolio Name',
          component: '@link',
          options: { route: 'portfolio.details', modelPath: 'account.portfolio.id' },
        },
        { key: 'account.portfolio.team.id', label: 'Team ID', hidden: true },
        { key: 'account.portfolio.team.guid', label: 'Team GUID', hidden: true },
        {
          key: 'account.portfolio.team.name',
          label: 'Team Name',
          component: '@link',
          options: { route: 'team.details', modelPath: 'account.portfolio.team.id' },
        },
        { key: 'account.custodian.id', label: 'Custodian ID', hidden: true },
        { key: 'account.custodian.guid', label: 'Custodian GUID', hidden: true },
        {
          key: 'account.custodian.name',
          label: 'Custodian Name',
          component: '@link',
          options: { route: 'institution.details', modelPath: 'account.custodian.id' },
        },
        {
          key: 'quantity',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00', colorize: true },
          action: ({ record }): void => this.gotoLedger(record),
        },
      ],
    };
  }

  gotoLedger(record: StockExtended): void {
    this.router.transitionTo('ledger', {
      queryParams: {
        datespanMax: this.args.ledgerDate || today(),
        accounts: record.account.id,
        artifact: record.artifact.id,
      },
    });
  }
}
