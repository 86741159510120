import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import axios from 'axios';
import { injectPrivateConfiguration, konfiguration, Konfiguration } from 'cafelatte/libs/embered/config';
import CLSessionService from 'cafelatte/services/-ts/session';
import DecafService from 'cafelatte/services/decaf';
import KonfigService from 'cafelatte/services/konfig';
// @ts-expect-error
import Base from 'ember-simple-auth/authenticators/base';

/**
 * Type encoding of successful login response.
 */
interface LoginResponse {
  token: string;
  principal: any;
}

/**
 * Attempts to perform a login against the Barista API.
 *
 * @param konfiguration Global konfiguration.
 * @param credentials Authentication credentials.
 * @returns Promise of login response.
 */
function login(konfiguration: Konfiguration, credentials: any): Promise<LoginResponse> {
  // Get DECAF Barista API base URL:
  const barista = konfiguration.APP.API_BASEURL;

  // Define login URL:
  const url = `${barista}/auth/login/`;

  // Attempt to login:
  return axios.post<LoginResponse>(url, credentials).then((r) => r.data);
}

/**
 * Attempts to perform a logout against the Barista API.
 *
 * @param konfiguration Global konfiguration.
 * @param token Current authentication credentials.
 * @param all Indicates if we are logging out from all sessions.
 * @returns Undefined
 */
function logout(konfiguration: Konfiguration, token: string, all: boolean): Promise<undefined> {
  // Get DECAF Barista API base URL:
  const barista = konfiguration.APP.API_BASEURL;

  // Define login URL:
  const url = `${barista}/auth/${all ? 'logoutall' : 'logout'}/`;

  // Define headers:
  const headers = { Authorization: `Token ${token}` };

  // Attempt to login:
  return axios.get<void>(url, { headers }).then((_r) => undefined);
}

/**
 * Attempts to perform heartbeat against the Barista API.
 *
 * @param konfiguration Global konfiguration.
 * @param token authentication token.
 * @returns Undefined
 */
function heartbeat(konfiguration: Konfiguration, token: string): Promise<undefined> {
  // Get DECAF Barista API base URL:
  const barista = konfiguration.APP.API_BASEURL;

  // Define heartbeat URL:
  const url = `${barista}/healthchecks/`;

  // Define headers:
  const headers = { Authorization: `Token ${token}` };

  // Attempt to login:
  return axios.get<void>(url, { headers }).then((_r) => undefined);
}

/**
 * Provides ember-simple-auth integration for DECAF authentication machinery.
 */
export default class DecafAuthenticator extends Base {
  @service declare decaf: DecafService;
  @service declare konfig: KonfigService;
  @service declare session: CLSessionService;

  /**
   * Attempts to restore an existing session.
   *
   * @param {*} data Authentication data, if any.
   */
  restore(data?: any) {
    // Create the promise:
    const promise = new Promise((resolve, reject) => (isEmpty(data?.token) ? reject(data) : resolve(data)));

    // Add handlers to the promise:
    promise
      .then(() => later({}, () => this._onSession(data.token), 1000))
      .catch((err) => console.error('Session could not be restored.', err));

    return promise;
  }

  /**
   * Attempts to authenticate and establish a new session.
   *
   * @param {*} credentials Authentication credentials.
   */
  authenticate(credentials: any) {
    return new Promise((resolve, reject) => {
      login(konfiguration, credentials)
        .then((r) => {
          resolve(r);
          return r;
        })
        .then((r) => {
          // external ?next=http://xxx parameter
          const nextExternal = location.search?.split('?next=')?.[1];

          // Check external next url:
          if (nextExternal) {
            window.location.href = nextExternal;
          } else {
            later({}, () => this._onSession(r.token, this.session.attemptedTransition), 1000);
          }
        })
        .catch((e) => reject(e));
    });
  }

  /**
   * Invalidates the session.
   *
   * @param {*} data Authentication data, if any.
   * @param _authenticator Params to authenticator.
   * @param all: Authenticate all sessions?
   */
  invalidate(data: any, _authenticator: string, all: boolean) {
    return new Promise((resolve) => logout(konfiguration, data.token, all).finally(() => resolve(undefined)));
  }

  /**
   * Provides the hook for establishing a session.
   */
  _onSession(token: string, nextURL?: any) {
    // Run the initial heartbeat:
    this._doHeartbeat();

    // Setup the private configuration:
    injectPrivateConfiguration(token).then(this.konfig.onKonfiugurationUpdated);

    // Setup DECAF service.
    this.decaf.setup(token);

    // Check if we have any previous transition. If so, retry:
    if (nextURL) {
      nextURL.retry();
    }
  }

  /**
   * Attempts to ping the remote authenticated /api/healthchecks/ endpoint and
   * schedules the next one.
   *
   * Note that any authorization error will cause to an automated logout. So,
   * this acts as a security (!) valve for online session expiry/cancellation.
   */
  _doHeartbeat() {
    // Attempt to get the token from the session:
    const token = this.session.token || '<undefined>';

    // Perform heartbeat:
    heartbeat(konfiguration, token)
      .then(() => console.log('♥'))
      .catch((err: any) => {
        if (err?.response?.status == 401) {
          console.error('Healthcheck request is not authenticated. Logging out...');
          this.session.invalidate('authenticator:decaf', false);
        } else {
          console.error('Healthcheck failed: ', err);
        }
      })
      .finally(() => later(this, () => this._doHeartbeat(), 60000));
  }
}
// XREVIEW=TODO
// TODO: Make sure that transition/next-url information is carried along
