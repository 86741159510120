import Model from '@ember-data/model';
import { get, set } from '@ember/object';
import { isNothing } from 'cafelatte/libs/headless/prelude/maybe';
import MeService from 'cafelatte/services/me';
import { FlexTableRowAction, FlexTableRowActionHandler, FlexTableRowArguments } from './-internal/runtime';
import { FlexTableColumnSpec } from './-internal/spec';

// Export all types from internal modules:
// TODO: refine exports
export * from './-internal/runtime';
export * from './-internal/spec';

///////////////
// UTILITIES //
///////////////

export interface CommonRowAction<T extends Model> {
  type: 'edit' | 'details' | 'link' | 'delete';
  action?: FlexTableRowActionHandler<T>;
  onDelete?: FlexTableRowActionHandler<T>;
}

export function mkCommonRowActions<T extends Model>(
  args: CommonRowAction<T>[],
  others?: FlexTableRowAction<T>[]
): FlexTableRowAction<T>[] {
  return [...args.map(mkCommonRowAction), ...(others || [])];
}

export function mkCommonRowAction<T extends Model>(arg: CommonRowAction<T>): FlexTableRowAction<T> {
  const deleter = arg.onDelete ? mkRecordDeleter(arg.onDelete) : undefined;

  const retval: FlexTableRowAction<T> = { action: deleter || arg.action || voidAction };

  if (arg.type == 'edit') {
    retval.type = 'warning';
    retval.icon = 'edit';
    retval.label = 'Edit';
  } else if (arg.type == 'details') {
    retval.type = 'info';
    retval.icon = 'eye';
    retval.label = 'Details';
  } else if (arg.type == 'link') {
    retval.type = 'info';
    retval.icon = 'link';
    retval.label = 'Details';
  } else if (arg.type == 'delete') {
    retval.type = 'danger';
    retval.icon = 'trash';
    retval.label = 'Delete';
  }

  return retval;
}

export function voidAction<T extends Model>(_args: FlexTableRowArguments<T>): void {
  return;
}

export function mkRecordDeleter<T extends Model>(
  onDelete: FlexTableRowActionHandler<T>
): (args: FlexTableRowArguments<T>) => void {
  return (args) => {
    if (
      confirm(
        'This will delete the record and all associated records. Are you sure you want to proceed with this operation?'
      )
    ) {
      args.record.destroyRecord().then(() => onDelete(args));
    }
  };
}

export function mkColumn<T>(key: string, props?: Partial<FlexTableColumnSpec<T>>): FlexTableColumnSpec<T> {
  props = props || {};
  return { label: props.label || key, ...(props || {}), key };
}

export function mkAuditFields<T>(me: MeService, path?: string): FlexTableColumnSpec<T>[] {
  return [
    { key: `${path ? path + '.' : ''}created`, label: 'Created At', component: '@date', options: { format: 'human' } },
    { key: `${path ? path + '.' : ''}creator.username`, label: 'Created By', hidden: true, exclude: !me.internal },
    { key: `${path ? path + '.' : ''}updated`, label: 'Updated At', component: '@date', options: { format: 'human' } },
    { key: `${path ? path + '.' : ''}updater.username`, label: 'Updated By', hidden: true, exclude: !me.internal },
  ];
}

export function mkNumberColumn<T>(x: Partial<FlexTableColumnSpec<T>> & { key: string }): FlexTableColumnSpec<T> {
  return {
    component: '@number',
    align: 'right',
    ...x,
    options: { format: '0,0.00', colorize: true, ...(x.options || {}) },
  };
}

export function mkLinkColumn<T>(
  route: string,
  modelPath: string,
  x: Partial<FlexTableColumnSpec<T>> & { key: string }
): FlexTableColumnSpec<T> {
  return { component: '@link', ...x, options: { route, modelPath, ...(x.options || {}) } };
}

export function mkDateColumn<T>(x: Partial<FlexTableColumnSpec<T>> & { key: string }): FlexTableColumnSpec<T> {
  return { component: '@date', ...x, options: { format: 'DD-MM-YYYY', ...(x.options || {}) } };
}

export interface CommonSorting {
  by: string;
  descending: boolean;
}

export function mkCommonSorting<T>(
  instance: T,
  sort: keyof T,
  by: string
): { onSort: (x: -1 | 0 | 1) => void; sorted?: () => -1 | 0 | 1 } {
  return {
    sorted: () =>
      // @ts-expect-error
      isNothing(sort) || get(instance, `${sort}.by`) != by ? 0 : get(instance, `${sort}.descending`) ? -1 : 1,
    onSort: (x) => {
      if (x == -1) {
        // @ts-expect-error
        set(instance, sort, { by, descending: true });
      } else if (x == 0) {
        // @ts-expect-error
        set(instance, sort, undefined);
      } else {
        // @ts-expect-error
        set(instance, sort, { by, descending: false });
      }
    },
  };
}
