import { action, get } from '@ember/object';
import { CommonController } from 'cafelatte/libs/embered';
import UserModel from 'cafelatte/models/user';

export default class extends CommonController {
  get authorized(): boolean {
    // Get is privileged:
    // @ts-expect-error
    const privileged: boolean = get(this, 'me.privileged');

    // Get roles:
    // @ts-expect-error
    const roles: { code: string }[] = get(this, 'me.roles');

    // Get extra indicators:
    const is_administrator: boolean = roles.map((x) => x.code == 'administrator').reduce((a, c) => a || c, false);
    const is_supervisor: boolean = roles.map((x) => x.code == 'supervisor').reduce((a, c) => a || c, false);

    // Check and return:
    return is_administrator || (privileged && !is_administrator && !is_supervisor);
  }

  get forself(): boolean {
    return `${this.model.id}` === `${get(this, 'me.cuid')}`;
  }

  get authorizedForSelf(): boolean {
    return this.authorized || this.forself;
  }

  @action onSave(record: UserModel): void {
    this.model.reload();
    this.router.transitionTo('user.details', record.id);
  }

  @action onError(message: string, error: any): void {
    console.error(error);
    this.flashMessages.danger(message);
  }

  @action onCancel(_record: UserModel): void {
    this.router.transitionTo('user');
  }

  @action cancel() {
    this.model.rollbackAttributes();
    this.router.transitionTo('user');
  }

  @action save() {
    this.model.save().then(
      (p: any) => this.router.transitionTo('user', p.id),
      () => console.error('Error while saving data.')
    );
  }
}
