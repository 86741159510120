import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import ArtifactModel from 'cafelatte/models/resource';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<ArtifactModel> {
  @service declare decaf: DecafService;

  get spec(): FlexTableSpec<ArtifactModel> {
    return {
      ident: 'cl-artifacts-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        {
          key: 'watched.status',
          label: 'W',
          component: '@bool',
          align: 'center',
          action: ({ record }): void => {
            this.decaf.client.barista
              .post(`/resources/${record.id}/watch/`, { status: !record.watched?.status })
              .then(() => {
                record.notifyPropertyChange('watched');
              });
          },
        },
        { key: 'description', label: 'D', component: '@note', options: { title: 'Description' } },
        { key: 'id', component: '@link', options: { route: 'resource.details', modelPath: 'id' } },
        { key: 'cflag', label: 'Flag', component: '@flag', action: ({ record }) => this.onFlagClicked(record) },
        { key: 'symbol' },
        { key: 'name' },
        { key: 'type' },
        { key: 'stype', label: 'Subtype' },
        { key: 'ccymain', label: 'CCY' },
        { key: 'issued', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'launch', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'horizon', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'mic', label: 'MIC' },
        { key: 'ticker' },
        { key: 'isin', label: 'ISIN' },
        { key: 'cusip', label: 'CUSIP' },
        { key: 'figi', label: 'FIGI' },
        { key: 'telekurs' },
        { key: 'ohlccode', label: 'OHLC Code' },
        { key: 'sector' },
        { key: 'sector_gics', label: 'Sector (GICS)' },
        { key: 'country' },
        { key: 'domicile' },
        { key: 'issuer' },
        { key: 'sp_rating', label: 'S&P Rating' },
        { key: 'sp_outlook', label: 'S&P Outlook' },
        {
          key: 'riskprofile.name',
          label: 'Risk Profile',
          component: '@link',
          options: { route: 'riskprofile.details', modelPath: 'riskprofile.id' },
        },
        { key: 'assetclass.address', label: 'Asset Class' },
        ...mkAuditFields<ArtifactModel>(this.me),
      ],
    };
  }

  onFlagClicked(record: ArtifactModel): void {
    // Attempt to get the next flag:
    const next = ((record.cflag || 0) + 1) % 6 || undefined;

    // Update remote:
    record.cflag = next;
    record
      .save()
      .then(() => {
        this.flashMessages.success(`Record is ${isEmpty(next) ? 'un' : ''}flagged.`);
        record.reload();
      })
      .catch(() => {
        this.flashMessages.danger('Record could not be flagged.');
      });
  }
}
