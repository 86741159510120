import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { isNothing, Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import Decimal from 'decimal.js';
import { Promise } from 'rsvp';
import {
  ActionHandlerFactory,
  ActionHandlerParamsBuilderArgs,
  ActionHandlerParamsBuilderResult,
  ArtifactTypeCodeList,
  ArtifactTypes,
  getToday,
} from './_machinery';

function computeFX(quantity: any, rate: any, quantize: Maybe<number>, flip: Maybe<boolean>): number {
  // Get the quantity in decimal:
  quantity = new Decimal(quantity);

  // Flip or not?
  flip = flip === null || flip === undefined ? false : flip;

  // Convert rate from percentage points:
  rate = new Decimal(rate);
  rate = flip ? rate.pow(-1) : rate;

  // Are we quantizing?
  quantize = quantize === null || quantize === undefined ? -1 : quantize;

  // Compute interest:
  const retval: Decimal = quantity.times(rate);

  // Quantize (or not) and return:
  return (quantize >= 0 ? retval.toDP(quantize, Decimal.ROUND_HALF_EVEN) : retval).toNumber();
}

class ActionPositionChange extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = undefined;
  ctype = 15;
  display = 'Position Change';
  opening = true;
  closing = true;
}

class ActionIO extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = undefined;
  ctype = 20;
  display = 'Inflow/Outflow';
  opening = true;
  closing = true;
}

class ActionPayment extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = undefined;
  ctype = 25;
  display = 'Payment';
  opening = true;
  closing = true;
}

class ActionTransfer extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = undefined;
  ctype = 30;
  display = 'Transfer';
  opening = true;
  closing = true;
}

class ActionAmendment extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = undefined;
  ctype = 39;
  display = 'Amendment';
  opening = false;
  closing = true;
}

class ActionSpotFX extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['CCY'];
  ctype = 60;
  display = 'Spot FX';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || getToday(commitment),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        pxmain: price || null,
      })
    );
  }
}

class ActionSubscriptionRedemption extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['CCY'];
  ctype = 35;
  display = 'Subscription/Redemption';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || getToday(commitment),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        pxmain: price || null,
      })
    );
  }
}

class ActionPartialJournalEntry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['CCY'];
  ctype = 300;
  display = 'Partial Journal Entry';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || getToday(commitment),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        pxmain: price || null,
      })
    );
  }
}

class ActionFullJournalEntry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['CCY'];
  ctype = 301;
  display = 'Full Journal Entry';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || getToday(commitment),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn: get(artifact, 'id'),
        accaltn: account,
        rescomp: get(artifact, 'id'),
        acccomp: account,
        pxmain: price || null,
      })
    );
  }
}

class ActionShareTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SHRE'];
  ctype = 80;
  display = 'Buy/Sell Shares';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || getToday(commitment),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionShareDividendPayment extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SHRE'];
  ctype = 81;
  display = 'Share Dividend Payment';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || getToday(commitment),
        resmain_symbol: get(artifact, 'ccymain'),
        accmain: account,
        resundr: get(artifact, 'id'),
        qtyundr: isEmpty(quantity) || isNothing(quantity) ? null : -quantity,
        pxmain: price || null,
      })
    );
  }
}

class ActionShareSplitMerge extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SHRE'];
  ctype = 86;
  display = 'Share Split/Merge';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || getToday(commitment),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        pxmain: price || null,
      })
    );
  }
}

class ActionShareSpinOff extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SHRE'];
  ctype = 87;
  display = 'Share Spin-Off';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || getToday(commitment),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        qtycntr: isEmpty(quantity) || isNothing(quantity) ? null : -quantity,
        pxmain: price || null,
      })
    );
  }
}

class ActionDCITrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['DCIP'];
  ctype = 180;
  display = 'DCI Trade';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'issued') || get(artifact, 'launch'),
        settlement: settlement || get(artifact, 'launch'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resprin_symbol: get(artifact, 'ccymain'),
        qtyprin: quantity ? -quantity : null,
      })
    );
  }
}

class ActionDCIExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['DCIP'];
  ctype = 182;
  display = 'DCI Expiry';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get day count service:
    const dcc = get(artifact, 'daycount');

    // Get the interest:
    const interest = dcc.getInterest(
      get(artifact, 'convday'),
      quantity ? -quantity : 0,
      get(artifact, 'pxmain'),
      get(artifact, 'launch'),
      get(artifact, 'expiry'),
      get(artifact, 'expiry')
    );

    return interest.then((x: any) => {
      return {
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resprin_symbol: get(artifact, 'ccymain'),
        qtyprin: quantity ? -quantity : 0,
        resintr_symbol: get(artifact, 'ccymain'),
        qtyintr: new Decimal(x).toDP(2, Decimal.ROUND_HALF_EVEN),
      };
    });
  }
}

class ActionDCIExercise extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['DCIP'];
  ctype = 181;
  display = 'DCI Exercise';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Compute the alternative quantity
    const altqty = computeFX(quantity, get(artifact, 'pxaltn'), 2, get(artifact, 'pxflip'));

    // Get day count service:
    const dcc = get(artifact, 'daycount');

    // Get the interest:
    const interest = dcc.getInterest(
      get(artifact, 'convday'),
      -altqty,
      get(artifact, 'pxmain'),
      get(artifact, 'launch'),
      get(artifact, 'expiry'),
      get(artifact, 'expiry')
    );

    return interest.then((x: any) => {
      return {
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resprin_symbol: get(artifact, 'ccyaltn'),
        qtyprin: -altqty,
        resintr_symbol: get(artifact, 'ccyaltn'),
        qtyintr: new Decimal(x).toDP(2, Decimal.ROUND_HALF_EVEN),
      };
    });
  }
}

class ActionDCINetOut extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['DCIP'];
  ctype = 184;
  display = 'DCI Net-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        rescomp_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionELNTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['ELNP'];
  ctype = 200;
  display = 'DCI Net-Out';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'issued') || get(artifact, 'launch'),
        settlement: settlement || get(artifact, 'launch'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resprin_symbol: get(artifact, 'ccymain'),
        qtyprin: quantity ? -quantity : null,
      })
    );
  }
}

class ActionELNExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['ELNP'];
  ctype = 202;
  display = 'ELN Expiry';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resprin_symbol: get(artifact, 'ccymain'),
        qtyprin: quantity ? -quantity : 0,
      })
    );
  }
}

class ActionELNExercise extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['ELNP'];
  ctype = 201;
  display = 'ELN Exercise';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get the price:
    const px = new Decimal(get(artifact, 'pxaltn') || 1);

    // As decimal:
    const quantityD = new Decimal(quantity ? quantity : 0);

    // Compute the number of shares:
    const noOfShares = quantityD.times(-1).dividedBy(px).floor();

    // Compute the cash amount equivalent:
    const cashEquiv = noOfShares.times(px);

    // Compute the balance, if any:
    const balance = quantityD.times(-1).minus(cashEquiv).toDP(2, Decimal.ROUND_HALF_EVEN);

    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resundr: get(get(artifact, 'underlying'), 'id'),
        qtyundr: noOfShares,
        rescntr_symbol: get(artifact, 'ccymain'),
        qtycntr: cashEquiv,
        rescomp_symbol: get(artifact, 'ccymain'),
        qtycomp: balance,
      })
    );
  }
}

class ActionELNNetOut extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['ELNP'];
  ctype = 203;
  display = 'ELN Net-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        rescomp_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionFXForwardTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXFWD'];
  ctype = 160;
  display = 'FX Forward Trade';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'issued') || get(artifact, 'launch'),
        settlement: settlement || get(artifact, 'launch'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionFXForwardDelivery extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXFWD'];
  ctype = 162;
  display = 'FX Forward Delivery';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get the main/altn currency:
    const ccymain = get(artifact, 'ccymain');
    const ccyaltn = get(artifact, 'ccyaltn');

    // Get price related information:
    const pxmain = get(artifact, 'pxmain');
    const pxflip = get(artifact, 'pxflip');

    // Get the main CCY quantity:
    const ccymainQTY = new Decimal(quantity || 0).times(-1);

    // Compute the alternative CCY quantity:
    const ccyaltnQTY = -computeFX(ccymainQTY, pxmain, 2, pxflip);

    // Get date of interest:
    const date = [getToday(), get(artifact, 'ceased') || get(artifact, 'expiry')].filter((x) => x).sort()[0];

    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || date,
        settlement: settlement || date,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resundr_symbol: ccymain,
        qtyundr: ccymainQTY,
        rescntr_symbol: ccyaltn,
        qtycntr: ccyaltnQTY,
      })
    );
  }
}

class ActionFXForwardNetOut extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXFWD'];
  ctype = 163;
  display = 'FX Forward Net-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        rescomp_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionTimeDepositTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['DEPO'];
  ctype = 40;
  display = 'Time Deposit (Full Resolution)';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'launch'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionTimeDepositOpenTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['DEPO'];
  ctype = 41;
  display = 'Time Deposit (Open)';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'launch'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionTimeDepositCloseTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['DEPO'];
  ctype = 42;
  display = 'Time Deposit (Close)';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionLoanTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['LOAN'];
  ctype = 50;
  display = 'Loan (Full Resolution)';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'launch'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionLoanOpenTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['LOAN'];
  ctype = 51;
  display = 'Loan (Open)';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'launch'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionLoanCloseTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['LOAN'];
  ctype = 52;
  display = 'Loan (Close)';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionCODTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['COD'];
  ctype = 400;
  display = 'Certificate of Deposit (Full Resolution)';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'launch'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionCODOpenTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['COD'];
  ctype = 401;
  display = 'Certificate of Deposit (Open)';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'launch'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionCODCloseTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['COD'];
  ctype = 402;
  display = 'Certificate of Deposit (Close)';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionStructuredProductTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SP'];
  ctype = 170;
  display = 'Buy/Sell Structured Product';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionStructuredProductExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SP'];
  ctype = 172;
  display = 'Structured Product Expiry';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionStructuredProductExercise extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SP'];
  ctype = 173;
  display = 'Structured Product Exercise';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionStructuredProductCouponPayment extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SP'];
  ctype = 171;
  display = 'Structured Product Coupon Payment';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain_symbol: get(artifact, 'ccymain'),
        accmain: account,
        resundr: get(artifact, 'id'),
        qtyundr: quantity ? -quantity : 0,
        pxmain: price || null,
      })
    );
  }
}

class ActionBondTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['BOND'];
  ctype = 100;
  display = 'Buy/Sell Bond';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        rescomp_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionBondExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['BOND'];
  ctype = 102;
  display = 'Bond Contract Maturity';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resprin_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionBondCouponPayment extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['BOND'];
  ctype = 101;
  display = 'Bond Coupon Payment';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain_symbol: get(artifact, 'ccymain'),
        accmain: account,
        resundr: get(artifact, 'id'),
        qtyundr: -(quantity || 0),
        pxmain: price || null,
      })
    );
  }
}

class ActionZeroCouponTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['ZCPN'];
  ctype = 90;
  display = 'Buy/Sell Zero Coupon';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionZeroCouponExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['ZCPN'];
  ctype = 91;
  display = 'Zero Coupon Contract Maturity';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resprin_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionOtherAssetTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['OTHER'];
  ctype = 190;
  display = 'Buy/Sell Other Asset';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionFXOptionTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXOPT'];
  ctype = 120;
  display = 'Buy/Sell FX Option';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionFXOptionExercise extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXOPT'];
  ctype = 121;
  display = 'FX Option Exercise';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Compute the alternative quantity
    const altqty = computeFX(quantity, get(artifact, 'pxmain'), 2, get(artifact, 'pxflip'));

    // Define the factor:
    const factor = get(artifact, 'callput') ? 1 : -1;

    // Get today's date:
    const todaydate = getToday();

    // Get the horizon:
    const horizon = get(artifact, 'ceased') || get(artifact, 'expiry') || todaydate;

    // Get the min of today and horizon:
    const date = todaydate < horizon ? todaydate : horizon;

    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || date,
        settlement: settlement || date,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resundr_symbol: get(artifact, 'ccymain'),
        accundr: account,
        qtyundr: -(quantity || 0) * factor,
        rescntr_symbol: get(artifact, 'ccyaltn'),
        acccntr: account,
        qtycntr: altqty * factor,
      })
    );
  }
}

class ActionFXOptionNetOut extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXOPT'];
  ctype = 122;
  display = 'FX Option Net-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        rescomp_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionFXOptionExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXOPT'];
  ctype = 123;
  display = 'FX Option Expiry';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionCommodityTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['COMM'];
  ctype = 70;
  display = 'Buy/Sell Commodities';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionOptionTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['OPT'];
  ctype = 110;
  display = 'Buy/Sell Option';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionOptionExercise extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['OPT'];
  ctype = 111;
  display = 'Option Exercise';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get the price:
    const pxraw = get(artifact, 'pxmain');
    const px = isEmpty(pxraw) ? null : new Decimal(pxraw || 1);

    // As decimal:
    const quantityD = new Decimal(quantity || 0);

    // Compute the number of shares:
    const noOfShares = quantityD.times(get(artifact, 'quantity'));

    // Compute the cash amount equivalent:
    const cashEquiv = isEmpty(px) || px === undefined || px === null ? null : noOfShares.times(px);

    // Define the factor:
    const factor = get(artifact, 'callput') ? 1 : -1;

    // Compute the qtrcntr:
    const qtycntr =
      isEmpty(cashEquiv) || cashEquiv === undefined || cashEquiv === null ? null : cashEquiv.times(factor);

    // Get today's date:
    const todaydate = getToday();

    // Get the horizon:
    const horizon = get(artifact, 'ceased') || get(artifact, 'expiry') || todaydate;

    // Get the min of today and horizon:
    const date = todaydate < horizon ? todaydate : horizon;

    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || date,
        settlement: settlement || date,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,

        resundr: get(get(artifact, 'underlying'), 'id'),
        accundr: account,
        qtyundr: -noOfShares.times(factor),

        rescntr_symbol: get(artifact, 'ccymain'),
        acccntr: account,
        qtycntr,
      })
    );
  }
}

class ActionOptionNetOut extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['OPT'];
  ctype = 112;
  display = 'Option Net-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        rescomp_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionOptionExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['OPT'];
  ctype = 113;
  display = 'Option Expiry';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionFXFutureTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXFUT'];
  ctype = 140;
  display = 'FX Future Trade';
  opening = true;
  closing = false;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'issued') || get(artifact, 'launch'),
        settlement: settlement || get(artifact, 'launch'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
      })
    );
  }
}

class ActionFXFutureDelivery extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXFUT'];
  ctype = 141;
  display = 'FX Future Delivery';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get the main/altn currency:
    const ccymain = get(artifact, 'ccymain');
    const ccyaltn = get(artifact, 'ccyaltn');

    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resundr_symbol: ccymain,
        rescntr_symbol: ccyaltn,
      })
    );
  }
}

class ActionFXFutureNetOut extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXFUT'];
  ctype = 142;
  display = 'FX Future Net-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        rescomp_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionFutureTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FUT'];
  ctype = 130;
  display = 'Buy/Sell Future';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionFutureDelivery extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FUT'];
  ctype = 131;
  display = 'Future Delivery';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resundr: get(get(artifact, 'underlying'), 'id'),
        rescntr_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionFutureNetOut extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FUT'];
  ctype = 132;
  display = 'Future Net-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        rescomp_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionForwardTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FWD'];
  ctype = 150;
  display = 'Buy/Sell Forward';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionForwardDelivery extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FWD'];
  ctype = 151;
  display = 'Forward Delivery';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    const date = [getToday(), get(artifact, 'ceased') || get(artifact, 'expiry')].filter((x) => x).sort()[0];

    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || date,
        settlement: settlement || date,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resundr: get(get(artifact, 'underlying'), 'id'),
        rescntr_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionForwardNetOut extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FWD'];
  ctype = 152;
  display = 'Forward Net-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'ceased') || get(artifact, 'expiry'),
        settlement: settlement || get(artifact, 'expiry'),
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        rescomp_symbol: get(artifact, 'ccymain'),
      })
    );
  }
}

class ActionSPMFTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SPMF'];
  ctype = 500;
  display = 'Mini-Future Contract Trade';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'launch') || getToday(),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionSPMFStrike extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SPMF'];
  ctype = 510;
  display = 'Mini-Future Contract Strike';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionSPMFKnockout extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SPMF'];
  ctype = 520;
  display = 'Mini-Future Contract Knock-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        pxmain: price || null,
      })
    );
  }
}

class ActionSPMFExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['SPMF'];
  ctype = 530;
  display = 'Mini-Future Contract Expiry';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry') || getToday(),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionFXMFTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXMF'];
  ctype = 600;
  display = 'FX Mini-Future Contract Trade';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'launch') || getToday(),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionFXMFStrike extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXMF'];
  ctype = 610;
  display = 'FX Mini-Future Contract Strike';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionFXMFKnockout extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXMF'];
  ctype = 620;
  display = 'FX Mini-Future Contract Knock-Out';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        pxmain: price || null,
      })
    );
  }
}

class ActionFXMFExpiry extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['FXMF'];
  ctype = 630;
  display = 'FX Mini-Future Contract Expiry';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry') || getToday(),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionCFDTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['CFD'];
  ctype = 700;
  display = 'Buy/Sell CFD';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    // Get date of interest:
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || null,
      })
    );
  }
}

class ActionCommercialPaperTrade extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['CMPO'];
  ctype = 800;
  display = 'Buy/Sell Commercial Paper';
  opening = true;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    price,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: get(artifact, 'launch') || get(artifact, 'issued') || getToday(commitment),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resaltn_symbol: get(artifact, 'ccymain'),
        pxmain: price || get(artifact, 'pxaltn'),
      })
    );
  }
}

class ActionCommercialPaperMaturity extends ActionHandlerFactory {
  types?: Array<ArtifactTypes> = ['CMPO'];
  ctype = 801;
  display = 'Commercial Paper Maturity';
  opening = false;
  closing = true;

  makeParams({
    artifact,
    account,
    quantity,
    commitment,
    settlement,
  }: ActionHandlerParamsBuilderArgs): ActionHandlerParamsBuilderResult {
    return new Promise((resolve, _reject) =>
      resolve({
        commitment: commitment || get(artifact, 'expiry'),
        settlement: settlement || null,
        resmain: get(artifact, 'id'),
        accmain: account,
        qtymain: quantity,
        resprin_symbol: get(artifact, 'ccymain'),
        qtyprin: quantity ? -quantity : quantity,
      })
    );
  }
}

const _allInstances: Array<ActionHandlerFactory> = [
  new ActionPositionChange(),
  new ActionIO(),
  new ActionPayment(),
  new ActionTransfer(),
  new ActionAmendment(),
  new ActionSpotFX(),
  new ActionSubscriptionRedemption(),
  new ActionPartialJournalEntry(),
  new ActionFullJournalEntry(),
  new ActionShareTrade(),
  new ActionShareDividendPayment(),
  new ActionShareSplitMerge(),
  new ActionShareSpinOff(),
  new ActionDCITrade(),
  new ActionDCIExpiry(),
  new ActionDCIExercise(),
  new ActionDCINetOut(),
  new ActionELNTrade(),
  new ActionELNExpiry(),
  new ActionELNExercise(),
  new ActionELNNetOut(),
  new ActionFXForwardTrade(),
  new ActionFXForwardDelivery(),
  new ActionFXForwardNetOut(),
  new ActionTimeDepositTrade(),
  new ActionTimeDepositOpenTrade(),
  new ActionTimeDepositCloseTrade(),
  new ActionLoanTrade(),
  new ActionLoanOpenTrade(),
  new ActionLoanCloseTrade(),
  new ActionCODTrade(),
  new ActionCODOpenTrade(),
  new ActionCODCloseTrade(),
  new ActionStructuredProductTrade(),
  new ActionStructuredProductExpiry(),
  new ActionStructuredProductExercise(),
  new ActionStructuredProductCouponPayment(),
  new ActionBondTrade(),
  new ActionBondExpiry(),
  new ActionBondCouponPayment(),
  new ActionZeroCouponTrade(),
  new ActionZeroCouponExpiry(),
  new ActionOtherAssetTrade(),
  new ActionFXOptionTrade(),
  new ActionFXOptionExercise(),
  new ActionFXOptionNetOut(),
  new ActionFXOptionExpiry(),
  new ActionCommodityTrade(),
  new ActionOptionTrade(),
  new ActionOptionExercise(),
  new ActionOptionNetOut(),
  new ActionOptionExpiry(),
  new ActionFXFutureTrade(),
  new ActionFXFutureDelivery(),
  new ActionFXFutureNetOut(),
  new ActionFutureTrade(),
  new ActionFutureDelivery(),
  new ActionFutureNetOut(),
  new ActionForwardTrade(),
  new ActionForwardDelivery(),
  new ActionForwardNetOut(),
  new ActionSPMFTrade(),
  new ActionSPMFStrike(),
  new ActionSPMFKnockout(),
  new ActionSPMFExpiry(),
  new ActionFXMFTrade(),
  new ActionFXMFStrike(),
  new ActionFXMFKnockout(),
  new ActionFXMFExpiry(),
  new ActionCFDTrade(),
  new ActionCommercialPaperTrade(),
  new ActionCommercialPaperMaturity(),
];

export const ArtifactActionCatalogue: Map<string, Array<ActionHandlerFactory>> = new Map([]);

ArtifactTypeCodeList.forEach((x) =>
  ArtifactActionCatalogue.set(
    x,
    _allInstances.filter((x) => !x.types)
  )
);

_allInstances
  .filter((x) => x.types)
  .forEach((x) => {
    x.types?.forEach((h) => {
      ArtifactActionCatalogue.get(h)?.push(x);
    });
  });
