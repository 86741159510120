import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, filter, map, mapBy, uniq } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import PortfolioModel from './portfolio';
import UserModel from './user';
import { DecafAuditFields, HasMany, makeValidators } from './_internal/machinery';

const _fields = ['name', 'control', 'portfolios', 'members'];

export default class PortfolioGroupModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @attr()
  declare control?: string;

  @hasMany('portfolio', { inverse: 'groups' })
  declare portfolios?: HasMany<PortfolioModel>;

  @hasMany('user', { inverse: 'portfolioGroups' })
  declare members?: HasMany<UserModel>;

  @computed('portfolios.[]')
  get portfoliosCount(): number {
    // TODO: Find a solution this `DS.Model#hasMany`.
    // @ts-expect-error
    return this.hasMany('portfolios').ids().length;
  }

  @computed('members.[]')
  get membersCount(): number {
    // @ts-expect-error
    return this.hasMany('members').ids().length;
  }

  @mapBy('members', 'email')
  memberEmails?: Array<string>;

  @filter('memberEmails', (item) => !isEmpty(item))
  memberNonEmptyEmails?: Array<string>;

  @map('memberNonEmptyEmails', (item) => item.split('@')[1])
  memberDomains?: Array<string>;

  @uniq('memberDomains')
  uniqueMemberDomains?: Array<string>;

  @alias('name')
  label?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    portfoliogroup: PortfolioGroupModel;
  }
}
