import { inject as service } from '@ember/service';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import PortfolioModel from 'cafelatte/models/portfolio';
import {
  FlexTableSpec,
  mkAuditFields,
  mkDateColumn,
  mkLinkColumn,
  mkNumberColumn,
} from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
import KonfigService from 'cafelatte/services/konfig';
import { RecordListComponent } from '../../commons/record/list';

export default class extends RecordListComponent<PortfolioModel> {
  @service declare konfig: KonfigService;
  @service declare decaf: DecafService;

  get spec(): FlexTableSpec<PortfolioModel> {
    return {
      ident: 'cl-portfolio-table',
      actions: this.args.operations,
      vfill: true,
      columns: [
        {
          key: 'watched.status',
          label: 'W',
          component: '@bool',
          align: 'center',
          exclude: !this.me.internal,
          action: ({ record }): void => {
            this.decaf.client.barista
              .post(`/portfolios/${record.id}/watch/`, { status: !record.watched?.status })
              .then(() => {
                record.notifyPropertyChange('watched');
              });
          },
        },
        mkLinkColumn('portfolio.details', 'id', { key: 'id' }),
        mkLinkColumn('portfolio.details', 'id', { key: 'name' }),
        { key: 'domicile.name', label: 'Domicile' },
        { key: 'team.id', hidden: true, exclude: !this.me.internal },
        mkLinkColumn('team.details', 'team.id', { key: 'team.name', exclude: !this.me.internal }),
        mkDateColumn({ key: 'inception' }),
        { key: 'rccy', label: 'CCY' },
        mkNumberColumn({
          key: 'quickvaluation.nav',
          label: 'NAV',
          action: ({ record }): void => this.gotoConsolidation(record),
        }),
        mkNumberColumn({
          key: 'quickvaluation.aum',
          label: 'AUM',
          action: ({ record }): void => this.gotoConsolidation(record),
        }),
        mkNumberColumn({
          key: 'quickvaluation.exp',
          label: 'Net Exposure',
          action: ({ record }): void => this.gotoConsolidation(record),
        }),
        { key: 'advisor' },
        { key: 'asset_manager' },
        { key: 'manager', label: 'Investment Manager' },
        { key: 'marketing_agent' },
        { key: 'objective', hidden: this.konfig.views?.portfolio?.index?.hideColumns?.includes('objective') },
        { key: 'mandate_profile' },
        { key: 'riskprofile.id', label: 'Risk Profile Id', hidden: true },
        mkLinkColumn('riskprofile.details', 'riskprofile.id', {
          key: 'riskprofile.name',
          label: 'Risk Profile Name',
          exclude: !this.me.internal,
        }),
        {
          key: 'pgs',
          label: 'Portfolio Groups',
          value: ({ record }) => (record.get('pgs') || []).map((x: any) => x.get('name')).join(', '),
          exclude: !this.me.internal,
        },
        { key: 'sandbox', component: '@bool', align: 'center', exclude: !this.me.internal },
        {
          key: 'data_source',
          exclude: !this.me.internal,
          hidden: this.konfig.views?.portfolio?.index?.hideColumns?.includes('data_source'),
        },
        mkDateColumn({ key: 'last.tx.commitment', label: 'TXN Last', exclude: !this.me.internal }),
        ...mkAuditFields<PortfolioModel>(this.me),
      ],
    };
  }

  gotoConsolidation(record: PortfolioModel): void {
    this.router.transitionTo(record.isFund ? 'reports.fund' : 'reports.portfolio', {
      queryParams: { ccy: record.rccy, portfolio: record.id, type: 'commitment', date: today() },
    });
  }
}
