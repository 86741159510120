import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import AssetClassModel from 'cafelatte/models/assetclass';
import { FlexTableRowAction, mkCommonRowActions } from '../components/x/flex-table';

export default class extends ControllerWithCurrentRecord<AssetClassModel> {
  createNewRecord(): AssetClassModel {
    return this.store.createRecord('assetclass');
  }

  get tableOperations(): FlexTableRowAction<any>[] {
    return mkCommonRowActions([{ type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record.instance) }]);
  }
}
