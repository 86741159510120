import TradeOrderExecutionModel from 'cafelatte/models/tradeorderexecution';
import { FlexTableSpec, mkAuditFields } from 'cafelatte/pods/components/x/flex-table';
import { RecordListComponent } from '../../../commons/record/list';

export default class extends RecordListComponent<TradeOrderExecutionModel> {
  get spec(): FlexTableSpec<TradeOrderExecutionModel> {
    return {
      ident: 'cl-trade-order-execution-list-table',
      vfill: true,
      actions: this.args.operations || [],
      columns: [
        { key: 'id' },
        { key: 'allocation.id', label: 'Allocation' },
        { key: 'date', component: '@date', options: { format: 'DD-MM-YYYY' } },
        { key: 'time', align: 'center' },
        { key: 'reference' },
        { key: 'cstate.name', label: 'State' },
        {
          key: 'booking.id',
          label: 'Booking',
          component: '@link',
          options: { route: 'trade.details', modelPath: 'booking.id' },
        },
        {
          key: 'monetary.qty',
          label: 'Value',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'quantity', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
        {
          key: 'pricenet',
          label: 'Net Price',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        {
          key: 'pricegrs',
          label: 'IBR Price',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'accrdccy', label: 'Acc. CCY' },
        {
          key: 'accrdqty',
          label: 'Acc. QTY',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'feestyp1', label: 'Fee Type 1' },
        { key: 'feesccy1', label: 'Fee CCY 1' },
        {
          key: 'feesqty1',
          label: 'Fee QTY 1',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'feestyp2', label: 'Fee Type 2' },
        { key: 'feesccy2', label: 'Fee CCY 2' },
        {
          key: 'feesqty2',
          label: 'Fee QTY 2',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'feestyp3', label: 'Fee Type 3' },
        { key: 'feesccy3', label: 'Fee CCY 3' },
        {
          key: 'feesqty3',
          label: 'Fee QTY 3',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        { key: 'feestyp4', label: 'Fee Type 4' },
        { key: 'feesccy4', label: 'Fee CCY 4' },
        {
          key: 'feesqty4',
          label: 'Fee QTY 4',
          align: 'right',
          component: '@number',
          options: { format: '0,0.00[0000]' },
        },
        ...mkAuditFields<TradeOrderExecutionModel>(this.me),
      ],
    };
  }
}
