import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { getDate, SDate, today } from 'cafelatte/libs/headless/prelude/datetime';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { APITokenFrequency } from 'cafelatte/libs/headless/services/profile';
import DecafService from 'cafelatte/services/decaf';

interface APITokenFrequencyComponentArgs {
  query?: Record<string, string>;
}

type PlotlySeries = { name: string; x: Array<SDate>; y: Array<Maybe<number>> };

export default class APITokenFrequencyComponent extends Component<APITokenFrequencyComponentArgs> {
  @service declare decaf: DecafService;

  @tracked barmode = 'stack';
  @tracked created__gte = getDate('last-year-end');
  @tracked created__lte = today();
  @tracked period = 'week';

  get data() {
    return this.decaf.services.profile
      .getAPITokenFrequency({
        ...(this.args.query || {}),
        created__gte: this.created__gte,
        created__lte: this.created__lte,
        period: this.period,
      })
      .then(this.convert);
  }

  convert(data: APITokenFrequency): Array<PlotlySeries> {
    // Initialize the value:
    const retval: Record<Id, { username: string; data: Record<SDate, number> }> = {};

    // Iterate over data and populate retval:
    data.forEach((x) => {
      // Ensure that we have the object in:
      if (!(x.principal in retval)) {
        retval[x.principal] = { username: x.principal__username, data: {} };
      }

      // Add to retval:
      // @ts-expect-error ts2532
      retval[x.principal].data[x.date] = x.count;
    });

    return Object.values(retval).map((x) => ({
      name: x.username,
      x: Object.keys(x.data),
      y: Object.values(x.data),
      type: 'bar',
    }));
  }

  get plotLayout() {
    return {
      title: 'Login Activity',
      xaxis: { title: 'Timeline' },
      yaxis: { title: 'Number of Logins' },
      margin: { b: 100 },
      barmode: this.barmode,
    };
  }
}
