import { helper } from '@ember/component/helper';

/**
 * Defines a type alias for data buffers of 8-bit unsigned integer values.\
 *
 * The actual type is an iterable of values of JS number primitive type.
 */
type Buffer = Iterable<number>;

/**
 * Defines a type alias for textual data compiled from character codes.
 *
 * The actual type is JS string primitive type.
 */
type CCData = string;

/**
 * Consumes data and its mime type, and produces a Base64 encoded Data URI string.
 *
 * @param data  Textual data
 * @param mime  Mime type
 */
export function textualDataToDataUri(data: CCData, mime: string): string {
  return `data:${mime};base64,${btoa(data)}`;
}

/**
 * Consumes a buffer and produces data representation of it.
 *
 * @param buffer  Data buffer
 */
export function bufferToTextualData(buffer: Buffer): CCData {
  // @ts-expect-error ts2345
  return String.fromCharCode.apply(null, new Uint8Array(buffer));
}

/**
 * Consumes data buffer and its mime type, and produces a Base64 encoded Data URI string.
 *
 * @param buffer  Data buffer
 * @param mime    Mime type
 */
export function bufferToDataUri(buffer: Buffer, mime: string): string {
  return textualDataToDataUri(bufferToTextualData(buffer), mime);
}

/**
 * Defines and export the helper.
 */
export default helper(([buffer, mime]) => bufferToDataUri(buffer, mime));
// XREVIEW=TODO
// TODO: Get rid of datauri-from-buffer helper (depends on beanbag refactor).
