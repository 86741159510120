import { Service } from '../-service';
import { ExternalValuationItem, ExternalValuationQuery, request } from './-internal';

// Export public symbols:
export { ExternalValuationQuery, ExternalValuationItem };

/**
 * Provides the barista external valuation service.
 */
export class ExternalValuationService extends Service {
  /**
   * Retrieves and compiles report data from the remote API endoint.
   *
   * @param query Report query.
   * @returns Report data.
   */
  query(query: ExternalValuationQuery): Promise<Array<ExternalValuationItem>> {
    return request(this.client, query);
  }
}
