import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { mergeDeep } from 'cafelatte/libs/headless/prelude/records';

/**
 * Defines default plot layout.
 */
const defaultLayout = {
  xaxis: {
    color: '#f0f3f4',
    titlefont: { color: '#f0f3f4' },
  },
  yaxis: {
    color: '#f0f3f4',
    titlefont: { color: '#f0f3f4' },
  },
  margin: { t: 60, r: 60, b: 60, l: 60, p: 0 },
  font: { color: '#f0f3f4' },
  titlefont: { color: '#f0f3f4' },
  plot_bgcolor: '#10161d',
  paper_bgcolor: '#10161d',
};

/**
 * Defines default plot settings.
 */
const defaultSettings = {
  displaylogo: false,
  showLink: false,
  editable: true,
  modeBarButtonsToRemove: ['sendDataToCloud'],
};

/**
 * Mock type for Plotly module.
 */
interface Plotly {
  newPlot(...args: any): void;
}

/**
 * Component arguments.
 */
interface Args {
  data?: any;
  layout?: any;
  settings?: any;
}

/**
 * Displays a plotly chart.
 */
export default class CafelattePlotly extends Component<Args> {
  /**
   * Plotly module.
   *
   * Plotly is loaded during object construction.
   */
  @tracked plotly?: Plotly;

  /**
   * Element to plot to.
   */
  @tracked plotElement?: HTMLElement;

  /**
   * Constructs the component and loads the plotly module.
   *
   * @param args Arguments to super.
   */
  constructor(...args: any) {
    // Load the plotly module:
    // @ts-expect-error
    import('plotly').then((module) => (this.plotly = module.default));

    // Continue constructing the component instance:
    // @ts-expect-error
    super(...args);
  }

  /**
   * Plot layout.
   */
  get layout(): any {
    return mergeDeep({}, defaultLayout, this.args.layout || {});
  }

  /**
   * Plot settings.
   */
  get settings(): any {
    return mergeDeep({}, defaultSettings, this.args.settings || {});
  }

  /**
   * Renders the plot.
   */
  get plot(): void {
    // Check if we have the data and the plotly module and the element:
    if (isEmpty(this.args.data) || isEmpty(this.plotly) || isEmpty(this.plotElement)) {
      return;
    }

    // Plot:
    this.plotly?.newPlot(this.plotElement, this.args.data, this.layout, this.settings);
  }

  /**
   * Captures the element to plot to.
   *
   * @param element Element inserted.
   */
  @action onInsert(element: HTMLElement) {
    this.plotElement = element;
  }
}
