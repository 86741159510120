import Store from '@ember-data/store';
import { action, computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { FlexTableSpec, mkLinkColumn, mkNumberColumn } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
import { Chart, registerables } from 'chart.js';
import {
  WatchedPortfolio,
  WatchedPortfolioWithNAV,
  buildWatchedPortfoliosWithNAV,
  buildWatchedPortfoliosWithPerformance,
  chartNoDataHandler,
  fetchWatchedPortfolios,
  watchOrUnwatchPortfolio,
} from './machinery';

Chart.register(...registerables);
Chart.register({
  id: 'no-data-message',
  afterDraw: chartNoDataHandler,
});

export default class extends Component {
  @service declare decaf: DecafService;
  @service declare router: RouterService;
  @service declare store: Store;

  @tracked watchedPortfolios: WatchedPortfolioWithNAV[] = [];
  @tracked selectedPortfolios: number[] = [];
  @tracked itemCount = 4;
  @tracked watchModalOpen = false;
  @tracked loading = true;
  @tracked previewingRecord?: WatchedPortfolioWithNAV;
  @tracked previewingRecordIndex?: number;
  @tracked pwlPage = 1; // watched portfolios page
  @tracked pwlPageSize = 10; // watched portfolios page size

  @computed('watchedPortfolios')
  get pwlTotalPages(): number {
    return Math.ceil(this.watchedPortfolios.length / this.pwlPageSize);
  }

  @computed('watchedPortfolios', 'pwlPage', 'pwlPageSize')
  get pwlPaginatedRecords(): WatchedPortfolioWithNAV[] {
    const start = (this.pwlPage - 1) * this.pwlPageSize;
    const end = start + this.pwlPageSize;
    return this.watchedPortfolios.slice(start, end);
  }

  get pwlTableSpec(): FlexTableSpec<WatchedPortfolioWithNAV> {
    return {
      ident: 'watched-portfolios-list',
      vfill: false,
      emptyTableMessage: 'No Watched Portfolios',
      disableFooterActions: true,
      actions: [
        {
          type: 'danger',
          icon: 'trash',
          action: ({ record }) => {
            this.unWatchPortfolio(record.id);
          },
        },
      ],
      columns: [
        mkLinkColumn('portfolio.details', 'id', { key: 'id' }),
        mkLinkColumn('portfolio.details', 'id', { key: 'name' }),
        mkNumberColumn({
          key: 'nav',
          label: 'NAV',
          action: ({ record }): void => this.gotoConsolidation(record),
        }),
        { key: 'ccy', label: 'CCY' },
        {
          key: '',
          component: '@watched-portfolio-popover',
        },
      ],
    };
  }

  @action async didInsert(): Promise<void> {
    await this.getAndBuildWathcedPortfolios();
    this.loading = false;
  }

  @action async getAndBuildWathcedPortfolios() {
    const _watchedPortfolios = await fetchWatchedPortfolios(this.store, this.decaf.client);
    this.itemCount = _watchedPortfolios.length;
    const withNav = await buildWatchedPortfoliosWithNAV(this.decaf.client, _watchedPortfolios);
    const withPerf = await buildWatchedPortfoliosWithPerformance(this.decaf, withNav);
    this.watchedPortfolios = withPerf;
  }

  @action async watchPortfolios() {
    const promises = this.selectedPortfolios.map((id) => watchOrUnwatchPortfolio(this.decaf.client, id, true));
    Promise.all(promises)
      .then(() => {
        this.getAndBuildWathcedPortfolios();
        this.toggleSelectionModal();
      })
      .catch((err) => {
        alert('Failed to watch the portfolios: ' + err.message);
      });
  }

  @action unWatchPortfolio(id: number) {
    const obj = this.watchedPortfolios.find((x) => id === x.id);
    if (obj !== undefined) {
      if (confirm(`You are about to unwatch Portfolio "${obj?.name}". Are you sure about this?`)) {
        return watchOrUnwatchPortfolio(this.decaf.client, id, false)
          .then(() => {
            this.watchedPortfolios.removeObject(obj);
          })
          .catch((err) => {
            alert('Failed to watch the portfolio: ' + err.message);
          });
      }
    }
  }

  @action toggleSelectionModal() {
    this.watchModalOpen = !this.watchModalOpen;
    this.selectedPortfolios = [];
  }

  @action gotoConsolidation(record: WatchedPortfolio): void {
    this.router.transitionTo(record.isFund ? 'reports.fund' : 'reports.portfolio', {
      queryParams: { ccy: record.ccy, portfolio: record.id, type: 'commitment', date: today() },
    });
  }

  @action setPreviewingRecord(record: WatchedPortfolioWithNAV, rowIndex: number) {
    this.previewingRecord = record;
    this.previewingRecordIndex = rowIndex;
  }

  @action pwlNextPage() {
    if (this.pwlPage < this.pwlTotalPages) {
      this.pwlPage++;
    }
  }

  @action pwlPrevPage() {
    if (this.pwlPage > 1) {
      this.pwlPage--;
    }
  }
}
