import Model, { attr, belongsTo } from '@ember-data/model';
import AgentTypeModel from './agent/type';
import InstitutionModel from './institution';
import { BelongsTo, DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['name', 'ctype', 'institution'];

export default class AgentModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @belongsTo('agent/type')
  declare ctype?: BelongsTo<AgentTypeModel>;

  @belongsTo('institution')
  declare institution?: BelongsTo<InstitutionModel>;

  @attr()
  declare htype?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    agent: AgentModel;
  }
}
