import { A } from '@ember/array';
import EmberObject, { action, computed, get, set, setProperties } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { dayjs } from 'cafelatte/libs/headless/prelude/datetime';
import { isNothing } from 'cafelatte/libs/headless/prelude/maybe';
import { Bean } from 'cafelatte/libs/headless/services/beanbag';
import CLAjaxService from 'cafelatte/services/ajax';
import saver from 'file-saver';
import config from '../../../config/environment';

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  @tracked errors: any = undefined;
  @tracked formElement: HTMLElement | undefined = undefined;

  @alias('model')
  bean!: Bean;

  @computed('bean')
  get parameters() {
    // Get the bean:
    const parameters = this.bean.parameters;

    // Define params:
    const params = EmberObject.create({});

    // Check:
    if (isEmpty(parameters) || isNothing(parameters)) {
      return params;
    }

    // If there are any defaults, add them:
    parameters.forEach((x: any) => {
      if (x.default) {
        if (x.type === 'choices') {
          set(params, x.name, x.default.value);
        } else if (x.type === 'multichoices') {
          set(
            params,
            x.name,
            x.default.map((x: any) => get(x, 'value'))
          );
          set(x, 'current', x.default);
        } else {
          set(params, x.name, x.default);
        }
      }
    });

    // Done, return:
    return params;
  }

  @action run() {
    // Get model parameters specification:
    const spec: any = get(this, 'bean.parameters');

    // Get current params:
    const params = this.parameters;

    // Get the requested data type:
    const datatype = get(this, 'bean.datatype');

    // Is it multipart?
    const multipart = get(this, 'bean.multipart') || false;

    // We need the full API address if it is not fully specified:
    let rurl: any = config.APP.API_BASEURL;
    if (!rurl.startsWith('http://') && !rurl.startsWith('https://')) {
      rurl = `${window.location.origin}/${config.APP.API_NAMESPACE}`;
    }

    const method = get(this, 'bean.method') || 'GET';

    // Define the ajax coniguration:
    const xconfig: any = {
      dataType: isEmpty(datatype) ? 'json' : datatype,
      method: method,
      headers: {
        'X-DECAF-APIURL': rurl,
        Authorization: `Token ${this.session.data.authenticated.token}`,
      },
    };

    // Define the payload data:
    if (multipart) {
      // Initialize the form data:
      const formData = new FormData();

      // Prepare form data:
      spec.forEach((x: any) => formData.append(x.name, params.get(x.name)));

      // Configure AJAX request:
      xconfig['data'] = formData;
      xconfig['contentType'] = false;
      xconfig['processData'] = false;
    } else {
      xconfig['data'] = isEmpty(spec) ? {} : params.getProperties(spec.map((x: any) => x.name));
    }

    // Binary?
    if (datatype === 'binary') {
      xconfig['xhr'] = () => {
        const myXhr = new XMLHttpRequest();
        myXhr.responseType = 'arraybuffer';
        return myXhr;
      };
    } else if (datatype === 'blob') {
      xconfig['xhr'] = () => {
        const myXhr = new XMLHttpRequest();
        myXhr.responseType = 'blob';
        return myXhr;
      };
    }

    // Run the bean:
    this.ajax
      // @ts-expect-error
      .raw(get(this, 'bean.endpoint'), xconfig)
      .then((response) => {
        // Get the data:
        const data: any = datatype === 'binary' ? response.payload : response.jqXHR.responseText;

        // Get the mimetype:
        const rtype = response.jqXHR.getResponseHeader('Content-Type');

        // Make sure that encoding descriptor is not included (quick and dirty implementation):
        const type = isEmpty(rtype)
          ? 'text/plain'
          : rtype.startsWith('application/json')
          ? 'application/json'
          : rtype.startsWith('text/html')
          ? 'text/html'
          : rtype;

        // Check the target:
        if (get(this, 'bean.target') === 'new' && (type === 'text/html' || type === 'text/plain')) {
          // Open the response payload in a new file.
          // @ts-expect-error
          window.open('', '_blank').document.write(data);
        } else if (get(this, 'bean.target') === 'download') {
          // Define the default filename:
          let filename = get(this, 'bean.filename');

          // Attempt to get the content disposition header:
          const disposition = response.jqXHR.getResponseHeader('Content-Disposition');

          // Attempt to get the filename from disposition:
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          // Save the file locally:
          // @ts-expect-error
          saver.saveAs(new Blob([data]), filename);
        } else {
          // Set stuff:
          // @ts-expect-error
          setProperties(this, { remoteData: data, remoteType: type });
        }
      })
      .catch((error) => {
        // Get field errors:
        const errors = error.payload;

        // Set it:
        set(
          this,
          'errors',
          A(
            Object.keys(errors).map((field) => {
              return {
                field,
                message: errors[field].join(' '),
              };
            })
          )
        );
      });
  }

  @action paramInstanceChanged(parameters: any, spec: any, instance: any) {
    set(parameters, spec.name, get(instance, spec.modelProperty));
    set(spec, 'temp', instance);
  }

  @action paramDateChanged(parameters: any, spec: any, instance: any) {
    set(parameters, spec.name, dayjs(instance).utc().format('YYYY-MM-DD'));
  }

  @action paramFileChanged(parameters: any, spec: any) {
    if (!this.formElement) {
      return;
    }
    // @ts-expect-error
    set(parameters, spec.name, this.formElement[spec.name].files[0]);
  }

  @action paramChoiceChanged(parameters: any, spec: any, instance: any) {
    set(parameters, spec.name, get(instance, 'value'));
    set(spec, 'current', instance);
  }

  @action formInserted(element: HTMLElement) {
    this.formElement = element;
  }

  @action paramMultiChoiceChanged(parameters: any, spec: any, instances: any) {
    set(
      parameters,
      spec.name,
      instances.map((x: any) => get(x, 'value'))
    );
    set(spec, 'current', instances);
  }
}
