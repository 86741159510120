import { Service } from '../-service';
import { Bean, BeanParameter, getBeanbagCatalogue } from './-internal';

// Export public symbols:
export { Bean, BeanParameter };

/**
 * Provides the barista beanbag service.
 */
export class BeanbagService extends Service {
  /**
   * Returns the beanbag catalogue.
   */
  getBeanbagCatalogue(url: string): Promise<Array<Bean>> {
    return getBeanbagCatalogue(this.client, url);
  }
}
