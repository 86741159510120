import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';

export default class extends CommonController {
  queryParams = ['page', 'page_size', 'search'];

  @tracked page = 1;
  @tracked page_size = 10;

  get tableOperations() {
    return mkCommonRowActions([
      { type: 'details', action: ({ record }) => this.router.transitionTo('shareclass.details', record.id) },
    ]);
  }
}
