import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DocumentModel from 'cafelatte/models/document';
import CLAjaxService from 'cafelatte/services/ajax';
import { AJAXOptions } from 'ember-ajax/-private/types';

interface Args {
  record: DocumentModel;
  modelctype: 'account' | 'portfolio' | 'resource' | undefined;
  modelident?: number;
  onSave?: Function;
  onError?: Function;
  onCancel?: Function;
  onDelete?: Function;
}

export default class extends Component<Args> {
  @service declare store: Store;
  @service declare ajax: CLAjaxService;
  @tracked form?: HTMLFormElement;

  @action registerElement(element: HTMLFormElement): void {
    this.form = element;
  }

  @action onFormSubmit(record: DocumentModel): void {
    if (this.form === undefined) {
      return;
    }

    // Construct the URL path:
    // TODO: Consider bare document uploads!
    let path = '';
    if (record.get('isNew')) {
      path = `/${this.args.modelctype}s/${this.args.modelident}/attachments/`;
    } else {
      path = `/documents/${record.id}/`;
    }

    // Get the initial form data:
    const data = new FormData(this.form);

    // Add fields:
    if (record.name !== undefined && !isEmpty(record.name)) {
      data.append('name', record.name);
    }
    if (record.external !== undefined && !isEmpty(record.external)) {
      data.append('external', record.external ? 'true' : 'false');
    }
    if (record.ctype !== undefined && !isEmpty(record.ctype)) {
      data.append('ctype', record.ctype);
    }
    if (record.category !== undefined && !isEmpty(record.category)) {
      data.append('category', record.category);
    }
    if (record.group !== undefined && !isEmpty(record.group)) {
      data.append('group', record.group);
    }
    if (record.date !== undefined && !isEmpty(record.date)) {
      data.append('date', record.date);
    }
    if (record.time !== undefined && !isEmpty(record.time)) {
      data.append('time', record.time);
    }
    if (record.summary !== undefined && !isEmpty(record.summary)) {
      data.append('summary', record.summary);
    }
    if (record.content !== undefined && !isEmpty(record.content)) {
      data.append('content', record.content);
    }
    data.append('extref', record.extref || '');

    // Prepare the request options:
    const options: AJAXOptions = {
      data: data,
      cache: false,
      contentType: false,
      processData: false,
      type: record.get('isNew') ? 'POST' : 'PUT',
    };

    // Send the request:
    this.ajax
      .request(path, options)
      .then((payload: Record<string, any>) => {
        this.store
          .findRecord('document', payload.id)
          .then((savedRecord: DocumentModel) => this.args.onSave?.(savedRecord));
      })
      .catch((error: any) => this.args.onError?.('Error while attempting to save the record', error));
  }

  @action onFormCancel(record: DocumentModel): void {
    const reverted = record.hasDirtyAttributes;
    record.rollbackAttributes();
    this.args.onCancel?.(record, reverted);
  }

  @action onFormDelete(record: DocumentModel): void {
    if (confirm('Are you sure you want to proceed with this operation?')) {
      record.destroyRecord().then(() => this.args.onDelete?.());
    }
  }
}
