import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { FundReportData } from 'cafelatte/libs/headless/services/consolidation-report/fund-report/-full';
import PortfolioModel from 'cafelatte/models/portfolio';

interface Args {
  data: FundReportData;
  portfolio: PortfolioModel;
}

export default class extends Component<Args> {
  @tracked _tab = '0';
}
