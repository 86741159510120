import Model, { attr } from '@ember-data/model';
import { DecafAuditFields, makeValidators } from './_internal/machinery';

const _fields = ['name', 'description'];

export default class StrategyModel extends Model.extend(DecafAuditFields, makeValidators(_fields)) {
  @attr()
  declare name?: string;

  @attr()
  declare description?: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    strategy: StrategyModel;
  }
}
