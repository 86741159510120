import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { uniqElements } from 'cafelatte/libs/headless/prelude/arrays';
import { SDate, SDateRange, dayjs } from 'cafelatte/libs/headless/prelude/datetime';
import { Maybe } from 'cafelatte/libs/headless/prelude/maybe';
import { SimpleChoices } from 'cafelatte/libs/headless/prelude/simple-choice';
import {
  AmountChoices,
  AmountType,
  BreakdownChoices,
  BreakdownType,
  VoucherTimelineItem,
  VoucherTimelineLabeledItem,
  VoucherTimelineQuery,
} from 'cafelatte/libs/headless/services/vouchers';
import { FlexTableCellAlignment, FlexTableSpec } from 'cafelatte/pods/components/x/flex-table';
import DecafService from 'cafelatte/services/decaf';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

interface TimelineRow {
  level: number;
  id: Maybe<Id>;
  label: string;
  parentId: Maybe<Id>;
  timeline: Record<string, CellValue>;
}

interface CellValue {
  level: number;
  breakdown1: Maybe<Id>;
  breakdown2: Maybe<Id>;
  year: number;
  month: number;
  settled: number;
  pending: number;
  total: number;
}

function mkYearMon(x: VoucherTimelineLabeledItem): string {
  return `${x.year}/${x.month < 10 ? '0' : ''}${x.month}`;
}

function mkRowKey(x: VoucherTimelineLabeledItem): string {
  return `${x.breakdown1Label}-${x.breakdown2Label}`;
}

function compileRecords(records: VoucherTimelineLabeledItem[]): [string[], TimelineRow[]] {
  // If empty, return:
  if (records.length == 0) {
    return [[], []];
  }

  // Get the timeline:
  const timeline = uniqElements(records.map(mkYearMon)).sort();

  // Declare rows:
  const rows: Record<string, TimelineRow> = {};

  // Populate rows:
  records.forEach((x) => {
    const key = mkRowKey(x);
    const yearmon = mkYearMon(x);

    if (!(key in rows)) {
      rows[key] = {
        level: 2,
        id: x.breakdown2,
        label: `${x.breakdown1Label || '#N/A'} » ${x.breakdown2Label || '#N/A'}`,
        parentId: x.breakdown1,
        timeline: {},
      };
    }

    // @ts-expect-error ts2532
    rows[key].timeline[yearmon] = {
      level: 2,
      breakdown1: x.breakdown1,
      breakdown2: x.breakdown2,
      year: x.year,
      month: x.month,
      settled: x.settled,
      pending: x.pending,
      total: x.total,
    };

    if (!(x.breakdown1Label in rows)) {
      rows[x.breakdown1Label] = {
        level: 1,
        id: x.breakdown1,
        label: x.breakdown1Label,
        parentId: undefined,
        timeline: {},
      };
    }

    // @ts-expect-error ts2532
    const existing = rows[x.breakdown1Label].timeline[yearmon] || { settled: 0, pending: 0, total: 0 };

    // @ts-expect-error ts2532
    rows[x.breakdown1Label].timeline[yearmon] = {
      level: 1,
      breakdown1: x.breakdown1,
      breakdown2: undefined,
      year: x.year,
      month: x.month,
      settled: x.settled + existing.settled,
      pending: x.pending + existing.pending,
      total: x.total + existing.total,
    };

    if (!('Total' in rows)) {
      rows['Total'] = {
        level: 1,
        id: undefined,
        label: 'Total',
        parentId: undefined,
        timeline: {},
      };
    }

    const existingTotal = rows['Total'].timeline[yearmon] || { settled: 0, pending: 0, total: 0 };

    rows['Total'].timeline[yearmon] = {
      level: 0,
      breakdown1: undefined,
      breakdown2: undefined,
      year: x.year,
      month: x.month,
      settled: x.settled + existingTotal.settled,
      pending: x.pending + existingTotal.pending,
      total: x.total + existingTotal.total,
    };
  });

  // Get row records:
  const rowRecords = Object.values(rows).sort((a, b) =>
    a.label == 'Total' ? 1 : b.label == 'Total' ? -1 : a.label.localeCompare(b.label)
  );

  // Done, return:
  return [timeline, rowRecords];
}

export default class extends CommonController {
  @service declare decaf: DecafService;

  queryParams = ['breakdown1', 'breakdown2', 'type', 'refccy', 'start', 'end'];

  @tracked breakdown1: BreakdownType = 'team';
  @tracked breakdown2: BreakdownType = 'institution';
  @tracked type: AmountType = 'gross';
  @tracked refccy = 'USD';
  @tracked start: Maybe<SDate> = undefined;
  @tracked end: Maybe<SDate> = undefined;
  @tracked what: 'settled' | 'pending' | 'total' = 'total';

  @tracked toggleBreakdowns = false;
  @tracked displaySubs = false;
  @tracked selected = undefined;

  choicesBreakdown = BreakdownChoices;

  choicesAmount = AmountChoices;

  choicesStatus: SimpleChoices<string> = [
    { value: 'settled', label: 'Settled' },
    { value: 'pending', label: 'Pending' },
    { value: 'total', label: 'Total' },
  ];

  @computed('start', 'end')
  get daterange() {
    return { start: this.start, end: this.end };
  }

  @computed('breakdown1', 'breakdown2', 'type', 'refccy', 'start', 'end')
  get query(): VoucherTimelineQuery {
    return {
      breakdown1: this.breakdown1,
      breakdown2: this.breakdown2,
      type: this.type,
      refccy: this.refccy,
      start: this.start,
      end: this.end,
    };
  }

  @computed('query')
  get timeline(): DS.PromiseArray<VoucherTimelineItem[]> {
    // @ts-expect-error
    return DS.PromiseArray.create({ promise: this.decaf.services.vouchers.timeline(this.query) });
  }

  @computed('timeline.[]')
  get compiledRecords() {
    return compileRecords((this.timeline.content as VoucherTimelineLabeledItem[] | undefined) || []);
  }

  @computed('compiledRecords.[]')
  get yearmons() {
    return this.compiledRecords[0];
  }

  @computed('compiledRecords.[]', 'toggleBreakdowns')
  get records() {
    return this.compiledRecords[1].filter((x) => (this.toggleBreakdowns ? true : x.level < 2));
  }

  @computed('yearmons')
  get spec(): FlexTableSpec<TimelineRow> {
    return {
      ident: 'cl-voucher-timeline',
      vfill: true,
      columns: [
        { key: 'level', hidden: true },
        { key: 'label', classes: [({ record }) => [`ps-${record.level ? (record.level - 1) * 4 : 0}`]] },
        ...this.yearmons.map((x) => ({
          key: x,
          // @ts-expect-error
          value: ({ record }) => record.timeline?.[x]?.[this.what] || 0,
          align: 'right' as FlexTableCellAlignment,
          component: '@number',
          options: { format: '0,0', colorize: true },
          // @ts-expect-error
          action: ({ record }) => {
            const cellValue: CellValue | undefined = record.timeline[x];

            if (!cellValue) {
              return;
            }

            // Get the first date of the month:
            const fdm = dayjs(x + '/01', 'YYYY/MM/DD');

            // Create query parametrs:
            const qp: any = {};
            qp['commitment__gte'] = fdm.format('YYYY-MM-DD');
            qp['commitment__lte'] = fdm.endOf('month').format('YYYY-MM-DD');
            qp['valueccy'] = this.refccy;
            qp['amounttype'] = this.type;
            qp['settlement__isnull'] = this.what == 'settled' ? 'False' : this.what == 'pending' ? 'True' : '';
            if (cellValue.level == 0) {
              // Nothing to add.
            } else if (cellValue.level == 1) {
              qp[this.breakdown1] = cellValue.breakdown1;
            } else if (cellValue.level == 2) {
              qp[this.breakdown1] = cellValue.breakdown1;
              qp[this.breakdown2] = cellValue.breakdown2;
            }
            // Open the url:
            window.open(this.router.urlFor('voucher.index', { queryParams: qp }));
          },
        })),
        {
          key: 'total',
          value: ({ record }) =>
            Object.values(record.timeline || {})
              .map((x) => x[this.what])
              .reduce((x, u) => x + u, 0),
          align: 'right',
          component: '@number',
          options: { format: '0,0', colorize: true },
        },
      ],
    };
  }

  @computed('records.[]', 'what')
  get plotData() {
    const total = this.records.filter((x) => x.label == 'Total')?.[0]?.timeline || {};
    const data = Object.entries(total).map(([x, y]) => [x, y[this.what]]);
    return [{ x: data.map(([x, _y]) => x), y: data.map(([_x, y]) => y), type: 'bar' }];
  }

  plotLayout = {
    title: 'Vouchers Summary Timeline',
    xaxis: {
      title: 'Timeline',
    },
    yaxis: {
      title: 'Total',
    },
    margin: { b: 100 },
  };

  @action onDateRangeChanged(value: SDateRange) {
    this.start = value.start;
    this.end = value.end;
  }
}
