import { action } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { isEmpty } from '@ember/utils';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import APIKeyModel from 'cafelatte/models/apikey';
import { FlexTableRowAction, mkCommonRowActions } from '../components/x/flex-table';

export default class extends ControllerWithCurrentRecord<APIKeyModel> {
  createNewRecord(): APIKeyModel {
    return this.store.createRecord('apikey');
  }

  get tableOperations(): FlexTableRowAction<APIKeyModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.doResetCurrentRecordRefreshModel },
    ]);
  }

  @action onSave(record: APIKeyModel) {
    const key = record.get('id');
    const secret = record.get('secret');
    record.set('secret', undefined);

    if (!isEmpty(secret)) {
      this.flashMessages.danger(
        htmlSafe(
          `Note the following secret as you won't ever see it again: <br/><br/><strong>Key ID:</strong> ${key}<br/><strong>Secret:</strong> ${secret}`
        ),
        {
          priority: 1000,
          sticky: true,
          destroyOnClick: false,
        }
      );
    }

    this.doResetCurrentRecordRefreshModel();
  }
}
