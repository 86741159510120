import { action } from '@ember/object';
import { cleanParams, CommonRoute, compileQueryParams, handle404OnNonExistingPage } from 'cafelatte/libs/embered';

export default class extends CommonRoute {
  queryParams = compileQueryParams([
    'page',
    'page_size',
    'commitment__gte',
    'commitment__lte',
    'ctype',
    'currency',
    'sharing',
    'kickback',
    'amount__gte',
    'amount__lte',
    'gross__gte',
    'gross__lte',
    'net__gte',
    'net__lte',
    'account',
    'portfolio',
    'team',
    'institution',
    'rebate_institution',
    'trade',
    'trade__ctype',
    'trade__resmain',
    'settlement__isnull',
    'institution__isnull',
    'rebate_institution__isnull',
    'team__isnull',
    'settlement',
    'settlement__invoice__icontains',
    'notes__icontains',
    'remarks__icontains',
    'amounttype',
    'valueccy',
    'breakdown1',
    'breakdown2',
    'pendings',
    'trade__resmain__ctype',
    'portfolio__groups',
    'search',
  ]);

  model(params: Record<string, string | undefined>) {
    return this.store.query('voucher', cleanParams(params));
  }

  @action error(error: any): boolean | void {
    return handle404OnNonExistingPage(this, error);
  }
}
