import { A } from '@ember/array';
import EmberObject, { action, computed, notifyPropertyChange } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { CommonController } from 'cafelatte/libs/embered';
import { Id } from 'cafelatte/libs/headless/commons/types';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { FlexTableSpec, mkNumberColumn } from 'cafelatte/pods/components/x/flex-table';
import CLAjaxService from 'cafelatte/services/ajax';

interface ConstituentRow {
  level: number;
  artifact?: Id;
  symbol: string;
  name?: string;
  currency?: string;
  type: string;
  subtype?: string;
  value: number;
  ratio: number;
}

export default class extends CommonController {
  @service declare ajax: CLAjaxService;

  queryParams = ['c', 'i', 'ccy', 'date', 'type', 'p'];

  @tracked c = 'portfolio';
  @tracked i = '';
  @tracked ccy = 'USD';
  @tracked date = today();
  @tracked type = 'commitment';
  @tracked p?: string = undefined;

  get selections() {
    return (this?.i?.split(',') || []).filter((x: any) => x);
  }

  set selections(x: any) {
    this.i = (x || []).filter((x: any) => x).join(',');
  }

  @computed('c')
  get instanceSelector() {
    if (this.c == 'account') {
      return 'cl-rsel-account';
    } else if (this.c == 'portfolio') {
      return 'cl-rsel-portfolio';
    } else if (this.c == 'custodian') {
      return 'cl-rsel-institution';
    } else if (this.c == 'team') {
      return 'cl-rsel-team';
    } else if (this.c == 'portfoliogroup') {
      return 'cl-rsel-portfoliogroup';
    }
    return undefined;
  }

  @computed()
  get riskprofileOptions() {
    const retval = A<string | undefined>([]);

    this.store.findAll('riskprofile').then((d) => {
      retval.pushObjects(d.map((d) => d.get('name')));
    });
    return retval;
  }

  @computed('i', 'ccy', 'date', 'type', 'p')
  get compliance(): any {
    // Define the return value:
    const retval = EmberObject.create({ data: undefined, rawdata: undefined });

    // Do we have something to consolidate?
    if (isEmpty(this.i) || isEmpty(this.p)) {
      // Nope, return empty:
      return retval;
    }

    // Create the parameters:
    const params = {
      c: this.c,
      i: (this.i || '').split(',').filter((x: any) => x),
      ccy: this.ccy,
      type: this.type,
      date: this.date,
      profile: this.p,
    };

    // Attempt to retrieve the consolidation data:
    this.ajax.request('/compliance/report/', { data: params, traditional: true }).then(
      (x: any) => {
        // Set the raw data:
        retval.set('rawdata', x);

        // Set the interpreted data:
        //retval.set("data", compileData(x));
      },
      (y: any) => y
    );

    // Done, return the promise bucket:
    return retval;
  }

  @computed('compliance.rawdata.profile.name')
  get checksData() {
    // Attempt to get the group data:
    const groups: any[] = this.compliance?.rawdata?.groups || [];

    // Return if it is empty:
    if (groups.length == 0) {
      return groups;
    }

    // Attempt to compute the aggregate, add to the data and return:
    return [
      ...groups,
      {
        passed: this.compliance.rawdata.passed,
        check_min: this.compliance.rawdata.check_min,
        check_max: this.compliance.rawdata.check_max,
        total: this.compliance.rawdata.groups_total,
        ratio: this.compliance.rawdata.groups_ratio,
        constraint: {
          name: 'Aggregate',
          lmin: this.compliance.rawdata.profile.lmin,
          lmax: this.compliance.rawdata.profile.lmax,
          type: { name: undefined },
          ctype: { name: this.compliance.rawdata.profile.type.name },
          order: undefined,
          fallback: undefined,
        },
      },
    ];
  }

  get checksSpec(): FlexTableSpec<any> {
    return {
      ident: 'cl-compliance-quick-checks',
      vfill: false,
      columns: [
        { key: 'constraint.name', label: 'Class' },
        { key: 'passed', label: 'Passed?', align: 'center', component: '@bool' },
        { key: 'check_min', align: 'center', component: '@bool' },
        { key: 'check_max', align: 'center', component: '@bool' },
        mkNumberColumn({ key: 'total', options: { format: '0,0' } }),
        mkNumberColumn({ key: 'ratio', options: { format: '0,0.00%' } }),
        mkNumberColumn({ key: 'constraint.lmin', label: 'Minimum', options: { format: '0,0.00%' } }),
        mkNumberColumn({ key: 'constraint.lmax', label: 'Maximum', options: { format: '0,0.00%' } }),
        { key: 'constraint.type.name', label: 'Class Value Type' },
        { key: 'constraint.ctype.name', label: 'Consolidation Value Type' },
        { key: 'constraint.order', label: 'Order', align: 'center' },
        { key: 'constraint.fallback', label: 'Order', align: 'center', component: '@bool' },
      ],
    };
  }

  @computed('compliance.rawdata.profile.name')
  get constituentsData(): ConstituentRow[] {
    // Attempt to get the group data:
    const groups: any[] = this.compliance?.rawdata?.groups || [];

    // Return if it is empty:
    if (groups.length == 0) {
      return groups;
    }

    // Build data and return:
    return groups.reduce((acc, g) => {
      return [
        ...acc,
        {
          level: 0,
          artifact: undefined,
          symbol: g.constraint.name,
          name: undefined,
          currency: undefined,
          type: undefined,
          subtype: undefined,
          value: g.total,
          ratio: 1,
        },
        ...(g.items || []).map((i: any) => ({
          level: 1,
          artifact: i.id,
          symbol: i.symbol,
          name: i.name,
          currency: i.ccy,
          type: i.type.name,
          subtype: i.stype,
          value: i.value,
          ratio: i.ratio,
        })),
      ];
    }, []);
  }

  get constituentsSpec(): FlexTableSpec<ConstituentRow> {
    return {
      ident: 'cl-compliance-quick-constituents',
      vfill: false,
      rowClasses: [({ record }) => (record.level == 0 ? ['table-secondary'] : [])],
      columns: [
        { key: 'level', hidden: true },
        { key: 'symbol' },
        { key: 'name', component: '@link', options: { route: 'resource.details', modelPath: 'artifact' } },
        { key: 'currency', align: 'center' },
        { key: 'type' },
        { key: 'subtype' },
        mkNumberColumn({ key: 'value', options: { format: '0,0' } }),
        mkNumberColumn({ key: 'ratio', options: { format: '0,0.00%' } }),
      ],
    };
  }

  @action refresh() {
    notifyPropertyChange(this, 'ccy');
  }

  @action triggerEmail() {
    if (!confirm('This will run all compliance report and send via email to related parties. Continue?')) {
      return;
    }
    this.ajax.request('jobs/reporting/compliance/', {}).then(
      (data) => this.flashMessages.success('Job has been queued successfully (' + data.id + ').'),
      (error) => {
        console.error(error);
        this.flashMessages.danger('An error has occured. Contact your systems administrator.');
      }
    );
  }
}
