import EmberObject, { action, computed, get, notifyPropertyChange } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { ControllerWithCurrentRecord } from 'cafelatte/libs/embered';
import { today } from 'cafelatte/libs/headless/prelude/datetime';
import { sum } from 'cafelatte/libs/headless/prelude/numeric';
import ActionModel from 'cafelatte/models/trade';
import { FlexTableRowAction, FlexTableSpec, mkCommonRowActions } from 'cafelatte/pods/components/x/flex-table';
import Decimal from 'decimal.js';
import { all } from 'rsvp';

export default class extends ControllerWithCurrentRecord<ActionModel> {
  @tracked page = 1;
  @tracked page_size = 20;

  createNewRecord(): ActionModel {
    // We will create an investment, but to aid convenience, we will populate as many fields as possible:
    const pctype = this.store.findRecord('trade/type', 35);
    const pshrcls = this.store.query('shareclass', { portfolio: this.model.id, page_size: 2 });
    const paccnts = this.store.query('account', { portfolio: this.model.id, page_size: 2 });
    const pagents = this.store.query('agent', { portfolio: this.model.id, page_size: 2 });

    const record = this.store.createRecord('trade', { commitment: today() });

    // Get all in a single promise:
    all([pctype, pshrcls, paccnts, pagents]).then(([ctype, shrcls, accnts, agents]) => {
      // Set type:
      record.set('ctype', ctype);

      // Check share classes and set if there is only one:
      if (shrcls.length === 1) {
        // Set the share class first.
        record.set('shrcls', shrcls.firstObject);

        // Now, we can set the main instrument:
        this.store
          .query('resource', { symbol: record.get('shrcls.currency') })
          .then((resources) => record.set('resmain', resources.firstObject));
      }

      // Check accounts and set if there is only one:
      if (accnts.length === 1) {
        record.set('accmain', accnts.firstObject);
      }

      // Check agents and set if there is only one:
      if (agents.length === 1) {
        record.set('agent', agents.firstObject);
      }
    });

    return record;
  }

  @computed('model.id', 'page', 'page_size')
  get records() {
    return this.store.query('trade', {
      ctype: 35,
      accmain__portfolio: this.model.id,
      page_size: this.page_size,
      page: this.page,
    });
  }

  /**
   * Returns a list of total-investment-by-investor records for the portfolio:
   */
  @computed('model.id')
  get summaryInner() {
    // Initialize the return value:
    const retval = EmberObject.create({ content: undefined });

    // Type definition for records:
    type IInvestment = {
      investor: string | number;
      quantity: Decimal;
    };

    this.store.query('trade', { ctype: 35, accmain__portfolio: this.model.id, page_size: -1 }).then((items) => {
      // First, get an array of investor ID and share count inflow/outflow
      // (IInvestment) records:
      const investorShareCountActions: IInvestment[] = items.map((x) => ({
        investor: get(x, 'agent.id') as string | number,
        quantity: new Decimal(get(x, 'sharecount')),
      }));

      // Initialize investments-by-investor database:
      const investmentsByInvestor: Record<string | number, Decimal> = investorShareCountActions.reduce(
        (acc, cur) => ({ ...acc, [cur.investor]: (acc[cur.investor] || new Decimal(0)).add(cur.quantity) }),
        {} as Record<string | number, Decimal>
      );

      // Set the return value contents:
      retval.setProperties({
        content: Object.entries(investmentsByInvestor).map(([investor, quantity]: [string | number, Decimal]) => ({
          investor,
          quantity,
        })),
      });
    });

    // Return the return value wrapping the list of records:
    return retval;
  }

  // TODO: Refactor this ugliness!
  @computed('summaryInner.content')
  get summary() {
    if (isEmpty(this.summaryInner.content) || !this.summaryInner.content) {
      return [];
    }

    // Type definition for records:
    type IInvestment = {
      investor: string | number;
      quantity: Decimal;
    };

    const records = this.summaryInner.content as IInvestment[];

    return this.store
      .query('agent', { page_size: -1, id__in: records.map(({ investor }) => investor).join(',') })
      .then((x) => {
        const investorsDatabase = x.reduce((db: any, i: any) => ({ ...db, [i.id]: i.name }), {});

        const retval = records.map(({ investor, quantity }) => ({
          investor: investorsDatabase[investor],
          quantity: quantity.toNumber(),
        }));

        retval.pushObject({
          investor: 'Total',
          quantity: sum(retval, (x) => x.quantity) || 0,
        });

        return retval;
      });
  }

  get summarySpec(): FlexTableSpec<{ investor: string; quantity: number }> {
    return {
      ident: 'cl-fund-investment-summary',
      vfill: true,
      rowClasses: [(x) => (x.idxrow == x.records.length - 1 ? ['table-info'] : [])],
      columns: [
        { key: 'investor' },
        { key: 'quantity', label: 'Shares', align: 'right', component: '@number', options: { format: '0,0.00[0000]' } },
      ],
    };
  }

  get operations(): FlexTableRowAction<ActionModel>[] {
    return mkCommonRowActions([
      { type: 'edit', action: ({ record }) => this.doSetCurrentRecord(record) },
      { type: 'delete', onDelete: this.onReset },
    ]);
  }

  @action onReset() {
    this.doResetCurrentRecord();
    notifyPropertyChange(this, 'records');
  }
}
