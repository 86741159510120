import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { CommonController } from 'cafelatte/libs/embered';
import ActionModel from 'cafelatte/models/trade';

export default class extends CommonController {
  @action onSave(action: ActionModel) {
    this.router.transitionTo('trade.details', action.id);
  }

  @action onError() {
    console.error('error');
  }

  // TODO: Redirect?
  @action onCancel() {
    console.log('cancelled');
  }

  @action onDelete() {
    this.router.transitionTo('resource');
  }

  @action onActionTypeMigration() {
    window.location.reload();
  }

  @action copy(trade: any, retainMainAccount: any) {
    // Get the JSON representation of the trade:
    const tradeJSON = trade.toJSON();

    // Iterate over all properties and set the empty ones to '':
    Object.keys(tradeJSON).forEach((prop) => {
      if (isEmpty(tradeJSON[prop])) {
        tradeJSON[prop] = '';
      }
    });

    // We are not done yet. We will clean up some resources. Define which ones to clean:
    const toBeCleaned = [
      'id',
      'created',
      'updated',
      'creator',
      'updater',
      'guid',
      'cflag',
      'tags',
      'isApproved',
      'isLocked',
      'type',
      'shrcls',
      'agent',
      'accaltn',
      'acccntr',
      'acccomp',
      'accintr',
      'accprin',
      'accundr',
    ];

    // Are we retaining the main account:
    if (!retainMainAccount) {
      toBeCleaned.push('accmain');
    }

    // Not done yet, clean some items:
    toBeCleaned.forEach((prop) => {
      delete tradeJSON[prop];
    });

    // Get the URL:
    const url = this.router.urlFor('trade.details', '__new__', {
      queryParams: tradeJSON,
    });

    // Open in new window:
    window.open(url);
  }
}
