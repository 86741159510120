import ApplicationAdapter from '../application';

// @ts-expect-error (TS2339)
export default class AgentType extends ApplicationAdapter.extend({}) {
  pathForType(): string {
    return 'agents/types';
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'agent/type': AgentType;
  }
}
