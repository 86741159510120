import Store from '@ember-data/store';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { Id } from 'cafelatte/libs/headless/commons/types';

/**
 * Component arguments definition.
 */
interface Args {
  recordModel: string;
  recordID: Id;
  options?: any;
}

/**
 * Provides a component which consumes a record model name and a record ID and yields the record and record loading status.
 */
export default class extends Component<Args> {
  /**
   * Store service.
   */
  @service declare store: Store;

  /**
   * Indicates if we can attempt to find the record.
   */
  @computed('recordModel', 'recordID')
  get _canAttempt(): boolean {
    return !(isEmpty(this.args.recordModel) || isEmpty(this.args.recordID));
  }

  /**
   * Record as retrieved from the store, if successful.
   */
  @computed('_canAttempt', 'options')
  get record(): any {
    return this._canAttempt
      ? this.store.findRecord(this.args.recordModel, this.args.recordID, this.args.options)
      : null;
  }
}
