import { tagName } from '@ember-decorators/component';
import { A } from '@ember/array';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import CLAjaxService from 'cafelatte/services/ajax';
// @ts-expect-error
import Control from 'ember-bootstrap/components/bs-form/element/control';
// @ts-expect-error
import layout from './template';

@tagName('')
export default class extends Control {
  @service declare ajax: CLAjaxService;

  layout = layout;

  @computed()
  get daycounts() {
    // Define the return value:
    const retval = A([]);

    // Attempt to get all daycounts and populate the return value:
    this.ajax.request('daycounts/').then((dccs: any) => {
      retval.pushObjects(dccs.map((x: any) => x.name));
    });

    // Done, return:
    return retval;
  }
}
